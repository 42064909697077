import {KTIcon} from '../../../_metronic/helpers'
import { UsersListFilter } from '../../modules/apps/user-management/users-list/components/header/UsersListFilter';
import { Modal } from 'react-bootstrap'; 
import { useState } from 'react';

const PreCRMListToolbar = () => {

  interface StaticProgressBarProps {
    progress: number; // Asegúrate de recibir un número para el progreso
  }


  // estado para controlar la visibilidad del modal
  const [showModal, setShowModal] = useState(false);

  // Función para cerrar el modal y recargar la tabla
  const openModal = () => {
    
    setShowModal(true);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    
    setShowModal(false);
  };

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      
       {/* Barra de búsqueda */}
       <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Buscar"
        />
        <button className="btn btn-outline-secondary" type="button">
          Buscar
        </button>
      </div>

      <UsersListFilter />

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3'>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={openModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Carga Manual
      </button>
      {/* end::Add user */}

      {/* Estructura del modal */}
      <Modal scrollable centered show={showModal} onHide={closeModal} className='modal-md'>
        <Modal.Header>
          <Modal.Title>
            <h1>Carga Manual</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Pasamos los datos del vendedor al formulario modal */}
          {/* <SellerEditModalForm/> */}
          {/* Botones */}
          <div className="d-flex justify-content-between mb-9">
            <button className='btn btn-sm btn-primary'>
              Descargar Layout
            </button>
            <button className='btn btn-sm btn-primary'>
              Cargar Base
            </button>
          </div>

        {/* Barra de progreso */}
        <div className="progress w-100">
            <div className="progress-bar" role="progressbar" style={{ width: '70%' }} aria-valuenow={70} aria-valuemin={0} aria-valuemax={100}>
              70%
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-sm btn-secondary' onClick={closeModal}>
            Cancelar
          </button>
          <button
            className="btn btn-sm btn-primary"
            type="submit"
          >
            Carga
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export {PreCRMListToolbar}
