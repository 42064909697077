// Importamos hooks de react que usaremos para este componente
import { useContext } from "react";
// Importamos componente Modal de la libreria de bootstrap para react
import { Modal } from "react-bootstrap";

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from "../../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------

// Importamos la funcion sessionExpired para vencimiento de sesion
import { sessionExpired } from "../../../utils/functions/_Functions";

// ---------------------------------------------------------------------------------------

// Importamos funcion roleMilestoneDelete para conexion con la API de eliminacion de hito por rol
import { roleMilestoneDelete } from "../services/_RolesMilestoneDeleteApi";

// Iniciamos con la construccion del componente ModalDeleteMilestoneSingle
function ModalDeleteMilestoneSingle ({
    closeModalDelete, 
    showModalDelete, 
    selectedMilestoneIndex, 
    rolId, 
    rolName,
    getMilestoneRegister, 
    alertInfo, 
    setAlertInfo

}) {
 
    //Algunas variables desde el usercontext
    const { token } = useContext(UserContext);
    const userToken = token;

    // Funcion para eliminar el hito seleccionado
    const handleFormSubmit = async () => {

        try {

            const { data: jsonData, code, msg } = await roleMilestoneDelete(rolId, selectedMilestoneIndex, userToken);

            if ( code === 401 && msg === 'Token no valido' ) { 

                sessionExpired('Session ha expirado');
                return;

            } else {

               setAlertInfo({
                text: code === 202 ? 'El hito se elimino correctamente' : 'Hubo algun error al eliminar el hito',
                variant: code === 202 ? 'success' : 'danger',
               });

               setTimeout(() => {
                setAlertInfo({
                    text: '',
                    variant: '',
                });
                closeModalDelete();
                getMilestoneRegister(rolId);
               }, 3000);

            }

        } catch (error) {

            console.log(error);

        }

    }

    return (

        <>
            <Modal
                show={showModalDelete}
                onHide={closeModalDelete}
            >
                <Modal.Header
                    closeButton
                    onClick={() => {
                        closeModalDelete();
                    }}
                >
                    <Modal.Title>
                        <h1>Eliminar hito</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='px-20'> 
                        <div 
                        className="alert alert-danger d-flex align-items-center justify-content-center text-center" 
                        role='alert' 
                        > 
                        <div>Estas a punto de eliminar un hito del rol <p className="text-bold">{rolName}</p></div> 
                        </div> 
                    </div> 
                </Modal.Body>
                {alertInfo.text && ( 
                <div className='px-20'> 
                    <div 
                    className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`} 
                    role='alert' 
                    > 
                    <div>{alertInfo.text}</div> 
                    </div> 
                </div> 
                )}
                <Modal.Footer>
                    <button
                        className="btn btn-sm btn-secondary"
                        type="button"
                        onClick={() => {
                            closeModalDelete();
                        }}
                    >
                        Cancelar
                    </button>

                    <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        onClick={() => {
                            handleFormSubmit();
                        }}
                    >
                        Continuar
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export {ModalDeleteMilestoneSingle};