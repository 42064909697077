import { apiDragon, DragonMainApi, EPRolesStatusFalse, EPRolesStatusTrue } from "../../../utils/constants/_Constants";

import { apiGetDeletePetitions, apiPetitions } from "../../../../_metronic/helpers/apis";

export async function rolesStatusFalse(userToken: string, rolId: string) {

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPRolesStatusFalse+rolId}`, {

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },
        
        'delete');

        const jsonDataRoleList = data;

        console.log(jsonDataRoleList)

        return jsonDataRoleList;

    } catch (error) {

        throw error;

    }

}

export async function rolesStatusTrue(userToken: string, rolId: string) {

    try {

        const data = await apiPetitions(apiDragon, `${DragonMainApi+EPRolesStatusTrue+rolId}`, {

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },
        
        'put');

        const jsonDataRoleList = data;

        console.log(jsonDataRoleList)

        return jsonDataRoleList;

    } catch (error) {

        throw error;

    }

}
