// Importamos hooks de react
import React, {useEffect, useState} from 'react'

interface ModalActiveUnactiveProductProps {
  product: any
}

export const ModalActiveUnactiveProduct: React.FC<ModalActiveUnactiveProductProps> = ({
  product,
}) => {
  const [Body, setBody] = useState<any>()

  console.log(product);

  const handleShowBody = () => {
    if (product.status === 1) {
      setBody(
        <div>
          <div className='alert alert-dismissible bg-light-warning border border-warning mb-4'>
            <div className='d-flex flex-column flex-sm-row p-3'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-exclamation-triangle-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
                </svg>
              </span>
              <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
                <h5 className='mb-1'>Desactivar producto</h5>
                <span className='text-dark'>
                  <p>¿Estás seguro de desactivar el producto: {product.name}</p>
                  <p>¿Con el Id: {product.pro_id}?</p>
                  <p>Se verán afectados todas las campañas correspondientes a este producto.</p>
                  <p>En dado caso, menciona el motivo del cambio y confirma</p>
                </span>
              </div>
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Motivo deactivación producto</label>
            <select className='form-select form-select-lg form-select-solid mb-4'>
              <option value='0'>
                --Selecciona el motivo por el cual desactivaras este producto--
              </option>
              <option value='1'>
                El producto no está generando el retorno de inversión esperado.
              </option>
              <option value='2'>Cambios en la estrategia de marketing de la empresa.</option>
              <option value='3'>El producto ha alcanzado el final de su ciclo de vida útil.</option>
              <option value='4'>
                El producto ha sido reemplazado por una versión mejorada o una alternativa más
                efectiva.
              </option>
              <option value='5'>
                La demanda del mercado para el producto ha disminuido significativamente.
              </option>
            </select>
            {/* <input
              className='form-control form-control-solid mb-4'
              placeholder='Motivo de desactivacion del producto'
            />
            <textarea
              value='Ingresa el motivo por el cual quieres desactivar este producto...'
              className='form-control form-control-solid'
            /> */}
          </div>
        </div>
      )
    } else {
      setBody(
        <div>
          <div className='alert alert-dismissible bg-light-warning border border-warning mb-4'>
            <div className='d-flex flex-column flex-sm-row p-3'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-exclamation-triangle-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
                </svg>
              </span>
              <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
                <h5 className='mb-1'>Activar producto</h5>
                <span className='text-dark'>
                  <p>¿Estás seguro de activar el producto: {product.name}</p>
                  <p>Se verán afectados todas las campañas correspondientes a este producto.</p>
                </span>
              </div>
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-4'>Motivo reactivacion producto</label>
            <select className='form-select form-select-lg form-select-solid mb-4'>
              <option value='0'>
                --Selecciona el motivo por el cual reactivaras este producto--
              </option>
              <option value='1'>
                El producto no está generando el retorno de inversión esperado.
              </option>
              <option value='2'>Cambios en la estrategia de marketing de la empresa.</option>
              <option value='3'>El producto ha alcanzado el final de su ciclo de vida útil.</option>
              <option value='4'>
                El producto ha sido reemplazado por una versión mejorada o una alternativa más
                efectiva.
              </option>
              <option value='5'>
                La demanda del mercado para el producto ha disminuido significativamente.
              </option>
            </select>
            {/* <input
              className='form-control form-control-solid mb-4'
              placeholder='Motivo de reactivacion del producto'
            />
            <textarea
              value='Ingresa el motivo por el cual quieres reactivar este producto...'
              className='form-control form-control-solid'
            /> */}
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    handleShowBody()
  }, [])

  return (
    <>
      <div>{Body}</div>
    </>
  )
}
