// Importamos constantes para realizar la peticion a la API de campañas
import { DragonMainApi, EPCampaignGeneralEdit, EPCampaignConnectEdit, EPCampaignNotifyEdit, apiDragon } from "../../../utils/constants/_Constants";

// ----------------------------------------------------------------------------------------

// Importamos funcion para obtener codigos de respuesta del servidor
import { apiPetitions } from "../../../../_metronic/helpers/apis";

// Funcion para realizar la peticion a la API de edicion de campaña en la tab general
export async function campaignGeneralInfoEdit(status: boolean, quality: boolean, cam_role:string, start_hour: string, end_hour: string, days: string[], shift: boolean, roleId: string, campaign_id: string, userToken: string) {

    try {

        const data = await apiPetitions(apiDragon,`${DragonMainApi+EPCampaignGeneralEdit+campaign_id}`, {
            status,
            quality,
            cam_role,
            start_hour,
            end_hour,
            days,
            shift,
            roleId

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },
        
        'put');

        const jsonDataCampaignGralInfoEdit = data;

        return jsonDataCampaignGralInfoEdit;

    } catch (error) {
        throw error;
    }

}

// Funcion para realizar la peticion a la API de edicion de campaña en la tab connectivity
export async function campaignConnectInfoEdit(mail_template: string, wab_bot: string, crm_id: string, grade_options: any, userToken: string, campaign_id:string){

    try {

        const data = await apiPetitions(apiDragon,`${DragonMainApi+EPCampaignConnectEdit+campaign_id}`, {
            mail_template,
            wab_bot,
            crm_id,
            grade_options,

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },
        
        'put');

        const jsonDataCampaignConnectEdit = data;

        return jsonDataCampaignConnectEdit;

    } catch (error) {
        throw error;
    }

}

// Funcion para realizar la peticion a la API de edicion de campaña en la tab notify
export async function campaignNotifyInfoEdit(notify: string[], call: string, cam_manuals: boolean, userToken: string, campaign_id: string) {

    try {

        const data = await apiPetitions(apiDragon,`${DragonMainApi+EPCampaignNotifyEdit+campaign_id}`, {
            notify,
            call,
            cam_manuals,

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },
        
        'put');

        const jsonDataCampaignNotifyEdit = data;

        return jsonDataCampaignNotifyEdit;

    } catch (error) {
        throw(error)
    }

}