import {useEffect} from 'react'
import {TwlioServicesMainTable} from './tables/TwlioServicesMainTable'
import {setPageTitle} from '../../../utils/functions/_Functions'
import {useIntl} from 'react-intl'
import {DragonCem} from '../../../utils/constants/_Constants'

export const TwlioServices = () => {
  const intl = useIntl()
  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    // setPageTitle(`${intl.formatMessage({id: 'TWLIO_SERVICES.TITLE'})} | ${DragonCem}`)
    setPageTitle(`Servicios de Twlio | ${DragonCem}`)
  }, [])

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2'>Twlio services</h1>
            </div>

            <p>
              To start conversationes with WhatsApp users, you must use an approved sender and the
              outbound message must follow a message template. Learn more about templates .
            </p>

            <div className='card card-custom'>
              <TwlioServicesMainTable />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
