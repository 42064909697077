// Importamos hooks de react que usaremos para este componente
import { useEffect, useState } from "react";

// ---------------------------------------------------------------------------------------

// Importamos pluggins de la libreria de FULLCALENDAR
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

// ---------------------------------------------------------------------------------------

// Importamos el componente ModalNewMilestoneSingle para la creacion de un nuevo hito
import { ModalNewMilestoneSingle } from "./ModalNewMilestoneSingle";
// Importamos el componente ModalEditMilestoneSingle para la edicion de un hito
import { ModalEditMilestoneSingle } from "./ModalEditMilestoneSingle";

// ---------------------------------------------------------------------------------------

// Importamos las interfaces de roles que usaremos en este componente
import { MilestoneInfo, FormDataNewMilestone, FormDataEditMilestone, UsersOrGroups } from "../interfaces/rolesTypes";
// Importamos las interfaces globales para este componente
import { AlertInfoPerInput } from "../../../utils/interfaces/_InterfacesTypes";

import '../../../utils/assets/css/styles.css';

// Iniciamos construccion del componente ModalRolView y recibimos props
function ModalRolView({milestonesArray, selectableState, selectMirrorState, editableState, resizableState, selectedRoleStatus, rolId, rolName, getMilestoneRegister, alertInfo, setAlertInfo}) {

    // console.log(selectableState);
    // console.log(selectMirrorState);
    // console.log(editableState);
    // console.log(resizableState);
    // console.log('status: ', selectedRoleStatus);

    /* Definimos una variable que usaremos para guardar 
    la fecha inicial (en formato de dia ej. domingo = 7) 
    de un hito cuando iniciamos la accion de moverlo */
    let dropOriginalStartDay: number | undefined;

    // Constante para mostrar u ocultar el modal de creación de Hito
    const [showModalNew, setShowModalNew] = useState<boolean>(false);

    // Constante para mostrar u ocultar el modal de edicion de Hito
    const [showModalEdit, setShowModalEdit] = useState<boolean>(false);

    // Constante para mostrar y ocultar el modal de eliminacion de hito
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);

    /* Constante para guardar la información del evento seleccionado en el calendario y
    que usaremos en el modal de creación de hito */
    const [selectedInfo, setSelectedInfo] = useState<MilestoneInfo | string>(); 

    // Constante para guardar el objeto 'calendar' para usarlo en el modal de creacion de hito
    const [selectedCalendar, setSelectedCalendar] = useState<any>();

    // Constante para guardar el index del hito seleccionado
    const [selectedMilestoneIndex, setSelectedMilestoneIndex] = useState<string>('');

    // Constantes para guardar las horas de inicio y fin en formato de 24 horas
    const [startHour24, setStartHour24] = useState<string | undefined | null>();
    const [endHour24, setEndHour24] = useState<string | undefined | null>();

    // Constante para guardar los elementos (usuarios o grupos) que se pintaran en el modal de edicion para los switch
    const [usersOrGroupsForSwitch, setUsersOrGroupsForSwitch] = useState<UsersOrGroups[]>([]);

    // Constante para manejo de visibilidad de boton de enviar en modal de edicion
    const [buttonSubmitState, setButtonSubmitState] = useState<boolean>(false);

    //Variables con el estado de la alerta da confirmacion o error por input
    const [alertInfoInput, setAlertInfoInput] = useState<AlertInfoPerInput>({
        text: '',
        variant: '',
        inputName: '',
    });

    // Definimos estado inicial para los campos del formulario de nuevo hito
    const initialFormNewMilestone: FormDataNewMilestone = {
        colorMilestone: '#003462',
        textColorMilestone: '#FFFFFF',
    }
    
    // Igualamos el estado inicial para el formData de nuevo hito con initialFormNewMilestone
    const[formDataNewMilestone, setFormDataNewMilestone] = useState<FormDataNewMilestone>(initialFormNewMilestone);

    // Definimos estado inicial para los campos del formulario de edicion de hito
    const initialFormDataEditMilestone: FormDataEditMilestone = {
        start: '',
        end: '',
        milestoneIndex: 0,
        sellersOrGroups: [],
        color: '',
        textColor: '',
    }

    // Igualamos el estado inicial para el formData de edicion de hito con initialFormEditMilestone
    const[formDataEditMilestone, setFormDataEditMilestone] = useState<FormDataEditMilestone>(initialFormDataEditMilestone);

    // Funcion para resetear el formData
    const resetFormNew = () => {
        setFormDataNewMilestone(initialFormNewMilestone);
    };

    // Funcion para resetear el formDataEdit
    const resetFormEdit = () => {
        setFormDataEditMilestone(initialFormDataEditMilestone);
    }

    // Funcion para abrir el modal y procesamos la informacion a mostrar
    const openModalNew = (info: MilestoneInfo | string, calendar: any) => {

        // console.log({calendar})
        // Si el tipo de 'info' es una cadena (mensaje de error)
        if (typeof info === 'string'){

            // Seteamos selectedInfo
            setSelectedInfo(info);
            
        // Si no es una cadena (viene el objeto completo 'info')
        } else {
            
            // Seteamos selectedInfo
            setSelectedInfo(info);

        }

        // Mostramos el modal
        setShowModalNew(true);
        setSelectedCalendar(calendar)

    }

    // Funcion para abrir el modal de edicion de hito y seteamos la informacion necesaria
    const openModalEdit = (milestoneIndex: string, startHour24: string | undefined | null, endHour24: string| undefined | null, info?: MilestoneInfo) => {
        setSelectedInfo(info);
        setSelectedMilestoneIndex(milestoneIndex);
        setStartHour24(startHour24);
        setEndHour24(endHour24);
        setShowModalEdit(true);
    }

    // Funcion para abrir el modal de eliminacion de hito
    const openModalDelete = () => {
        setShowModalDelete(true);
    }

    // Funcion para cerrar el modal y resetar formulario
    const closeModalNew = () => {
        setShowModalNew(false);
        resetFormNew();
    }

    // Funcion para cerrar el modal de edicion y resetear formulario
    const closeModalEdit = () => {
        setShowModalEdit(false);
        resetFormEdit();
        setUsersOrGroupsForSwitch([]);
        setAlertInfoInput({ 
            text: '',
            variant: '',
            inputName: '',
        });
        setButtonSubmitState(false)
    }

    // Funcion para cerrar el modal de eliminacion de hito
    const closeModalDelete = () => {
        setShowModalDelete(false);
    }

    // Efecto para reenderizado de calendario
    useEffect(() => {

        let calendarEl: HTMLElement | null = document.getElementById('calendar');

        if (calendarEl) {
            let calendar = new Calendar(calendarEl, {
                plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin], //Pasamos en un arreglo los pluggins adicionales para el calendario
                // Definimos los elementos que queremos mostrar en el header del calendario
                headerToolbar: {
                    left: '',
                    center: '',
                    right: '',
                },
                firstDay: 1, // Definimos que dia empieza la semana (en esta caso el dia 7 'Domingo')
                initialView: 'timeGridWeek', // Definimos cual será la vista inicial del calendario (en este caso una vista semanal)
                initialDate: '2024-02-05', // Definimos cual sra la fecha inicial fija (en este caso 2024-02-04) el calendario siempre se mostrara apartir de esta fecha
                dayMaxEvents: 12, // Definimos un maximo de eventos por dia (en este caso 12)
                dayMaxEventRows: 12, // Definimos un total de dias por filas (en este caso 12)
                allDaySlot: false, // Le decimos que no queremos la casilla de 'Todo el dia'
                fixedWeekCount: true, // Le decimos que queremos una semana fija
                selectable: selectableState, // Le decimos que queremos un calendario seleccionable y le pasamos una variable dinamica que establecimos en 'Assigns'
                selectMirror: selectMirrorState, // Le decimos que queremos un calendario seleccionable con efecto espejo y le pasamos una variable dinamica que establecimos en 'Assigns'
                editable: editableState, // Le decimos que sera un calendario editable  
                eventResizableFromStart: resizableState, // Permitir redimensionamiento desde el inicio
                events: milestonesArray, // Le pasamos el arreglo con los hitos que nos regresa la seleccion de rol
                slotMinTime: '00:00:00', // Le definimos la hora minima disponible en el calendario (en este caso 00:00:00)
                slotMaxTime: '24:00:00', // Le definimos la hora maxima disponible en el calendario (en este caso 24:00:00)
                scrollTime: '00:00:00', // Definimos la hora a la que queremos que se desplace el calendario (en este caso a las 00:00:00 que seria el inicio)
                slotLabelInterval: '00:30:00',// Definimos el intervalo de horas a mostrar en el calendario (en este caso un intervalo de 30 minutos)
                eventBackgroundColor: '#003462', // Predefinimos un color para el evento que se esta seleccionando al hacer click
                eventBorderColor: '#FFFFFF', // Predefinimos un color de borde para el evento que se esta seleccionando al hacer click
                eventTextColor: '#FFFFFF', // Predefinimos un color de texto para el evento que se esta seleccionando al hacer click
                
                // Le decimos que queremos mostrar nombres cortos de los dias en el calendario
                dayHeaderContent: (args) => {
                    const day = args.date.toLocaleString('en-US', { weekday: 'short' });
                    return day;
                },

                // Inician los eventos de interaccion en la interfaz del calendario 
                
                // Evento que se dispara al seleccionar un espacio del calendario
                select: function (info) {
                    
                    const newEventStart: Date | null = null;
                    const newEventEnd: Date | null = null;
                    const caseOrigin: string = 'select';

                    validateEventOverlap(newEventStart, newEventEnd, calendar, info, caseOrigin);

                },

                // Evento que se dispara al dar click en algun evento existente en el calendario
                eventClick: function (info) {

                    if (selectedRoleStatus === true){

                        const milestoneIndex: string = info.event.id;
                        const startHour24: string | null = null;
                        const endHour24: string | null = null;
    
                        // console.log({info})
    
                        openModalEdit(milestoneIndex, startHour24, endHour24);

                    }

                },

                // Evento que se dispara cuando redimensionamos algun evento existente en el calendario
                eventResize: function (info) {
                    // Lógica de validación para eventos al redimensionarlos
                    const newEventStart: Date | null = info.event.start;
                    const newEventEnd: Date | null = info.event.end;
                    const caseOrigin: string = 'resize';

                    validateEventOverlap(newEventStart, newEventEnd, calendar, info, caseOrigin);
                
                    info.revert();
                },

                // Evento que se dispara al inicio de la accion de mover un evento de posicion (fecha y/o horario)
                eventDragStart: function (info) {
                    // console.log('iniciaeventodrag')
                    const originalStartDay: number | undefined = info.event.start?.getDate();
                    // console.log('fecha inciio ondrag'+originalStartDay);
                    
                    // console.log(typeof originalStartDay)
                    // console.log({originalStartDay})
                    
                    dropOriginalStartDay = originalStartDay;

                },

                // Evento que se dispara cuando soltamos un evento (cuando lo estamos arrastrando y lo soltamos)
                eventDrop: function (info) {

                    const newEventStart: Date | null = info.event.start;
                    const newEventEnd: Date | null = info.event.end;

                    // console.log('dia viejo: '+dropOriginalStartDay)
                    // console.log('dia nuevo: '+newStartDay)

                    const caseOrigin: string = 'drop';

                    validateEventOverlap(newEventStart, newEventEnd, calendar, info, caseOrigin);
        
                }
                
            });

            
            calendar.render();
            calendarEl.classList.add('miClaseCalendario');
        }

    }, [milestonesArray]);

    // Funcion para validaciones de superposicion de eventos en el calendario durante la accion de diferentes disparadores (select, resize y drop)
    function validateEventOverlap(newEventStart: Date | null, newEventEnd: Date | null, calendar: any, info: any, caseOrigin: string) {
        // Verificar si el evento redimensionado se superpone con eventos existentes

        // console.log({newEventStart})
        // console.log(typeof newEventEnd)
        // console.log({calendar})
        // console.log(typeof info)

        // Iniciamos un switch para el trafico de los diferentes casos posibles en los eventos del calendario
        switch (caseOrigin) {

            case 'select':

            console.log('evento select')
                // Verificar si el nuevo evento se superpone con eventos existentes
                // const overlappingMilestonesSelect = calendar.getEvents().filter((milestone: MilestoneInfo) => {
                //     if (milestone.start !== null && milestone.end !== null && milestone.start !== undefined && milestone.end !== undefined) {
                //         return (
                //             info.start < milestone.end && info.end > milestone.start
                //         );
                //     }
                //     return false;
                // });
            
                // if (overlappingMilestonesSelect.length === 0) {

                //     // console.log({info})

                //     openModalNew(info, calendar);

                // } else {

                //     const errorMsg: string = 'El nuevo hito se superpone con otro hito existente. Por favor, seleccione otro horario.';
                //     openModalNew(errorMsg, calendar);

                //     calendar.unselect(); 
            
                // }

                // if (info.start.toDateString() === info.end.toDateString()) {
                //     let isOverlap = false;
                
                //     // Verificar superposición de eventos
                //     for (const milestone of calendar.getEvents()) {
                //         if (milestone.start !== null && milestone.end !== null && milestone.start !== undefined && milestone.end !== undefined) {
                //             const startOverlap = info.start < milestone.end && info.end > milestone.start;
                //             const dateOverlap = info.start.toDateString() === milestone.start.toDateString() && info.end.toDateString() === milestone.end.toDateString();
                //             const timeOverlap = info.start.getHours() < milestone.end.getHours() && info.end.getHours() > milestone.start.getHours();
                
                //             if ((startOverlap || (dateOverlap && timeOverlap)) && !isOverlap) {
                //                 isOverlap = true;
                //             }
                //         }
                //     }
                
                //     if (!isOverlap) {
                //         openModalNew(info, calendar);
                //     } else {
                //         const errorMsg: string = 'El nuevo hito se superpone con otro hito existente. Por favor, seleccione otro horario.';
                //         openModalNew(errorMsg, calendar);
                //         calendar.unselect();
                //     }
                // } else {
                //     const errorMsg: string = 'Solo se pueden seleccionar horarios del mismo día.';
                //     openModalNew(errorMsg, calendar);
                //     calendar.unselect();
                // }

                // Verificar si ambas horas están en la misma fecha
                if (info.start.toDateString() === info.end.toDateString()) {
                    let isOverlap = false;

                    // Verificar superposición de eventos
                    for (const milestone of calendar.getEvents()) {
                        if (milestone.start !== null && milestone.end !== null && milestone.start !== undefined && milestone.end !== undefined) {
                            const startOverlap = info.start < milestone.end && info.end > milestone.start;
                            const dateOverlap = info.start.toDateString() === milestone.start.toDateString() && info.end.toDateString() === milestone.end.toDateString();
                            const timeOverlap = info.start.getHours() < milestone.end.getHours() && info.end.getHours() > milestone.start.getHours();

                            if ((startOverlap || (dateOverlap && timeOverlap)) && !isOverlap) {
                                isOverlap = true;
                            }
                        }
                    }

                    if (!isOverlap) {
                        openModalNew(info, calendar);
                    } else {
                        const errorMsg: string = 'El nuevo hito se superpone con otro hito existente. Por favor, seleccione otro horario.';
                        openModalNew(errorMsg, calendar);
                        calendar.unselect();
                    }
                } else {
                    // Verificar si la hora de fin es igual a '00:00'
                    if (info.end.getHours() === 0) {
                        openModalNew(info, calendar);
                    } else {
                        const errorMsg: string = 'Solo se pueden seleccionar horarios del mismo día.';
                        openModalNew(errorMsg, calendar);
                        calendar.unselect();
                    }
                }               

                break;

            case 'drop':

                const newStartDay: MilestoneInfo = info.event.start?.getDate();
                const milestoneIndex: string = info.event.id;

                const selectedEventStart: Date | null = info.event.start;
                const selectedEventEnd: Date | null = info.event.end;

                // Convertir la fecha a formato local
                const startHour24 = selectedEventStart?.toLocaleString('es-MX', { hour: '2-digit', minute: '2-digit', hour12: false });
                const endHour24 = selectedEventEnd?.toLocaleString('es-MX', { hour: '2-digit', minute: '2-digit', hour12: false });

                const overlappingMilestonesResize = calendar.getEvents().filter((milestone: MilestoneInfo) => {
                    if (milestone.start !== null && milestone.end !== null && newEventStart !== null && newEventEnd !== null && milestone.start !== undefined && milestone.end !== undefined) {
                        return (
                            newEventStart < milestone.end && newEventEnd > milestone.start
                        );
                    }
                    return false;
                });
            
                if (overlappingMilestonesResize.length > 1) { // Se verifica si hay más de un evento (el mismo evento se superpone consigo mismo)
                    // console.log('entro en if')
                    const errorMsg: string = 'El evento redimensionado se superpone con otros eventos existentes. Por favor, seleccione otro horario.';
                    //aqui mandarlo a un nuevo modal que sea el de edicion
                    openModalNew(errorMsg, calendar);
                    // Revertir el redimensionamiento (volver al tamaño original del evento)
                    info.revert();
                
                } else if(dropOriginalStartDay !== newStartDay) {
                    // console.log('entro en else if')

                    const errorMsg: string = 'El hito solo se puede mover dentro del mismo día.';
                    openModalNew(errorMsg, calendar);
                    info.revert();
                } else {
                    // console.log('entro en else')

                    openModalEdit(milestoneIndex, startHour24, endHour24, info);
                }
                
                break;

            case 'resize':

                const msg = 'No es posible redimensionar el hito, si quieres modificar el horario, da clic en el hito para poder editarlo.';

                openModalNew(msg, calendar);

                info.revert();

                break;

            default:

                break;
        }

    }


    return (
        <div>
            <div id="calendar"></div>
            
            {/* Modal para la creacion de hito */}
            <ModalNewMilestoneSingle 
                closeModalNew={closeModalNew}
                showModalNew={showModalNew}
                selectedInfo={selectedInfo}
                selectedCalendar={selectedCalendar}
                rolId={rolId}
                rolName={rolName}
                getMilestoneRegister={getMilestoneRegister}
                formDataNewMilestone={formDataNewMilestone}
                setFormDataNewMilestone={setFormDataNewMilestone}
                alertInfo={alertInfo}
                setAlertInfo={setAlertInfo}
            />

            {/* Modal para la edicion de un hito */}
            <ModalEditMilestoneSingle 
                closeModalEdit={closeModalEdit}
                showModalEdit={showModalEdit}
                selectedMilestoneIndex={selectedMilestoneIndex}
                rolId={rolId}
                rolName={rolName}
                getMilestoneRegister={getMilestoneRegister}
                formDataEditMilestone={formDataEditMilestone}
                setFormDataEditMilestone={setFormDataEditMilestone}
                usersOrGroupsForSwitch={usersOrGroupsForSwitch}
                setUsersOrGroupsForSwitch={setUsersOrGroupsForSwitch}
                startHour24={startHour24}
                endHour24={endHour24}
                selectedInfo={selectedInfo}
                alertInfoInput={alertInfoInput}
                setAlertInfoInput={setAlertInfoInput}
                alertInfo={alertInfo}
                setAlertInfo={setAlertInfo}
                buttonSubmitState={buttonSubmitState}
                setButtonSubmitState={setButtonSubmitState}
                openModalDelete={openModalDelete}
                closeModalDelete={closeModalDelete}
                showModalDelete={showModalDelete}
            />

        </div>
    );
}

export { ModalRolView };