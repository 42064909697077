import axios from 'axios'
import {DragonMainApi, EPGroupSellersEdit, apiDragon} from '../../../../utils/constants/_Constants'
import {apiPetitions} from '../../../../../_metronic/helpers/apis'

async function groupsEdit(
  idClient,
  idGroup,
  name: string,
  manager: string,
  users: string[],
  userToken: string
) {
  // console.log('token antes del envio en brands: ', userToken)
  try {
    // Construye el objeto de datos condicionalmente
    const data: {
      idClient
      idGroup
      name: string
      manager?: string
      users: string[]
    } = {
      idClient,
      idGroup,
      name,
      users,
    }

    // Verificamos si userPrivilage es distinto de 0 antes de agregarlo al objeto requestData
    if (manager !== '') {
      data.manager = manager
    }

    const response = await apiPetitions(
      apiDragon,
      `${DragonMainApi}${EPGroupSellersEdit}/${idGroup}`,
      data,
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'put'
    )

    const jsonDataSellersEdit = response
    console.log(jsonDataSellersEdit)
    return jsonDataSellersEdit

    // Resto de tu código de manejo de respuesta
  } catch (error) {
    throw error
  }
}

export {groupsEdit}
