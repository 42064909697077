// Imposrtamos constantes y funciones necesarias
import {apiDragon, DragonMainApi, EPSellersListByClient} from '../../../utils/constants/_Constants'
import {apiGetDeletePetitions} from '../../../../_metronic/helpers/apis'

export const amSellersBUList = async (userToken: string, clientId: string): Promise<any> => {

    try {

        // Realizamos peticion a la API
        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPSellersListByClient+clientId}`, {
            /* Los parametros se mandan como undefined
            ya que estos se envian solo en peticiones de eliminacion 'delete */
            undefined
        }, {
            // Encabezados de la solucitud incluyen el token de usuario y el tipo de contenido
            'Content-Type': 'application/json',
            'x-token': userToken
        },
    
        'get'); // Metodo de la solictud API, en este caso 'get'

        // Guardamos la data regresada por la API
        const jsonDataSellerBUList: any = data;

        // console.log(jsonDataSellerBUList)

        // Devolvemos los datos obtenidos
        return jsonDataSellerBUList;
    
    } catch (error: any) {

        // throw error;
        console.log('error getting sellers list: ', error)

    } 

}