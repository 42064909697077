import { FC, useState, useEffect, useContext } from "react"
import { TextArea } from "react-aria-components"
import { Modal } from "react-bootstrap"
import { SellerList, ProductList, CampaignList } from "../../../utils/interfaces/_InterfacesTypes"
import { UserContext } from "../../../app-context/user-context/UserContext"
import { FormDataNewLead } from '../interfaces/leadsTypes';
import { productsListByClient } from "../../products/services/_ProductsListApi"
import { campaignsListForProduct } from "../../campaigns/services/_CampaignsListApi"
import { sellersListByCampaign } from "../../control-sellers/sellers/services/_SellersListApi"
import { sessionExpired } from "../../../utils/functions/_Functions"
import { Campaign } from '../../campaigns/interfaces/campaignsTypes';
import { leadNew } from "../services/_LeadNewApi"
import { AlertInfo } from "../../../utils/interfaces/_InterfacesTypes"
import { ModalAdviceDefaultSize } from "../../../utils/components/modals/ModalAdviceDefaultSize"

type Props = {
    closeModalLeadNew: () => void
    getLeadsByClient: () => void
}

const ModalNewLead: FC<Props> = ({
    closeModalLeadNew,
    getLeadsByClient
}) => {

    //Algunas constantes desde el userContext
    const { user, token, updateToken } = useContext(UserContext)
    const clientId: string = user.cli_id
    const userToken: string = token

    const [campaignDropdownState, setCampaignDropdownState] = useState<boolean>(true)
    const [sellerDropdownState, setSellerDropdownState] = useState<boolean>(true)

    const [productsList, setProductsList] = useState<ProductList[]>([])
    const [campaignsList, setCampaignsList] = useState<CampaignList[]>([])
    const [sellersList, setSellersList] = useState<SellerList[]>([])

    const initialFormDataNewLead: FormDataNewLead = {
        name: '',
        phone: '',
        mail: '',
        campaign: '',
        userAssigned: '',
        comments: '',
    }

    const [formDataNewLead, setFormDataNewLead] = useState<FormDataNewLead>(initialFormDataNewLead)

    //Variables para manejo de estado de errores en inputs del formulario
    const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({})

    const [showModalAdvice, setShowModalAdvice] = useState<boolean>(false)

    const openModalAdvice = () => {
        setShowModalAdvice(true)
    }

    const closeModalAdvice = () => {
        setShowModalAdvice(false)
    }

    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        text: '',
        variant: 'primary',
    })

    const getProductsList = async (): Promise<void> => {
        try {
            const { data: jsonData, code, msg } = await productsListByClient(userToken, clientId)

            if (code === 401 && msg === 'Token no valido') {
                sessionExpired('La sesion ha caducado')
                return
            } else {
                const productsListReceived: ProductList[] = jsonData.data

                const userTokenNew: string = jsonData.token

                updateToken(userTokenNew)

                console.log({ productsListReceived })

                const productOption = productsListReceived.map((product: ProductList) => {
                    return {
                        pro_id: product.pro_id,
                        name: product.name,
                    }
                })

                setProductsList(productOption)
            }

        } catch (error) {
            console.log('Ocurrio un error al recuperar el listado de productos: ', error)
        }
    }

    const getCampaignsList = async (e: string): Promise<void> => {

        console.log('valor seleccionado: ', e)


        const productId: string = e;

        try {

            const { data: jsonData, code, msg } = await campaignsListForProduct(userToken, productId)

            if (code === 401 && msg === 'Token no valido') {
                sessionExpired('La sesion ha caducado')
                return
            } else {
                const campaignsListReceived: CampaignList[] = jsonData.data

                const userTokenNew: string = jsonData.token

                updateToken(userTokenNew)

                console.log({ campaignsListReceived })

                const campaignOption = campaignsListReceived.map((campaign: CampaignList) => {
                    return {
                        cam_id: campaign.cam_id,
                        name: campaign.name
                    }
                })

                setCampaignsList(campaignOption)
                setCampaignDropdownState(false)
            }

        } catch (error) {
            console.log('Ocurrio un error obteniendo las campañas del producto: ', error)
        }


    }

    const getSellersList = async (campaign: string) => {

        const campaignId: string = campaign;

        try {
            const { data: jsonData, code, msg } = await sellersListByCampaign(userToken, campaignId)

            if (code === 401 && msg === 'Token no valido') {
                sessionExpired('La sesion ha caducado')
                return
            } else {
                const sellersListReceived: SellerList[] = jsonData.data

                const userTokenNew: string = jsonData.token

                updateToken(userTokenNew)

                console.log({ sellersListReceived })

                const sellerOption = sellersListReceived.map((seller: SellerList) => {
                    return {
                        user_id: seller.user_id,
                        usr_name: seller.usr_name,
                    }
                })

                setSellersList(sellerOption)
                setSellerDropdownState(false)
            }

        } catch (error) {
            console.log('Error obteniendo el listado de vendedores: ', error)

        }


    }

    const handleProductSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target

        setFormDataNewLead((prevData) => ({
            ...prevData,
            campaign: '',
            userAssigned: '',
        }))

        setCampaignsList([])
        setCampaignDropdownState(true)

        setSellersList([])
        setSellerDropdownState(true)

        if (value !== '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                product: undefined,
            }))

            getCampaignsList(value)

        }

        if (value === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                product: 'Debes seleccionar un producto',
            }))
        }

    }

    const handleCampaignSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target

        setFormDataNewLead((prevData) => ({
            ...prevData,
            userAssigned: '',
        }))

        setSellersList([])
        setSellerDropdownState(true)

        if (value !== '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                campaign: undefined,
            }))
        }

        if (value === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                campaign: 'Debes seleccionar una campaña',
            }))
        }

        setFormDataNewLead((prevData) => ({
            ...prevData,
            campaign: value,
        }))

        getSellersList(value)

    }

    const handleSellerSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target

        setFormDataNewLead((prevData) => ({
            ...prevData,
            userAssigned: value,
        }))

    }

    const handleNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        if (value !== '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: undefined,
            }))

        }

        if (value === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Debes agregar un nombre al lead',
            }))
        }

        setFormDataNewLead((prevData) => ({
            ...prevData,
            name: value,
        }))
    }

    const handleMailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        if (value !== '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                mail: undefined,
            }))

        }

        if (value === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                mail: 'Debes agregar un email al lead',
            }))
        }

        setFormDataNewLead((prevData) => ({
            ...prevData,
            mail: value,
        }))
    }

    const handlePhoneInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        if (value !== '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: undefined,
            }))

        }

        if (value === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: 'Debes agregar un telefono al lead',
            }))
        }

        setFormDataNewLead((prevData) => ({
            ...prevData,
            phone: value,
        }))
    }

    const handleCommentsInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target

        setFormDataNewLead((prevData) => ({
            ...prevData,
            comments: value,
        }))
    }

    const handleFormSubmit = async (): Promise<void> => {
        console.log('ya se va el form')
        console.log({ formDataNewLead })

        const name: string = formDataNewLead.name
        const phone: string = formDataNewLead.phone
        const mail: string = formDataNewLead.mail
        const campaign: string = formDataNewLead.campaign
        const userAssigned: string = formDataNewLead.userAssigned
        const comments: string = formDataNewLead.comments

        try {
            const { data: jsonData, code, msg } = await leadNew(userToken, name, phone, mail, campaign, userAssigned, comments)

            if (code === 401 && msg === 'Token no valido') {
                sessionExpired('Ha expirado el token')
                return
            } else {

                setAlertInfo({
                    text: code === 200 ? 'El lead se creo correctamente' : 'No se pudo crear el lead',
                    variant: code === 200 ? 'success' : 'danger'
                })

                setTimeout(() => {
                    setAlertInfo({
                        text: '',
                        variant: ''
                    })

                    closeModalLeadNew()
                    closeModalAdvice()
                    setFormDataNewLead(initialFormDataNewLead)
                    setErrors({})
                    getLeadsByClient()
                }, 3000);

            }
        } catch (error) {
            setAlertInfo({
                text: 'Error en la solicitud',
                variant: 'danger'
            })
            closeModalAdvice()
            closeModalLeadNew()
            setErrors({})
            getLeadsByClient()
        }

    }

    const checkFormToSubmit = () => {

        if (formDataNewLead.campaign === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                campaign: 'Debes seleccionar una campaña'
            }))
            return
        }

        if (formDataNewLead.name === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Debes agregar un nombre al lead'
            }))
            return
        }

        if (formDataNewLead.mail === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                mail: 'Debes agregar un mail al lead'
            }))
            return
        }

        if (formDataNewLead.phone === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: 'Debes agregar un telefono al lead'
            }))
            return
        }

        if (formDataNewLead.userAssigned === '') {
            openModalAdvice()
        } else {
            handleFormSubmit()
        }
    }

    useEffect(() => {
        getProductsList()
    }, [])

    return (
        <>
            <Modal.Header closeButton onClick={closeModalLeadNew}>
                <Modal.Title>
                    Lead manual
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='px-7'>
                    <div className='mb-5'>
                        <label className='fs-6 fw-bold required'>
                            Producto
                        </label>
                        <select
                            // className="form-select form-select-solid"
                            className={`form-select form-select-solid mb-3 mb-lg-0 ${errors.product && 'is-invalid'}`}
                            name='product'
                            onChange={(e) => {
                                handleProductSelect(e)
                            }}
                        >
                            <option value=''>-- Selecciona un producto --</option>
                            {productsList.map((product: ProductList) => (
                                <option key={product.pro_id} value={product.pro_id}>
                                    {product.name}
                                </option>
                            ))}
                        </select>
                        {errors.product && <div className='invalid-feedback'>{errors.product}</div>}
                    </div>

                    <div className='mb-5'>
                        <label className='fs-6 fw-bold required'>
                            Campaña
                        </label>
                        <select
                            // className="form-select form-select-solid"
                            className={`form-select form-select-solid mb-3 mb-lg-0 ${errors.campaign && 'is-invalid'}`}
                            required
                            name='campaign'
                            disabled={campaignDropdownState}
                            value={formDataNewLead.campaign}
                            onChange={(e) => {
                                handleCampaignSelect(e)
                            }}
                        >
                            <option value=''>-- Selecciona una campaña --</option>
                            {campaignsList.map((campaign: CampaignList) => (
                                <option key={campaign.cam_id} value={campaign.cam_id}>
                                    {campaign.name}
                                </option>
                            ))}
                        </select>
                        {errors.campaign && <div className='invalid-feedback'>{errors.campaign}</div>}
                    </div>

                    <div className='mb-5'>
                        <label className='fs-6 fw-bold'>
                            Vendedor
                        </label>
                        <select
                            className="form-select form-select-solid"
                            name='sellerId'
                            disabled={sellerDropdownState}
                            value={formDataNewLead.userAssigned}
                            onChange={(e) => {
                                handleSellerSelect(e)
                            }}
                        >
                            <option value=''>-- Selecciona un vendedor --</option>
                            {sellersList.map((seller: SellerList) => (
                                <option key={seller.user_id} value={seller.user_id}>
                                    {seller.usr_name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='mb-5'>
                        <div>
                            <label className='fs-6 fw-bold required'>
                                Nombre del lead
                            </label>
                            <input
                                required
                                type='text'
                                name='name'
                                className={`form-control form-control-solid mb-3 mb-lg-0 ${errors.name && 'is-invalid'}`}
                                // className='form-control form-control-solid mb-3 mb-lg-0 required'
                                placeholder='Nombre'
                                value={formDataNewLead.name}
                                onChange={(e) => {
                                    handleNameInput(e)
                                }}
                            />
                            {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                        </div>
                    </div>

                    <div className='mb-5'>
                        <div>
                            <label className='fs-6 fw-bold required'>
                                Correo electrónico del lead
                            </label>
                            <input
                                required
                                type='mail'
                                name='mail'
                                // className='form-control form-control-solid mb-3 mb-lg-0 required'
                                className={`form-control form-control-solid mb-3 mb-lg-0 ${errors.mail && 'is-invalid'}`}
                                placeholder='Correo electrónico'
                                value={formDataNewLead.mail}
                                onChange={(e) => {
                                    handleMailInput(e)
                                }}
                            />
                            {errors.mail && <div className='invalid-feedback'>{errors.mail}</div>}
                        </div>
                    </div>

                    <div className='mb-5'>
                        <div>
                            <label className='fs-6 fw-bold required'>
                                Teléfono del lead
                            </label>
                            <input
                                required
                                type='phone'
                                name='phone'
                                // className='form-control form-control-solid mb-3 mb-lg-0 required'
                                className={`form-control form-control-solid mb-3 mb-lg-0 ${errors.phone && 'is-invalid'}`}
                                placeholder='Teléfono'
                                value={formDataNewLead.phone}
                                onChange={(e) => {
                                    handlePhoneInput(e)
                                }}

                            />
                            {errors.phone && <div className='invalid-feedback'>{errors.phone}</div>}
                        </div>
                    </div>

                    <div className='mb-5'>
                        <div>
                            <label className='fs-6 fw-bold'>
                                Comentarios
                            </label>
                            <textarea
                                placeholder='Escribe un comentario sobre el lead...'
                                className='form-control form-control-solid'
                                name='comments'
                                rows={2}
                                value={formDataNewLead.comments}
                                onChange={(e) => {
                                    handleCommentsInput(e)
                                }}
                            />
                        </div>
                    </div>
                </div>


                {alertInfo.text && (
                    <div className='px-20'>
                        <div
                            className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`}
                            role='alert'
                        >
                            <div>{alertInfo.text}</div>
                        </div>
                    </div>
                )}

            </Modal.Body>
            <Modal.Footer>
                <button
                    className='btn btn-sm btn-primary'
                    type='submit'
                    onClick={() => {
                        checkFormToSubmit()
                    }}
                >
                    Guardar
                </button>
                <button
                    className='btn btn-sm btn-secondary'
                    type='button'
                    onClick={() => {
                        closeModalLeadNew()
                    }}
                >
                    Cancelar
                </button>
            </Modal.Footer>

            <ModalAdviceDefaultSize 
                titleAdvice={'¿No seleccionaste un vendedor?'}
                descriptionAdvice={'Si creas un lead sin seleccionar un vendedor, se le asignara uno de manera automatica de acuerdo al rol activo de la campaña'}
                showModalAdvice={showModalAdvice}
                setShowModalAdvice={setShowModalAdvice}
                openModalAdvice={openModalAdvice}
                closeModalAdvice={closeModalAdvice}
                handleFormSubmit={handleFormSubmit}
           />

        </>
    )
}

export { ModalNewLead }