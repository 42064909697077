// Formulario de templates

import React, {useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Link} from 'react-router-dom'

export const TwlioTemplatesAddForm = () => {
  const [selectedOption, setSelectedOption] = useState('')

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const renderTooltip = (props) => (
    <Tooltip {...props}>Volver a la tabla de registro de plantillas</Tooltip>
  )

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2'>Agregar una nueva plantilla de Mensaje</h1>
            </div>
            <div className='d-flex justify-content-between'>
              <div>
                <p>
                  Outboond messages to start conversationes with WhatsApp users must be sent using
                  message template.
                </p>
                <p>
                  WhatsApp will approve or reject your template in 48 hours or less. Learn more
                  about templates
                </p>
              </div>
              <div>
                <OverlayTrigger placement='bottom' overlay={renderTooltip}>
                  <Link to={'/twlio-services/twlio-templates'}>
                    <button className='btn btn-primary mb-3'>Volver</button>
                  </Link>
                </OverlayTrigger>
              </div>
            </div>
            <div className='card card-custom'>
              <div className='card card-body'>
                <h2 className='my-2'>General Information</h2>
                <div className='d-flex row'>
                  <div className='col-md-6'>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Nombre de la plantilla</label>
                      <input
                        placeholder='Nombre'
                        type='text'
                        name='template_name'
                        value={''}
                        className='form-control form-control-lg form-control-solid'
                        //   className={`form-control form-control-lg form-control-solid ${
                        //     errors.SellerName && 'is-invalid'
                        //   }`}
                        autoComplete='off'
                        //   onChange={handleInputChange}
                      />
                      {/* {errors.SellerName && <div className='invalid-feedback'>{errors.SellerName}</div>} */}
                      <div className='p-2 mt-2'>
                        <label htmlFor='fs-6 mt-1 text-muted'>
                          Can only contain lowercase alphanumeric characters and underscores
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>
                        Categoria de la plantilla
                      </label>

                      <select
                        name='template_category'
                        className='form-control form-control-lg form-control-solid'
                        //   className={`form-control form-control-lg form-control-solid ${
                        //     errors.SellerName && 'is-invalid'
                        //   }`}
                      >
                        {/* {errors.SellerName && <div className='invalid-feedback'>{errors.SellerName}</div>} */}
                        <option value=''>Selecciona una opción</option>
                        <option value='1'>Categoría 1</option>
                        <option value='2'>Categoría 2</option>
                      </select>
                      <div className='p-2 mt-2'>
                        <label htmlFor='fs-6 mt-1 text-muted'>
                          This category will be providad to whatsApp for review.
                        </label>
                        <label htmlFor='fs-6 mt-1 text-muted'>
                          {' '}
                          WhatsApp may reassign to a different category based on their analysis of
                          the content provided
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card card-custom mt-5'>
              <div className='card card-body'>
                <h2 className='my-2'>Message Template</h2>
                <p>
                  if you need the same template in different languages, add multiple languages and
                  indicate the corresponding translated message bodies for each. All template
                  translations are more likely to get approved if submitted together. Learn tips to
                  get approved.
                </p>
                <div className='row'>
                  <div className='col-md-2'></div>
                  <div className='col-md-8'>
                    <div className='card card-bordered py-5'>
                      <div className='d-flex row justify-content-center'>
                        <div className='col-md-10'>
                          <div className='fv-row mb-3'>
                            <label className='required fw-bold fs-6 mb-2'>
                              Lenguaje del mensaje
                            </label>

                            <select
                              name='template_category'
                              className='form-control form-control-lg form-control-solid'
                              //   className={`form-control form-control-lg form-control-solid ${
                              //     errors.SellerName && 'is-invalid'
                              //   }`}
                            >
                              {/* {errors.SellerName && <div className='invalid-feedback'>{errors.SellerName}</div>} */}
                              <option value=''>Selecciona una opción</option>
                              <option value='1'>Español (MEX)</option>
                              <option value='2'>Español (ARG)</option>
                              <option value='2'>Inglés (EEUU)</option>
                            </select>
                            <div className='p-2 mt-2'>
                              {/* Aquí va el label o los mensajes de error */}
                            </div>
                          </div>
                          <div className='fv-row mb-3'>
                            <label className='required fw-bold fs-6 mb-2'>
                              Cuerpo del mensaje (1024 caracteres o menos)
                            </label>
                            <textarea
                              name='message_body'
                              className='form-control form-control-lg form-control-solid'
                              rows={8}
                              cols={70}
                            ></textarea>
                            {/* {errors.SellerName && <div className='invalid-feedback'>{errors.SellerName}</div>} */}
                            <div className='p-2 mt-2'>
                              <p className='fs-6 mt-1 text-muted'>
                                Use double curly braces to indicate where you plan to use dynamic
                                content. For example, to send "Your coupon code for Twilio is 1234",
                                Your coupon code for {'{{1}}'} is {'{{2}}'}. Learn more.
                              </p>
                            </div>
                          </div>
                          <div className='fv-row mb-3'>
                            <div className='d-flex align-items-center'>
                              <label className='fw-bold fs-6 mb-2 me-2'>Botones</label>
                              <label className='fw-bold fs-6 mb-2 me-2'>-</label>
                              <label className='text-muted fs-6 mb-2 me-2'>
                                <em>Opcional</em>
                              </label>
                            </div>
                            <label className='fs-6 mt-1 text-muted mb-3'>
                              These will be show as clickable buttons below your message
                            </label>
                            <div className='d-flex flex-column'>
                              {/* Radio Button - None */}
                              <div className='form-check form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  value=''
                                  name='buttonsOption'
                                  id='radioNone'
                                  checked={selectedOption === ''}
                                  onChange={handleOptionChange}
                                />
                                <label className='form-check-label' htmlFor='radioNone'>
                                  None
                                </label>
                              </div>

                              {/* Radio Button - call_to_action */}
                              <div className='form-check form-check-custom form-check-solid mt-3'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  value='call_to_action'
                                  name='buttonsOption'
                                  id='radioOption2'
                                  checked={selectedOption === 'call_to_action'}
                                  onChange={handleOptionChange}
                                />
                                <div className='d-flex flex-column'>
                                  <label
                                    className='form-check-label text-white'
                                    htmlFor='radioOption2'
                                  >
                                    Call to action
                                  </label>
                                  <label className='form-check-label' htmlFor='radioOption2'>
                                    Create up to 2 buttons that let customers respond to your
                                    messages or take action.
                                  </label>
                                </div>
                              </div>
                              {/* Conditional rendering for 'call_to_action' */}
                              {selectedOption === 'call_to_action' && (
                                <div className='card card-bordered p-5' id='forCall_to_action'>
                                  <div className='row'>
                                    <div className='col-md-12'>
                                      <div className='d-flex align-items-center'>
                                        <div className='d-flex flex-column me-5'>
                                          <label
                                            htmlFor='action_type'
                                            className='fw-bold fs-6 mb-2'
                                          >
                                            Type of action
                                          </label>
                                          <select
                                            name='action_type'
                                            id='type_of_action'
                                            className='form-control form-control-md form-control-solid'
                                          >
                                            <option value='phone_number'>Phone number</option>
                                            <option value='other'>Other</option>
                                          </select>
                                        </div>
                                        <div className='d-flex flex-column me-5'>
                                          <label
                                            htmlFor='action_type'
                                            className='required fw-bold fs-6 mb-2'
                                          >
                                            Button text
                                          </label>
                                          <input
                                            placeholder='Call us'
                                            type='text'
                                            name='button_text'
                                            value={''}
                                            className='form-control form-control-lg form-control-solid'
                                            //   className={`form-control form-control-lg form-control-solid ${
                                            //     errors.SellerName && 'is-invalid'
                                            //   }`}
                                            autoComplete='off'
                                            //   onChange={handleInputChange}
                                          />
                                        </div>
                                        <div className='d-flex flex-column me-5'>
                                          <label htmlFor='lada_phone' className='fw-bold fs-6 mb-2'>
                                            Country
                                          </label>
                                          <select
                                            name='lada_phone'
                                            id='phone_lada'
                                            className='form-control form-control-md form-control-solid'
                                          >
                                            <option value='phone_number'>US +1</option>
                                            <option value='other'>MX +52</option>
                                          </select>
                                        </div>
                                        <div className='d-flex flex-column me-5'>
                                          <label
                                            htmlFor='action_type'
                                            className='required fw-bold fs-6 mb-2'
                                          >
                                            Phone number
                                          </label>
                                          <input
                                            type='text'
                                            name='phone_number'
                                            value={''}
                                            className='form-control form-control-lg form-control-solid'
                                            //   className={`form-control form-control-lg form-control-solid ${
                                            //     errors.SellerName && 'is-invalid'
                                            //   }`}
                                            autoComplete='off'
                                            //   onChange={handleInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-md-12'>
                                      <div className='d-flex align-items-center'>
                                        <div className='d-flex flex-column me-5'>
                                          <label
                                            htmlFor='action_type'
                                            className='fw-bold fs-6 mb-2'
                                          >
                                            Type of action
                                          </label>
                                          <select
                                            name='action_type'
                                            id='type_of_action'
                                            className='form-control form-control-md form-control-solid'
                                          >
                                            <option value='phone_number'>Website</option>
                                            <option value='other'>Phone number</option>
                                          </select>
                                        </div>
                                        <div className='d-flex flex-column me-5'>
                                          <label
                                            htmlFor='action_type'
                                            className='required fw-bold fs-6 mb-2'
                                          >
                                            Button text
                                          </label>
                                          <input
                                            placeholder='Visit our website'
                                            type='text'
                                            name='button_text'
                                            value={''}
                                            className='form-control form-control-lg form-control-solid'
                                            //   className={`form-control form-control-lg form-control-solid ${
                                            //     errors.SellerName && 'is-invalid'
                                            //   }`}
                                            autoComplete='off'
                                            //   onChange={handleInputChange}
                                          />
                                        </div>
                                        <div className='d-flex flex-column me-5 flex-grow-1'>
                                          <label
                                            htmlFor='action_type'
                                            className='required fw-bold fs-6 mb-2'
                                          >
                                            Website URL
                                          </label>
                                          <input
                                            type='text'
                                            name='website_url'
                                            value={''}
                                            className='form-control form-control-lg form-control-solid'
                                            //   className={`form-control form-control-lg form-control-solid ${
                                            //     errors.SellerName && 'is-invalid'
                                            //   }`}
                                            autoComplete='off'
                                            //   onChange={handleInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Radio Button - quick_reply */}
                              <div className='form-check form-check-custom form-check-solid mt-3'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  value='quick_reply'
                                  name='buttonsOption'
                                  id='radioOption2'
                                  checked={selectedOption === 'quick_reply'}
                                  onChange={handleOptionChange}
                                  // Aquí puedes definir la lógica para el estado checked de Option 2
                                />
                                <div className='d-flex flex-column'>
                                  <label
                                    className='form-check-label text-white'
                                    htmlFor='radioOption2'
                                  >
                                    Quick reply
                                  </label>
                                  <label className='form-check-label' htmlFor='radioOption2'>
                                    Create up to 3 buttons that let customers respond to your
                                    messages or take action.
                                  </label>
                                </div>
                              </div>
                              {/* Conditional rendering for 'quick_reply' */}
                              {selectedOption === 'quick_reply' && (
                                <div className='card card-bordered p-5' id='forQuick_reply'>
                                  <div className='d-flex flex-column me-5'>
                                    <div className='d-flex align-items-end mb-4'>
                                      <div className='me-3'>
                                        <label htmlFor='button_text1' className='fw-bold fs-6 mb-2'>
                                          Button text
                                        </label>
                                        <input
                                          placeholder='Text here...'
                                          type='text'
                                          name='button_text1'
                                          value={''}
                                          className='form-control form-control-lg form-control-solid'
                                          autoComplete='off'
                                        />
                                      </div>
                                      <button className='btn btn-bg-light btn-color-danger mb-2'>
                                        Remove
                                      </button>
                                    </div>
                                    <div className='d-flex align-items-end mb-4'>
                                      <div className='me-3'>
                                        <label htmlFor='button_text1' className='fw-bold fs-6 mb-2'>
                                          Button text
                                        </label>
                                        <input
                                          placeholder='Text here...'
                                          type='text'
                                          name='button_text1'
                                          value={''}
                                          className='form-control form-control-lg form-control-solid'
                                          autoComplete='off'
                                        />
                                      </div>
                                      <button className='btn btn-bg-light btn-color-danger mb-2'>
                                        Remove
                                      </button>
                                    </div>
                                    <div className='d-flex align-items-end mb-4'>
                                      <div className='me-3'>
                                        <label htmlFor='button_text1' className='fw-bold fs-6 mb-2'>
                                          Button text
                                        </label>
                                        <input
                                          placeholder='Text here...'
                                          type='text'
                                          name='button_text1'
                                          value={''}
                                          className='form-control form-control-lg form-control-solid'
                                          autoComplete='off'
                                        />
                                      </div>
                                      <button className='btn btn-bg-light btn-color-danger mb-2'>
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <button className='btn btn-primary mt-5'>
                            Add a template translation
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'></div>
                </div>
                <div className='row'>
                  <div className='col-md-2'></div>
                  <div className='col-md-8'>
                    <div className='d-flex align-items-center'>
                      <button className='btn btn-primary mt-5 me-3'>
                        Save template for WhatsApp approval
                      </button>
                      <button className='btn btn-bg-light btn-color-danger mt-5 me-3'>
                        Delete draft
                      </button>
                    </div>
                  </div>
                  <div className='col-md-2'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
