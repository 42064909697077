import {useEffect} from 'react'
// import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'

// const sdk = new ChartsEmbedSDK({
//   baseUrl: 'https://charts.mongodb.com/charts-charts-fixture-tenant-zdvkh',
//   showAttribution: false,
// })
// const salesChart = sdk.createChart({
//   chartId: '48043c78-f1d9-42ab-a2e1-f2d3c088f864',
//   height: 700,
//   width: 800,
//   theme: 'light',
//   autoRefresh: true,
//   showAttribution: true,
//   //  filter: { "suburb": { $gte: 20 } },
//   renderingSpec: {
//     version: 1,
//     title: 'Chart dinamico bien jalado bien tumbado',
//     description: 'Descripción bien jalada bien tumbada alv',
//   },
// })

export default function MyChartComponent() {
  useEffect(() => {
    const chartContainer = document.getElementById('chart-data')
    // if (chartContainer) {
    //   salesChart.render(chartContainer)
    // }
  }, [])
  return (
    <div className='d-flex justify-content-center'>
      <div className='MyChartComponent'>
        <h1>Mongo DB</h1>
        <h2>Gráfica traida de mongo:</h2>
        <div id='chart-data' className=' d-flex align-items-center'></div>
      </div>
    </div>
  )
}
