// Importamos constantes con rutas de api
import { apiDragon, DragonMainApi, EPCampaignsReport } from '../../../../../utils/constants/_Constants'
// Importamos funcion para realizar la peticion
import { apiGetDeletePetitions } from '../../../../../../_metronic/helpers/apis'

// Funcion para realizar la peticion al endpoint
export const campaignsReportPetitionNoCampaign = async (
    userToken: string,
    clientId: string,
    productId: string,
    firstDateStart: string,
    firstDateEnd: string,
    secondDateStart: string,
    secondDateEnd: string
) => {
    try {
        const data = await apiGetDeletePetitions(
            apiDragon,
            `${DragonMainApi + EPCampaignsReport + clientId}`,
            {
                proId: productId,
                firstDateStart,
                firstDateEnd,
                secondDateStart,
                secondDateEnd
            },
            {
                'Content-Type': 'application/json',
                'x-token': userToken,
            },
            'get'
        )

        const jsonDataCampaignReport = data
        return jsonDataCampaignReport
    } catch (error) {
        console.error('Hubo un error en la peticion: ', error)
        throw error
    }
}

