import { SingleChatA } from './SingleChatA'
import { SingleChatB } from './SingleChatB'
import { Conversation } from './Conversation'
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { setPageTitle, dateFormatterTextualEnTwo, dateFormatterISO8601Variant } from '../../utils/functions/_Functions';
import { DragonCem } from '../../utils/constants/_Constants';
import { KTIcon } from '../../../_metronic/helpers';
import { DateRangePickerFull } from '../../utils/components/date-pickers/DateRangePickerFull';

export const ChatWab = () => {

  // Valores iniciales para el rango de fechas
  const initialStartDate: Date = new Date(new Date().setDate(new Date().getDate() - 30));
  const initialEndDate: Date = new Date()

  // Constantes que usaremos para manejar las variables para los labels que muestran la fecha
  const [labelStartDate, setLabelStartDate] = useState<Date>(initialStartDate)
  const [labelEndDate, setLabelEndDate] = useState<Date>(initialEndDate)

  // Constantes que usaremos para manejar las fechas que enviaremos a la peticion
  const [startDate, setStartDate] = useState<string>(dateFormatterISO8601Variant(initialStartDate))
  const [endDate, setEndDate] = useState<string>(dateFormatterISO8601Variant(initialEndDate))

  // Manejador para la disponibilidad del dateRangePicker
  const [dataRangeState, setDataRangeState] = useState<boolean>(false)

  // Manejamos las acciones que queremos para cuando se interactue con las fechas del DateRange
  const handleDates = (): void => {
    const startDateNew: string = (dateFormatterISO8601Variant(labelStartDate))
    const endDateNew: string = (dateFormatterISO8601Variant(labelEndDate))

    setStartDate(startDateNew)
    setEndDate(endDateNew)

  }

  const intl = useIntl();
  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    setPageTitle(`${intl.formatMessage({ id: "CHATWAB.TITLE" })} | ${DragonCem}`);
  }, [])

  return (
    <>
      <div className='d-flex flex-column flex-lg-row h-100'>
        <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
          <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
            <div className='card card-flush'>
              <div className='card-header pt-7' id='kt_chat_contacts_header'>
                <form className='w-100 position-relative' autoComplete='off'>
                  <div className='d-flex mb-3 w-600px'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-secondary me-2'
                      disabled={dataRangeState}
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-start'
                      data-kt-menu-flip='bottom-start'
                    >
                      <KTIcon iconName='calendar' className='fs-2' />
                    </button>

                    <button
                      type='button'
                      className='btn btn-sm btn-secondary disabled'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      {'De: ' + dateFormatterTextualEnTwo(labelStartDate) + '  a: ' + dateFormatterTextualEnTwo(labelEndDate) + '   '}
                    </button>

                    {/* Componente con el DateRangePicker */}
                    <DateRangePickerFull
                      initialStartDate={initialStartDate}
                      initialEndDate={initialEndDate}
                      months={2}
                      handleDates={handleDates}
                      setLabelStartDate={setLabelStartDate}
                      setLabelEndDate={setLabelEndDate}
                    />
                  </div>

                  <div className='mb-3'>
                    <select
                      className="form-select form-select-solid form-select-sm"
                      name='productId'
                    >
                      <option value=''>Selecciona un producto</option>
                      <option value=''>I.T.</option>
                      <option value=''>MKT DragonCEM</option>
                      <option value=''>Estrategia Comercial</option>
                    </select>
                  </div>

                  <div className='mb-3'>
                    <select
                      className="form-select form-select-solid form-select-sm"
                      name='campaignId'
                    >
                      <option value=''>Selecciona una campaña</option>
                      <option value=''>Lead Gen</option>
                      <option value=''>Referenciado</option>
                      <option value=''>Gallardetes</option>
                    </select>
                  </div>
                  <div className='mb-3'>
                    <input
                      type='text'
                      className='form-control form-control-solid form-select-sm'
                      name='search'
                      placeholder='Buscar'
                    />

                  </div>
                </form>
              </div>
              <div className='card-body pt-5' id='kt_chat_contacts_body'>
                <div
                  className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
                  data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
                  data-kt-scroll-offset='0px'
                >

                  <SingleChatA userIcon={'A'} userName={'Angel Ortiz'} userMail={'aortiz@dragon.com'} userLastConnection={'5 mins'} />
                  <SingleChatA userIcon={'I'} userName={'Ivan daddy'} userMail={'dadyivan@dragon.com'} userLastConnection={'7 mins'} />
                  <SingleChatB userIconSource={'300-3'} userName={'Dan Rico'} userMail={'5512345678'} userLastConnection={'30 mins'} />
                  <SingleChatA userIcon={'C'} userName={'Claleo Guzman'} userMail={'claleoguzman@dragon.com'} userLastConnection={'1 hrs'} />
                  <SingleChatB userIconSource={'300-5'} userName={'Bryan Duck'} userMail={'7712345678'} userLastConnection={'1 hrs'} />
                  <SingleChatA userIcon={'M'} userName={'Melcho wey'} userMail={'melchowe@dragon.com'} userLastConnection={'5 hours'} />
                  <SingleChatB userIconSource={'300-2'} userName={'Will Smith'} userMail={'5612346587'} userLastConnection={'10 hrs'} />
                  <SingleChatA userIcon={'T'} userName={'Tlaloc deidad'} userMail={'tdeidad@dragon.com'} userLastConnection={'11 hrs'} />
                  <SingleChatB userIconSource={'300-6'} userName={'Taylor S'} userMail={'tswift@kt.com'} userLastConnection={'13 hrs'} />
                  <SingleChatB userIconSource={'300-1'} userName={'Max Smith'} userMail={'max@kt.com'} userLastConnection={'21 hrs'} />
                  <SingleChatA userIcon={'T'} userName={'Tony Escupo'} userMail={'tescupo@dragon.com'} userLastConnection={'1 day'} />
                  <SingleChatB userIconSource={'300-4'} userName={'Sam Gomez'} userMail={'sgomez@kt.com'} userLastConnection={'3 days'} />



                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
          <Conversation />
        </div>
      </div>
    </>
  )
}
