import React from "react";
import { Tab, Tabs, Form, Button, Container, Row, Col, Card } from 'react-bootstrap';

function ModalQrCampaign (){
    return (
        <div className="container">
            <Card style={{ width: '32rem' }} className="py-4">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img 
                        src="https://storage.googleapis.com/dragon-bucket-prod/app/img/qrs/cam_id10367-DcemQrs.png"
                        style={{ width: '300px', height: 'auto', margin: '0 auto' }}
                    />
                </div>
            </Card>
                <button className='mt-8 btn btn-primary btn-sm'>Descargar</button>
        </div>
    )
}

export { ModalQrCampaign };