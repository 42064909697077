import { FC, useState, useContext, useEffect, ChangeEvent } from "react";
import { Lead } from "../interfaces/leadsTypes";
import { Modal } from "react-bootstrap";
import { FormDataEditLead } from "../interfaces/leadsTypes";
import { AlertInfo } from "../../../utils/interfaces/_InterfacesTypes";
import { UserContext } from "../../../app-context/user-context/UserContext";
import { leadEdit } from "../services/_LeadEditApi";
import { sessionExpired } from "../../../utils/functions/_Functions";

type Props = {
    lead: Lead
    getLeadsByClient: () => void
    closeModalLeadEdit: () => void
}

const ModalEditLead: FC<Props> = ({
    lead,
    getLeadsByClient,
    closeModalLeadEdit
}) => {

    const initialFormDataEditLead: FormDataEditLead = {
        leadId: lead.lead_id,
        name: lead.lead_data.name,
        phone: lead.lead_data.lead_phone,
        mail: lead.lead_data.lead_mail,
        leadUrl: lead.leadUrl,
        dcfs: lead.dcfs,
        comments: lead.lead_comments,
        reassonModification: ''
    }

    const [formDataEditLead, setFormDataEditLead] = useState<FormDataEditLead>(
        initialFormDataEditLead
    )

    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        text: '',
        variant: 'primary',
    })

    //Variables para manejo de estado de errores en inputs del formulario
    const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({})

    //Algunas constantes desde el userContext
    const { user, token, updateToken } = useContext(UserContext)
    const clientId: string = user.cli_id
    const userToken: string = token

    const handleNameInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        if (value !== '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: undefined,
            }))
        }

        if (value === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Debes dar un nombre al  lead',
            }))
        }

        setFormDataEditLead((prevData) => ({
            ...prevData,
            name: value
        }))
    }

    const handleMailInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        if (value !== '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                mail: undefined,
            }))
        }

        if (value === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                mail: 'Debes dar un mail al lead',
            }))
        }

        setFormDataEditLead((prevData) => ({
            ...prevData,
            mail: value
        }))
    }

    const handlePhoneInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        if (value !== '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: undefined,
            }))
        }

        if (value === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: 'Debes dar un telefono al lead',
            }))
        }

        setFormDataEditLead((prevData) => ({
            ...prevData,
            phone: value
        }))
    }

    const handleCommentsTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target

        setFormDataEditLead((prevData) => ({
            ...prevData,
            comments: value
        }))

    }

    const handleReassonTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target

        if (value !== '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                reassonModification: undefined,
            }))
        }

        if (value === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                reassonModification: 'Debes llenar este campo',
            }))
        }

        setFormDataEditLead((prevData) => ({
            ...prevData,
            reassonModification: value
        }))
    }

    const handleFormSubmit = async (): Promise<void> => {

        console.log('ya se fue el formulario')
        console.log({ formDataEditLead })

        const leadId: string = formDataEditLead.leadId
        const name: string = formDataEditLead.name
        const mail: string = formDataEditLead.mail
        const phone: string = formDataEditLead.phone
        const LeadUrl: string = formDataEditLead.leadUrl
        const dcfs: string[] = formDataEditLead.dcfs
        const comments: string = formDataEditLead.comments
        const reason: string = formDataEditLead.reassonModification

        try {
            const { data: jsonData, code, msg } = await leadEdit(userToken, leadId, name, mail, phone, LeadUrl, dcfs, comments, reason)

            if (code === 401 && msg === 'Token no valido') {
                sessionExpired('Ha expirado la sesion')
                return
            } else {
                setAlertInfo({
                    text: code === 200 ? 'El lead se modificó correctamente' : 'No se pudo modificar el lead',
                    variant: code === 200 ? 'success' : 'danger'
                })

                setTimeout(() => {
                    setAlertInfo({
                        text: '',
                        variant: ''
                    })
                    closeModalLeadEdit()
                    setFormDataEditLead(initialFormDataEditLead)
                    setErrors({})
                    getLeadsByClient()
                }, 3000);
            }
        } catch (error) {
            setAlertInfo({
                text: 'Error en la solicitud',
                variant: 'danger'
            })
            closeModalLeadEdit()
            setFormDataEditLead(initialFormDataEditLead)
            setErrors({})
        }

    }

    const checkFormToSubmit = () => {
        if (formDataEditLead.name === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Debes dar un nombre al  lead',
            }))
            return
        }

        if (formDataEditLead.mail === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                mail: 'Debes dar un mail al lead',
            }))
            return
        }

        if (formDataEditLead.phone === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: 'Debes dar un telefono al lead',
            }))
            return
        }

        if (formDataEditLead.reassonModification === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                reassonModification: 'Debes llenar este campo',
            }))
            return
        }

        handleFormSubmit()
    }

    return (
        <>
            <Modal.Header closeButton onClick={closeModalLeadEdit}>
                <Modal.Title>
                    <h1>Editar lead</h1>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='px-7'>

                    <div className='mb-5'>
                        <div>
                            <label className='fs-6 fw-bold required'>
                                Nombre del lead
                            </label>
                            <input
                                required
                                type='text'
                                name='name'
                                className={`form-control form-control-solid mb-3 mb-lg-0 ${errors.name && 'is-invalid'}`}
                                // className='form-control form-control-solid mb-3 mb-lg-0 required'
                                placeholder='Nombre'
                                value={formDataEditLead.name}
                                onChange={(e) => {
                                    handleNameInput(e)
                                }}
                            />
                            {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                        </div>
                    </div>

                    <div className='mb-5'>
                        <div>
                            <label className='fs-6 fw-bold required'>
                                Correo electrónico del lead
                            </label>
                            <input
                                required
                                type='mail'
                                name='mail'
                                className={`form-control form-control-solid mb-3 mb-lg-0 ${errors.mail && 'is-invalid'}`}
                                // className='form-control form-control-solid mb-3 mb-lg-0 required'
                                placeholder='Correo electrónico'
                                value={formDataEditLead.mail}
                                onChange={(e) => {
                                    handleMailInput(e)
                                }}
                            />
                            {errors.mail && <div className='invalid-feedback'>{errors.mail}</div>}
                        </div>
                    </div>

                    <div className='mb-5'>
                        <div>
                            <label className='fs-6 fw-bold required'>
                                Teléfono del lead
                            </label>
                            <input
                                required
                                type='phone'
                                name='phone'
                                className={`form-control form-control-solid mb-3 mb-lg-0 ${errors.phone && 'is-invalid'}`}
                                // className='form-control form-control-solid mb-3 mb-lg-0 required'
                                placeholder='Teléfono'
                                value={formDataEditLead.phone}
                                onChange={(e) => {
                                    handlePhoneInput(e)
                                }}
                            />
                            {errors.phone && <div className='invalid-feedback'>{errors.phone}</div>}
                        </div>
                    </div>

                    <div className='mb-5'>
                        <div>
                            <label className='fs-6 fw-bold'>
                                Comentarios
                            </label>
                            <textarea
                                placeholder='Agrega un comentario sobre el lead...'
                                className='form-control form-control-solid'
                                rows={2}
                                name='comments'
                                value={formDataEditLead.comments}
                                onChange={(e) => {
                                    handleCommentsTextArea(e)
                                }}
                            />
                        </div>
                    </div>

                    <div className='mb-5'>
                        <div>
                            <label className='fs-6 fw-bold required'>
                                Motivo de la actualización
                            </label>
                            <textarea
                                placeholder='Escribe el motivo de la edición de lead...'
                                className={`form-control form-control-solid mb-3 mb-lg-0 ${errors.reassonModification && 'is-invalid'}`}
                                // className='form-control form-control-solid'
                                rows={2}
                                name='reassonModification'
                                value={formDataEditLead.reassonModification}
                                onChange={(e) => {
                                    handleReassonTextArea(e)
                                }}
                            />
                            {errors.reassonModification && <div className='invalid-feedback'>{errors.reassonModification}</div>}
                        </div>
                    </div>
                </div>
                
                {alertInfo.text && (
                    <div className='px-20'>
                        <div
                            className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`}
                            role='alert'
                        >
                            <div>{alertInfo.text}</div>
                        </div>
                    </div>
                )}
                
            </Modal.Body>
            <Modal.Footer>
                <button
                    className='btn btn-sm btn-primary'
                    type='submit'
                    onClick={() => {
                        checkFormToSubmit()
                    }}
                >
                    Editar
                </button>
                <button
                    className='btn btn-sm btn-secondary'
                    type='button'
                    onClick={() => {
                        closeModalLeadEdit()
                    }}
                >
                    Cancelar
                </button>
            </Modal.Footer>
        </>

    )
}

export { ModalEditLead }