import { Modal } from "react-bootstrap";
import { FC, useContext, useEffect, useState } from "react";
import { SpamDataSimplyfied } from "../interfaces/leadsTypes";
import { AlertInfo } from "../../../utils/interfaces/_InterfacesTypes";
import { sessionExpired } from "../../../utils/functions/_Functions";
import { UserContext } from "../../../app-context/user-context/UserContext";
import { restoreLead } from "../services/_LeadSpamApi";

type Props = {
    lead: SpamDataSimplyfied
    closeModalRestoreLead: () => void
    getLeadsSpamByClient: () => void
}

const ModalRestoreLead: FC<Props> = ({
    lead,
    closeModalRestoreLead,
    getLeadsSpamByClient
}) => {
    console.log({ lead })

    //Algunas constantes desde el userContext
    const { user, token, updateToken } = useContext(UserContext)
    const clientId: string = user.cli_id
    const userToken: string = token

    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        text: '',
        variant: 'primary',
    })

    const handleFormSubmit = async (): Promise<void> => {
        console.log('se ha enviado el form')

        const leadId: string = lead.lead_id

        try {
            const { data: jsonData, code, msg } = await restoreLead(userToken, leadId)

            if (code === 401 && msg === 'Token no valido') {
                sessionExpired('Ha expirado la sesion')
                return
            } else {
                setAlertInfo({
                    text: code === 200 ? 'El lead ha sido restaurado' : 'No se pudo restaurar el lead',
                    variant: code === 200 ? 'success' : 'danger'
                })

                setTimeout(() => {
                    setAlertInfo({
                        text: '',
                        variant: ''
                    })
                    closeModalRestoreLead()
                    getLeadsSpamByClient()
                }, 3000);
            }

        } catch (error) {
            setAlertInfo({
                text: 'Hubo un error al intentar restaurar el lead',
                variant: 'danger'
            })
        }
    }

    return (
        <>
            <Modal.Header closeButton onClick={closeModalRestoreLead}>
                <Modal.Title>
                    <h1>Restaurar Leads</h1>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className='alert alert-dismissible bg-light-warning border border-warning mb-4'>
                        <div className='d-flex flex-column flex-sm-row p-5'>
                            <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-exclamation-triangle-fill'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
                                </svg>
                            </span>
                            <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
                                <h5 className='mb-1'>Confirmación de restauración de Lead</h5>
                                <span className='text-dark'>
                                    <p>¿Estás seguro de querer restaurar al lead: <span className='fw-bold'>{lead.lead_name}</span>, con el id <span className='fw-bold'>{lead.lead_id}</span>?</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {alertInfo.text && (
                    <div className='px-20'>
                        <div
                            className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`}
                            role='alert'
                        >
                            <div>{alertInfo.text}</div>
                        </div>
                    </div>
                )}

            </Modal.Body>
            <Modal.Footer>
                <button
                    className='btn btn-sm btn-secondary'
                    onClick={closeModalRestoreLead}
                >
                    Cancelar
                </button>

                <button
                    className='btn btn-sm btn-primary'
                    type='submit'
                    onClick={() => {
                        handleFormSubmit()
                    }}
                >
                    Restaurar
                </button>
            </Modal.Footer>
        </>
    )

}

export { ModalRestoreLead }