import React, { FC, useState, useEffect, useContext, ChangeEvent } from "react";
import { Lead } from "../interfaces/leadsTypes";
import { Modal } from "react-bootstrap";
import { campaignById } from "../../campaigns/services/_CampaignsListApi";
import { UserContext } from "../../../app-context/user-context/UserContext";
import { sessionExpired } from "../../../utils/functions/_Functions";
import { FormDataGradeLead } from "../interfaces/leadsTypes";
import { AlertInfo } from "../../../utils/interfaces/_InterfacesTypes";
import { leadGrade } from "../services/_LeadGradeApi";

type Props = {
    lead: Lead
    closeModalLeadGrade: () => void
    getLeadsByClient: () => void
}

type Option = {
    value: string;
    label: string;
};

const ModalGradeLead: FC<Props> = ({
    lead,
    closeModalLeadGrade,
    getLeadsByClient
}) => {
    const initialFormDataGradeLead: FormDataGradeLead = {
        leadId: lead.lead_id,
        score: lead.leadGrade,
        comments: lead.leadGradeComments,
    }

    const [formDataGradeLead, setFormDataGradeLead] = useState<FormDataGradeLead>(initialFormDataGradeLead)

    //Variables para manejo de estado de errores en inputs del formulario
    const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({})

    //Algunas constantes desde el userContext
    const { user, token, updateToken } = useContext(UserContext)
    const clientId: string = user.cli_id
    const userToken: string = token

    // Inicializamos el estado con un array vacío de opciones
    const [options, setOptions] = useState<Option[]>([]);

    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        text: '',
        variant: 'primary',
    })

    console.log({ lead })

    const getGradesConfig = async (): Promise<void> => {

        const campaignId: string = lead.cam_id

        try {
            const { data: jsonData, code, msg } = await campaignById(userToken, campaignId)

            if (code === 401 && msg === 'Token no valido') {
                sessionExpired('La sesion ha caducado')
                return
            } else {
                const gradeOptions: Record<string, string> = jsonData.grade_options

                const transformedOptions: Option[] = Object.entries(gradeOptions).map(([value, label]) => ({
                    value,
                    label: `${value} - ${label}`,
                  }));
                
                setOptions(transformedOptions);

                console.log({gradeOptions})
            }
        } catch (error) {
            console.log('Error obteniendo la información de la campaña: ', error)
        }

    }

    const handleGradeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target

        if (value !== '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                score: undefined,
            }))
        }

        if (value === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                score: 'Debes seleccionar una calificación valida',
            }))
        }

        setFormDataGradeLead((prevData) => ({
            ...prevData,
            score: value
        }))

    }

    const handleGradeCommentsInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target

        if (value !== '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                comments: undefined,
            }))
        }

        if (value === '' || value === 'sin calificación') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                comments: 'Debes llenar este campo',
            }))
        }

        setFormDataGradeLead((prevData) => ({
            ...prevData,
            comments: value
        }))
    }

    const handleFormSubmit = async (): Promise<void> => {

        console.log('ya se va el formulario')
        console.log({formDataGradeLead})

        const leadId: string = formDataGradeLead.leadId
        const score: string = formDataGradeLead.score
        const comments: string = formDataGradeLead.comments

        try {
            const { data: jsonData, code, msg } = await leadGrade(userToken, leadId, score, comments)

            if (code === 401 && msg === 'Token no valido') {
                sessionExpired('Ha expirado la sesion')
                return
            } else {
                setAlertInfo({
                    text: code === 200 ? 'El lead se califico correctamente' : 'No se pudo calificar el lead',
                    variant: code === 200 ? 'success' : 'danger'
                })

                setTimeout(() => {
                    setAlertInfo({
                        text: '',
                        variant: ''
                    })
                    closeModalLeadGrade()
                    setFormDataGradeLead(initialFormDataGradeLead)
                    setErrors({})
                    getLeadsByClient()
                }, 3000);
            }
        }catch (error){
            setAlertInfo({
                text: 'Error en la solicitud',
                variant: 'danger'
            })
            closeModalLeadGrade()
            setFormDataGradeLead(initialFormDataGradeLead)
            setErrors({})
        }

    }

    const checkFormToSubmit = () => {

        if (formDataGradeLead.score === '' || formDataGradeLead.score === 'sin calificación') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                score: 'Debes seleccionar una calificación valida',
            }))
            return
        }

        if (formDataGradeLead.comments === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                comments: 'Debes llenar este campo',
            }))
            return
        }

        handleFormSubmit()

    }

    useEffect (() => {
        getGradesConfig()
    }, [])

    return (
        <>
            <Modal.Header closeButton onClick={closeModalLeadGrade}>
                <Modal.Title>
                    <h1>Calificar lead</h1>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex">
                    <span className="fw-bold">Nombre del lead: </span>&nbsp;&nbsp;<p>{lead.lead_data.name}</p>
                </div>
                <div className="d-flex">
                    <span className="fw-bold">Correo electrónico del lead: </span>&nbsp;&nbsp;<p>{lead.lead_data.lead_mail}</p>
                </div>
                <div className="d-flex">
                    <span className="fw-bold">Teléfono del lead: </span>&nbsp;&nbsp;<p>{lead.lead_data.lead_phone}</p>
                </div>

                <div className='mb-5 mt-4'>
                    <label className='fs-6 fw-bold required'>
                        Calificación
                    </label>
                    <select
                        // className="form-select form-select-solid"
                        className={`form-select form-select-solid mb-3 mb-lg-0 ${errors.score && 'is-invalid'}`}
                        name='score'
                        value={formDataGradeLead.score}
                        onChange={(e) => {
                            handleGradeSelect(e)
                        }}
                    >
                        <option value=''>-- Selecciona una calificacion --</option>
                        {options.map(option => (
                            <option key={option.value} value={option.value}>
                            {option.label}
                            </option>
                        ))}
                        {/* <option value=''>1 - No Localizable</option>
                        <option value=''>2 - No Interesado</option>
                        <option value=''>3 - Interés Bajo</option>
                        <option value=''>4 - Interés Medio</option>
                        <option value=''>5 - Interés Alto</option> */}
                    </select>
                    {errors.score && <div className='invalid-feedback'>{errors.score}</div>}
                </div>

                <div className='mb-3'>
                    <div>
                        <label className='fs-6 fw-bold required'>
                            Comentarios
                        </label>
                        <textarea
                            placeholder='Escribe un comentario sobre el lead...'
                            name='comments'
                            // className='form-control form-control-solid'
                            className={`form-control form-control-solid mb-3 mb-lg-0 ${errors.comments && 'is-invalid'}`}
                            rows={2}
                            value={formDataGradeLead.comments}
                            onChange={(e) => {
                                handleGradeCommentsInput(e)
                            }}
                        />
                        {errors.comments && <div className='invalid-feedback'>{errors.comments}</div>}
                    </div>
                </div>

                {alertInfo.text && (
                    <div className='px-20'>
                        <div
                            className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`}
                            role='alert'
                        >
                            <div>{alertInfo.text}</div>
                        </div>
                    </div>
                )}

            </Modal.Body>
            <Modal.Footer>
                <button 
                    className='btn btn-sm btn-primary' 
                    type='submit'
                    onClick={() => {
                        checkFormToSubmit()
                    }}
                >
                    Calificar
                </button>
                <button
                    className='btn btn-sm btn-secondary'
                    type='reset'
                    onClick={() => {
                        closeModalLeadGrade()
                    }}
                >
                    Cancelar
                </button>
            </Modal.Footer>
        </>
    )
}

export { ModalGradeLead }