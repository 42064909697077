import axios from 'axios';
import { DragonMainApi, EPSellersNew, apiDragon } from '../../../../utils/constants/_Constants';
import { apiPetitions } from '../../../../../_metronic/helpers/apis';

async function sellersNew(
    idClient: string,
    type: string,
    userPrivilage: number,
    name: string,
    phone: string,
    whatsapp: string,
    mail: string,
    password: string,
    validPassword: string,
    calendly: string,
    userToken: string,
    productsAssigned: any
    ){
        console.log(productsAssigned)
    
    try{
        const requestData: any = { // Definimos requestData como any para poder agregar propiedades dinámicamente
            idClient,
            type,
            name,
            phone,
            whatsapp,
            mail,
            password,
            validPassword,
            calendly,
            productsAssigned
        };

        // Verificamos si userPrivilage es distinto de 0 antes de agregarlo al objeto requestData
        if (userPrivilage !== 0) {
            requestData.userPrivilage = userPrivilage;
        }

        const response = await apiPetitions(apiDragon, DragonMainApi+EPSellersNew, requestData, {
                'Content-Type': 'application/json',
                'x-token': userToken
        }, 'post');

        console.log(response)

        return response;

        

    } catch (error) {
        throw error;
        // console.log('Error de nuevo Vendedor ', error)
    }
}

export { sellersNew };