import React from 'react'

export const Manual = () => {
  return (
    <>
    <div>
        Oa Manual
    </div>
    </>
  )
}
