import React, {FC, useContext, useState} from 'react'
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import {RankingInfo, rankItem} from '@tanstack/match-sorter-utils'
import {ChangeStatusObject, ProductByClientBU} from '../interfaces/accountManagerTypes'
import {Button, Dropdown, DropdownButton, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AlertInfo} from '../../../utils/interfaces/_InterfacesTypes'
import {ModalActiveUnactiveProduct} from '../modals/ModalActiveUnactiveProduct'
import {ModalRenameProduct} from '../modals/ModalRenameProduct'
import {formatTextMIddleDash, sessionExpired} from '../../../utils/functions/_Functions'
import {useIntl} from 'react-intl'
import {AMProductStatusActivate} from '../services/_AMProductStatusActivate'
import {AMProductStatusDeactivate} from '../services/_AMProductStatusDeactivate'
import {UserContext} from '../../../app-context/user-context/UserContext'

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

type Props = {
  productsByClientList: any
  currentClientIdSelected: string
  currentClientNameSelected: string
  currentClientStatusSelected: number | undefined
  updateProductsByClientList: (
    clientId: string,
    clientStatus: number | undefined,
    clientName: string
  ) => void;
}

export const ProductsAccountManagerTable: FC<Props> = ({
  productsByClientList,
  currentClientIdSelected,
  currentClientNameSelected,
  currentClientStatusSelected,
   updateProductsByClientList
}) => {
  console.log('-------------')
  console.log(currentClientIdSelected)
  console.log(currentClientStatusSelected)
  console.log(currentClientNameSelected)
  console.log('-------------')

  // console.log(productsByClientList)

  const renderTooltip = (props) => <Tooltip {...props}>Copiar id de producto</Tooltip>

  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [showModalProductStatus, setshowModalProductStatus] = useState<boolean>(false)
  const [showModalProductRename, setshowModalProductRename] = useState<boolean>(false)

  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })
  const [productModalData, setproductModalData] = useState<any>()
  const {user, token, updateToken} = useContext(UserContext)
  const userToken = token

  //Definimos variable para guardar info del producto cuyo estatus será modificado
  const [productData, setProductData] = useState({})

  const handleCopyToClipboard = (text: string) => {
    // Intentar copiar el texto al portapapeles
    navigator.clipboard.writeText(text).catch((error) => {
      console.error('Error al copiar al portapapeles:', error)
      alert('Error al copiar al portapapeles. Por favor, inténtelo de nuevo.')
    })
  }

  //Abrimos el modal de activación de producto
  const handleOpenModalProductStatus = (product) => {
    setProductData(product)
    setshowModalProductStatus(true)
    setproductModalData(product)
  }

  //Abrimos el modal de cambio de nombre de producto
  const handleOpenModalProductRename = (product) => {
    setProductData(product)
    setshowModalProductRename(true)
    setproductModalData(product)
  }

  //Cerramos el modal de cambio de nombre de producto
  const handleCloseModalProductRename = () => {
    setshowModalProductRename(false)
  }

  //Cerramos el modal de activación de producto
  const handleCloseModalProductStatus = () => {
    setshowModalProductStatus(false)
  }

  //Guardamos cambios sobre el cambio del status del producto
  const handleSaveProductStatus = () => {
    //Definimos el state del alert
    setAlertInfo({
      text: 'Solicitud Enviada Correctamente. En espera de aprobación',
      variant: 'success',
    })

    //Esperamos 3 segundos, limpiamos las alertas y cerramos el modal y apagamos el switch
    setTimeout(() => {
      setAlertInfo({
        text: '',
        variant: '',
      })
      setshowModalProductStatus(false)
    }, 3000)
  }

  //Aquí se debe disparar la función que hará el cambio del estatus del producto
  const handleChangeProductStatus = async (product) => {
    console.log(product)
    console.log(product.status)

    try {
      let response
      let petitionType
      if (product.status == 1) {
        response = await AMProductStatusDeactivate(product.pro_id, userToken)
        petitionType = 'AMProductStatusDeactivate'
        console.log('Se intentó desactivar')
      } else {
        response = await AMProductStatusActivate(product.pro_id, userToken)
        petitionType = 'AMProductStatusActivate'
        console.log('Se intentó activar')
      }

      const {code, msg}:ChangeStatusObject = response

      if (code === 401 && msg === 'Token no válido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      }

      if (code > 199 && code < 300 && petitionType === 'AMProductStatusDeactivate') {
        setAlertInfo({
          text: '¡Se ha enviado la solicitud para desactivar este producto correctamente!',
          variant: 'success',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          updateProductsByClientList(currentClientIdSelected,currentClientStatusSelected,currentClientNameSelected  );
          handleCloseModalProductStatus()
        }, 3000)
        return
      }

      if (code > 199 && code < 300 && petitionType === 'AMProductStatusActivate') {
        setAlertInfo({
          text: '¡Se ha enviado la solicitud para activar este producto correctamente!',
          variant: 'success',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          updateProductsByClientList(currentClientIdSelected,currentClientStatusSelected,currentClientNameSelected  );
          handleCloseModalProductStatus()
        }, 3000)
        return
      }
    } catch (error) {
      setAlertInfo({
        text: `${error}`,
        variant: 'danger',
      })
      setTimeout(() => {
        setAlertInfo({
          text: '',
          variant: '',
        })
      }, 3000)
      throw error
    }
  }

  const columns = React.useMemo<ColumnDef<ProductByClientBU, any>[]>(
    () => [
      {
        header: `Productos del Cliente: ${formatTextMIddleDash(currentClientNameSelected)}`,
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'pro_id',
            cell: (info) => (
              <OverlayTrigger placement='top' overlay={renderTooltip}>
                <button
                  className='btn btn-light-primary'
                  onClick={() => handleCopyToClipboard(info.getValue())}
                >
                  {info.getValue()}
                </button>
              </OverlayTrigger>
            ),
            header: () => <span>Id</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'name',
            cell: (info) => info.getValue(),
            header: () => <span>Nombre</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'date_add',
            id: 'date_add',
            cell: (info) => info.getValue(),
            header: () => <span>Fecha</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'totalCampaigns',
            id: 'totalCampaigns',
            cell: (info) => info.getValue(),
            header: () => <span>Campañas</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'status',
            id: 'status',
            cell: (info) => {
              const status = info.getValue()
              let statusText: string = ''
              let colorText: string = ''
              switch (status) {
                case 0:
                  statusText = 'Inactivo'
                  colorText = 'danger'
                  break
                case 1:
                  statusText = 'Activo'
                  colorText = 'success'
                  break
                case 2:
                  statusText = 'Por desactivar'
                  colorText = 'warning'
                  break
                case 3:
                  statusText = 'Por activar'
                  colorText = 'warning'
                  break
                default:
                  statusText = 'Desconocido'
                  colorText = 'danger'
              }
              return <span className={`badge badge-light-${colorText} p-4`}>{statusText}</span>
            },
            header: () => <span>Estatus</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'actions',
            cell: (info) => {
              const status: number | string = info.row.original.status
              let statusText: string = ''
              let stateButton: boolean = false

              if (currentClientStatusSelected === 0) {
                switch (status) {
                  case 0:
                    statusText = 'Activar'
                    stateButton = true
                    break

                  case 1:
                    statusText = 'Desactivar'
                    stateButton = true
                    break

                  case 2:
                    statusText = 'Por desactivar'
                    stateButton = true
                    break
                  case 3:
                    statusText = 'Por activar'
                    stateButton = true
                    break
                  default:
                    statusText = 'Desconocido'
                    stateButton = true
                    break
                }
              } else if (currentClientStatusSelected === 1) {
                switch (status) {
                  case 0:
                    statusText = 'Activar'
                    stateButton = false
                    break

                  case 1:
                    statusText = 'Desactivar'
                    stateButton = false
                    break

                  case 2:
                    statusText = 'Por desactivar'
                    stateButton = true
                    break
                  case 3:
                    statusText = 'Por activar'
                    stateButton = true
                    break
                  default:
                    statusText = 'Desconocido'
                    stateButton = true
                    break
                }
              } else if (currentClientStatusSelected === 2 || currentClientStatusSelected === 3) {
                switch (status) {
                  case 0:
                    statusText = 'Activar'
                    stateButton = true
                    break

                  case 1:
                    statusText = 'Desactivar'
                    stateButton = true
                    break

                  case 2:
                    statusText = 'Por desactivar'
                    stateButton = true
                    break
                  case 3:
                    statusText = 'Por activar'
                    stateButton = true
                    break
                  default:
                    statusText = 'Desconocido'
                    stateButton = true
                    break
                }
              }

              console.log(info.row.original) // Aquí está el console.log
              return (
                <div>
                  <DropdownButton
                    size='sm'
                    id={`dropdown-${info.row.original.pro_id}`}
                    title='Acciones'
                    disabled={stateButton}
                  >
                    <Dropdown.Item
                      disabled={stateButton}
                      onClick={() => handleOpenModalProductStatus(info.row.original)}
                    >
                      {statusText}
                    </Dropdown.Item>

                    <Dropdown.Item
                      disabled={stateButton}
                      onClick={() => handleOpenModalProductRename(info.row.original)}
                    >
                      Actualizar nombre
                    </Dropdown.Item>
                  </DropdownButton>
                  {/* <button
                    className='btn btn-primary btn-sm'
                    disabled={stateButton}
                    // id={`button-${info.row.original.id}`}
                    onClick={() => handleOpenModalProductStatus(info.row.original)}
                  >
                    {statusText}
                  </button> */}
                </div>
              )
            },
            header: () => <span>Acciones</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  )

  const table = useReactTable({
    data: productsByClientList,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  React.useEffect(() => {
    if (table.getState().columnFilters[1]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name' || table.getState().sorting[0]?.desc) {
        table.setSorting([{id: 'name', desc: false}])
      }
    }
  }, [table.getState().columnFilters[1]?.id])

  return (
    <>
      <div className='card-body table-responsive'>
        <table className='table table-row-bordered gs-3 gy-4 gx-12'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className='card-footer'>
        <div className='d-flex justify-content-between gap-2'>
          <div id='SwitchPages'>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
          </div>

          <div id='PagesFinder' className='text-center'>
            {' '}
            <span className='flex items-center gap-1'>
              Página{' '}
              <strong>
                {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
              </strong>{' '}
              | Ir a la página:
              <input
                type='number'
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
                className='form-control form-control-solid w-16'
              />
            </span>
          </div>

          <div id='showFiles' className='text-center'>
            <select
              className='form-select form-select-solid'
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Mostrar {pageSize} registros
                </option>
              ))}
            </select>
            <p>{table.getPrePaginationRowModel().rows.length} Registros en total</p>
          </div>
        </div>
      </div>
      {/* Modal del status del producto */}
      <Modal
        show={showModalProductStatus}
        onHide={handleCloseModalProductStatus}
        scrollable
        className='modal-lg'
      >
        <Modal.Header closeButton onClick={handleCloseModalProductStatus}>
          <Modal.Title>Estatus del Producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalActiveUnactiveProduct product={productModalData} />
        </Modal.Body>
        {alertInfo.text && (
          <div className='px-20'>
            <div
              className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`}
              role='alert'
            >
              <div>{alertInfo.text}</div>
            </div>
          </div>
        )}
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => {
              handleChangeProductStatus(productData)
            }}
          >
            Guardar
          </Button>
          <Button
            variant='secondary'
            onClick={() => {
              handleCloseModalProductStatus()
            }}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de cambio de nombre del producto */}
      <Modal
        show={showModalProductRename}
        onHide={handleCloseModalProductRename}
        scrollable
        className='modal-default'
      >
        <Modal.Header closeButton onClick={handleCloseModalProductRename}>
          <Modal.Title>Cambiar nombre de producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ModalRenameProduct product={productModalData}/>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary btn btn-sm'
            // onClick={() => {
            //   handleChangeProductStatus(productData)
            // }}
          >
            Guardar
          </Button>
          <Button
            variant='secondary'
            onClick={() => {
              handleCloseModalProductRename()
            }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

function Filter({column, table}: {column: Column<any, unknown>; table: Table<any>}) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
    {column.id === 'actions' ? (
      <>
        <datalist id={column.id + 'list'}>
          {sortedUniqueValues.slice(0, 5000).map((value: any) => (
            <option value={value} key={value} />
          ))}
        </datalist>
        <DebouncedInput
          type='text'
          value={(columnFilterValue ?? '') as string}
          onChange={(value) => column.setFilterValue(value)}
          placeholder={`Buscar... (${column.getFacetedUniqueValues().size})`}
          className='w-36 border shadow rounded'
          list={column.id + 'list'}
          disabled={true}
        />
        <div className='h-1' />
      </>
    ) : (
      <>
        <datalist id={column.id + 'list'}>
          {sortedUniqueValues.slice(0, 5000).map((value: any) => (
            <option value={value} key={value} />
          ))}
        </datalist>
        <DebouncedInput
          type='text'
          value={(columnFilterValue ?? '') as string}
          onChange={(value) => column.setFilterValue(value)}
          placeholder={`Buscar... (${column.getFacetedUniqueValues().size})`}
          className='w-36 border shadow rounded'
          list={column.id + 'list'}
        />
        <div className='h-1' />
      </>
    )}
  </>
  )
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}
