import React, { FC, useEffect, useState } from 'react'
import { Lead, MailsLeadDetail, Mail, LeadDetailInfo } from '../../../../interfaces/leadsTypes';
import { LoadingAnimation } from '../../../../../../utils/components/loading/LoadingAnimation'
import { KTIcon } from '../../../../../../../_metronic/helpers';

type Props = {
  leadData: LeadDetailInfo | undefined;
  currentNavSelected: string;
}

export const MailNav: FC<Props> = ({
  leadData,
  currentNavSelected
}) => {

  const initialMailsItem: MailsLeadDetail = {
    mails: [],
    mailsCount: 0
  }

  //Constante para mnejar el item con el listado de mails
  const [mailsItem, setMailsItem] = useState<MailsLeadDetail>(initialMailsItem)

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>("");

  //Funcion para verificar el tamaño del arreglo de mails
  const checkMailsListLength = () => {
    //Seteamos mensaje informativo
    mailsItem.mails.length === 0 && setInfoMessageLoading("No se encontro ningún registro de mails")
  }

  //Funcion para obtener el registro de mails por id de lead
  const getMailsByLead = () => {
    setTimeout(() => {
      try {
        const newMailsList: MailsLeadDetail =
        {
          mails: [
            {
              mailId: "2456456",
              mailDateAdd: "2024-05-03 10:13:48",
              mailLeadOrSeller: "Lead",
              mailSender: leadData?.leadData.leadName,
              mailEvent: "delivered",
              mailgunId: "egregf-34t345-gerger-fff",
              mailTo: leadData?.leadData.leadMail
            },
            {
              mailId: "2456455",
              mailDateAdd: "2024-05-02 10:10:48",
              mailLeadOrSeller: "Lead",
              mailSender: leadData?.leadData.leadName,
              mailEvent: "opened",
              mailgunId: "rtyury-3563-gerte-343433",
              mailTo: leadData?.leadData.leadMail
            }
          ],
          mailsCount: 2,
        }

        setMailsItem(newMailsList)
        //Ocultamos la animacion de carga
        setIsLoading(false)
        //Verificamos el tamaño del arreglo de mails disparando la funcion
        checkMailsListLength()

      } catch (error) {
        console.log('Error obteniedo los mails: ', error)
        //Ocultamos la animacion de carga
        setIsLoading(false)
        //Seteamos mensaje informativo
        setInfoMessageLoading('Hubo un error en la petición')
      }
    }, 2000);
  }

  /*Disparamos la funcion de obtencion de registros al 
  montar el componente*/
  useEffect(() => {
    getMailsByLead()
  }, [])


  return (
    <>
      <div className="container ml-15 mt-4 py-12" id="mails-container">

        {/* Si isLoading es igual a 'true' lo mostramos */}
        {isLoading && <div className='py-12'><LoadingAnimation alignment='center' label='Obteniendo registros de mails...' /></div>}

        {/* Si isLoading es diferente a 'true' y el arreglo de mails es mayor a '0' montamos información */}
        {!isLoading && mailsItem.mails.length > 0 && (
          <>
            <div className='mb-8'>
              <span className='h5 mb-1'>{mailsItem.mailsCount}</span>&nbsp;<span> interacciones de mails</span>
            </div>
            <div className='mb-2 mt-2'>
              {mailsItem.mails.map((mail: Mail, index: number) => (
                <div
                  key={index}
                  className={`d-flex alignt-items-center ${mail.mailLeadOrSeller === 'Lead'
                    ? 'justify-content-start'
                    : 'justify-content-end'
                    } mb-10`}
                >
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <KTIcon
                        iconType='outline'
                        iconName={`${mail.mailLeadOrSeller === 'Lead' ? 'profile-circle' : 'user-square'}`}
                        className='fs-2 me-1'
                      />
                      <div>
                        <span className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'>
                          {mail.mailLeadOrSeller}
                        </span>

                      </div>
                    </div>
                    <div className={`p-5 rounded ${mail.mailLeadOrSeller === 'Lead' ? 'bg-light-info' : 'bg-light-primary'
                      } text-dark fw-bold mw-lg-400px text-start`}
                      data-kt-element='message-text'
                    >
                      <p>
                        <strong>Fecha: </strong>{mail.mailDateAdd}
                      </p>
                      <p>
                        <strong>Correo: </strong>{mail.mailTo}
                      </p>
                      <p>
                        <strong>Estatus: </strong>{mail.mailEvent}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {/* //Si isLoading es diferente a 'true' y el arreglo de mails es iguala '0' mostramos mensaje de error */}
        {!isLoading && mailsItem.mails.length === 0 && (
          <div className='py-12'><p>{infoMessageLoading}</p></div>
        )}

      </div>

    </>
  )
}
