// Importamos hooks de react que usaremos para este componente
import React, { useState, useContext } from "react";
// Importamos componentes de la libreria de bootstrap para react
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
// Importamos libreria para manejo de lenguaje 
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from "../../../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------

// Importamos funcion fileNameCleaner para limpiar el nombre del archivo que viene del back
import { fileNameCleaner } from "../../../../utils/functions/_Functions";

// ---------------------------------------------------------------------------------------

// Importamos la funcion productsEditMedia para conexion con API de productos
import { productsEditMedia } from "../../services/_ProductsEditApi";

// ---------------------------------------------------------------------------------------

import { sessionExpired } from "../../../../utils/functions/_Functions";

// ---------------------------------------------------------------------------------------

// Importamos las interfaces globales que utilizaremos en este componente
import { AlertInfo, AlertInfoPerInput } from "../../../../utils/interfaces/_InterfacesTypes";

// Importamos constantes
import { BucketMediaURL } from "../../../../utils/constants/_Constants";

// Iniciamos funcion para construir componente FormProductMedia
function MultimediaTabForm({idProduct, merchPdfProduct, logoProduct, headerProduct, whaImgProduct, formDataEdit, getProductsRegisters, closeModalEdit}) {
    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl();

    //Algunas variables desde el usercontext
    const { token } = useContext(UserContext);
    const userToken = token;

    //Variables con el estado de la alerta da confirmacion o error en peticion
    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        text: '',
        variant: 'primary',
    });

    //Variables con el estado de la alerta da confirmacion o error por input
    const [alertInfoInput, setAlertInfoInput] = useState<AlertInfoPerInput>({
        text: '',
        variant: '',
        inputName: '',
    })

    //Variables para el manejo del arreglo de archivos a enviar
    const [archivos, setArchivos] = useState<File[]>([]);
    
    //Variables para el manejo de visibilidad de los inputs dependiendo los errores existentes
    const [headerInputState, setHeaderInputState] = useState<boolean>(false);
    const [whaInputState, setWhaInputState] = useState<boolean>(false);
    const [pdfInputState, setPdfInputState] = useState<boolean>(false);
    const [logoInputState, setLogoInputState] = useState<boolean>(false);
    const [buttonSubmitState, setButtonSubmitState] = useState<boolean>(true);

    // Funcion para determinar el mensaje de error que mostraremos dependiendo el error utilizando switch
    const handleInputValidation = (errorType: string, inputName: string) => {

        // Inicia switch
        switch (errorType) {
            // Caso error en nombre del pdf
            case 'pdfName':

                setAlertInfoInput({
                    text: intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT_TAB_MEDIA.ERROR_UNDERSCORE" }),
                    variant: 'danger',
                    inputName: inputName
                });
                setHeaderInputState(true);
                setWhaInputState(true);
                setLogoInputState(true);
                setButtonSubmitState(true);
                
                break;

            // Caso error en tamaño del pdf
            case 'pdfSize' :

                setAlertInfoInput({
                    text: `${intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT_TAB_MEDIA.ERROR_SIZE" })} (máx 16 mb)`,
                    variant: 'danger',
                    inputName: inputName
                });
                setHeaderInputState(true);
                setWhaInputState(true);
                setLogoInputState(true);
                setButtonSubmitState(true);

                break;

            // Caso error en nombre del header
            case 'headerName' :

                setAlertInfoInput({
                    text: intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT_TAB_MEDIA.ERROR_UNDERSCORE" }),
                    variant: 'danger',
                    inputName: inputName
                });
                setPdfInputState(true);
                setWhaInputState(true);
                setLogoInputState(true);
                setButtonSubmitState(true);
                
                break;

            // Caso error en tamaño del header
            case 'headerSize' :

                setAlertInfoInput({
                    text: `${intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT_TAB_MEDIA.ERROR_SIZE" })} (máx 10 mb)`,
                    variant: 'danger',
                    inputName: inputName
                });
                setPdfInputState(true);
                setWhaInputState(true);
                setLogoInputState(true);
                setButtonSubmitState(true);

                break;

            //Caso error en resolucion del header
            case 'headerResolution' :

                setAlertInfoInput({
                    text: `${intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT_TAB_MEDIA.ERROR_RESOLUTION" })} (1500x500 px)`,
                    variant: 'danger',
                    inputName: inputName
                });
                setPdfInputState(true);
                setWhaInputState(true);
                setLogoInputState(true);
                setButtonSubmitState(true);

                break;

            // Caso error en nombre de la imagen wha
            case 'whaName' :

                setAlertInfoInput({
                    text: intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT_TAB_MEDIA.ERROR_UNDERSCORE" }),
                    variant: 'danger',
                    inputName: inputName
                });
                setHeaderInputState(true);
                setPdfInputState(true);
                setLogoInputState(true);
                setButtonSubmitState(true);

                break;

            // Caso error en tamaño de la imagen wha
            case 'whaSize' :

                setAlertInfoInput({
                    text: `${intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT_TAB_MEDIA.ERROR_SIZE" })} (máx 10 mb)`,
                    variant: 'danger',
                    inputName: inputName
                });
                setHeaderInputState(true);
                setPdfInputState(true);
                setLogoInputState(true);
                setButtonSubmitState(true);

                break;

            // Caso error en resolucion de la imagen wha
            case 'whaResolution' :

                setAlertInfoInput({
                    text: `${intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT_TAB_MEDIA.ERROR_RESOLUTION" })} (800x800 px)`,
                    variant: 'danger',
                    inputName: inputName
                });
                setHeaderInputState(true);
                setPdfInputState(true);
                setLogoInputState(true);
                setButtonSubmitState(true);

                break;

            // Caso error en nombre del logo
            case 'logoName' :

                setAlertInfoInput({
                    text: intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT_TAB_MEDIA.ERROR_UNDERSCORE" }),
                    variant: 'danger',
                    inputName: inputName
                });
                setHeaderInputState(true);
                setWhaInputState(true);
                setPdfInputState(true);
                setButtonSubmitState(true);

                break;

            // Caso error en tamaño del logo
            case 'logoSize' :

                setAlertInfoInput({
                    text: `${intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT_TAB_MEDIA.ERROR_SIZE" })} (máx 10 mb)`,
                    variant: 'danger',
                    inputName: inputName
                });
                setHeaderInputState(true);
                setWhaInputState(true);
                setPdfInputState(true);
                setButtonSubmitState(true);

                break;

            // Caso error en resolucion del logo
            case 'logoResolution' :

                setAlertInfoInput({
                    text: `${intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT_TAB_MEDIA.ERROR_RESOLUTION" })} (65x65 px)`,
                    variant: 'danger',
                    inputName: inputName
                });
                setHeaderInputState(true);
                setWhaInputState(true);
                setPdfInputState(true);
                setButtonSubmitState(true);

                break;

            // Caso de archivos validos
            case 'success' :

                setAlertInfoInput({
                    text: '',
                    variant: '',
                    inputName: inputName
                });
                setHeaderInputState(false);
                setPdfInputState(false);
                setWhaInputState(false);
                setLogoInputState(false);
                setButtonSubmitState(false);

                break;
            
            // Caso por default
            default:

                setAlertInfoInput({
                    text: '',
                    variant: '',
                    inputName: inputName
                });
                setHeaderInputState(false);
                setPdfInputState(false);
                setWhaInputState(false);
                setLogoInputState(false);
                setButtonSubmitState(false);

                break;
        }

    }
    // Función para manejar el cambio de archivo para cada input
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
        const file = event.target.files && event.target.files[0];
    
        if (file) {

            let renamedFile = file;

            const imgMaxSize = 10 * 1024 * 1024;
            const pdfMaxSize = 16 * 1024 * 1024;

            const image = new Image();
            image.src = URL.createObjectURL(file);

            if ( inputName === 'pdfFile' ){
                if ( file.name.includes('_') ){
                    console.log('entra pdf nombre');
                    handleInputValidation('pdfName', inputName);
                }else if ( file.size > pdfMaxSize ){
                    console.log('entra pdf size');
                    handleInputValidation('pdfSize', inputName);
                }else{   
                    const newFileName = `pdf_${file.name}`;
                    renamedFile = new File([file], newFileName, { type: file.type });

                    setArchivos((prevArchivos) => [...prevArchivos, renamedFile]); // Agrega el archivo a la variable archivos

                    handleInputValidation('success', inputName);
                }
            }

            if ( inputName === 'imgHeader' ){
                if ( file.name.includes('_') ){
                    console.log('entra header nombre');
                    handleInputValidation('headerName', inputName);
                }else if ( file.size > imgMaxSize ){
                    console.log('entra header size');
                    handleInputValidation('headerSize', inputName);
                }else if ( inputName.startsWith('img') ){
                    console.log('entra header resol');
                    image.onload = () => {
                        const width = image.width;
                        const height = image.height;

                        console.log('widht ', width)
                        console.log('height', height)

                        if ( width !== 1500 || height !== 500 ){
                            console.log('entra header resol incorrecto');

                            handleInputValidation('headerResolution', inputName);
                        }else{
                            console.log('entra header resol correcto');

                            const newFileName = `head_${file.name}`;
                            renamedFile = new File([file], newFileName, { type: file.type });

                            setArchivos((prevArchivos) => [...prevArchivos, renamedFile]); // Agrega el archivo a la variable archivos

                            handleInputValidation('success', inputName);

                        }
                    }
                }else{
                    const newFileName = `head_${file.name}`;
                    renamedFile = new File([file], newFileName, { type: file.type });

                    setArchivos((prevArchivos) => [...prevArchivos, renamedFile]); // Agrega el archivo a la variable archivos

                    handleInputValidation('success', inputName);
                }
            }

            if ( inputName === 'imgWhatsapp' ){
                if ( file.name.includes('_') ){
                    console.log('entra wha nombre');
                    handleInputValidation('whaName', inputName);
                }else if ( file.size > imgMaxSize ){
                    console.log('entra wha size');
                    handleInputValidation('whaSize', inputName);
                }else if ( inputName.startsWith('img') ){
                    console.log('entra wha resol');
                    image.onload = () => {
                        const width = image.width;
                        const height = image.height;

                        console.log('widht ', width)
                        console.log('height', height)

                        if ( width !== 800 || height !== 800 ){
                            console.log('entra wha resol incorrecto');

                            handleInputValidation('whaResolution', inputName);
                        }else{
                            console.log('entra wha resol correcto');

                            const newFileName = `wha_${file.name}`;
                            renamedFile = new File([file], newFileName, { type: file.type });
        
                            setArchivos((prevArchivos) => [...prevArchivos, renamedFile]); // Agrega el archivo a la variable archivos
        
                            handleInputValidation('success', inputName);
                        }
                    }
                }else{
                    const newFileName = `wha_${file.name}`;
                    renamedFile = new File([file], newFileName, { type: file.type });

                    setArchivos((prevArchivos) => [...prevArchivos, renamedFile]); // Agrega el archivo a la variable archivos

                    handleInputValidation('success', inputName);
                }
            }

            if ( inputName === 'imgLogoProd' ){
                if ( file.name.includes('_') ){
                    console.log('entra logo nombre');
                    handleInputValidation('logoName', inputName);
                }else if( file.size > imgMaxSize ){
                    console.log('entra logo size');
                    handleInputValidation('logoSize', inputName);
                }else if( inputName.startsWith('img') ){
                    console.log('entra logo resol');
                    image.onload = () => {
                        const width = image.width;
                        const height = image.height;

                        console.log('widht ', width)
                        console.log('height', height)

                        if( width !== 65 || height !== 65 ){
                            console.log('entra logo resol incorrecto');

                            handleInputValidation('logoResolution', inputName);
                        }else{
                            const newFileName = `logo_${file.name}`;
                            console.log('entra logo resol correcto');

                            renamedFile = new File([file], newFileName, { type: file.type });

                            setArchivos((prevArchivos) => [...prevArchivos, renamedFile]); // Agrga el archivo a la variable archivos

                            handleInputValidation('success', inputName);
                        }
                    }
                }else{
                    const newFileName = `logo_${file.name}`;
                    renamedFile = new File([file], newFileName, { type: file.type });

                    setArchivos((prevArchivos) => [...prevArchivos, renamedFile]); // Agrga el archivo a la variable archivos

                    handleInputValidation('success', inputName);
                }
            }

        }
        
    };

    //Funcion para el envio de formulario con los archivos
    const handleMediaFormSubmit = async () => {
        const formData = new FormData();

        for (let index = 0; index < archivos.length; index++){
            formData.append("file", archivos[index]);
        }

        try {
            const {data: jsonData, code, msg} = await productsEditMedia(idProduct, formData, userToken);
            console.log('prod:', idProduct)

            console.log('Envío de datos multimedia completado con éxito');

            if ( code === 401 && msg === 'Token no valido' ) {
                
                sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
                return;

            } else {

                setAlertInfo({
                    text: code === 200 ? intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.SUCCESS_PRODUCTMEDIA_EDITION" }) :  intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.FAIL_PRODUCTMEDIA_EDITION" }),
                    variant: code === 200 ? 'success' : 'danger',
                });
    
                setTimeout(() => {
                    setAlertInfo({
                        text: '',
                        variant: '',
                    });
                    closeModalEdit();
                    getProductsRegisters();
                }, 3000);
                // Convierte FormData a un objeto común para imprimirlo
                const formDataObject = {};
                formData.forEach((value, key) => {
                    formDataObject[key] = value;
                });
    
                // console.log('formdata:', formDataObject)

            }
            
        }catch(error){
            console.log('algo salio mal');
            setAlertInfo({
                text: 'Error en la solicitud Axios',
                variant: 'danger',
            });
        }
    };

    const TooltipHeader = (props) => <Tooltip {...props}>{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_MEDIA.INPUTHEADIMG_TOOLTIP" })}</Tooltip>
    const TooltipWhatsapp = (props) => <Tooltip {...props}>{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_MEDIA.INPUTWHAIMG_TOOLTIP" })}</Tooltip>
    const TooltipPdf = (props) => <Tooltip {...props}>{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_MEDIA.INPUTFILEADV_TOOLTIP" })}</Tooltip> 
    const TooltipLogo = (props) => <Tooltip {...props}>{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_MEDIA.INPUTLOGOIMG_TOOLTIP" })}</Tooltip>

    return(
        <div className="container">
            <Card style={{ width: '32rem' }}>
                <Card.Img variant="top" src={BucketMediaURL+headerProduct} alt="Portada" />
                <Card.Body>
                    <Card.Title>{formDataEdit.nameProduct}</Card.Title>
                    <span className='text-muted'>{formDataEdit.countryProduct} - {formDataEdit.stateProduct} - {formDataEdit.zoneProduct}</span><br /><br />
                    
                    <div className='d-flex mb-8'>
                        <div className='col'>
                            <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_MEDIA.LOGOIMG" })}</label><br />
                            <div className='flex-shrink-0 mr-4 symbol symbol-65 symbol-circle'>
                                <img src={BucketMediaURL+logoProduct} alt="img" />
                            </div>
                        </div>
                        <div className='col'>
                            <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_MEDIA.WHAIMG" })}</label><br />
                            <div className='flex-shrink-0 mr-4 symbol symbol-65 symbol-circle'>
                                <img src={BucketMediaURL+whaImgProduct} alt="img" />
                            </div>
                        </div>
                    </div>

                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_MEDIA.WELCOMEMSG" })}</label><br />
                    <span className='text-muted'>Hola, aqui encontraras toda la inormacion que necesitas sobre nuestro producto de igual manera te mandamos un mensaje por WhatsApp por si tienes duadas o deseas conocer mas sobre nosotros.</span><br /><br />
                
                    <label className="form-label fw-bold">
                        {intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_MEDIA.INPUTHEADIMG" })}&nbsp;&nbsp;
                        <OverlayTrigger placement="top" overlay={TooltipHeader}>
                            <i className="fa fa-question-circle pl-2"></i>
                        </OverlayTrigger>
                    </label><br />

                    <span className='text-muted mb-4'>
                        {intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.SPAN_CURRENTFILE" })} 
                        {fileNameCleaner(headerProduct)}
                    </span>

                    <div className="input-group mb-3">
                        <input 
                            type="file" 
                            id="imgHeader"
                            name="imgHeader"
                            className="form-control" 
                            accept="image/png, image/jpg, image/jpeg, image/svg" 
                            disabled={headerInputState}
                            onChange={(e) => handleFileChange(e, 'imgHeader')} 
                        />
                    </div>
                    {alertInfoInput.text && alertInfoInput.variant === 'danger' && alertInfoInput.inputName === 'imgHeader' && (
                        <div className='mb-4'>
                            <span className={`text-${alertInfoInput.variant}`}>*{alertInfoInput.text}</span>
                        </div>
                    )}

                    <label className="form-label fw-bold">
                        {intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_MEDIA.INPUTWHAIMG" })}&nbsp;&nbsp;
                        <OverlayTrigger placement="top" overlay={TooltipWhatsapp}>
                            <i className="fa fa-question-circle pl-2"></i>
                        </OverlayTrigger>
                    </label><br />

                    <span className='text-muted mb-4'>
                        {intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.SPAN_CURRENTFILE" })} 
                        {fileNameCleaner(whaImgProduct)}
                    </span>

                    <div className="input-group mb-3">
                        <input 
                            type="file" 
                            id="imgWhatsapp" 
                            name="imgWhatsapp"
                            className="form-control" 
                            accept="image/png, image/jpg, image/jpeg, image/svg"  
                            disabled={whaInputState}
                            onChange={(e) => handleFileChange(e, 'imgWhatsapp')}
                        />
                    </div>
                    {alertInfoInput.text && alertInfoInput.variant === 'danger' && alertInfoInput.inputName === 'imgWhatsapp' && (
                        <div className='mb-4'>
                            <span className={`text-${alertInfoInput.variant}`}>*{alertInfoInput.text}</span>
                        </div>
                    )}

                    <label className="form-label fw-bold">
                        {intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_MEDIA.INPUTFILEADJ" })}&nbsp;&nbsp;
                        <OverlayTrigger placement="top" overlay={TooltipPdf}>
                            <i className="fa fa-question-circle pl-2"></i>
                        </OverlayTrigger>
                    </label><br />

                    <span className='text-muted mb-4'>
                        {intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.SPAN_CURRENTFILE" })} 
                        {fileNameCleaner(merchPdfProduct)}
                    </span>

                    <div className="input-group mb-3">
                        <input 
                            type="file" 
                            id="pdfFile"
                            name="pdfFile"
                            className="form-control" 
                            accept="application/pdf" 
                            disabled={pdfInputState}
                            onChange={(e) => handleFileChange(e, 'pdfFile')}
                        />
                    </div>
                    {alertInfoInput.text && alertInfoInput.variant === 'danger' && alertInfoInput.inputName === 'pdfFile' && (
                        <div className='mb-4'>
                            <span className={`text-${alertInfoInput.variant}`}>*{alertInfoInput.text}</span>
                        </div>
                    )}

                    <label className="form-label fw-bold">
                        {intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_MEDIA.INPUTLOGOIMG" })}&nbsp;&nbsp;
                        <OverlayTrigger placement="top" overlay={TooltipLogo}>
                            <i className="fa fa-question-circle pl-2"></i>
                        </OverlayTrigger>
                    </label><br />

                    <span className='text-muted mb-4'>
                        {intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.SPAN_CURRENTFILE" })} 
                        {fileNameCleaner(logoProduct)}
                    </span>

                    <div className="input-group mb-4">
                        <input 
                            type="file" 
                            id="imgLogoProd"
                            name="imgLogoProd"
                            className="form-control" 
                            accept="image/png, image/jpg, image/jpeg, image/svg" 
                            disabled={logoInputState}
                            onChange={(e) => handleFileChange(e, 'imgLogoProd')}
                        />
                    </div>
                    {alertInfoInput.text && alertInfoInput.variant === 'danger' && alertInfoInput.inputName === 'imgLogoProd' && (
                        <div className='mb-4'>
                            <span className={`text-${alertInfoInput.variant}`}>*{alertInfoInput.text}</span>
                        </div>
                    )}
                    {alertInfo.text && (
                        <div className={`alert alert-${alertInfo.variant} d-flex align-items-center`} role='alert'>
                            <div>{alertInfo.text}</div>
                        </div>
                    )}

                    <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                            <button 
                                type="submit" 
                                className='mt-8 btn btn-primary btn-sm' 
                                disabled={buttonSubmitState}
                                onClick={handleMediaFormSubmit}>{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_MEDIA.BUTTONSUBMIT" })}
                            </button>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export { MultimediaTabForm }