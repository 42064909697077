import { apiDragon, DragonMainApi, EPGradesReport } from "../../../../../utils/constants/_Constants";
import { apiGetDeletePetitions } from "../../../../../../_metronic/helpers/apis";

export const gradesReportDataPetition = async (userToken?, clientId?, productId?, campaignId?, startDate?, endDate?) => {

    try {
        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPGradesReport+productId}`, {
            dateStart: startDate,
            dateEnd: endDate,
            ...(campaignId && { camId: campaignId })
        },{
            'Content-Type': 'application/json',
            'x-token': userToken,
        },
    'get');

        const jsonDataGradeReport = data 
        return jsonDataGradeReport;

    }catch (error){

        console.error('Hubo un error en la peticion: ', error)
        throw error
    }
}