import axios from 'axios';
import { DragonMainApi, EPSellersNew, apiDragon } from '../../../../utils/constants/_Constants';
import { apiPetitions } from '../../../../../_metronic/helpers/apis';

async function sellersEdit(
  idUser,
  type: string,
  userPrivilage: number,
  name: string,
  phone: string,
  whatsapp: string,
  mail: string,
  password: string,
  validPassword: string,
  calendly: string,
  status: boolean | undefined | null,
  productsAssigned: any,
  userToken: string){
    // console.log('token antes del envio en brands: ', userToken)
    try {
        // Construye el objeto de datos condicionalmente
        const data: {
            type: any;
            userPrivilage?: any;
            name: any;
            phone: any;
            whatsapp: any;
            mail: any;
            calendly?: any;
            password?: any; // La propiedad password es opcional
            validPassword?: any;
            status: any;
            productsAssigned?: any; 
          } = {
            type,
            name,
            phone,
            whatsapp,
            mail,
            status
          };

          console.log(status)
      
        // Agrega los campos de contraseña solo si no están vacíos
        if (password && validPassword) {
          data.password = password;
          data.validPassword = validPassword;
        }

        //Agrega si existe calendly
        if(calendly){
          data.calendly = calendly;
        }

        if(productsAssigned.length > 0){
          data.productsAssigned = productsAssigned;
        }

        // Verificamos si userPrivilage es distinto de 0 antes de agregarlo al objeto requestData
        if (userPrivilage !== 0) {
          data.userPrivilage = userPrivilage;
      }

      
        const response = await apiPetitions(apiDragon, `${DragonMainApi}${EPSellersNew}/${idUser}`, data, {
          
            'Content-Type': 'application/json',
            'x-token': userToken
          
        },
        'put');

        const jsonDataSellersEdit = response;
        console.log(data)
        return jsonDataSellersEdit;
      
        // Resto de tu código de manejo de respuesta
      } catch (error) {
        throw error
      }
}

export { sellersEdit };