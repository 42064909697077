import { FC, useRef, useState, useEffect } from "react"
import { useThemeMode } from "../../../../../../_metronic/partials";
import { GradeReport } from "../interfaces/gradesReportTypes"
import { getCSS, getCSSVariableValue } from "../../../../../../_metronic/assets/ts/_utils";
import { dataExtract } from "../../../../../utils/functions/_Functions";
import ApexCharts, { ApexOptions } from "apexcharts";
import { Horizontal } from "../../../../../modules/wizards/components/Horizontal";
import { Modal } from "react-bootstrap";
import { GradesReportByGradeTabe } from "../tables/GradesReportByGradeTable";

type Props = {
    gradesReportItemByProduct: any;
    gradesReportItemCampaignOne: any;
    gradesReportItemCampaignTwo: any;
    selectedNameCampaignOne: string;
    selectedNameCampaignTwo: string;
    selectedIdCampaignOne: string;
    selectedIdCampaignTwo: string;
    selectedIdProduct: string;
    startDate: any
    endDate: any
}

const GradesReportComparativeChart: FC<Props> = ({
    gradesReportItemByProduct,
    gradesReportItemCampaignOne,
    gradesReportItemCampaignTwo,
    selectedNameCampaignOne,
    selectedNameCampaignTwo,
    selectedIdCampaignOne,
    selectedIdCampaignTwo,
    selectedIdProduct,
    startDate,
    endDate
}) => {

    const chartRef = useRef<HTMLDivElement | null>(null)
    const { mode } = useThemeMode()

    const [optionNumberSelected, setOptionNumberSelected] = useState<number>(0);
    const [optionTextSelected, setOptionTextSelected] = useState<string>('');
    const [optionCampaignSelected, setOptionCampaignSelected] = useState<string>('');

    const [cardTitleSelected, setCardTitleSelected] = useState<string>('')

    const [showModal, setShowModal] = useState<boolean>(false)

    // const categoriesArray = Object.keys(gradesReportItemByProduct.gradeCounts)

    const categoriesArray = Array.from(new Set([...Object.keys(gradesReportItemCampaignOne.gradeCounts), ...Object.keys(gradesReportItemCampaignTwo.gradeCounts)]))

    console.log(categoriesArray)

    const [campaignOnFocus, setCampaignOnFocus] = useState()

    const [idCampaignOnFocus, setIdCampaignOnFocus] = useState<string>('')

    const openModal = () => {
        setShowModal(true)
    }

    const closeModal = () => {
        setShowModal(false)
        setOptionCampaignSelected('')
    }

    useEffect(() => {
        const chart = refreshChart()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, mode, gradesReportItemCampaignOne, gradesReportItemCampaignTwo])

    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))
        const c_type = 'bar'

        const categoriesArrayOne = Object.keys(gradesReportItemCampaignOne.gradeCounts)
        // const countsArrayOne = Object.values(gradesReportItemCampaignOne.gradeCounts)
        const countsArrayOne = categoriesArray.map(key => gradesReportItemCampaignOne.gradeCounts[key] || 0)

        const categoriesArrayTwo = Object.keys(gradesReportItemCampaignTwo.gradeCounts)
        // const countsArrayTwo = Object.values(gradesReportItemCampaignTwo.gradeCounts)

        const countsArrayTwo = categoriesArray.map(key => gradesReportItemCampaignTwo.gradeCounts[key] || 0)
    
        console.log(categoriesArrayOne)
        console.log(countsArrayOne)

        console.log(categoriesArrayTwo)
        console.log(countsArrayTwo)

        // const serieOptionsOne = dataExtract(gradesReportItemCampaignOne, "option")
        // const serieCountsOne = dataExtract(gradesReportItemCampaignOne, "count")

        // const serieOptionsTwo = dataExtract(gradesReportItemCampaignTwo, "option")
        // const serieCountsTwo = dataExtract(gradesReportItemCampaignTwo, "count")

        const chart = new ApexCharts(chartRef.current, getChartOptions(height, c_type, gradesReportItemCampaignOne, gradesReportItemCampaignTwo, categoriesArrayOne, countsArrayOne, categoriesArrayTwo, countsArrayTwo, selectedNameCampaignOne, selectedNameCampaignTwo, setOptionNumberSelected, setOptionTextSelected, setOptionCampaignSelected, openModal, categoriesArray, handleSelection, countsArrayOne, countsArrayTwo))
        if (chart) {
            chart.render()
        }

        return chart

    }

    const handleSelection = (indexSelection: number | undefined, campaignSelection: string | undefined, series: any) => {

        if (indexSelection !== undefined && indexSelection >= 0 ) {

            console.log(categoriesArray)

            const categorySelected = String(categoriesArray[indexSelection])

            const getNumberCategory = parseInt(categorySelected.charAt(0), 0);

            console.log(getNumberCategory)

            console.log(categorySelected)

            setOptionNumberSelected(getNumberCategory)

            setCardTitleSelected(categorySelected)

            if (series.cat === 'campaignOne'){
                setCampaignOnFocus(gradesReportItemCampaignOne)
                setIdCampaignOnFocus(selectedIdCampaignOne)
            }else{
                setCampaignOnFocus(gradesReportItemCampaignTwo)
                setIdCampaignOnFocus(selectedIdCampaignTwo)
            }

            openModal()

        } else {
            console.log('Indice inválido o no definido')
        }
    }

    console.log({ gradesReportItemCampaignOne })
    console.log({ gradesReportItemCampaignTwo })
    console.log(selectedNameCampaignOne)
    console.log(selectedNameCampaignTwo)

    return (
        <>

            <div className="card-xxl-stretch mb-5 mb-xl-10">
                <div className="">
                    <div ref={chartRef} id="kt_charts_widget_1_chart" style={{ height: '350px' }} />
                </div>
            </div>

            <Modal 
                show={showModal}
                onHide={closeModal}
                className="modal-xl"
                scrollable
            >
                <Modal.Header 
                    closeButton 
                    onClick={() => {
                        closeModal()
                    }}
                >
                    <Modal.Title>
                    <div className="d-flex">
                            <div className="me-2">
                                <h1>{cardTitleSelected}</h1>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <p>Aqui va a ir la tabla</p> */}
                    {/* <GradesReportByGradeTabe 
                        optionNumberSelected={optionNumberSelected} 
                        optionCampaignSelected={optionCampaignSelected}
                    /> */}
                    <GradesReportByGradeTabe 
                        optionNumberSelected={optionNumberSelected}
                        cardTitleSelected={cardTitleSelected}
                        gradesReportItemByProduct={campaignOnFocus}
                        selectedIdProduct={selectedIdProduct}
                        selectedIdCampaign={idCampaignOnFocus}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="reset"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                            closeModal();
                        }}
                    >
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export { GradesReportComparativeChart }

function getChartOptions(height: number, c_type: any, gradesReportItemCampaignOne: any, gradesReportItemCampaignTwo: any, serieOptionsOne: any, serieCountsOne: any, serieOptionsTwo: any, serieCountsTwo: any, selectedNameCampaignOne: string, selectedNameCampaignTwo: string, setOptionNumberSelected, setOptionTextSelected, setOptionCampaignSelected, openModal, categoriesArray, handleSelection, countsArrayOne, countsArrayTwo) {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')

    return {
        series: [
            {
                name: selectedNameCampaignOne,
                cat: 'campaignOne',
                group: 'Campaigns',
                data: countsArrayOne
            },

            {
                name: selectedNameCampaignTwo,
                cat: 'campaignTwo',
                group: 'Campaigns',
                data: countsArrayTwo
            }
        ],
        chart: {
            type: c_type,
            height,
            fontFamily: "inherit",
            stacked: true,
            events: {
                dataPointSelection: function (event, chartContext, config) {
                    console.log('se dio click datapointselection')
                    const series: any = chartContext.w.config.series[config.seriesIndex];
                    console.log('Nombre de la serie:', series.name);      
                    console.log('Se hizo clic en el elemento: ', config.dataPointIndex)

                    const campaignSelection: string = series.name 
                    const indexSelection: number = config.dataPointIndex             

                    handleSelection(indexSelection, campaignSelection, series)

                }
            },
        },
        toolbar: {
            show: true,
            tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true
            },
            export: {
                csv: {
                    filename: "Reporte-calificaciones"
                },
                svg: {
                    filename: "Reporte-calificaciones"
                },
                png: {
                    filename: "Reporte-calificaciones"
                },
            }
        },
        // colors: ['#80c7fd', '#00E396', '#008FFB', '#80f1cb'],
        colors: ['#00C1DE', '#FF6C0E'],

        plotOptions: {
            bar: {
                horizontal: false,
                // distributed: true,
                columnWidth: "30%",
                borderRadius: 5,
            }
        },
        legend: {
            show: true,
            fontFamily: "inherit",
            fontSize: "13px",
            labels: {
                colors: labelColor
            },
            position: 'top',
            horizontalAlign: 'left',
        },
        dataLabels: {
            enabled: true
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: categoriesArray,
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: true,
                style: {
                    colors: labelColor,
                    fontSize: "12px",
                }
            }
        },
        yaxis: {
            lables: {
                style: {
                    colors: labelColor,
                    fontSize: "12px",
                }
            }
        },
        fill: {
            opacity: 1
        },
        states: {
            normal: {
                filter: {
                    type: "none",
                    value: 0
                }
            },
            hover: {
                filter: {
                    tytpe: "none",
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: "none",
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: "12px",
            },
            y: {
                formatter: function (val) {
                    return val + " leads"
                },
            },
        },
        grid: {
            borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    }
}