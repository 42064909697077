// Importamos constantes para realizar la peticion a la API de marcas
import { apiDragon, DragonMainApi, EPBrandsListByClient, EPBrandInfoById } from "../../../utils/constants/_Constants";

// Importamos funcion para get
import { apiGetDeletePetitions } from "../../../../_metronic/helpers/apis";

// Funcion para obtener el listado de marcas por cliente
export async function brandsListByClient(userToken: string, cliId: string) {

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPBrandsListByClient+cliId}`, { 

            /* Los parametros se mandan como undefined 
            ya que estos se envian solo en peticiones de eliminacion 'delete' */
            undefined 

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },

        'get');

        const jsonDataBrandsList = data;

        return jsonDataBrandsList;
    
    } catch (error) {

        throw error;

    }

}

// Funcion para obtener el listado de marcas por un ID especifico
export async function brandInfoById(userToken: string, brandId: string) {

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPBrandInfoById+brandId}`, {

            /* Los parametros se mandan como undefined 
            ya que estos se envian solo en peticiones de eliminacion 'delete' */
            undefined

        }, {
            'Contant-Type': 'application/json',
            'x-token': userToken
        }, 

        'get');

        const jsonDataBrand = data;

        return jsonDataBrand;

    } catch (error) {

        throw error;
        
    }
    
}
