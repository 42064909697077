import {KTCard} from '../../../_metronic/helpers'
import { PreCRMListToolbar } from './PreCRMListToolbar'
import { PreCRMTable } from './PreCRMTable'

const PreCRMList = () => {

  return (
    <>
      <KTCard>
        <PreCRMListToolbar/>
        <PreCRMTable />
      </KTCard>
    </>
  )
}

const PreCRMListWrapper = () => (
  
        <PreCRMList />
      
)

export {PreCRMListWrapper}