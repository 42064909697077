// Importamos hooks de react que usaremos para este componente
import { useState, useContext, useEffect } from "react";
// Importamos libreria para manejo de lenguaje
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from "../../../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------

// Importamos funcion campaignsMediumList para conexion con API de campañas
import { campaignsMediumList } from "../../services/_CampaignsMediumListApi";
// Importamos funcion manualCampaignsNew para conexion con API de campañas
import { manualCampaignsNew } from "../../services/_CampaignsNewApi";
// Importamos la funcion rolesListByClient para conexion con API de roles
import { rolesListByClient } from "../../../assigns/services/_RolesListApi";

// ---------------------------------------------------------------------------------------

// Importamos las interfaces globales que usaremos para este modulo
import { AlertInfo } from "../../../../utils/interfaces/_InterfacesTypes";
// Importamos las interfaces de campañas que se usaran para este componente
import { CampaignMedium } from "../../interfaces/campaignsTypes";
// Importamos las interfaces de roles que usaremos en este componente
import { Role } from "../../../assigns/interfaces/rolesTypes";

// ---------------------------------------------------------------------------------------

// Importamos funcion para el vencimiento de sesion
import { sessionExpired } from "../../../../utils/functions/_Functions";

// Iniciamos funcion para construir componente ManualTabForm
function ManualTabForm ({productId, formDataManual, setFormDataManual, closeModal, getCampaignsRegisters, setCurrentPage}){

    const [rolSelected, setRolSelected] = useState<string>('');

    const [campaignsMediumListReceived, setCampaignsMediumListReceived] = useState<CampaignMedium[]>([]);

    const [errors, setErrors] = useState({
        name: '',
        connectionType: '',
        medium: '',
        roleId: '',
        wab_messages: ''
    });

    //Algunas variables desde el usercontext
    const { user, token, updateToken } = useContext(UserContext);
    const clientId = user.cli_id;
    const userToken = token;

    const validateForm = () => {
        let valid = true;
        const newErrors = {
            name: '',
            connectionType: '',
            medium: '',
            roleId: '',
            wab_messages: '' 
        };

        if (!formDataManual.name) {
            newErrors.name = 'El nombre de la campaña es requerido';
            valid = false;
        }

        if (!formDataManual.connectionType) {
            newErrors.connectionType = 'El tipo de conexión es requerido';
            valid = false;
        }

        if (!formDataManual.medium) {
            newErrors.medium = 'El medio es requerido';
            valid = false;
        }

        if (!formDataManual.roleId) {
            newErrors.roleId = 'El rol es requerido';
            valid = false;
        }

        // if (!formDataManual.wab_messages) { 
        //     newErrors.wab_messages = 'El mensaje es requerido';
        //     valid = false;
        // }

        setErrors(newErrors);
        return valid;
    };

    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl();

    const [rolesList, setRolesList] = useState<Role[]>([]);

    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        text: '',
        variant: 'primary',
    })

    const handleChangeRol = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const valueSelected: string = e.target.value;
        const idSelected: string = e.target.options[e.target.selectedIndex].id;
        setRolSelected(valueSelected);

        const scheduleDiv = document.getElementById('schedules');

        if (valueSelected !== '0') {
            if (scheduleDiv) {
                scheduleDiv.style.display = 'none';
            }
        } else {
           if (scheduleDiv) {
                scheduleDiv.style.display = 'block';
            }
        }
        console.log("Value selected:", valueSelected);
    console.log("ID selected:", idSelected);

    setFormDataManual(prevState => ({
        ...prevState,
        roleId: idSelected 
    }));
    }

    const handleMediumChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const valueSelected: string = e.target.value;
        const idSelected: string = e.target.options[e.target.selectedIndex].id; // Obtener el ID de la opción seleccionada
    
        console.log("Value selected:", valueSelected);
        console.log("ID selected:", idSelected);
    
        setFormDataManual(prevState => ({
            ...prevState,
            medium: idSelected, // Aquí se actualiza medium con idSelected en lugar de valueSelected
            channelId: valueSelected // Agregar el valor seleccionado a formDataManual
        }));
    };
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;
        const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;
    
        // Imprimir estado antes del cambio
        console.log('Estado anterior:', formDataManual);
    
        if (name === 'channelId') {
            handleMediumChange(e as React.ChangeEvent<HTMLSelectElement>);
        } else {
            setFormDataManual(prevState => ({
                ...prevState,
                [name]: newValue
            }));
        }
    
        // Imprimir estado después del cambio
        console.log('Estado posterior:', formDataManual);
    };

    const handleFormSubmit = async () => {

        if (!validateForm()) {
            setAlertInfo({
                text: 'Por favor, completa todos los campos requeridos.',
                variant: 'danger',
            });
            return;
        }

        const channelId = formDataManual.channelId;
        const connectionType = formDataManual.connectionType;
        const medium = formDataManual.medium;
        
        const name = formDataManual.name;
        const mailTemplate = formDataManual.mailTemplate;
        const channelCrmId = formDataManual.channelCrmId;
        const crmId = formDataManual.crmId;
        const startHour = formDataManual.startHour;
        const endHour = formDataManual.endHour;
        const quality = formDataManual.quality;
        const iscore = formDataManual.iscore;
        const roleId = formDataManual.roleId;
    
        // console.log('channelid: '+channelId);
        // console.log('connectype: '+connectionType);
        // console.log('medio: '+medium);
        // console.log('name: '+name);
        // console.log('mailtemp: '+mailTemplate);
        // console.log('channelcrmid: '+channelCrmId);
        // console.log('starth: '+startHour);
        // console.log('endh: '+endHour);
        // console.log('quality: '+quality);
        // console.log('iscore: '+iscore);

        // console.log(roleId)

        try {

            const { data: jsonData, code, msg } = await manualCampaignsNew(productId, clientId, channelId, connectionType, medium, name, mailTemplate, channelCrmId, crmId, startHour, endHour, quality, iscore, roleId, userToken);

            if ( code === 401 && msg === 'Token no valido' ) {

                sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
                return;

            } else {

                setAlertInfo({
                    text: code === 200 ? intl.formatMessage({ id: "CAMPAIGNS.MODAL_NEW.SUCCESS_CAMPAIGN_CREATION" }) : intl.formatMessage({ id: "CAMPAIGNS.MODAL_NEW.FAIL_CAMPAIGN_CREATION" }),
                    variant: code === 200 ? 'success' : 'danger',
                });

                if(code === 200) {
                    setTimeout(() => {
                        setAlertInfo({
                            text: '',
                            variant: '',
                        });
                        closeModal();
                        getCampaignsRegisters();
                        setCurrentPage(1);
                    }, 3000);
                }

            }

        } catch (error) {

            setAlertInfo({
                text: 'Error en la solicitud Axios',
                variant: 'danger',
              });
               
        }
    
    };

    const getMediumList = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const connectionTypeValue = e.target.value;
        console.log("Valor seleccionado:", connectionTypeValue);

        try {

            if ( connectionTypeValue !== '') {

                const { data: jsonData, code, msg } = await campaignsMediumList(userToken, connectionTypeValue);

                if ( code === 401 && msg === 'Token no valido' ) {
                    
                    sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
                    return;
                    
                } else {

                    const campaignsMediumListReceived = jsonData.data;

                    setCampaignsMediumListReceived(campaignsMediumListReceived);
    
                    const userTokenNew = jsonData.token;
    
                    updateToken(userTokenNew);
    
                    console.log(campaignsMediumListReceived)
                }

            }
            
        } catch (error) {
        }

    }

    const getRolesRegisters = async () => {

        try {

            const { data: jsonData, code, msg } = await rolesListByClient(userToken, clientId);

            if (code === 401 && msg === 'Token no valido') {

                sessionExpired('La sesion ha expirado');
                return;
            
            } else {

                const rolesListReceived = jsonData.assigns;

                console.log(rolesListReceived);

                const userTokenNew = jsonData.token;

                updateToken(userTokenNew);

                setRolesList(rolesListReceived);

            } 

        } catch (error) {
            // console.log(error);
        }

    }

    useEffect(() => {
        getRolesRegisters();
    }, [])

    return (
        <>
            <div>
                
                <h3>Configura la campaña</h3>
                <span className="text-muted">Si no encuentras el medio que buscas contacta con tu asesor de ventas</span>

                <input
                    type="text"
                    placeholder="Ingresa el nombre de la campaña"
                    name="name"
                    className="form-control form-control-solid mt-4"
                    onChange={handleInputChange}
                />
                {errors.name && <div className="text-danger mb-6">{errors.name}</div>}

                <div className="d-flex mt-4">
                    <div className="col-6 me-2">
                        <select 
                            className="form-select form-select-solid" 
                            name="connectionType" 
                            value={formDataManual.connectionType}                    
                            onChange={(e) =>{
                                handleInputChange(e);
                                getMediumList(e);   
                            }}
                        >
                            <option value=''>Tipo de conexión</option>
                            <option value="Online">Online</option>
                            <option value="Offline">Offline</option>
                            <option value="WhatsApp">WhatsApp</option>
                        </select>
                        {errors.connectionType && <div className="text-danger mb-6">{errors.connectionType}</div>}
                    </div>

                    <div className="col-6">
                        <select 
                            className="form-select form-select-solid" 
                            name="channelId"          
                            value={formDataManual.channelId}           
                            onChange={handleInputChange}
                        >
                            <option value="">Medio</option>
                            {campaignsMediumListReceived.map((campaignMedium, index) => (
                                <option key={index} id={campaignMedium.name} value={(campaignMedium.channel_id)}>
                                    {campaignMedium.name}
                                </option>
                            ))}
                        </select>
                        {errors.medium && <div className="text-danger mb-6">{errors.medium}</div>}
                    </div>
                </div>

                <div className="d-flex mt-4">
                    <div className="col-6 me-2">
                        <select className="form-select form-select-solid">
                            <option value =''>Configuración de WhatsApp</option>
                            <option value = ''>General DragonCEM</option>
                        </select>
                        {errors.wab_messages && <div className="text-danger mb-6">{errors.wab_messages}</div>}
                    </div>

                    <div className="col-6">
                        <select className="form-select form-select-solid">
                            <option value = ''>Selecciona Bot</option>
                            <option value="botGralDragonCEM">botGralDragonCEM</option>
                        </select>
                    </div>
                </div>

                <div className="d-flex mt-4">
                    <div className="col-6 me-2">
                        <select 
                            className="form-select form-select-solid" 
                            name="mailTemplate"                     
                            onChange={handleInputChange}
                        >
                            <option value=''>Mail template</option>
                            <option value="b2c_notification">b2c_notification</option>
                        </select>
                    </div>

                    <div className="col-6">
                        <select className="form-select form-select-solid">
                            <option value="">Configuración CRM</option>
                            <option value="Hubspot DragonCEM">Hubspot DragonCEM</option>
                        </select>
                    </div>
                </div>

                <div className="d-flex mt-4">
                    <div className="col-6 me-2">
                        <input  
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Canal CRM ID"
                            name="channelCrmId"
                            value={formDataManual.channelCrmId}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col-6">
                        <input  
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="CRM ID"
                            name="crmId"
                            value={formDataManual.crmId}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <h3 className="mt-6 mb-3">Calificaciones bot</h3>
                <div className="d-flex">
                    <div className="col-6 me-2">
                        <label className="mb-3">Configuración de calificaciones</label>
                        <select className="form-select form-select-solid">
                            <option value=''>Configuracion calificaciones</option>
                            <option value="1">5 ponderadores</option>
                            <option value="2">7 ponderadores</option>
                        </select>
                        <span className="text-muted mt-4">Selecciona un bundle y visualiza su configuración en el cuadro de 'Ponderadores'. Si no seleccionas uno, se guardará el predeterminado.</span>
                    </div>

                    <div className="col-6">
                        <label className="mb-3">Ponderadores</label>
                        <textarea className="form-control form-control-solid" rows={4} readOnly={true}>

                        </textarea>
                    </div>
                </div>

                <div className="d-flex">
                    <div className="col-6 me-2">
                        <h3 className="mt-6 mb-3">Rol</h3>
                        <label className="mb-3">Roles activos</label>
                        <select className="form-select form-select-solid" value={formDataManual.roleId} onChange={handleChangeRol} name="roles">
                            <option value=''>Roles configurados</option>
                            {rolesList.map((role, index) => (
                                <option key={index} id={role.role_id} value={role.role_id}>
                                    {role.role_name}
                                </option>
                            ))}
                        </select>
                        <span className="text-muted mt-4">Selecciona uno de los roles previamente configurados y guardados en este cliente.</span>
                        {errors.roleId && <div className="text-danger mb-6">{errors.roleId}</div>}
                    </div>

                    <div className="col-6">
                        <h3 className="mt-6 mb-3">Quality</h3>
                        <label className="mb-6">Encuesta quality automática</label>&nbsp;&nbsp;&nbsp;
                        <div className="form-check form-switch mb-5">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="toggleSwitchQa"
                                // checked={formDataManual.quality}
                                defaultChecked={formDataManual.quality}
                                name="quality"
                                onChange={handleInputChange}
                            />
                        </div>
                        <span className="text-muted">Enciende o apaga el switch dependiendo si quieres que se envien encuestas QA de manera automatica.</span>
                    </div>
                </div>
                
                {/* <div id="schedules">
                    <h3 className="mt-6 mb-3">Horario de llamadas</h3>
                    <span className="text-muted">Selecciona los días y horario en que tus vendedores recibirán llamadas</span>

                    <div className="d-flex mt-4">
                        <label>
                            <input type="checkbox" className="form-check-input"/> Lunes
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> Martes
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> Miercoles
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> Jueves
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> Viernes
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> Sabado
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> Domingo
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>

                    <div className="d-flex mt-8">
                        <div className="col-6 me-3">
                            <div>
                                <label htmlFor="startHour">Hora de inicio:</label>
                            </div>

                            <div>
                                <input
                                    type="time"
                                    className="form-control form-control-solid"
                                    id="startHour"
                                    name="startHour"
                                    value={formDataManual.startHour}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="col-6 me-3">
                            <div>
                                <label htmlFor="endHour">Hora de fin:</label>
                            </div>
                        
                            <div>
                                <input
                                    type="time"
                                    className="form-control form-control-solid"
                                    id="endHour"
                                    name="endHour"
                                    value={formDataManual.endHour}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                    </div>
                </div> */}

                <h3 className="mt-6 mb-3">IScore</h3>
                <span className="text-muted">Selecciona qué valor tendrá esta campaña para promediar el iscore</span>

                <div className="mt-4">
                    <input
                        type="range"
                        min="1"
                        max="10"
                        value={formDataManual.iscore}
                        className="form-range"
                        name="iscore"
                        onChange={handleInputChange}
                    />
                </div>

                {alertInfo.text && (
                    <div className={`alert alert-${alertInfo.variant} d-flex align-items-center`} role='alert'>
                      <div>{alertInfo.text}</div>
                    </div>
                )}

                <button type="submit" className='mt-8 btn btn-primary btn-sm' onClick={handleFormSubmit}>Enviar</button>
            </div>
        </>
    )
}

export { ManualTabForm };