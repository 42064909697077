import React, { useState } from 'react';

interface DaySchedule {
  day: string;
  startTime: string;
  endTime: string;
}

const ModalCalendarSettings: React.FC = () => {
  const [selectedDays, setSelectedDays] = useState<DaySchedule[]>([]);
  const [startTimes, setStartTimes] = useState<{ [key: string]: string }>({});
  const [endTimes, setEndTimes] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState<{
    dateDuration: string;
    selectedDays: DaySchedule[];
  }>({
    dateDuration: '15m',
    selectedDays: [],
  });

  const handleAddDay = (day: string) => {
    if (!selectedDays.some((item) => item.day === day)) {
      const newDay = { day, startTime: '', endTime: '' };
      const updatedSelectedDays = [...selectedDays, newDay];
      setSelectedDays(updatedSelectedDays);
      console.log(`Día agregado: ${JSON.stringify(newDay)}`);
      updateFormData(updatedSelectedDays); // Actualiza formData con el nuevo estado de selectedDays
    }
  };

  const handleRemoveDay = (day: string) => {
    const removedDay = selectedDays.find((item) => item.day === day);
    const updatedSelectedDays = selectedDays.filter((item) => item.day !== day);
    setSelectedDays(updatedSelectedDays);
    console.log(`Día eliminado: ${JSON.stringify(removedDay)}`);
    updateFormData(updatedSelectedDays); // Actualiza formData con el nuevo estado de selectedDays
  };

  const handleStartTimeChange = (day: string, value: string) => {
    setStartTimes({ ...startTimes, [day]: value });
    updateSelectedDays(day, 'startTime', value);
  };

  const handleEndTimeChange = (day: string, value: string) => {
    setEndTimes({ ...endTimes, [day]: value });
    updateSelectedDays(day, 'endTime', value);
  };

  const updateSelectedDays = (day: string, key: string, value: string) => {
    setSelectedDays((prevSelectedDays) => {
      const updatedSelectedDays = prevSelectedDays.map((item) => {
        if (item.day === day) {
          return { ...item, [key]: value } as DaySchedule;
        }
        return item;
      });
  
      updateFormData(updatedSelectedDays);
      return updatedSelectedDays;
    });
  };

  const updateFormData = (updatedSelectedDays: DaySchedule[]) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedDays: updatedSelectedDays,
    }));
  };

  console.log('Días y horarios:', selectedDays);
  console.log('FormData (Duracion cada cita):', formData);

  return (
    <>
      <label htmlFor='dateDuration'>Duración de la cita:</label><br />
      <span className='text-muted mb-2'>¿Cuánto debe durar cada cita?</span>
      <select
        name='dateDuration'
        className='form-select form-select-sm mb-4'
        value={formData.dateDuration}
        onChange={(e) => setFormData({ ...formData, dateDuration: e.target.value })}
      >
        {/* Options for different durations */}
        <option value='15m'>15 minutos</option>
        <option value='30m'>30 minutos</option>
        <option value='45m'>45 minutos</option>
        <option value='1h'>1 hora</option>
        <option value='1.5h'>1.5 horas</option>
        <option value='2h'>2 horas</option>
      </select>

      {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
        <div key={day}>
          <label className='mb-3 mt-3 fw-bolder' htmlFor={`startTime${day}`}>
            {day}
          </label>
          <button
            type='button'
            className='btn btn-clean btn-icon btn-active-light-primary btn-lg'
            onClick={() => handleAddDay(day)}
          >
            <i className='fas fa-plus'></i>
          </button>
          <button
            type='button'
            className='btn btn-clean btn-icon btn-active-light-danger btn-lg'
            onClick={() => handleRemoveDay(day)}
          >
            <i className='fas fa-ban'></i>
          </button>
          <br />
          {selectedDays.some((item) => item.day === day) && (
            <div className='d-flex mb-4'>
              <label htmlFor={`startTime${day}`}>Inicia:</label>
              <input
                name={`startTime${day}`}
                type='time'
                className='form-control form-control-solid'
                id={`startTime${day}`}
                value={startTimes[day]}
                onChange={(e) => handleStartTimeChange(day, e.target.value)}
              />
              <label htmlFor={`endTime${day}`}>Termina:</label>
              <input
                name={`endTime${day}`}
                type='time'
                className='form-control form-control-solid'
                id={`endTime${day}`}
                value={endTimes[day]}
                onChange={(e) => handleEndTimeChange(day, e.target.value)}
              />
            </div>
          )}
          <hr />
        </div>
      ))}
    </>
  );
};

export { ModalCalendarSettings };
