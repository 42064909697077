

export const WhatsappTabForm = () => {
  return (
<>
<div className="d-flex mb-6">
            <label className="col-3">Configuración de mensajes WhatsApp: </label>
            <select className="form-select" name="wabmsgconfig" id="wabmsgconfig">
                <option value="General DragonCEM">General DragonCEM</option>
                <option value="DragonCEM producto">DragonCEM producto</option>
            </select>
            </div>
    
            <div className="d-flex mb-6">
            <label className="col-3">Mensaje principal:</label>
            <textarea
                id="mainmsg"
                className="form-control form-control-solid"
                value="¡Hola %1$s!
                Gracias por haberte puesto en contacto con nosotros solicitando información de %2$s.
                Recibimos tu solicitud a través de %4$s y tenemos mucha información que creemos que puede resultarte útil, te invitamos a que selecciones una de las siguientes opciones para poder brindarte la información necesaria:"
                readOnly
                rows={4}
            >
            </textarea>
            </div>
    
            <div className="d-flex mb-6">
            <label className="col-3">Mensaje quality:</label>
            <textarea
                id="mainmsg"
                className="form-control form-control-solid"
                value="¡Hola *%1$s*!
                Recientemente solicitaste información sobre *%2$s*
                ¿Te atendieron correctamente y respondieron todas tus dudas?"
                readOnly
                rows={4}
            >
            </textarea>
            </div>
            <div>
                <label className="mb-6 mt-6">Manejo de duplicados a nivel: </label>
                <div className="form-check mb-4">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="optionssss"
                        id="option1"
                        value="option1"
                        checked={true}
                    />
                    <label className="form-check-label" htmlFor="option1">
                        Campaña
                    </label>
                </div>

                <div className="form-check mb-4">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="optionssss"
                        id="option2"
                        value="option2"
                        
                    />
                    <label className="form-check-label" htmlFor="option2">
                        Producto
                    </label>
                </div>

                <div className="form-check mb-4">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="optionssss"
                        id="option2"
                        value="option2"
                        
                    />
                    <label className="form-check-label" htmlFor="option2">
                        Marca
                    </label>
                </div>
                </div>

                <div className="d-flex mb-6 mt-8">
                <label className="col-3">Días para detectar duplicados:</label>
                <input 
                    type="text"
                    className="form-control form-control-solid"
                    value={30}
                />
                </div>

            <div className="d-flex">
                <label className="mb-4">Detectar leads duplicados sin improtar la fecha:</label>&nbsp;&nbsp;&nbsp;
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={true}
                    />
            </div>

            <div className="d-flex">
                <label className="mb-4">Iscore:</label>&nbsp;&nbsp;&nbsp;
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={true}
                    />&nbsp;&nbsp;&nbsp;
                    <span className="text-muted">Puntuación máxima 20</span>
            </div>
            <button type="submit" className='mt-8 btn btn-primary btn-sm'>Enviar</button>
</>
  )
}

