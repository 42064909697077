// Importamos constantes para realizar la peticion a la API de productos
import {DragonMainApi, EPProductDeactivate, apiDragon} from '../../../utils/constants/_Constants'

// ---------------------------------------------------------------------------------------
// Importamos funcion para obtener codigos de respuesta del servidor
import {apiGetDeletePetitions} from '../../../../_metronic/helpers/apis'

//Función para realizar la petición a la API de desactivación de un producto
async function AMProductStatusDeactivate(product_id: string, userToken: string) {
  try {
    const data = await apiGetDeletePetitions(
      apiDragon,
      `${DragonMainApi + EPProductDeactivate + product_id}`,
      {},
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'delete'
    )

    const jsonDataSellersDeactivate = data

    return jsonDataSellersDeactivate
  } catch (error) {
    throw error
  }
}

export {AMProductStatusDeactivate}
