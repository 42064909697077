import React, { FC, useEffect, useState } from "react"
import { KTIcon } from "../../../../../../_metronic/helpers";
import {
    Column,
    Table,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getGroupedRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    FilterFn,
    ColumnDef,
    flexRender,
} from "@tanstack/react-table";
import { Tooltip } from "react-bootstrap";
import { DragonCem } from "../../../../../utils/constants/_Constants";
import { setPageTitle } from "../../../../../utils/functions/_Functions";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import { YearlyReportProduct, YearlyRegistersProductTotals, YearlyRegistersProduct } from "../interfaces/yearlyReportTypes";

declare module "@tanstack/table-core" {
    interface FilterFns {
        fuzzy: FilterFn<unknown>;
    }
    interface FilterMeta {
        itemRank: RankingInfo;
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
        itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
};

type Props = {
    processedDataForTable: any;
}

export const YearlyReportProductTable: FC<Props> = ({
    processedDataForTable
}) => {

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState<string>("");

    useEffect(() => {
        setPageTitle(`Reporte Anual (Producto) |  ${DragonCem}`);
    }, []);

    const columns = React.useMemo<ColumnDef<YearlyReportProduct, any>[]>(
        () => [
            {
                header: `Reporte anual por productos`,
                footer: (props) => props.column.id,
                columns: [
                    {
                        accessorKey: "channel",
                        cell: (info) => info.getValue(),
                        header: "Canal",
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: "campaign",
                        cell: (info) => info.getValue(),
                        header: () => <span>Campaña</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: "january",
                        cell: (info) => info.getValue(),
                        header: () => <span>Enero</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: "february",
                        cell: (info) => info.getValue(),
                        header: () => <span>Febrero</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: "march",
                        cell: (info) => info.getValue(),
                        header: () => <span>Marzo</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: "april",
                        cell: (info) => info.getValue(),
                        header: () => <span>Abril</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: "may",
                        cell: (info) => info.getValue(),
                        header: () => <span>Mayo</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: "june",
                        cell: (info) => info.getValue(),
                        header: () => <span>Junio</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: "july",
                        cell: (info) => info.getValue(),
                        header: () => <span>Julio</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: "august",
                        cell: (info) => info.getValue(),
                        header: () => <span>Agosto</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: "september",
                        cell: (info) => info.getValue(),
                        header: () => <span>Septiembre</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: "october",
                        cell: (info) => info.getValue(),
                        header: () => <span>Octubre</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: "november",
                        cell: (info) => info.getValue(),
                        header: () => <span>Noviembre</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: "december",
                        cell: (info) => info.getValue(),
                        header: () => <span>Diciembre</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: "total",
                        cell: (info) => info.getValue(),
                        header: () => <span>Total</span>,
                        footer: (props) => props.column.id,
                    },
                ],
            },
        ],
        []
    );

    const table = useReactTable({
        data: processedDataForTable, //6. Se definen los datos finales de la tabla con el seteo del punto 3.2
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    });

    React.useEffect(() => {
        if (table.getState().columnFilters[1]?.id === 'name') {
            if (table.getState().sorting[0]?.id !== 'name' || table.getState().sorting[0]?.desc) {
                table.setSorting([{ id: 'name', desc: false }])
            }
        }
    }, [table.getState().columnFilters[1]?.id])

    console.log({ processedDataForTable })

    return (
        <div className="col-md-12">
            <div className="row gy-10 gx-xl-12 mb-10">
                <div className="card card-custom">
                    <div className="p-2">
                        <div className="card-header pt-6 pb-6">
                            <div className="d-flex justify-content-between">
                                <DebouncedInput //7. Filtrado global
                                    value={globalFilter ?? ""}
                                    onChange={(value) => setGlobalFilter(String(value))}
                                    className={`form-control form-control-solid w-250px ps-14 me-3`}
                                    placeholder="Buscar registro..."
                                //   disabled={disabledButtons}
                                />

                                <button //8. Botón que muestra el dropdown para exportar datos
                                    type="button"
                                    className={`btn btn-primary d-flex align-items-center me-3`}
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                    data-kt-menu-flip="top-end"
                                //   onClick={handleSetData}
                                //   disabled={disabledButtons}
                                >
                                    <KTIcon
                                        iconType="outline"
                                        iconName="file-down"
                                        className="fs-2 me-2"
                                    />{" "}
                                    <span>Exportar datos</span>
                                </button>
                            </div>
                        </div>
                        <div className="h-2" />
                        <div id="forTable">
                            <div className="card-body table-responsive">
                                <table className="table table-row-bordered gs-3 gy-4 gx-12">
                                    <thead>
                                        {table.getHeaderGroups().map((headerGroup) => (
                                            <tr
                                                key={headerGroup.id}
                                                className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200"
                                            >
                                                {headerGroup.headers.map((header) => {
                                                    return (
                                                        <th key={header.id} colSpan={header.colSpan}>
                                                            {header.isPlaceholder ? null : (
                                                                <>
                                                                    <div
                                                                        {...{
                                                                            className: header.column.getCanSort()
                                                                                ? "cursor-pointer select-none"
                                                                                : "",
                                                                            onClick:
                                                                                header.column.getToggleSortingHandler(),
                                                                        }}
                                                                    >
                                                                        {flexRender(
                                                                            header.column.columnDef.header,
                                                                            header.getContext()
                                                                        )}
                                                                        {{
                                                                            asc: " 🔼",
                                                                            desc: " 🔽",
                                                                        }[header.column.getIsSorted() as string] ??
                                                                            null}
                                                                    </div>
                                                                    {header.column.getCanFilter() ? (
                                                                        <div>
                                                                            <Filter
                                                                                column={header.column}
                                                                                table={table}
                                                                            />
                                                                        </div>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody>
                                        {table.getRowModel().rows.map((row) => {
                                            return (
                                                <tr
                                                    key={row.id}
                                                    className={row.original.isTotal ? "bg-gray-300" : ""}
                                                >
                                                    {row.getVisibleCells().map((cell) => {
                                                        return (
                                                            <td key={cell.id}>
                                                                {flexRender(
                                                                    cell.column.columnDef.cell,
                                                                    cell.getContext()
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <div className="h-2" />

                            <div className="card-footer">
                                <div className="d-flex justify-content-between gap-2">
                                    <div id="SwitchPages">
                                        <button
                                            className="btn btn-primary border rounded p-1"
                                            onClick={() => table.setPageIndex(0)}
                                            disabled={!table.getCanPreviousPage()}
                                        >
                                            {"<<"}
                                        </button>
                                        <button
                                            className="btn btn-primary border rounded p-1"
                                            onClick={() => table.previousPage()}
                                            disabled={!table.getCanPreviousPage()}
                                        >
                                            {"<"}
                                        </button>
                                        <button
                                            className="btn btn-primary border rounded p-1"
                                            onClick={() => table.nextPage()}
                                            disabled={!table.getCanNextPage()}
                                        >
                                            {">"}
                                        </button>
                                        <button
                                            className="btn btn-primary border rounded p-1"
                                            onClick={() =>
                                                table.setPageIndex(table.getPageCount() - 1)
                                            }
                                            disabled={!table.getCanNextPage()}
                                        >
                                            {">>"}
                                        </button>
                                    </div>

                                    <div id="PagesFinder" className="text-center">
                                        {" "}
                                        <span className="flex items-center gap-1">
                                            Página{" "}
                                            <strong>
                                                {table.getState().pagination.pageIndex + 1} de{" "}
                                                {table.getPageCount()}
                                            </strong>{" "}
                                            | Ir a la página:
                                            <input
                                                type="number"
                                                defaultValue={table.getState().pagination.pageIndex + 1}
                                                onChange={(e) => {
                                                    const page = e.target.value
                                                        ? Number(e.target.value) - 1
                                                        : 0;
                                                    table.setPageIndex(page);
                                                }}
                                                className="form-control form-control-solid w-16"
                                            />
                                        </span>
                                    </div>

                                    <div id="showFiles" className="text-center">
                                        <select
                                            className="form-select form-select-solid"
                                            value={table.getState().pagination.pageSize}
                                            onChange={(e) => {
                                                table.setPageSize(Number(e.target.value));
                                            }}
                                        >
                                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                                <option key={pageSize} value={pageSize}>
                                                    Mostrar {pageSize} registros
                                                </option>
                                            ))}
                                        </select>
                                        <p>
                                            {table.getPrePaginationRowModel().rows.length} Registros
                                            en total
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Filter({
    column,
    table,
}: {
    column: Column<any, unknown>;
    table: Table<any>;
}) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id);

    const columnFilterValue = column.getFilterValue();

    const sortedUniqueValues = React.useMemo(
        () =>
            typeof firstValue === "number"
                ? []
                : Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [column.getFacetedUniqueValues()]
    );

    return typeof firstValue === "number" ? (
        <div>
            <div className="flex space-x-2">
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
                    value={(columnFilterValue as [number, number])?.[0] ?? ""}
                    onChange={(value) =>
                        column.setFilterValue((old: [number, number]) => [value, old?.[1]])
                    }
                    placeholder={`Min ${column.getFacetedMinMaxValues()?.[0]
                            ? `(${column.getFacetedMinMaxValues()?.[0]})`
                            : ""
                        }`}
                    className="w-24 border shadow rounded"
                />
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
                    value={(columnFilterValue as [number, number])?.[1] ?? ""}
                    onChange={(value) =>
                        column.setFilterValue((old: [number, number]) => [old?.[0], value])
                    }
                    placeholder={`Max ${column.getFacetedMinMaxValues()?.[1]
                            ? `(${column.getFacetedMinMaxValues()?.[1]})`
                            : ""
                        }`}
                    className="w-24 border shadow rounded"
                />
            </div>
            <div className="h-1" />
        </div>
    ) : (
        <>
            <datalist id={column.id + "list"}>
                {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                    <option value={value} key={value} />
                ))}
            </datalist>
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? "") as string}
                onChange={(value) => column.setFilterValue(value)}
                placeholder={`Buscar... (${column.getFacetedUniqueValues().size})`}
                className="w-36 border shadow rounded"
                list={column.id + "list"}
            />
            <div className="h-1" />
        </>
    );
}

// A debounced input react component
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number;
    onChange: (value: string | number) => void;
    debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <input
            {...props}
            value={value}
            onChange={(e) => setValue(e.target.value)}
        />
    );
}