/* eslint-disable react/jsx-no-target-blank */

import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {UserContext} from '../../../../../app/app-context/user-context/UserContext'
import {useContext} from 'react'
import { KTIcon } from '../../../../helpers'

const SidebarMenuMain = () => {
  const intl = useIntl()

  const dataFromContext = useContext(UserContext)
  const clientName = dataFromContext.user.cli_name

  return (
    <>
      {/* OPCION DASHBOARD */}
      <SidebarMenuItem
        to='/dashboard'
        icon='abstract-28'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {/* OPCION SOPORTE */}
      <SidebarMenuItemWithSub
        to='/crafted/'
        title='Ayuda'
        icon='information-2'
        fontIcon='bi-info-circle'
      >
        <SidebarMenuItem
          to='/support'
          hasBullet={true}
          title={intl.formatMessage({id: 'MENU.SUPPORT'})}
        />
        <SidebarMenuItem to='/faq' hasBullet={true} title={'Faq'} />
        <SidebarMenuItem to='/manual' hasBullet={true} title={'manual'} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem to='/account-management' icon='setting-4' title='Gestión de cuenta' />


      <SidebarMenuItemWithSub
        to='/crafted/'
        title='WhatsApp'
        icon='whatsapp'
        fontIcon='bi-whatsapp'
      >
        <SidebarMenuItem to='/twlio-services/twlio-services' hasBullet={true} title={'Servicios'} />
        <SidebarMenuItem to='/twlio-services/twlio-templates' hasBullet={true} title={'Plantillas'} />
      </SidebarMenuItemWithSub>

      {/* BANNER OPCIONES DEL CLIENTE EN SESION */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted fs-8 ls-1'>{clientName}</span>
        </div>
      </div>

      {/* OPCION LEADS */}
      <SidebarMenuItem
        to='/leads'
        icon='check-circle'
        title={intl.formatMessage({id: 'MENU.LEADS'})}
      />

      {/* OPCION MARCAS */}
      <SidebarMenuItem
        to='/brands'
        icon='bookmark'
        title={intl.formatMessage({id: 'MENU.CLIENT.BRANDS'})}
      />

      {/* OPCION PRODUCTOS */}
      <SidebarMenuItem
        to='/products'
        icon='bookmark-2'
        title={intl.formatMessage({id: 'MENU.CLIENT.PRODUCTS'})}
      />

      {/* OPCION MENU VENDEDORES */}
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Control de Vendedores'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem
          to='/sellers'
          hasBullet={true}
          title={intl.formatMessage({id: 'MENU.CLIENT.SELLERS'})}
        />
        <SidebarMenuItem to='/group-sellers' hasBullet={true} title='Grupos' />
      </SidebarMenuItemWithSub>

      {/* OPCION ASIGNACIONES */}
      <SidebarMenuItem
        to='/assigns'
        icon='switch'
        title={intl.formatMessage({id: 'MENU.CLIENT.ASSIGNS'})}
      />

      {/* OPCION SHARE ACCESS */}
      <SidebarMenuItem
        to='/share-access'
        icon='share'
        title={intl.formatMessage({id: 'MENU.CLIENT.SHARE_ACCESS'})}
      />

      {/* OPCION LINKS DCEM */}
      <SidebarMenuItem
        to='/linksdcem'
        icon='click'
        title={intl.formatMessage({id: 'MENU.MODULES.LINKS_DCEM'})}
        fontIcon='bi-layers'
      />

      {/* OPCION LOGS */}
      <SidebarMenuItem
        to='/logs'
        icon='notepad-bookmark'
        title={intl.formatMessage({id: 'MENU.CLIENT.LOGS'})}
      />

      {/* OPCION MENU REPORTES */}
      <SidebarMenuItemWithSub to='' title='Reportes' icon='chart-pie-4' fontIcon='bi-person'>
        <SidebarMenuItem to='/report-leads' hasBullet={true} title='Leads' />
        <SidebarMenuItem to='/grades-report' hasBullet={true} title='Calificaciones' />
        <SidebarMenuItem to='/crm-report' hasBullet={true} title='CRM' />
        <SidebarMenuItem to='/quality-report' hasBullet={true} title='Quality' />
        <SidebarMenuItem to='/campaigns-report' hasBullet={true} title='Campañas' />
        <SidebarMenuItem to='/iscore-report' hasBullet={true} title='iScore' />
        <SidebarMenuItem to='/interactions-report' hasBullet={true} title='Interacciones' />
        <SidebarMenuItem to='/yearly-report' hasBullet={true} title='Anual' />
      </SidebarMenuItemWithSub>

      {/* BANNER OPCIONES DE MODULOS PLUS DEL CLIENTE EN SESION */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1 me-2'>
            {intl.formatMessage({id: 'MENU.MODULES'})}
          </span>
          <KTIcon iconName='lock-3' className='fs-1 text-warning'/>
        </div>
      </div>

      {/* OPCION CHAT WAB */}
      <SidebarMenuItem
        to='/chat-wab'
        icon='message-notif'
        title={intl.formatMessage({id: 'MENU.MODULES.CHAT_WAB'})}
        fontIcon='bi-layers'
      />

      {/* OPCION BI */}
      <SidebarMenuItem
        to='/bi'
        icon='graph-up'
        title={intl.formatMessage({id: 'MENU.MODULES.BI'})}
        fontIcon='bi-layers'
      />

      {/* OPCION DATES */}
      <SidebarMenuItem
        to='/dates'
        icon='calendar'
        title={intl.formatMessage({id: 'MENU.MODULES.DATES'})}
        fontIcon='calendar'
      />

      {/* OPCION PRE-CRM */}
      <SidebarMenuItem
        to='/pre-CRM'
        icon='bi bi-calendar2-range-fill'
        title='Pre-CRM'
        fontIcon='bi-layers'
      />
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
