import { useIntl } from "react-intl";
import MyChartComponent from "./ChartBi"
import { useEffect } from "react";
import { setPageTitle } from "../../utils/functions/_Functions";
import { DragonCem } from "../../utils/constants/_Constants";

export const Bi = () => {

  const intl = useIntl();
  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    setPageTitle(`${intl.formatMessage({ id: "BI.TITLE" })} | ${DragonCem}`);
  }, [])

  return (
    <>
            <div className='container-fluid'>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                    <MyChartComponent/>
                    </div>
                </div>
            </div>
    </>
  )
}
