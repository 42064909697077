import React, {FC, useEffect, useState} from 'react'
import {
  TwlServiceForm,
  TwlServiceFormErrors,
  TwlioServicesAddModalProps,
} from '../twlio-services/interfaces/TwlioServices'

export const TwlioServicesAddModal: FC<TwlioServicesAddModalProps> = ({
  formValues,
  setFormValues,
  errors,
  handleInputChange,
  handlePhoneNumberChange,
  phoneNumbersValues,
  errorWab,
}) => {

  return (
    <div>
      <form className='form'>
        <div className='row mb-7'>
          <div className='col-md-1'></div>
          <div className='col-md-10'>
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Nombre amigable</label>
              <input
                placeholder='Nombre del servicio...'
                type='text'
                name='friendlyName'
                value={formValues.friendlyName}
                className={`form-control form-control-lg form-control-solid ${
                  errors.friendlyName && 'is-invalid border border-danger'
                }`}
                autoComplete='off'
                onChange={handleInputChange}
              />
            </div>
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Id del servicio</label>
              <input
                placeholder='Id del servicio'
                type='text'
                name='serviceId'
                value={formValues.serviceId}
                className={`form-control form-control-lg form-control-solid ${
                  errors.serviceId && 'is-invalid border border-danger'
                }`}
                autoComplete='off'
                onChange={handleInputChange}
              />
            </div>
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Numeros de WAB</label>
              <input
                placeholder='+5215512345678, +5215512345679,...'
                type='text'
                name='wabNumbers'
                value={phoneNumbersValues}
                className={`form-control form-control-lg form-control-solid ${
                  errorWab.length > 0 ? 'is-invalid border border-danger' : ''
                }`}
                autoComplete='off'
                onChange={handlePhoneNumberChange}
              />
              {errorWab.length > 0 && (
                <div>
                  {errorWab.map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
                </div>
              )}
              <span className='text-muted'>Los números deben ir separados por , </span>
              <p className='text-muted'>Es decir: +5215512345678,+5215512344321,+5215512341234.</p>
            </div>

            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>AccessToken</label>
              <input
                placeholder='AccessToken'
                type='text'
                name='accTk'
                value={formValues.accTk}
                onChange={handleInputChange}
                className={`form-control form-control-lg form-control-solid ${
                  errors.accTk && 'is-invalid border border-danger'
                }`}
                autoComplete='off'
              />
            </div>
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Access Id</label>
              <input
                placeholder='AccessId'
                type='text'
                name='accSid'
                value={formValues.accSid}
                onChange={handleInputChange}
                className={`form-control form-control-lg form-control-solid ${
                  errors.accSid && 'is-invalid border border-danger'
                }`}
                autoComplete='off'
              />
            </div>
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Tipo de servicio</label>
              <select
                name='serviceType'
                id=''
                className={`form-control form-control-lg form-control-solid ${
                  errors.serviceType && 'is-invalid border border-danger'
                }`}
                value={formValues.serviceType}
                onChange={handleInputChange}
              >
                <option value=''>Selecciona una opción</option>
                <option value='leads'>Leads</option>
                <option value='sellers'>Vendedores</option>
                <option value='chatwab'>Chatwab</option>
              </select>
            </div>
          </div>
          <div className='col-md-1'></div>
        </div>
      </form>
    </div>
  )
}
