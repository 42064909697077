import axios from 'axios';
import { DragonMainApi, EPGroupsListByClient } from '../../../../utils/constants/_Constants';

async function GroupsListByClient(userToken: string, cliId: string){
    try {
        const response = await axios.get(DragonMainApi+EPGroupsListByClient+cliId,{
            headers:{
                'Content-Type': 'application/json',
                'x-token': userToken
            }
        });

        const jsonDataSellersList = response.data;

        // const token = jsonDataBrandsList.token;

        // console.log('token desde brands: ', token);

        // console.log(jsonDataBrandsList)

        return jsonDataSellersList;

    } catch (error) {
        // throw error;
        console.log('Error de listado de vendedores ', error)

    }
}

export { GroupsListByClient };