import React, {useContext, useEffect, useState} from 'react'
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import {RankingInfo, rankItem} from '@tanstack/match-sorter-utils'
import {UserContext} from '../../../../app-context/user-context/UserContext'
import {AlertInfo} from '../../../../utils/interfaces/_InterfacesTypes'
import {sessionExpired} from '../../../../utils/functions/_Functions'
import {useIntl} from 'react-intl'
import {SellerFormData, UserData} from '../../sellers/Interfaces/sellersTypes'
import {groupsEdit} from '../services/_GroupSellersEditApi'
import {GroupsellersAdd} from '../services/_GroupSellersAddApi'
import {GroupsListByClient} from '../services/_GroupSellersListApi'
import {Dropdown, DropdownButton, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import ModalEditGroup from '../modals/ModalEditGroup'
import ModalAddGroup from '../modals/ModalAddGroup'
import {KTIcon} from '../../../../../_metronic/helpers'
import {sellersListByClient} from '../../sellers/services/_SellersListApi'
import {DropdownExport} from '../../../../../_metronic/partials/content/dropdown/DropdownExport'
import {SellersGroupHistoryTable} from './SellersGroupHistoryTable'
import {SellersGroupData, SellersGroupDataForm} from '../Interfaces/SellersGroupTypes'

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

export function GroupSellersTableComponent() {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const initialGroupData: SellersGroupData = {
    id: '',
    name: '',
    manager: '',
    managerName: '',
    mail: '',
    users: [],
    usersCount: 0,
  }

  // Estado inicial para el formulario de nuevo vendedor
  const initialFormNewSeller = {
    name: '',
    email: '',
    phone: '',
    whatsapp: '',
    newpassword: '',
    verifypassword: '',
    calendly: '',
    role: '',
    brandProducts: [],
  }

  const initialFormNewSellersGroup = {
    name: '',
    manager: '',
    sellers: [],
  }

  // Definición de variables traidas del context
  const {user, token, updateToken} = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token
  const type = user.user_type

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [dataToExport, setDataToExport] = useState({}) // Definimos variables para la exportación de la data a formato hoja de calculos
  const [errors, setErrors] = useState<{[key: string]: string}>({})
  const [formData, setFormData] = useState<SellerFormData>(initialFormNewSeller)
  const [data, setData] = React.useState<SellersGroupData[]>([]) // Definimos las variables que se utilizarán para definir los estados que llenarán los registros de la tabla
  // estado para almacenar los datos del vendedor seleccionado para editar
  const [selectedGroup, setSelectedGroup] = useState<SellersGroupData>(initialGroupData)
  //Variables para el manejo de estado de la información del formulario del grupo de vendedores
  const [sellersGroupFormData, setSellersGroupFormData] = useState<SellersGroupDataForm>(
    initialFormNewSellersGroup
  )

  //Variables con el estado de la alerta da confirmacion o error
  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })

  // Variables para definir los estados para abrir y cerrar modales
  const [showModalAddSellersGroup, setShowModalAddSellersGroup] = useState(false)
  const [showModalEditSellersGroup, setShowModalEditSellersGroup] = useState(false)
  const [showModalSellersGroupLogs, setShowModalSellersGroupLogs] = useState(false)
  const [sellers, setSellers] = useState<UserData[]>([])

  const handleCopyToClipboard = (text: string) => {
    // Intentar copiar el texto al portapapeles
    navigator.clipboard.writeText(text).catch((error) => {
      console.error('Error al copiar al portapapeles:', error)
      alert('Error al copiar al portapapeles. Por favor, inténtelo de nuevo.')
    })
  }

  const renderTooltip = (props) => <Tooltip {...props}>Copiar id del grupo</Tooltip>


  //Función para abrir el modal para agregar un grupo de vendedores
  const handleOpenModalForNewSellersGroup = () => {
    setSellersGroupFormData((prevFormData) => ({
      ...prevFormData,
    }))
    setShowModalAddSellersGroup(true)
    getSellersRegisters()
  }

  //Función para cerrar el modal para agregar un grupo de vendedores
  const handleCloseModalForNewSellersGroup = (): void => {
    setShowModalAddSellersGroup(false)
  }

  // Función para abrir el modal de edición del grupo de vendedores, manejar la edición de un vendedor y obtener los datos del grupo seleccionado
  const handleOpenModalForEditSellersGroup = (group: SellersGroupData) => {
    if (group) {
      setShowModalEditSellersGroup(true)
      setSelectedGroup(group)
    }
  }

  // Función para cerrar el modal de edición
  const closeModal = () => {
    setShowModalEditSellersGroup(false)
    setErrors({})
    resetForm()
  }

  //Función para abrir el modal del historial del grupo de vendedores
  const handleOpenModalForSellersGroupLogs = (sellersGroup) => {
    setShowModalSellersGroupLogs(true)
  }

  //Función para cerrar el modal del historial del grupo de vendedores
  const handleCloseModalForSellersGroupLogs = () => {
    setShowModalSellersGroupLogs(false)
  }

  //Función para resetear el formulario del grupo de vendedores
  const resetSellersGroupForm = (): void => {
    setSellersGroupFormData(initialFormNewSellersGroup)
  }

  //Funcion para resetear formulario
  const resetForm = (): void => {
    setFormData(initialFormNewSeller)
  }

  // Función para obtener los registros de los grupos de vendedores
  const getGroupsRegisters = async () => {
    try {
      //Ejecutamos el servicio y obtenemos la data que nos regresa ese servicio
      const dataSellers = await GroupsListByClient(userToken, clientId)
      const GroupsListReceived = dataSellers.data
      const userTokenNew = await dataSellers.token

      // Actualizar el token
      updateToken(userTokenNew)

      console.log(GroupsListReceived)

      // Mapear los datos de los vendedores, que nos regresó el servicio
      const mappedGroups = GroupsListReceived.map((key) => ({
        id: key.group_id,
        name: key.friendly_name,
        manager: key.manager.usr_id,
        managerName: key.manager.name,
        mail: key.mail_manager,
        users: key.users,
        usersCount: key.users.length,
        status: key.status,
      }))

      // console.log(mappedSellers)

      // Establecer los vendedores y restablecer reloadTable en false para que cada que se edite se vuelva a cargar la tabla
      setData(mappedGroups)
    } catch (error) {
      console.error('Error receiving sellers: ', error)
      sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
    }
  }

  // Función para obtener los registros de vendedores
  const getSellersRegisters = async () => {
    try {
      //Ejecutamos el servicio y obtenemos la data que nos regresa ese servicio
      const {data: jsonData, code, msg} = await sellersListByClient(userToken, clientId)
      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      } else {
        const sellersListReceived = jsonData.data
        const userTokenNew = await jsonData.token

        // Actualizar el token
        updateToken(userTokenNew)

        // Mapear los datos de los vendedores, que nos regresó el servicio
        const mappedSellers = sellersListReceived.map((key) => ({
          id: key.uid,
          name: key.usrData.usr_name,
        }))

        console.log(mappedSellers)

        // Establecer los vendedores y restablecer reloadTable en false para que cada que se edite se vuelva a cargar la tabla
        setSellers(mappedSellers)
      }
    } catch (error) {
      console.error('Error receiving sellers: ', error)
      sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
    }
  }

  //Función para realizar la petición para agregar un nuevo registro en el grupo de vendedores
  const handleFormNewGroup = async () => {
    const name = sellersGroupFormData.name
    const manager = sellersGroupFormData.manager
    const sellers = sellersGroupFormData.sellers

    try {
      const {
        data: jsonData,
        code,
        msg,
      } = await GroupsellersAdd(clientId, sellers, name, manager, userToken)

      setAlertInfo({
        text: code === 200 ? '¡Grupo creado correctamente!' : '¡Algo salió mal!',
        variant: code === 200 ? 'success' : 'danger',
      })

      // Validación del mensaje y llamada a la función limpiarYRecargar
      if (msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
      }

      setTimeout(() => {
        if (code === 200) {
          setAlertInfo({
            text: '',
            variant: '',
          })

          handleCloseModalForNewSellersGroup()
          resetSellersGroupForm()
          // setReloadTable(true)
        }
      }, 3000)

      console.log(clientId)
    } catch (error) {
      setAlertInfo({
        text: 'Error en la solicitud Axios',
        variant: 'danger',
      })
    }
  }

  //Función para realizar la petición de edición de un grupo de vendedores
  const handleFormSubmit = async () => {
    const name = selectedGroup.name
    const manager = selectedGroup.manager
    const sellers = selectedGroup.users
    const groupId = selectedGroup.id

    console.log(groupId, name, manager, sellers)

    try {
      const {
        data: jsonData,
        code,
        msg,
      } = await groupsEdit(clientId, groupId, name, manager, sellers, userToken)
      setAlertInfo({
        text: code === 200 ? '¡Usuario editado correctamente!' : '¡Algo salió mal!',
        variant: code === 200 ? 'success' : 'danger',
      })

      if (msg === 'Token no válido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
      }

      setTimeout(() => {
        if (code === 200) {
          setAlertInfo({
            text: '',
            variant: '',
          })

          closeModal()
        }
      }, 3000)
    } catch (error) {
      setAlertInfo({
        text: 'Error en la solicitud Axios',
        variant: 'danger',
      })
    }
  }

  // Esta funcion se ejecuta al cargar la página o al cambiar reloadTable y currentPage
  useEffect(() => {
    getGroupsRegisters()
  }, [userToken, clientId, showModalAddSellersGroup]) // Ejecutar al montar el componente y cada vez que userToken o clientId cambien

  const columns = React.useMemo<ColumnDef<SellersGroupData, any>[]>(
    () => [
      {
        header: 'Datos',
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'id',
            cell: (info) => (
              <OverlayTrigger placement='top' overlay={renderTooltip}>
              <button
                className='btn btn-light-primary'
                onClick={() => handleCopyToClipboard(info.getValue())}
              >
                {info.getValue()}
              </button>
            </OverlayTrigger>
            ),
            header: () => <span>Id grupo</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'name',
            cell: (info) => info.getValue(),
            header: () => <span>Nombre</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'managerName',
            cell: (info) => info.getValue(),
            header: () => <span>Manager</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'mail',
            id: 'mail',
            cell: (info) => info.getValue(),
            header: () => <span>Email</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'usersCount',
            id: 'usersCount',
            cell: (info) => info.getValue(),
            header: () => <span>Integrantes</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'actions',
            cell: (info) => {
              //  console.log(info. row.original); // Aquí está el console.log
              return (
                <div className='d-flex align-items-center flex-column'>
                  <DropdownButton
                    size='sm'
                    id={`dropdown-${info.row.original.id}`}
                    title='Acciones'
                  >
                    <Dropdown.Item
                      onClick={() => handleOpenModalForEditSellersGroup(info.row.original)}
                    >
                      Editar
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleOpenModalForSellersGroupLogs(info.row.original)}
                    >
                      Historial
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              )
            },
            header: () => <span>Acciones</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  )

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  React.useEffect(() => {
    if (table.getState().columnFilters[1]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name' || table.getState().sorting[0]?.desc) {
        table.setSorting([{id: 'name', desc: false}])
      }
    }
  }, [table.getState().columnFilters[1]?.id])

  const handleSetData = () => {
    const dataToExport = table.getRowModel().flatRows.map((row) => row.original)
    setDataToExport(dataToExport)
  }

  return (
    <div className='p-2'>
      <div className='card-header pt-6 pb-6'>
        <div className='d-flex justify-content-between'>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Buscar grupo...'
          />

          <button
            type='button'
            className='btn btn-primary d-flex align-items-center me-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={handleSetData}
          >
            <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />{' '}
            <span>Exportar datos</span>
          </button>

          <DropdownExport dataToExport={dataToExport} title={'sellersGroupData'} />
        </div>

        <div>
          {/* begin::Add sellers group */}
          <button
            type='button'
            className='btn btn-primary me-3'
            onClick={handleOpenModalForNewSellersGroup}
          >
            <KTIcon iconName='plus' className='fs-2' />
            Agregar grupo de vendedores
          </button>
          {/* end::sellers group */}
        </div>
      </div>

      <div className='h-2' />

      <div className='card-body table-responsive'>
        <table className='table table-row-bordered gs-3 gy-4 gx-12'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className='h-2' />

      <div className='card-footer'>
        <div className='d-flex justify-content-between gap-2'>
          <div id='SwitchPages'>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
          </div>

          <div id='PagesFinder' className='text-center'>
            {' '}
            <span className='flex items-center gap-1'>
              Página{' '}
              <strong>
                {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
              </strong>{' '}
              | Ir a la página:
              <input
                type='number'
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
                className='form-control form-control-solid w-16'
              />
            </span>
          </div>

          <div id='showFiles' className='text-center'>
            <select
              className='form-select form-select-solid'
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Mostrar {pageSize} registros
                </option>
              ))}
            </select>
            <p>{table.getPrePaginationRowModel().rows.length} Registros en total</p>
          </div>
        </div>
      </div>

      {/* Modal agregar grupo de vendedores */}
      <Modal
        show={showModalAddSellersGroup}
        onHide={() => {
          handleCloseModalForNewSellersGroup()
          resetSellersGroupForm()
        }}
        scrollable
        centered
        className='modal-md'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Agregar grupo de vendedores</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalAddGroup
            sellers={sellers}
            sellersGroupFormData={sellersGroupFormData}
            setSellersGroupFormData={setSellersGroupFormData}
          />
        </Modal.Body>
        {alertInfo.text && (
          <div className='px-20'>
            <div
              className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`}
              role='alert'
            >
              <div>{alertInfo.text}</div>
            </div>
          </div>
        )}
        <Modal.Footer>
          <button className='btn btn-sm btn-secondary' onClick={handleCloseModalForNewSellersGroup}>
            Cancelar
          </button>

          <button className='btn btn-sm btn-primary' type='submit' onClick={handleFormNewGroup}>
            Guardar
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal para editar el grupo de vendedores */}
      <Modal
        show={showModalEditSellersGroup}
        onHide={handleCloseModalForNewSellersGroup}
        scrollable
        centered
        className='modal-lg'
      >
        <Modal.Header>
          <Modal.Title>
            <h1>Editar Grupo</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalEditGroup groupData={selectedGroup} setGroupData={setSelectedGroup} />
        </Modal.Body>
        {alertInfo.text && (
          <div
            className={`alert alert-${alertInfo.variant} d-flex align-items-center`}
            role='alert'
          >
            <div>{alertInfo.text}</div>
          </div>
        )}
        <Modal.Footer>
          <button className='btn btn-sm btn-secondary' onClick={closeModal}>
            Cancelar
          </button>
          <button className='btn btn-sm btn-primary' type='submit' onClick={handleFormSubmit}>
            Guardar
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal del historial del grupo de vendedores*/}
      <Modal
        show={showModalSellersGroupLogs}
        onHide={handleCloseModalForSellersGroupLogs}
        scrollable
        centered
        className='modal-xl'
      >
        <Modal.Header closeButton onClick={handleCloseModalForSellersGroupLogs}>
          <Modal.Title>
            <h1>Historial</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SellersGroupHistoryTable SellersGroupData={selectedGroup} />
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-sm btn-primary' onClick={handleCloseModalForSellersGroupLogs}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

function Filter({column, table}: {column: Column<any, unknown>; table: Table<any>}) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Buscar... (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded'
        list={column.id + 'list'}
      />
      <div className='h-1' />
    </>
  )
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}
