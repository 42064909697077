import React, {useEffect, useMemo, useState} from 'react'
import {OverlayTrigger, Tooltip, TooltipProps} from 'react-bootstrap'

import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'

import {ReportApiMail, ReportApiMailDetails} from '../interfaces/interactionsReportsTypes'
import {RankingInfo, rankItem} from '@tanstack/match-sorter-utils'
import {DropdownExportGral} from '../../../../../../_metronic/partials/content/dropdown/DropdownExportGral'
import {useNavigate} from 'react-router-dom'
import {LoadingAnimation} from '../../../../../utils/components/loading/LoadingAnimation'

type PropsInteractionMailReportTable = {
  detailReportMail: ReportApiMail[]
}
declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

export const InteractionDetailReportMailTable: React.FC<PropsInteractionMailReportTable> = ({
  detailReportMail,
}) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState<string>('')

  // Constante para el manejo inicial de la data de la tabla
  const [interactionMailList, setInteractionMailList] = useState<ReportApiMail[]>(detailReportMail)
  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(false)
  //Estado para almacenar la informacion de la tabla y utilizarla en el boton exportar
  const [actualrows, setActualRows] = useState<ReportApiMail[]>([])

  // Constantes para tooltips de botones
  const renderTooltip = (props: TooltipProps): JSX.Element => (
    <Tooltip {...props}>Ir al detalle del lead</Tooltip>
  )

  const columns = useMemo<ColumnDef<ReportApiMailDetails, any>[]>( //5 Se vuelve a definir la interfaz del objeto como prop de las columnas de la tabla
    () => [
      {
        header: `Reporte de interacciones | Correo`, // 5.1 Se pone algun titulo o header a mostrar: Historial de cambios globales | Tabla de Plantillas | etc
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'date',
            cell: (info) => info.getValue(),
            header: () => <span>Fecha</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'leadId',
            cell: (info) => {
              {
                return (
                  <OverlayTrigger placement='top' overlay={renderTooltip}>
                    <button
                      onClick={() => {
                        toLeadDetail(info.getValue())
                      }}
                      className='btn btn-sm btn-outline-primary px-3 py-1'
                    >
                      {info.getValue()}
                    </button>
                  </OverlayTrigger>
                )
              }
            },
            header: () => <span>Lead id</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'channel',
            cell: (info) => (
              <div>
                <p className='fw-bold'>{info.row.original.product}</p>
                <p>{info.row.original.channel}</p>
              </div>
            ),
            header: () => <span>Producto | Canal</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'name',
            cell: (info) => (
              <div>
                <p className='fw-bold'>{info.row.original.name}</p>
                <p>{info.row.original.mail}</p>
                <p>{info.row.original.phone}</p>
              </div>
            ),
            header: () => <span>Datos del lead</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'sellerMail',
            cell: (info) => info.getValue(),
            header: () => <span>Asesor asignado</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'event',
            cell: (info) => info.getValue(),
            header: () => <span>Evento del correo</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'info',
            cell: (info) => info.getValue(),
            header: () => <span>Info del evento</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  )
  // Constante para manejar los datos de la tabla
  useEffect(() => {
    console.log('detalle de correos', detailReportMail)
    setInteractionMailList(detailReportMail)
  }, [detailReportMail])

  const table = useReactTable({
    data: interactionMailList[0]?.leadData || [],
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  //Funcion para navegar a la apgina de detalle de lead pasando el objeto lead
  const navigate = useNavigate()
  const toLeadDetail = (leadId: string | undefined) => {
    // navigate('/lead-detail/', { state: lead })
    if (leadId) navigate(`/lead-detail?leadId=${leadId}`)
  }
  //Animacion de cargando antes de mostrar la tabla y gráfica
  useEffect(() => {
    setIsLoading(false)
    setTimeout(() => {
      setIsLoading(true)
    }, 2000)
  }, [])
  //Observar cambios en la tabla, creando un nuevo arreglo y mandarlos como props a la gráfica
  useEffect(() => {
    // Obtener las filas actuales de la tabla
    const rows = table.getPrePaginationRowModel().rows
    let actualrows = rows.map((obj) => obj.original)
    setActualRows(actualrows.map(({['reportData']: _, ...rest}) => rest))
  }, [table.getPrePaginationRowModel().rows])

  return (
    <>
      {!isLoading && (
        <div className='py-6'>
          <LoadingAnimation alignment='center' label='Generando tabla...' />
        </div>
      )}
      {isLoading && interactionMailList.length > 0 && (
        <>
          {/* Table */}
          <div className='col-md-12'>
            <div className='row gy-10 gx-xl-12 mb-10'>
              <div className='card card-custom'>
                <div className='p-2'>
                  <div className='card-header pt-6 pb-6'>
                    <div className='d-flex justify-content-between'>
                      <DebouncedInput //7. Filtrado global
                        value={globalFilter ?? ''}
                        onChange={(value) => setGlobalFilter(String(value))}
                        className={`form-control form-control-solid w-250px ps-14 me-3`}
                        placeholder='Buscar registro...'
                        //   disabled={disabledButtons}
                      />
                      {/* BOTÓN EXPORTAR DATOS  */}
                      <DropdownExportGral
                        dataToExport={actualrows}
                        size='btn-lg'
                        title={'Correos_detalle'}
                      />
                    </div>
                  </div>
                  <div className='h-2' />
                  <div id='forTable'>
                    <div className='card-body table-responsive'>
                      <table className='table table-row-bordered gs-3 gy-4 gx-12'>
                        <thead>
                          {table.getHeaderGroups().map((headerGroup, index) => (
                            <tr
                              key={headerGroup.id}
                              className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                            >
                              {headerGroup.headers.map((header) => {
                                return (
                                  <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                      <>
                                        <div
                                          {...{
                                            className: header.column.getCanSort()
                                              ? 'cursor-pointer select-none'
                                              : '',
                                            onClick: header.column.getToggleSortingHandler(),
                                          }}
                                        >
                                          {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                          )}
                                          {{
                                            asc: ' 🔼',
                                            desc: ' 🔽',
                                          }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                        {header.column.getCanFilter() ? (
                                          <div>
                                            <Filter column={header.column} table={table} />
                                          </div>
                                        ) : null}
                                      </>
                                    )}
                                  </th>
                                )
                              })}
                            </tr>
                          ))}
                        </thead>
                        <tbody>
                          {table.getRowModel().rows.map((row) => {
                            return (
                              <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                  return (
                                    <td key={cell.id}>
                                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className='h-2' />
                    <div className='card-footer'>
                      <div className='d-flex justify-content-between gap-2'>
                        <div id='SwitchPages'>
                          <button
                            className='btn btn-primary border rounded p-1'
                            onClick={() => table.setPageIndex(0)}
                            disabled={!table.getCanPreviousPage()}
                          >
                            {'<<'}
                          </button>
                          <button
                            className='btn btn-primary border rounded p-1'
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                          >
                            {'<'}
                          </button>
                          <button
                            className='btn btn-primary border rounded p-1'
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                          >
                            {'>'}
                          </button>
                          <button
                            className='btn btn-primary border rounded p-1'
                            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                            disabled={!table.getCanNextPage()}
                          >
                            {'>>'}
                          </button>
                        </div>
                        <div id='PagesFinder' className='text-center'>
                          <span className='flex items-center gap-1'>
                            Página{' '}
                            <strong>
                              {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
                            </strong>{' '}
                            | Ir a la página:
                            <input
                              type='number'
                              defaultValue={table.getState().pagination.pageIndex + 1}
                              onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                table.setPageIndex(page)
                              }}
                              className='form-control form-control-solid w-16'
                            />
                          </span>
                        </div>
                        <div id='showFiles' className='text-center'>
                          <select
                            className='form-select form-select-solid'
                            value={table.getState().pagination.pageSize}
                            onChange={(e) => {
                              table.setPageSize(Number(e.target.value))
                            }}
                          >
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                              <option key={pageSize} value={pageSize}>
                                Mostrar {pageSize} registros
                              </option>
                            ))}
                          </select>
                          <p>{table.getPrePaginationRowModel().rows.length} Registros en total</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
function Filter({column, table}: {column: Column<any, unknown>; table: Table<any>}) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Buscar... (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded'
        list={column.id + 'list'}
      />
      <div className='h-1' />
    </>
  )
}
// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}
