import { useEffect, useState } from 'react'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

const events = [
  {
    title: 'Evento 1',
    start: '2023-11-10 17:00:00',
    end: '2023-11-10 18:00:00'
  },
  {
    title: 'Evento 2',
    start: '2023-11-15 17:00:00',
    end: '2023-11-15 19:00:00'
  },
  {
    title: 'Evento 3',
    start: '2023-11-09 11:00:00',
    end: '2023-11-09 13:00:00'
  },
]

function CalendarView() {

  useEffect(() => {
    let calendarEl = document.getElementById('calendar');

    if (calendarEl) {
      let calendar = new Calendar(calendarEl, {
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        firstDay: 1,
        initialView: 'timeGridWeek',
        selectable: true,
        selectMirror: true,
        events: events,
        slotMinTime:'09:00:00',
        slotMaxTime:'22:00:00',
        scrollTime: '22:00:00',
        slotDuration:'01:00:00',
        slotLabelInterval: '01:00:00', 
        dateClick: function(info) {
          const startTime = info.date;
          const endTime = new Date(info.date);
          endTime.setHours(endTime.getHours() + 1); // Añade 1 hora para obtener la hora de fin
        
          const startTimeFormatted = startTime.toLocaleTimeString();
          const endTimeFormatted = endTime.toLocaleTimeString();
 
          alert('Hora de inicio: ' + startTimeFormatted);
          alert('Hora de fin: ' + endTimeFormatted); 

        }
      });

      calendar.render();
    }
  }, []);

  return (
    <div>
      <div id="calendar"></div>
    </div>
  );
}

export default CalendarView;