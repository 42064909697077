// Importamos constantes para realizar la peticion a la API de marcas

import { apiGetDeletePetitions } from "../../../../../_metronic/helpers/apis"
import { DragonMainApi, EPSellerDeactivate, apiDragon } from "../../../../utils/constants/_Constants"

// ---------------------------------------------------------------------------------------

// Importamos funcion para obtener codigos de respuesta del servidor

//Función para realizar la petición a la API de desactivación de marcas
async function sellerStatusDeactivate(selller_id: string, userToken: string) {
  try {
    const data = await apiGetDeletePetitions(
      apiDragon,
      `${DragonMainApi + EPSellerDeactivate + selller_id}`,{},
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'delete')

    const jsonDataSellersDeactivate = data

    return jsonDataSellersDeactivate
  } catch (error) {
    throw error
  }
}

export {sellerStatusDeactivate}
