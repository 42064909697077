import axios from 'axios';
import { DragonMainApi, EPRoleNew, apiDragon } from '../../../utils/constants/_Constants';
import { apiPetitions } from '../../../../_metronic/helpers/apis';

async function RolNew(
    idClient,
    roleName: string,
    users: [],
    idGroups: [],
    proIds: [],
    camIds: [],
    brandId: string,
    schedules: [[], [], [], [], [], [], []],
    userToken
) {
    try {
        // Filtrar los campos que no estén vacíos
        const requestData: {
            idClient: string,
            roleName: string,
            users?: [],
            idGroups?: [],
            proIds?: [],
            camIds?: [],
            brandId?: string,
            schedules: [[], [], [], [], [], [], []]
        } = {
            idClient,
            roleName,
            schedules
        };

        if (users.length > 0) requestData.users = users;
        if (idGroups.length > 0) requestData.idGroups = idGroups;
        if (proIds.length > 0) requestData.proIds = proIds;
        if (camIds.length > 0) requestData.camIds = camIds;
        if (brandId) requestData.brandId = brandId;
        const response = await apiPetitions(
            apiDragon,
            `${DragonMainApi + EPRoleNew}`,
            requestData,
            {
                'Content-Type': 'application/json',
                'x-token': userToken
            },
            'post'
        );

        console.log(requestData)

        const jsonDataRolNew = response;

        console.log(response);

        return jsonDataRolNew;
    } catch (error) {
        // console.log('Error de nueva marca ', error)
        throw error;
    }
}

export { RolNew };
