import React, { FC, useState, useContext, useEffect } from "react";
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import { Button, Dropdown, DropdownButton, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ProductsHistory } from "../interfaces/productsTypes";

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

type Props = {
  idProduct: string
  nameProduct: string
}

export const ProductsHistoryTable: FC<Props> = ({
  idProduct,
  nameProduct
}) => {

  console.log('renderizamos componente de la tabla')

  const handleCopyToClipboard = (text: string) => {
    // Intentar copiar el texto al portapapeles
    navigator.clipboard.writeText(text).catch((error) => {
      console.error('Error al copiar al portapapeles:', error)
      alert('Error al copiar al portapapeles. Por favor, inténtelo de nuevo.')
    })
  }

  const renderTooltip = (props) => <Tooltip {...props}>Copiar id del registro</Tooltip>

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')

  const [productsHistoryList, setProductsHistoryList] = useState<ProductsHistory[]>([])

  // Esta es la funcion en donde haremos la peticion al endpoint de historial de productos
  const getProductsHistory = () => {
    const newProductsHistory = [
      {
        id: 'POO-98-LFO',
        name: 'Mauricio Hernandez',
        description: 'Se edita el correo del producto: producto patito',
        dateAdd: '2024-04-22'
      },
      {
        id: 'KAO-21-LMR',
        name: 'Angel Alfaro',
        description: 'Se se creo el producto: productoxpdo',
        dateAdd: '2024-04-10'
      },
      {
        id: 'QWS-43-GHE',
        name: 'Leo Porras',
        description: 'Se se creo el producto: producto egr',
        dateAdd: '2024-04-06'
      }
    ];
    setProductsHistoryList(newProductsHistory);
  };

  //Disparamos la funcion con un useEffect
  useEffect(() => {
    getProductsHistory()
  }, [])

  const columns = React.useMemo<ColumnDef<ProductsHistory, any>[]>(
    () => [
      {
        header: `Historial de cambios del producto: ${nameProduct}`,
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'id',
            cell: (info) => (
              <OverlayTrigger placement='top' overlay={renderTooltip}>
                <button className='btn btn-light-primary'>{info.getValue()}</button>
              </OverlayTrigger>
            ),
            header: () => <span>Id</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'name',
            cell: (info) => info.getValue(),
            header: () => <span>Nombre</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'description', // Nueva clave de acceso para la combinación de teléfono y WhatsApp
            cell: (info) => (
              <div>
                <p>{info.row.original.description}</p>
              </div> // Mostrar teléfono y WhatsApp separados por /
            ),
            header: () => <span>Descripcion</span>,
          },
          {
            accessorKey: 'dateAdd',
            id: 'dateAdd',
            cell: (info) => info.getValue(),
            header: () => <span>Fecha</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  )

  const table = useReactTable({
    data: productsHistoryList,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  React.useEffect(() => {
    if (table.getState().columnFilters[1]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name' || table.getState().sorting[0]?.desc) {
        table.setSorting([{ id: 'name', desc: false }])
      }
    }
  }, [table.getState().columnFilters[1]?.id])

  return (
    // <h1>hola</h1>

    <div className='p-2'>

      <div className='h-2' />

      <div className='card-body table-responsive'>
        <table className='table table-row-bordered gs-3 gy-4 gx-12'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className='h-2' />

      <div className='card-footer'>
        <div className='d-flex justify-content-between gap-2'>
          <div id='SwitchPages'>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
          </div>

          <div id='PagesFinder' className='text-center'>
            {' '}
            <span className='flex items-center gap-1'>
              Página{' '}
              <strong>
                {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
              </strong>{' '}
              | Ir a la página:
              <input
                type='number'
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
                className='form-control form-control-solid w-16'
              />
            </span>
          </div>

          <div id='showFiles' className='text-center'>
            <select
              className='form-select form-select-solid'
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Mostrar {pageSize} registros
                </option>
              ))}
            </select>
            <p>{table.getPrePaginationRowModel().rows.length} Registros en total</p>
          </div>
        </div>
      </div>




    </div>

  )
}

function Filter({ column, table }: { column: Column<any, unknown>; table: Table<any> }) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
            }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
            }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Buscar... (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded'
        list={column.id + 'list'}
      />
      <div className='h-1' />
    </>
  )
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}