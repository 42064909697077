import { useIntl } from 'react-intl';
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { PreCRMListWrapper } from './PreCRMListWrapper'
import { useEffect } from 'react';
import { setPageTitle } from '../../utils/functions/_Functions';
import { DragonCem } from '../../utils/constants/_Constants';

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Pre-CRM',
    path: '/pre-CRM',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PreCRM = () => {

  const intl = useIntl();
  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    setPageTitle(`${intl.formatMessage({ id: "PRECRM.TITLE" })} | ${DragonCem}`);
  }, [])


  return (
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Pre-CRM</PageTitle>
              <div className='container-fluid'>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                      <h1 className='my-2'>
                        Módulo de Pre-CRM
                      </h1>
                      <PreCRMListWrapper />
                    </div>
                </div>
              </div>
            </>
  )
}

export {PreCRM}