import axios from 'axios'
import {
  apiDragon,
  DragonMainApi,
  EPSellersListByClient,
  EPSellersListByCampaign,
} from '../../../../utils/constants/_Constants'

import {apiGetDeletePetitions} from '../../../../../_metronic/helpers/apis'

export async function sellersListByClient(userToken: string, cliId: string) {
  try {
    const response = await apiGetDeletePetitions(
      apiDragon,
      `${DragonMainApi + EPSellersListByClient + cliId}`,
      {
        undefined,
      },
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'get'
    )

    const jsonDataSellersList = response

    // const token = jsonDataBrandsList.token;

    // console.log('token desde brands: ', token);

    // console.log(jsonDataBrandsList)

    return jsonDataSellersList
  } catch (error) {
    throw error
  }
}

export async function sellersListByCampaign(userToken: string, campaignId: string) {
  try {
    const response = await apiGetDeletePetitions(
      apiDragon,
      `${DragonMainApi + EPSellersListByCampaign + campaignId}`,
      {
        undefined,
      },
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'get'
    )

    const jsonDataSellersList = response

    return jsonDataSellersList
  } catch (error) {
    throw error
  }
}