// Importamos hooks de react que usaremos para este componente
import { useContext, useState } from "react";
// Importamos libreria para manejo de lenguaje 
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from "../../../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------

// Importamos funcion para vencimiento de sesion
import { sessionExpired } from "../../../../utils/functions/_Functions";

// ---------------------------------------------------------------------------------------

// Importamos las interfaces globales que usaremos para este modulo
import { AlertInfo } from "../../../../utils/interfaces/_InterfacesTypes";
// Importamos la funcion productsNew para conexion con API de productos
import { productsNew } from "../../services/_ProductsNewApi";

// Iniciamos funcion para construir componente Step6
function Step6({ data, handlePrev, closeModal, getProductsRegisters, setCurrentPage }) {
    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl();

    const { user, token } = useContext(UserContext);
    const cli_id = user.cli_id;
    const userToken = token;

    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        text: '',
        variant: 'primary',
    });

    const handleFormSubmit = async () => {
        const pro_name = data.productName;
        const pro_mails = data.productMails;
        const pro_type = data.productType;
        const pro_legals = data.productLegals;
        const pro_country = data.productCountry;
        const pro_state = data.productState;
        const pro_city = data.productCity;
        const pro_zone = data.productLocal;
        const crm_id = data.productCrm;
        const productWab = data.productWab;
        const pro_price = data.productPrize;
        const brand_id = data.productBrand;
        const automaticsCampaigns = data.productCampaigns;

        try {

            const { data: jsonData, code, msg } = await productsNew(
                pro_name,
                pro_mails,
                pro_type,
                pro_legals,
                pro_country,
                pro_state,
                pro_city,
                pro_zone,
                crm_id,
                productWab,
                pro_price,
                brand_id,
                automaticsCampaigns,
                cli_id,
                userToken
            );

            if (code === 401 && msg === 'Token no valido') {

                sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
                return;

            } else {

                setAlertInfo({
                    text: code === 200 ? intl.formatMessage({ id: "PRODUCTS.MODAL_NEW.SUCCESS_PRODUCT_CREATION" }) : intl.formatMessage({ id: "PRODUCTS.MODAL_NEW.FAIL_PRODUCT_CREATION" }),
                    variant: code === 200 ? 'success' : 'danger',
                });

                setTimeout(() => {
                    setAlertInfo({
                        text: '',
                        variant: '',
                    });
                    // setShowModal(false); 
                    // reRenderProducts();
                    closeModal();
                    getProductsRegisters();
                    setCurrentPage(1);
                }, 3000);

            }

        } catch (error) {

            // console.error("Error en el guardado del producto", error);
            setAlertInfo({
                text: 'Error en la solicitud Axios',
                variant: 'danger',
            });

        }

    };

    return (
        <div>
            <h2 className='modal-title mb-4'>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.LABEL_PROSUMMARY' })}</h2>
            <p>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SUMMARY_PRONAME' })} {data.productName}</p>
            <p>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SUMMARY_MAILS' })} {data.productMails}</p>
            <p>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SUMMARY_PROTYPE' })} {data.productType}</p>
            <p>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SUMMARY_PROLEGALS' })} {data.productLegals}</p>
            <p>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SUMMARY_PROCOUNTRY' })} {intl.formatMessage({ id: `PRODUCTS.COUNTRY.${data.productCountry}` })}</p>
            <p>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SUMMARY_PROCITY' })} {data.productCity}</p>
            <p>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SUMMARY_PROSTATE' })} {data.productState}</p>
            <p>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SUMMARY_PROMUNICIPALITY' })} {data.productLocal}</p>
            <p>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SUMMARY_PROCRM' })} {data.productCrm}</p>
            <p>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SUMMARY_PROWAB' })} {data.productWab}</p>
            <p>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SUMMARY_PROPRICE' })} {data.productPrize}</p>
            <p>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SUMMARY_PROBRAND' })} {data.productBrand}</p>
            <p>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SUMMARY_PROCAMAUTO' })} {data.productCampaigns}</p>

            {alertInfo.text && (
                <div className={`alert alert-${alertInfo.variant} d-flex align-items-center`} role='alert'>
                    <div>{alertInfo.text}</div>
                </div>
            )}

            <button className="btn btn-light btn-sm mt-6 me-2" onClick={handlePrev}>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.BUTTONLAST' })}</button>

            <button type="button" className="btn btn-primary btn-sm mt-6" onClick={(e) => { handleFormSubmit(); }}>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SUBMIT_BUTTON' })}</button>

        </div>
    )

}

export { Step6 };