import { Dropdown } from 'react-bootstrap';

export const DropdownItemExportJSON = ({ dataToExport, title }) => {

  const handleToExportProductsAssignedDataJSON = () => {
    try {
      // Verificar si hay datos para exportar
      if (!dataToExport || !dataToExport.brandProducts) {
        console.error('No hay datos para exportar o no hay productos disponibles.');
        return;
      }

      // Obtener el usuario y sus productos
      const { id, name, brandProducts } = dataToExport;

      // Crear un objeto con los datos del usuario y sus productos
      const jsonData = {
        userId: id,
        userName: name,
        products: brandProducts.map(product => ({
          productId: product.pro_id,
          productName: product.name,
        })),
      };

      // Convertir el objeto a formato JSON
      const jsonString = JSON.stringify(jsonData, null, 2);

      // Crear un enlace temporal para descargar el archivo JSON
      const blob = new Blob([jsonString], { type: 'application/json' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `Data exportada de los productos asignados a ${title}.json`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error al generar el archivo JSON:', error);
    }
  };

  return (
    <Dropdown.Item>
      <span onClick={handleToExportProductsAssignedDataJSON}>
        Exportar productos asignados del vendedor (.json)
      </span>
    </Dropdown.Item>
  );
};
