// Importamos constantes para realizar la peticion a la API de productos
import {DragonMainApi, EPProductActivate, apiDragon} from '../../../utils/constants/_Constants'

// ---------------------------------------------------------------------------------------
// Importamos funcion para obtener codigos de respuesta del servidor
import {apiPetitions} from '../../../../_metronic/helpers/apis'

//Función para realizar la petición a la API de activación de un producto
async function AMProductStatusActivate(product_id: string, userToken: string) {
  try {
    const data = await apiPetitions(
      apiDragon,
      `${DragonMainApi + EPProductActivate + product_id}`,
      {},
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'put'
    )

    const jsonDataSellersDeactivate = data

    return jsonDataSellersDeactivate
  } catch (error) {
    throw error
  }
}

export {AMProductStatusActivate}
