// Importamos constantes para realizar la peticion a la API de marcas
import { DragonMainApi, EPBrandsNew, apiDragon } from "../../../utils/constants/_Constants";

// ---------------------------------------------------------------------------------------

// Importamos funcion para obtener codigos de respuesta del servidor
import { apiPetitions } from "../../../../_metronic/helpers/apis";

// Funcion para realizar la peticion a la API de creacion de marca
async function brandsNew(name_brand: string, crm_id: string | undefined, brandsProductsArray: string[] | undefined, roleId: string, cli_id_API: string, userToken: string){
    // console.log('token antes del envio en brands: ', userToken)
    try{
        const response = await apiPetitions(apiDragon,`${DragonMainApi + EPBrandsNew}`, {
            
            nameBrand: name_brand,
            cliIdAPI: cli_id_API,
            crmId: crm_id,
            products: brandsProductsArray,
            roleId
            
        }, {
            
            'Content-Type': 'application/json',
            'x-token': userToken,
           
        },
        'post');

        const jsonDataBrandsNew = response;

        // const token = jsonDataBrandsNew.token;

        // console.log('Token desde marca nueva', token);

        console.log(jsonDataBrandsNew)
        // console.log(jsonDataBrandsNew);
        return jsonDataBrandsNew;

    } catch (error) {
        throw error;
        // console.log('Error de nueva marca ', error)
    }
}

export { brandsNew };