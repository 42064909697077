// Importamos constantes para realizar la peticion a la API de marcas
import { DragonMainApi, EPRoleMilestoneNew, apiDragon } from "../../../utils/constants/_Constants";

// Importamos funcion para obtener codigos de respuesta del servidor
import { apiPetitions } from "../../../../_metronic/helpers/apis";

// Funcion para realizar la peticion a la API de creacion de hitos por rol
async function roleMilestoneNew (dayNameLong: string, startTime: string, endTime: string, colorMilestone: string, textColorMilestone: string, userToken: string, rolId: string) {

    try {

        const response = await apiPetitions(apiDragon, `${DragonMainApi + EPRoleMilestoneNew + rolId}`, {

            start: startTime,
            end: endTime,
            day: dayNameLong,
            color: colorMilestone,
            textColor: textColorMilestone,

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },

        'put'); 
        // console.log({ response });

        const jsonDataRoleHitoNew = response;

        return jsonDataRoleHitoNew;

    } catch (error) {

        console.log('algo salio mal creando el hito '+ error);
        throw error;

    }

}

export { roleMilestoneNew };