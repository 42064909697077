// Importamos hooks de react que usaremos para este componente
import React, { useState, useContext, useEffect } from "react";
// Importamos libreria para manejo de lenguaje 
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from "../../../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------

// Importamos la funcion campaignGeneralInfoEdit para conexion con API de campañas
import { campaignGeneralInfoEdit } from "../../services/_CampaignsEditApi";
// Importamos la funcion rolesListByClient para conexion con API de roles
import { rolesListByClient } from "../../../assigns/services/_RolesListApi";

// ---------------------------------------------------------------------------------------

// Importamos las interfaces globales que usaremos para este modulo
import { AlertInfo } from "../../../../utils/interfaces/_InterfacesTypes";
// Importamos las interfaces de roles que usaremos para este componente
import { Role } from "../../../assigns/interfaces/rolesTypes";

// ---------------------------------------------------------------------------------------

// Importamos funcion sessionExpired para vencimiento de sesion
import { sessionExpired } from "../../../../utils/functions/_Functions";

// Iniciamos funcion para construir componente GeneralTabForm
function GeneralTabForm ({formDataEditGral, setFormDataEditGral, cam_idCampaign, closeModalEdit, getCampaignsRegisters}){

    console.log(formDataEditGral)
    
    //Variables desde el usercontext
    const { user, token, updateToken } = useContext(UserContext);
    const userToken = token;
    const clientId = user.cli_id;
    const cam_role = formDataEditGral.cam_role 

    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl();

    console.log(formDataEditGral);

    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        text: '',
        variant: 'primary',
    });

    const [rolesList, setRolesList] = useState<Role[]>([]);

    const [selectedDays, setSelectedDays] = useState(formDataEditGral.days || []);

    console.log(formDataEditGral.days);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        const { name, value, type, checked } = event.target;

        const inputValue = type === 'checkbox' ? checked : value;

        setFormDataEditGral(prevState => ({
            ...prevState,
            [name]: inputValue
        }));

    }

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
    
        setFormDataEditGral(prevState => ({
            ...prevState,
            [name]: value
        }));

        console.log('nuevo rol: ', formDataEditGral.roleId);
        
    };

    const handleDayChange = (e) => {
        const day = e.target.name;
        const isChecked = e.target.checked;
    
        let updatedDays;
        if (isChecked) {
            updatedDays = [...formDataEditGral.days, day];
        } else {
            updatedDays = formDataEditGral.days.filter((selectedDay) => selectedDay !== day);
        }
    
        setFormDataEditGral((prevFormData) => ({
            ...prevFormData,
            days: updatedDays
        }));
    
        setSelectedDays(updatedDays);
    };    

    const days = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"];

    const handleFormSubmit = async () => {
        console.log("FormDataEditGral values:");
        console.log("status:", formDataEditGral.status);
        console.log("quality:", formDataEditGral.quality);
        console.log("cam_role:", formDataEditGral.cam_role);
        console.log("start_hour:", formDataEditGral.start_hour);
        console.log("end_hour:", formDataEditGral.end_hour);
        console.log("shift:", formDataEditGral.shift);
        console.log("days:", formDataEditGral.days);
        console.log("rolid:", formDataEditGral.roleId);

        const status = formDataEditGral.status;
        const quality = formDataEditGral.quality;
        const cam_role = formDataEditGral.cam_role;
        const start_hour = formDataEditGral.start_hour;
        const end_hour = formDataEditGral.end_hour;
        const days = formDataEditGral.days;
        const shift = formDataEditGral.shift;
        const roleId = formDataEditGral.roleId;

        try {

            const { data: jsonData, code, msg } = await campaignGeneralInfoEdit(status, quality, cam_role, start_hour, end_hour, days, shift, roleId, cam_idCampaign, userToken);

            if ( code === 401 && msg === 'Token no valido' ) {

                sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
                return;

            } else {

                setAlertInfo({
                    text: code === 200 ? intl.formatMessage({ id: "CAMPAIGNS.MODAL_EDIT.SUCCESS_CAMPAIGN_EDITION" }) : intl.formatMessage({ id: "CAMPAIGNS.MODAL_EDIT.FAIL_CAMPAIGN_EDITION" }),
                    variant: code === 200 ? 'success' : 'danger'
                });

                setTimeout(() => {
                    setAlertInfo({
                        text: '',
                        variant: '',
                    });
                    closeModalEdit();
                    getCampaignsRegisters();
                }, 3000);

            }

        } catch (error) {

            console.log(error);

        }

    }

    const getRolesRegisters = async () => {

        try {

            const { data: jsonData, code, msg} = await rolesListByClient(userToken, clientId);

            if (code === 401 && msg === 'token no valido') {

                sessionExpired('La sesion ha expirado, porfavor vuelva a iniciar sesion');
                return;

            } else {

                const rolesListReceived = jsonData.assigns;

                console.log(rolesListReceived);

                const userTokenNew = jsonData.token;

                updateToken(userTokenNew);

                setRolesList(rolesListReceived);

            }

        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        getRolesRegisters();
    }, []);

    return (
        <>
        <div className="d-flex mb-8">
            <label className="mr-8">Estatus: </label>&nbsp;&nbsp;&nbsp;
            <div className="form-check form-switch">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id="toggleSwitch"
                    name="status"
                    checked={formDataEditGral.status}
                    onChange={handleInputChange}
                />
                <label className="form-check-label" htmlFor="toggleSwitch">
                    Apagado/Encendido
                </label>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <label className="mr-8">Quality: </label>&nbsp;&nbsp;&nbsp;
            <div className="form-check form-switch">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id="toggleSwitchQa"
                    name="quality"
                    checked={formDataEditGral.quality}
                    onChange={handleInputChange}

                />
                <label className="form-check-label" htmlFor="toggleSwitch">
                    Apagado/Encendido
                </label>
            </div>
            </div>

            <div>
                <label className="mb-6">Rol de campaña: </label>
                <div className="d-flex">
                    <div className="col-6 me-2">
                        <select className="form-select form-select-solid" name="cam_role" value={formDataEditGral.cam_role} onChange={handleSelectChange}>
                            <option value=""> {cam_role} </option>
                            {/* <option value={formDataEditGral.cam_role}>{formDataEditGral.cam_role}</option> */}
                            {rolesList.map((role, index) => (
                                <option key={index} id={role.role_id} value={role.role_id}>
                                    {role.role_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-6">
                        <span className="text-muted">Selecciona de los roles configurados y guardados previamente en este cliente.</span>
                    </div>
                </div>

            </div>

            {/* <div className="mt-6 mb-4">
                <label>Horario de atencion:</label>
            </div> */}

            {/* <div className="d-flex">
                <div>
                    <div className="mb-3">
                        <label htmlFor="start_hour">Hora de inicio:</label>
                        <input
                            type="time"
                            className="form-control form-control-solid"
                            id="start_hour"
                            name="start_hour"
                            value={formDataEditGral.start_hour}
                            onChange={handleInputChange}

                        />
                    </div>
                </div>
            <div> */}

                {/* <div className="mb-3">
                    <label htmlFor="end_hour">Hora de fin:</label>
                    <input
                        type="time"
                        className="form-control form-control-solid"
                        id="end_hour"
                        name="end_hour"
                        value={formDataEditGral.end_hour}
                        onChange={handleInputChange}

                    />
                </div>
            </div>
            </div> */}
            
            {/* <div>
                <label className="mt-6 mb-4">Días de atencion:</label>
                {days.map((day) => (
                    <div className="form-check" key={day}>
                        <input 
                            type="checkbox"
                            className="form-check-input"
                            id={day}
                            name={day}
                            checked={selectedDays.includes(day)}
                            onChange={handleDayChange}
                        />
                        <label className="form-check-label" htmlFor={day}>
                            {day}
                        </label>
                    </div>
                ))}
            </div> */}

            {/* <div className="mt-6 mb-4">
                <label>Cambio de turno:</label>
            </div> */}

            {/* <div className="d-flex">
                <div>
                    <div className="mb-3">
                        <input
                            type="time"
                            className="form-control form-control-solid"
                            id="changeTime"
                            name="shift"
                            value={formDataEditGral.shift}
                            onChange={handleInputChange}

                        />
                    </div>
                </div>

            </div> */}

        {alertInfo.text && (
            <div className={`alert alert-${alertInfo.variant} d-flex align-items-center`} role='alert'>
                <div>{alertInfo.text}</div>
            </div>
        )}

        <button type="submit" className='btn btn-primary btn-sm mt-8' onClick={handleFormSubmit}>Enviar</button>
    </>
    )
}

export { GeneralTabForm };