import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { sessionExpired } from '../../../../utils/functions/_Functions';
import { sellersListByClient } from '../../../control-sellers/sellers/services/_SellersListApi';
import { GroupsListByClient } from '../../../control-sellers/sellers-groups/services/_GroupSellersListApi';
import { UserContext } from '../../../../app-context/user-context/UserContext';
import { UserForAssign } from '../../interfaces/rolesTypes';


//En este paso, almacenaremos los id y nombre dependiendo la seleccion si es por vendedores y grupos
function Step3Rol({ data, onNext, handlePrev, prevData, setStep3Data }) {
  const [users, setUsers] = useState<string[]>(prevData?.users || []);
  const [groups, setGroups] = useState<string[]>(prevData?.groups || []);
  const [usersName, setUsersName] = useState<string[]>(prevData?.usersName || []);
  const [groupsName, setGroupsName] = useState<string[]>(prevData?.groupsName || []);
  const assignForUsers = data.assignForUsers;

  const [usersOptions, setUsersOptions] = useState<UserForAssign[]>([]);
  const [groupsOptions, setGroupsOptions] = useState<UserForAssign[]>([]);
  const { user, token, updateToken } = useContext(UserContext);
  const clientId = user.cli_id;
  const userToken = token;

  const intl = useIntl();

  const handleNext = () => {
  
    onNext({
      users,
      usersName,
      groups,
      groupsName
    }
    );

    setStep3Data({
      users,
      usersName,
      groups,
      groupsName
    });

    console.log(usersName)
  };

  // En este useEffect se va a cargar cada que se renderiza el componente dependiendo la seleccion que hicieron si es por vendedores o grupos
  useEffect(() => {
    const fetchData = async () => {
      if (assignForUsers === 'Vendedor') {
        try {
          const { data: jsonData, code, msg } = await sellersListByClient(userToken, clientId)
      if (code === 401 && msg === 'Token no valido'){
          
        sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
        return;
        
      } else {
        const sellersListReceived = jsonData.data;
          const userTokenNew = await jsonData.token;
      
          updateToken(userTokenNew);

          const mappedSellers = sellersListReceived.map((key) => ({
            id: key.uid,
            name: key.usrData.usr_name
          }));

          setUsersOptions(mappedSellers);
      }
          
        } catch (error) {
          sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
        }
      } else if (assignForUsers === 'Grupo') {
        try {
          const dataGroups = await GroupsListByClient(userToken, clientId);
          const groupsListReceived = dataGroups.data;
          const userTokenNew = await dataGroups.token;

          updateToken(userTokenNew);

          const mappedGroups = groupsListReceived.map((key) => ({
            id: key.group_id,
            name: key.friendly_name
          }));

          setGroupsOptions(mappedGroups);
        } catch (error) {
          console.error('Error receiving groups: ', error);
          sessionExpired(intl.formatMessage({ id: 'GLOBAL.ALERT.SESSION_EXPIRED' }));
        }
      }
    };

    fetchData();
  }, []);


  // Esta funcion almacena el id de cada vendedor que se selecciona en el switch
  const handleUserSwitchChange = (userId: string, userName: string) => {
    if (users.includes(userId)) {
      setUsers(users.filter(id => id !== userId));
  
      // Remover el nombre correspondiente al ID deseleccionado
      setUsersName(usersName.filter(name => name !== userName));
    } else {
      setUsers([...users, userId]);
  
      // Agregar el nombre correspondiente al ID seleccionado
      setUsersName([...usersName, userName]);
    }
  };
  
  // Esta funcion almacena el id de cada grupo que se selecciona en el switch
  const handleGroupSwitchChange = (groupId: string, groupName: string) => {
    if (groups.includes(groupId)) {
      setGroups(groups.filter(id => id !== groupId));
      // Si el grupo está deseleccionado, también eliminamos su nombre del estado
      setGroupsName(groupsName.filter(name => name !== groupName));
    } else {
      setGroups([...groups, groupId]);
      // Si el grupo está seleccionado, agregamos su nombre al estado
      setGroupsName([...groupsName, groupName]);
    }
  };

  return (
    <>
      {assignForUsers === 'Vendedor' && (
        <div className='mb-5'>
          <p>Elige uno o más vendedores:</p>
          {usersOptions.map((user) => (
            <div key={user.id} className='form-check form-switch form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id={`userSwitch${user.id}`}
                value={user.id}
                checked={users.includes(user.id)}
                onChange={(e) => handleUserSwitchChange(e.target.value, user.name)}
              />
              <label className='form-check-label' htmlFor={`userSwitch${user.id}`}>
                {user.name}
              </label>
            </div>
          ))}
        </div>
      )}

      {assignForUsers === 'Grupo' && (
        <div className='mb-5'>
          <p>Elige uno o más grupos:</p>
          {groupsOptions.map((group) => (
            <div key={group.id} className='form-check form-switch form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id={`groupSwitch${group.id}`}
                value={group.id}
                checked={groups.includes(group.id)}
                onChange={(e) => handleGroupSwitchChange(e.target.value, group.name)}
              />
              <label className='form-check-label' htmlFor={`groupSwitch${group.id}`}>
                {group.name}
              </label>
            </div>
          ))}
        </div>
      )}

      <button className='btn btn-light btn-sm mt-6 me-2' onClick={handlePrev}>
        Anterior
      </button>

      <button className='btn btn-primary btn-sm mt-6' onClick={handleNext}>
        Siguiente
      </button>
    </>
  );
}

export { Step3Rol };
