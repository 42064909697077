import { CountryCode } from "libphonenumber-js/types.cjs";

// ****************** MAIN API AND ENDPOINTS CONSTANTS ******************
// ************** CONSTANTES DE API PRINCIPAL Y ENDPOINTS **************

import axios from "axios";
// Constant with the MAIN API URL
// Constante con la URL de la API principal
export const DragonMainApi: string = 'https://dev-api-main.dcem.io/';

export const apiDragon = axios.create({
    baseURL: DragonMainApi,
});

// Constant with the login endpoint
// Constante con el endpoint para el login
export const EPLogin: string = 'auth/login';

// Constant with the brands list endpoint
// Constante con el endpoint para el listado de marcas
export const EPBrandsListByClient: string = 'brand/';

// Constant with the brand info by brand id endpoint
// Constante con el endpoint para la informacion de marca por id de marca
export const EPBrandInfoById: string = 'brand/byId/';

// Constant with the brands new endpoint
// Constante con el endpoint para nueva marca
export const EPBrandsNew: string = 'brand/';

// Constant with the brands edit endpoint
// Constante con el endpoint para editar marca
export const EPBrandEdit: string = 'brand/';

// Constant with the brands deactivate endpoint
// Constante con el endpoint para desactivar marca
export const EPBrandDeactivate: string = 'brand/';

// Constant with the brands activate endpoint
// Constante con el endpoint para activar marca
export const EPBrandActivate: string = 'brand/activate/';

// Constant with the products list By client endpoint
// Constante con el endpoint para el listado de productos por cliente
export const EPProductsListByClient: string = 'product/byClient/';

// Constant with the products list assigned by user
// Constante con el endpoint para el listado de productos asignados a un usuario
export const EPProductsListAssignedByUser: string = 'product/assignedproducts/'

// Constant with the products list By brand endpoint
// Constante con el endpoint para el listado de productos por marca
export const EPProductsListByBrand: string = 'product/byBrand/';

// Constant with the info of one product endpoint
// Constante con el endpoint para la informacion de un solo producto
export const EPOneProductInfo: string = 'product/byId/'

// Constant with the products new endpoin
// Constante con el endpoint para nuevo producto
export const EPProductNew: string = 'product/';

// Constant with the edit product status (deactivate)
// Constante con el endpoint para edicion del status de producto (desactivación)
export const EPProductDeactivate: string = 'product/delete/';

// Constant with the edit product status (Activate)
// Constante con el endpoint para edicion del status de producto (Activación)
export const EPProductActivate: string = 'product/activate/';

// Constant with the products edit endpoint
// Constante con el endpoint para editar producto
export const EPProductEditInfo: string = 'product/dataEdit/';

// Constant with the products media edit endpoint
// Constante con el endpoint para editar media del producto
export const EPProductEditMedia: string = 'product/mediaEdit/';

// Constant with the product iscore list endpoint
// Constante con el endpoint para el listado de iscore por producto
export const EPProductIscoreList: string = 'product/iscoreData/';

// Constant with the product iscore edit endpoint
// Constante con el endpoint para edicion de iscore de un producto
export const EPProductIscoreEdit: string = 'product/iscores/';

// Constant with the campaigns list per product endpoint
// Constante con el endpoint para el lsitado de campañas por producto
export const EPCampaignsList: string = 'campaign/all/';

// Constant with the campaigns list per product endpoint
// Constante con el endpoint para el lsitado de campañas por producto
export const EPCampaignsListByClient: string = 'campaign/byClient/';

// Constant with the campaign by id endpoint
// Constante con el endpoint de campaña por id
export const EPCampaignById: string = 'campaign/';

// Constant with the campaigns medium list endpoint
// Constante con el endpoint para el listado de medios de las campañas
export const EPCampaignsMediumList: string = 'campaign/channel/'

// Constant with the new automatic campaigns endpoint
// Constante con el endpoint para nuevas campañas automaticas
export const EPAutoCampaignNew: string = 'campaign/automatics/';

// Constant with the new manual campaign endpoint
// Constante con el endpoint para nuevas campañas manuales
export const EPManualCampaignNew: string = 'campaign/manuals/';

// Constant with the edit campaign general info endpoint
// Constante con el endpoint para edicion de informacion general de campañas
export const EPCampaignGeneralEdit: string = 'campaign/general/';

// Constant with the edit campaign connectivity info endpoint
// Constante con el endpoint para edicion de informacion de conectividad de campañas
export const EPCampaignConnectEdit: string = 'campaign/connectivity-advance/'; 

// Constant with the edit campaign notify info endpoint
// Constante con el endpoint para edicion de informacion de notificaciones
export const EPCampaignNotifyEdit: string = 'campaign/notify-duplicates/';

// Constant with the edit campaign status (deactivate)
// Constante con el endpoint para edicion del status de campaña (desactivación)
export const EPCampaignDeactivate: string = 'campaign/';

// Constant with the edit campaign status (Activate)
// Constante con el endpoint para edicion del status de campaña (Activación)
export const EPCampaignActivate: string = 'campaign/activate/';

// Constante con el endpoint para el listado de usuarios con accesos compartidos
export const EPUsersSharedAccessList: string = 'share-access/users/';

// Constant with the shared clients endpoint
// Constante con el endpoint de los clientes compartidos
export const EPClientsSharedList: string = 'share-access/clients/';

// Constant with the shared clients by BU list endpoint
// Constante con el endpoint para listado de clientes compartidos por BU
export const EPClientsSharedBUList: string = 'share-access/clientsbu/';

// Constant with the reasign client endpoint
// Constante con el endpoint de reasignar cliente
export const EPClientReasign: string = 'share-access/users/';

// Constant with the products by shared client endpoint
// Constante con el endpoint de productos por cliente compartido
export const EPProductsByClientList: string = 'product/byClientBu/';

// Constant with the shared clients endpoint
// Constante con el endpoint de agregar vendedores
export const EPSellersNew: string = 'user';

// Constante con el endpoint de agregar vendedores
export const EPSellerEdit: string = 'user';

// Constante con el endpoint desactivar vendedor
export const EPSellerDeactivate: string = 'user/delete/';

// Constante con el endpoint activar vendedor
export const EPSellerActivate: string = 'user/activate/';

// Constante con el endpoint de agregar vendedores
export const EPGroupSellersNew: string = 'group';

// Constante con el endpoint de editar vendedores
export const EPGroupSellersEdit: string = 'group';

// Constant with the shared clients endpoint
// Constante con el endpoint de agregar vendedores
export const EPSellersListByClient: string = 'user/all/';

// constant with the sellers list by campaign endpoint
// Constante con el endpoint de listado de vendedores por campaña
export const EPSellersListByCampaign: string = 'user/cam/';

// Constant with the shared clients endpoint
// Constante con el endpoint de listar grupos
export const EPGroupsListByClient: string = 'group/byClient/';

// Constant with the roles list by client endpoint
// Constante con el endpoint de listado de roles por cliente
export const EPRolesListByClient: string = 'assign/role/byClient/';

// Constant with the roles list by id role endpoint
// Constante con el enpoint de listado de roles por id de rol
export const EPRoleListById: string = 'assign/role/';

// Constant with the role milestone new endpoint
// Constante con el endpoint para la creacion de hito por rol 
export const EPRoleMilestoneNew: string = 'assign/role/add-milestone/';

// Constant with the role milestone edit endpoint
// Constante con el endpoint para la edicion de hito por rol
export const EPRoleMilestoneEdit: string = 'assign/role/edit-milestone/';

// Constant with the role milestone delete endpoint
// Constante con el endpoint para la eliminacion de un hito por rol
export const EPRoleMilestoneDelete: string = 'assign/role/delete-milestone/';

// Constante con el endpoint de agregar vendedores
export const EPClientNew: string = 'client';

// Constant with the edit client status (deactivate)
// Constante con el endpoint para edicion del status de cliente (desactivación)
export const EPClientStatusDeactivate: string = 'client/';

// Constant with the edit client status (activate)
// Constante con el endpoint para edicion del status de cliente (activación)
export const EPClientStatusActivate: string = 'client/activate/';

// Constante con el endpoint para editar usuarios compartidos
export const EPUsersSharedAccessEdit: string = 'share-access/users/'

// Constante con el endpoint para agregar usuarios compartidos
export const EPUsersSharedAccessNew: string = 'share-access/users/'

// Constante con el endpoint para validar whatsapp e email
export const EPValidateEmailWhats: string = 'user/check-mail-whatsapp';

// Constant with the role new endpoin
// Constante con el endpoint para nuevo rol
export const EPRoleNew: string = 'assign/role';

// Constant with the rol edit endpoint
// Constante con el endpoint para nuevo producto
export const EPRoleEdit: string = 'assign/role';

// Constant with the roles status endpoint
// Constante con el endpoint de status de rol
export const EPRolesStatusFalse: string = 'assign/role/';
// Constant with the roles status endpoint
// Constante con el endpoint de status de rol
export const EPRolesStatusTrue: string = 'assign/role/activate/';

// Constant with the roles status endpoint
// Constante con el endpoint de status de rol
export const EPGroupStatusFalse: string = 'group/';

// Constant with the roles status endpoint
// Constante con el endpoint de status de rol
export const EPGroupStatusTrue: string = 'group/activate/';

// Constant with the leads list endpoint
// Constante con el endpoint de listado de leads
export const EPLeadsListByClient: string = 'lead/byClient/';

// Constant with the lead detail info endpoint
// Constante con el endpoint de obtencion de la informacion para detalle de lead
export const EPLeadDetailInfo: string = 'lead/detail/';

// Constant with the lead reassign endpoint
// Constante con el endpoint de reasignacion de lead
export const EPLeadReassign: string = 'lead/reassign/';

// Constant with the lead new endpoint
// Constante con el endpoint de nuevo lead
export const EPLeadNew: string = 'lead/manual/';

// Constant with the lead edit endpoint
// Constante con el endpoint de edicion de lead
export const EPLeadEdit: string = 'lead/';

// Constant with the lead to spam endpoint
// Constante con el endpoint para enviar lead al spam
export const EPLeadToSpam: string = 'lead/spam/';

// Constant with the lead grade endpoint
// Constante con el endpoint para calificar lead
export const EPLeadGrade: string = 'lead/score/lead/'

// *********************** REPORTS API'S ***********************
// *********************** API'S REPORTES **********************

export const EPLeadsReport: string = 'lead/report/'

export const EPIscoreReport: string = 'lead/report/iscore/'

export const EPCallsReport : string = 'twilio/report/calls/'

export const EPMailsReport : string = 'lead/report/mails/'

export const EPWhatsappReport : string = 'twilio/report/wab/'

export const EPCampaignsReport : string = 'lead/campaign/report/'

export const EPGradesReport: string = 'lead/report/grades/'

export const EPGradesReportLeads: string = 'lead/detail/report/grades/'

// ****************** OTHER GENERAL CONSTANTS ******************
// ***************** OTRAS CONSTANTES GENERALES ****************

// Constante para la impresión del nombre en las pestañas.
export const DragonCem: string = 'DragonCEM';

// Constante con la URL del bucket (Multimedia - products)
export const BucketMediaURL: string = 'https://storage.googleapis.com/dragon-bucket-prod/tests/'; 

// Constante con la URL del bucket (Logos channels- campaigns)
export const BucketChannelLogosURL: string = 'https://storage.googleapis.com/dragon-bucket-prod/tests/logos-campaigns/';

// Constante con la URL del bucket (Banners de los clientes - Reporte de Leads)
export const BucketBannerClientsURL: string = 'https://storage.googleapis.com/dragon-bucket-prod/app/img/clients/'

//Constante con los paises permitidos en los dropdowns de las ladas
export const allowedCountries: CountryCode[] = ['MX', 'BR', 'US'];

// Constant with the validate token endpoint
// Constante con el endpoint de validar token
export const EPValidateToken : string = "auth/validate"

// ************* STYLES CONSTANT FOR DROPDOWN EXPORT COMPONENT *******************
// ******* CONSTANTE DE ESTILOS PARA EL COMPONENTE DROPDOWN DE EXPORTACION *******

export const DropdownExportStyles = {
    dropdownContainer: {
        position: 'relative' as 'relative',
        display: 'inline-block',
    },
    svgIcon: {
        transition: 'transform 0.3s ease',
        marginLeft: '8px',
        marginRight: '0',
    },
    rotate180: {
        transform: 'rotate(180deg)',
    },
    menuSubDropdown: {
        position: 'absolute' as 'absolute',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 16px 46px rgba(0, 0, 0, 0.1)',
        width: '200px',
        zIndex: 9999,
        opacity: 0,
        transform: 'translateY(-10px)',
        transition: 'transform 0.3s ease', // Tiempo de aparición
    },
    menuDropDown: {
        top: '100%',
        left: 'auto',
        right: '0',
    },
    menuDropUp: {
        bottom: '100%',
        left: 'auto',
        right: '0',
    },
    menuItem: {
        padding: '8px 16px',
    },
    alignRight: {
        left: '0',
    },
    alignLeft: {
        right: '0',
    },
    showMenu: {
        opacity: 1, // Aparecerá con opacidad total
        transform: 'translateY(0)', // Se moverá a su posición original
    },
    hideMenu: {
        opacity: 0, // Se desvanecerá
        transform: 'translateY(-10px)', // Se desplazará hacia arriba
    },
    menuLink: {
        textDecoration: 'none',
        color: '#333',
        fontWeight: 'bold' as 'bold',
    },
}