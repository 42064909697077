import React, {FC, useEffect} from 'react';

const ButtonHelpFreshdesk: FC = () => {

    useEffect(() => {
        const script1 = document.createElement('script');
        script1.innerHTML = `window.fwSettings={
            'widget_id': 66000003119
        };`;
        
        document.body.appendChild(script1);

        const script2 = document.createElement('script');
        script2.setAttribute('type', 'text/javascript');
        script2.setAttribute('src', 'https://widget.freshworks.com/widgets/66000003119.js');
        script2.setAttribute('async', 'true');
        script2.setAttribute('defer', 'true');
        document.body.appendChild(script2);

        // Limpia los scripts cuando el componente se desmonta
        // return () => {
        //     document.body.removeChild(script1);
        //     document.body.removeChild(script2);
        // };

    }, []);

    return (
        <div>
        </div>
    );
}

export {ButtonHelpFreshdesk}