// Importamos hooks de react que usaremos para este componente
import { useState } from "react";
// Importamos componentes de la libreria de bootstrap para react
import { Tab, Tabs, Container, Row, Col } from "react-bootstrap";
// Importamos libreria para manejo de lenguaje 
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos el componente InformationTabForm para el modal de edicion de producto
import { InformationTabForm } from "./tabsEditProduct/InformationTabForm";
// Importamos el componente MultimediaTabForm para el modal de edicion de producto
import { MultimediaTabForm } from "./tabsEditProduct/MultimediaTabForm";

// Iniciamos funcion para construir componente ModalEditProduct
function ModalEditProduct({ idProduct, merchPdfProduct, logoProduct, headerProduct, whaImgProduct, formDataEdit, setFormDataEdit, mailsArrayEdit, setMailsArrayEdit, getProductsRegisters, closeModalEdit }) {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl();

  const [activeTab, setActiveTab] = useState('multimedia');
  console.log('mails: ', mailsArrayEdit);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Container>
      <Row>
        <Col>
          <Tabs activeKey={activeTab} onSelect={handleTabChange}>
            <Tab eventKey="multimedia" title={intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.LABEL_TABMEDIA" })}>
                <MultimediaTabForm 
                  idProduct={idProduct} 
                  merchPdfProduct={merchPdfProduct}
                  logoProduct={logoProduct}
                  headerProduct={headerProduct}
                  whaImgProduct={whaImgProduct}
                  formDataEdit={formDataEdit}
                  getProductsRegisters={getProductsRegisters}
                  closeModalEdit={closeModalEdit}
                />
            </Tab>
            <Tab eventKey="Información" title={intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.LABEL_TABINFO" })}>
                <InformationTabForm 
                  idProduct={idProduct} 
                  headerProduct={headerProduct}
                  formDataEdit={formDataEdit} 
                  setFormDataEdit={setFormDataEdit} 
                  mailsArrayEdit={mailsArrayEdit} 
                  setMailsArrayEdit={setMailsArrayEdit} 
                  getProductsRegisters={getProductsRegisters}
                  closeModalEdit={closeModalEdit}
                />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}

export {ModalEditProduct};