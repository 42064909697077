// Importamos hooks de react que usaremos para este componente
import { useContext } from "react";
// Importamos componente Modal de la libreria de bootstrap para react
import { Modal } from "react-bootstrap";
// Importamos componente Separator de la libreria de react-aria-components para react
import { Separator } from "react-aria-components";

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from "../../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------

// Importamos la funcion sessionExpired para vencimiento de sesion
import { sessionExpired } from "../../../utils/functions/_Functions";

// ---------------------------------------------------------------------------------------

// Importamos funcion roleMilestoneNew para conexion con la API de creacion de hito
import { roleMilestoneNew } from "../services/_RolesMilestoneNewApi";

// Iniciamos funcion para construir componente ModalNewMilestoneSingle
function ModalNewMilestoneSingle({
    closeModalNew,
    showModalNew,
    selectedInfo,
    selectedCalendar,
    rolId,
    rolName,
    getMilestoneRegister,
    formDataNewMilestone,
    setFormDataNewMilestone,
    alertInfo,
    setAlertInfo,

}) {

    // Constantes con valores del contexto de usuario
    const { token, updateToken } = useContext(UserContext);
    const userToken = token;

    // Funcion para el manejo del input de cambio de color 
    const handleColorChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;

        setFormDataNewMilestone((prevData) => {

            const updatedFormData = {
                ...prevData,
                [name]: value,
            }

            return updatedFormData;

        })
    }

    // Funcion para el envio de formulario de creacion de hito
    const handleMilestoneSubmit = async () => {
        
        if (typeof selectedInfo !== 'string') {

            const dayNameLong = selectedInfo?.start?.toLocaleDateString('en-US', { weekday: 'long' });
            const startTime = selectedInfo?.start?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const endTime = selectedInfo?.end?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const colorMilestone = formDataNewMilestone.colorMilestone;
            const textColorMilestone = formDataNewMilestone.textColorMilestone;
            console.log('Se envio lo siguiente');
            console.log('Id del rol: ' + rolId);
            console.log('Dia: ' + dayNameLong);
            console.log('Hora de inicio: '+ startTime);
            console.log('Hora de fin: ' + endTime);
            // console.log('Color de fondo seleccionado: ' + colorMilestone);
            // console.log('color de texto seleccionado: ' + textColorMilestone)

            try {

                const { data: jsonData, code, msg } = await roleMilestoneNew(dayNameLong, startTime, endTime, colorMilestone, textColorMilestone, userToken, rolId);

                if ( code === 401 && msg === 'Token no valido' ) {

                    sessionExpired('la sesion ha expirado');
                    return;

                } else if (code === 400){

                    // console.log(msg.day.msg);

                    let milestoneMaxLimit = msg.day.msg;
                    let day = msg.day.value;

                    if (milestoneMaxLimit && milestoneMaxLimit === 'No se puede agregar otro hito') {

                        setAlertInfo({
                            text: `Haz alcanzado el limite de hitos permitidos para el dia ${day}`,
                            variant:'danger',
                        });
    
                        setTimeout(() => {
                            setAlertInfo({
                                text: '',
                                variant: '',
                            });
    
                            closeModalNew();

                        }, 3000)

                    } else {

                        setAlertInfo({
                            text: 'Hubo un error inesperado',
                            variant:'danger',
                        });
    
                        setTimeout(() => {
                            setAlertInfo({
                                text: '',
                                variant: '',
                            });
    
                            closeModalNew();

                        }, 3000);

                    }
                    
                } else {
                    setAlertInfo({
                        text: code === 200 ? 'El hito se creo con exito' : 'No pudimos crear el hito',
                        variant: code === 200 ? 'success' : 'danger',
                    });

                    setTimeout(() => {
                        setAlertInfo({
                            text: '',
                            variant: '',
                        });

                        closeModalNew();
                        getMilestoneRegister(rolId);

                    }, 3000);

                }

            } catch (error) {

                console.log('error '+error);

            }

        }

    }

    return (
        <>
            <Modal
                show={showModalNew}
                onHide={() => {
                    closeModalNew(); 

                    if(selectedCalendar){
                        selectedCalendar.unselect();
                    }
                    
                }}
                className="modal-default"
            >
                <Modal.Header 
                    closeButton 
                    onClick={() => {
                        closeModalNew();

                        if(selectedCalendar){
                            selectedCalendar.unselect();
                        }

                        }}>
                    <Modal.Title>
                    {selectedInfo && typeof selectedInfo !== 'string' ? (
                        <>
                           <h1>Nuevo Hito</h1>
                        </>
                    ) : (
                        <>
                           <h1>¡Error!</h1>
                        </>
                    )}
                        
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                {selectedInfo && typeof selectedInfo !== 'string' ? (
                    <>
                        <div className="d-flex">
                            <div className="col-6 me-8">
                                <label className="form-label fw-bold">Día seleccionado: </label> 
                            </div>
                            <div className="col-6">
                                <p>{selectedInfo.start?.toLocaleDateString('en-US', { weekday: 'long' })}</p>
                            </div>
                        </div>

                        <div className="d-flex">
                            <div className="col-6 me-8">
                                <label className="form-label fw-bold">Hora de inicio seleccionada: </label>
                            </div>
                            <div className="col-6">
                                <p>{selectedInfo.start?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>        
                            </div>
                        </div>

                        <div className="d-flex">
                            <div className="col-6 me-8">
                                <label className="form-label fw-bold">Hora de fin seleccionada: </label>
                            </div>
                            <div className="col-6">
                                {selectedInfo.end?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) === '00:00' ? (
                                    <p>23:59</p>
                                ) : (
                                    <p>{selectedInfo.end?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                                )}
                            </div>
                        </div>

                        <div className="d-flex">
                            <div className="col-6 me-8">
                                <label className="form-label fw-bold">Rol de destino: </label>
                            </div>
                            <div className="col-6">
                                <p>{rolName}</p>
                            </div>
                        </div>

                        <div className="mb-5 mt-5">
                            <div className="alert alert-info align-items-center" role="alert">
                                <div>Al crear este nuevo hito, se le asignaran los vendedores o grupos de vendedores que tiene el rol al que se esta creando el hito.</div>
                            </div>
                        </div>

                        <Separator className="my-6"/>
                        
                        <div>
                            <label className="text-muted mb-6">Si lo deseas, puedes personalizar el color de fonto y texto para identificar este hito, asegurate de que la combinación de colores que eligas sea facil de visualizar para ti, si decides no elegir ningun color, el hito se creará con los colores prestablecidos en los recuadros:  </label>
                        </div>
                        <div className="d-flex">
                            <div className="col-9 me-8">
                                <label className="form-label fw-bold">Color de fondo para el hito:  </label>
                            </div>
                            <div className="col-3">
                                <input type="color" name="colorMilestone" value={formDataNewMilestone.colorMilestone} onChange={handleColorChange}/>
                            </div>
                        </div>

                        <div className="d-flex">
                            <div className="col-9 me-8">
                                <label className="form-label fw-bold">Color del texto para el hito:  </label>
                            </div>

                            <div className="col-3 me-8">
                                <input type="color" name="textColorMilestone" value={formDataNewMilestone.textColorMilestone} onChange={handleColorChange}/>
                            </div>
                        </div>

                    </>
                ) : (
                    <>
                        <span>{selectedInfo}</span>
                    </>
                )}

                </Modal.Body>

                {alertInfo.text && ( 
                    <div className='px-20'> 
                      <div 
                      className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`} 
                        role='alert' 
                      > 
                        <div>{alertInfo.text}</div> 
                      </div> 
                    </div> 
                  )}

                <Modal.Footer>
                    <button
                        className="btn btn-sm btn-secondary"
                        type="button"
                        onClick={() => {
                            closeModalNew();

                            if(selectedCalendar){
                                selectedCalendar.unselect();
                            }
                        }}
                    >
                        Cancelar
                    </button>

                    {selectedInfo && typeof selectedInfo !== 'string' && (
                        <>
                            <button
                                className="btn btn-sm btn-primary"
                                type="submit"
                                onClick={() => {
                                    handleMilestoneSubmit();
                                }}
                            >
                                Enviar
                            </button>
                        </>
                    )}
                    
                </Modal.Footer>
            </Modal>
        </>
    );

}

export {ModalNewMilestoneSingle};