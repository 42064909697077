import {AxiosInstance} from 'axios'
//Put y post
export const apiPetitions = async (
  api: AxiosInstance,
  route = '',
  body: Object,
  headers: Object,
  type = 'post'
) => {
  try {
    /* Ejecuta petición http hacia la API users para crear el usuario */
    const respuesta = await api[type](route, body, {
      headers,
    }).catch((err) => {
      console.log('Al hacer la petición a la API de usuarios: ' + err)

      return {data: {err}}
    })
    const {data} = respuesta

    /* Si hubo algún error al hacer la petición se regresa en la respuesta de la petición */
    if (data?.err) {
      if (!data.err.response?.data.length) {
        return {
          code: data.err.response?.status ?? 503,
          msg: data.err.response?.data.msg,
          data: {},
        }
      }
      return {
        code: data.err.response?.status ?? 503,
        msg: data.err.response.data[0]?.messages ?? data.err.response.data[0].msg,
        data: {},
      }
    }

    /* Regresa los datos en la respuesta */
    return {
      code: respuesta.status,
      msg: data.msg,
      data,
    }
  } catch (err) {
    console.log(`Al procesar la petición para la api: ` + err)
    return {
      code: 500,
      msg: 'Algo salió mal',
      data: {},
    }
  }
}

export const apiGetDeletePetitions = async (
  api: AxiosInstance,
  route = '',
  params: Object,
  headers: Object,
  type = 'get'
) => {
  try {
    /* Ejecuta petición http hacia la API users para crear el usuario */
    const respuesta = await api[type](route, {
      params,
      headers,
    }).catch((err) => {
      console.log('Al hacer la petición a la API de usuarios: ' + err)

      return {data: {err}}
    })

    const {data} = respuesta
    /* Si hubo algún error al hacer la petición se regresa en la respuesta de la petición */
    if (data?.err) {
      console.log(data)
      if (!data.err.response?.data.length) {
        return {
          code: data.err.response?.status ?? 500,
          msg: data.err.response?.data.msg,
          data: data.err.response?.data ?? {},
        }
      }
      return {
        code: data.err.response?.status ?? 500,
        msg: data.err.response.data[0]?.message ?? data.err.response.data[0].msg,
        data: {},
      }
    }

    /* Regresa los datos en la respuesta */

    return {
      code: respuesta.status,
      msg: data.msg,
      data,
    }
  } catch (err) {
    console.log(`Al procesar la petición para la api: ` + err)
    return {
      code: 500,
      msg: 'Algo salió mal',
      data: {},
    }
  }
}

export const apiGetDeletePetitionsWithBody = async (
  api: AxiosInstance,
  route = '',
  body: Object,
  headers: Object,
  type = 'get'
) => {
  try {
    /* Ejecuta petición HTTP hacia la API */
    const respuesta = await api[type](route, {
      headers,
      data: body,
    }).catch((err) => {
      console.log('Al hacer la petición a la API: ' + err)

      return {data: {err}}
    })

    const {data} = respuesta

    /* Si hubo algún error al hacer la petición se regresa en la respuesta de la petición */
    if (data?.err) {
      console.log(data)
      if (!data.err.response?.data.length) {
        return {
          code: data.err.response?.status ?? 500,
          msg: data.err.response?.data.msg,
          data: data.err.response?.data ?? {},
        }
      }
      return {
        code: data.err.response?.status ?? 500,
        msg: data.err.response.data[0]?.message ?? data.err.response.data[0].msg,
        data: {},
      }
    }

    /* Regresa los datos en la respuesta */
    return {
      code: respuesta.status,
      msg: data.msg,
      data,
    }
  } catch (err) {
    console.log(`Al procesar la petición para la API: ` + err)
    return {
      code: 500,
      msg: 'Algo salió mal',
      data: {},
    }
  }
}
