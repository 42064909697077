import React, { createContext, useContext, useState } from "react";
import { UserContext } from './UserContext';

const UserProvider = ({ children }) => {
  // Obtener el objeto del localStorage (en formato string) y convertirlo a objeto
  const jsonString = localStorage.getItem('miData');
  const jsonData = jsonString ? JSON.parse(jsonString) : null;

  // Definir el valor inicial del usuario
  let initialUser;

  if (jsonData) {
    //Definimos todas las propiedas de nuestro objeto para pasarlas a la variable initialUser
    // Dentro de user
    const crm_id = jsonData.user.crm_id;
    const user_type = jsonData.user.user_type;
    const acc_rdwr = jsonData.user.acc_rdwr;
    //Dentro de usr_data
    const usr_name = jsonData.user.usr_data.usr_name;
    const usr_phone = jsonData.user.usr_data.usr_phone;
    const wha_phone = jsonData.user.usr_data.wha_phone;
    const usr_mail = jsonData.user.usr_data.usr_mail;
    const sms_phone = jsonData.user.usr_data.sms_phone;
    //Generales
    const calendly = jsonData.user.calendly;
    const status = jsonData.user.status;
    const date_add = jsonData.user.date_add;
    const date_modify = jsonData.user.date_modify;
    const chatwab_status = jsonData.user.chatwab_status;
    const privilage_user_type = jsonData.user.privilage_user_type;
    const uid = jsonData.user.uid;
    const cli_id = jsonData.user.cli_id;
    const cli_name = jsonData.user.cli_name;
    const clients_shared = jsonData.user.clients_shared;
    const products_assigned = jsonData.user.products_assigned
    const sessionStatus = true;
    //Token
    const token = jsonData.token;

    initialUser = {
      user: {
        crm_id,
        user_type,
        acc_rdwr,
        usr_data: {
          usr_name,
          usr_phone,
          wha_phone,
          usr_mail,
          sms_phone,
        },
        calendly,
        status,
        date_add,
        date_modify,
        chatwab_status,
        products_assigned,
        product_shared: [
          {
            name: 'ESTE PRODUCTO NO EXISTE',
            pro_id: '655521118fca012919b3a154',
            status: true,
            date_add: '2023-09-11T15:48:59.287Z',
            _id: '655521398fca012919b3a155',
          },
          {
            name: 'ESTE PRODUCTO NO EXISTE',
            pro_id: '655521118fca012919b3a155',
            status: true,
            date_add: '2023-09-11T15:48:59.287Z',
            _id: '655521398fca012919b3a155',
          },
        ],
        clients_shared,
        privilage_user_type,
        uid,
        cli_id,
        cli_name,
        sessionStatus
      },
      token,
      //FUNCION PARA ACTUALIZAR TOKEN
      updateToken: (newToken) => {
        Object.assign(initialUser, { token: newToken ?? initialUser.token });
        console.log('Token actualizado:', user.token);

        // Actualizar localStorage para que se guarde nuevamente en el UseContext
        localStorage.setItem('miData', JSON.stringify(initialUser));
      },
    };
  } else {
    console.log('No hay nanais')

    initialUser = {
      user: {
        crm_id: 0,
        user_type: '',
        acc_rdwr: false,
        usr_data: {
          usr_name: '',
          usr_phone: 0,
          wha_phone: '',
          usr_mail: '',
          sms_phone: '',
        },
        calendly: '',
        status: true,
        date_add: '',
        date_modify: '',
        chatwab_status: false,
        products_assigned: [],
        product_shared: [],
        clients_shared: [],
        privilage_user_type: 0,
        uid: '',
        cli_id: '',
        cli_name: '',
        sessionStatus: false
      },
      token: '',
      updateToken: (newToken) => {
        setUser((prevUser) => ({
          ...prevUser,
          token: newToken,
        }));
        console.log('Token actualizado:', newToken);
      },
    };
  }

  // Definir el estado del usuario
  const [user, setUser] = useState(initialUser);

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
