// Importamos hooks y aux de React
import {FC, useState, Dispatch, SetStateAction} from 'react'

// ---------------------------------------------------------------------------------------

// Importamos componente y recursos de DateRange
import {DateRangePicker} from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

// Interface para el objeto DateRange
interface DateRange {
  startDate: Date | null
  endDate: Date | null
  key: string
}

// Propiedades que tiene que recibir el componente
type Props = {
  // Valor inicial para fecha de inicio
  initialStartDate: Date
  // Valor inicial para fecha de fin
  initialEndDate: Date
  // Numero de meses a mostrar en el calendario
  months: number
  // Funcion para manejar el cambio de fechas
  handleDates: () => void
  // (**OPCIONAL) Manejador para fecha de inicio en la pantalla
  // Se usa para actualizar algun label o elemento que muestre la fecha
  setLabelStartDate?: Dispatch<SetStateAction<Date>>
  // (**OPCIONAL) Manejador para fecha de fin en la pantalla
  // Se usa para actualizar algun label o elemento que muestre la fecha
  setLabelEndDate?: Dispatch<SetStateAction<Date>>
}

// Montamos componente DateRangePickerFull
const DateRangePickerFull: FC<Props> = ({
  initialStartDate,
  initialEndDate,
  months,
  setLabelStartDate,
  setLabelEndDate,
  handleDates,
}) => {
  // Creamos un manejador para nuestro objeto DateRange
  const [dateRange, setDateRange] = useState<DateRange[]>([
    {
      startDate: initialStartDate,
      endDate: initialEndDate,
      key: 'selection',
    },
  ]) 

  //Constantes que permiten guardar el estado inicial de las fechas. 
  const beforeStartDate = initialStartDate
  const beforeEndDate = initialEndDate

  //Función para resetear las fechas en caso de oprimir botón cancelar
  const handleResetDates = () => {
    // Verificamos si las propiedades opcionales llegaron
    if (setLabelStartDate && setLabelEndDate) {
        setLabelStartDate(beforeStartDate)
        setLabelEndDate(beforeEndDate)
      }
  }

  return (
    <>
      <div className='menu menu-sub menu-sub-dropdown w-920px w-md-920px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div>
            <div className='d-flex flex-column'></div>
            <DateRangePicker
              onChange={(item) => {
                setDateRange([item.selection])

                // Verificamos si las propiedades opcionales llegaron
                if (setLabelStartDate && setLabelEndDate) {
                  setLabelStartDate(item.selection.startDate)
                  setLabelEndDate(item.selection.endDate)
                }
              }}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={months}
              ranges={dateRange}
              direction='horizontal'
            />
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-light btn-active-light-primary me-3'
              data-kt-menu-dismiss='true'
              onClick={handleResetDates}
            >
              Cancelar
            </button>

            <button
              type='submit'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={() => {
                handleDates()
              }}
            >
              Aplicar
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export {DateRangePickerFull}
