// Importamos constantes con rutas de api
import {apiDragon, DragonMainApi, EPLeadsReport} from '../../../../../utils/constants/_Constants'
// Importamos funcion para realizar la peticion
import {apiGetDeletePetitions} from '../../../../../../_metronic/helpers/apis'

// Funcion para realizar la peticion al endpoint
export const leadsReportDataPetition = async (
  userToken: string,
  productId: string,
  startDate: string,
  endDate: string
) => {
  try {
    const data = await apiGetDeletePetitions(
      apiDragon,
      `${DragonMainApi + EPLeadsReport + productId}`,
      {
        dateStart: startDate,
        dateEnd: endDate,
      },
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'get'
    )

    const jsonDataLeadsReport = data
    return jsonDataLeadsReport
  } catch (error) {
    console.error('Hubo un error en la peticion: ', error)
    throw error
  }
}
