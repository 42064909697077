import React from 'react';

const PreCRMTable = () => {
  const fakeData = [
    { name: 'John Doe', producto: 'API DIGITAL', campana: 'Tik-Tok', canal: 'Facebook', medioContacto: 'Teléfono', estatus: 'Sin Contacto' },
    { name: 'Jane Smith', producto: 'E-COMMERCE', campana: 'Instagram', canal: 'Email', medioContacto: 'Chat en Vivo', estatus: 'En Proceso' },
    { name: 'Bob Johnson', producto: 'SERVICIOS', campana: 'YouTube', canal: 'WhatsApp', medioContacto: 'Correo Electrónico', estatus: 'Contactado' },
    { name: 'Alice Williams', producto: 'API DIGITAL', campana: 'Twitter', canal: 'LinkedIn', medioContacto: 'WhatsApp', estatus: 'Contactado' },
    { name: 'Charlie Brown', producto: 'E-COMMERCE', campana: 'Facebook', canal: 'Instagram', medioContacto: 'Teléfono', estatus: 'Sin Contacto' },
    { name: 'Eva Davis', producto: 'SERVICIOS', campana: 'LinkedIn', canal: 'YouTube', medioContacto: 'Chat en Vivo', estatus: 'En Proceso' },
    { name: 'David Miller', producto: 'API DIGITAL', campana: 'WhatsApp', canal: 'Twitter', medioContacto: 'Correo Electrónico', estatus: 'Contactado' },
    { name: 'Grace Wilson', producto: 'E-COMMERCE', campana: 'Email', canal: 'Facebook', medioContacto: 'Teléfono', estatus: 'Sin Contacto' },
    { name: 'Frank Johnson', producto: 'SERVICIOS', campana: 'Instagram', canal: 'LinkedIn', medioContacto: 'Chat en Vivo', estatus: 'En Proceso' },
    { name: 'Helen Brown', producto: 'API DIGITAL', campana: 'YouTube', canal: 'WhatsApp', medioContacto: 'Correo Electrónico', estatus: 'Contactado' }
  ];

  return (
    <div className='card-body py-4'>
      <div className='table-responsive'>
        <table id='kt_table_users' className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th>Name</th>
              <th>Producto</th>
              <th>Campaña</th>
              <th>Canal</th>
              <th>Medio de Contacto</th>
              <th>Estatus</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {fakeData.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.producto}</td>
                <td>{item.campana}</td>
                <td>{item.canal}</td>
                <td>{item.medioContacto}</td>
                <td>{item.estatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='d-flex justify-content-between mt-4'>
        <div className='fs-6 fw-bold text-gray-700'>
          Showing 1 to {fakeData.length} of {fakeData.length} entries
        </div>
        <ul className="pagination">
          <li className="page-item active">
            <button className="page-link" onClick={() => {}}>1</button>
          </li>
          {/* Puedes agregar más botones de paginación aquí */}
        </ul>
      </div>
    </div>
  );
};

export { PreCRMTable };
