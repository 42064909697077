import {useState, ChangeEvent, useEffect, useContext, ReactElement} from 'react'
import '../../../../../_metronic/assets/styles/PhoneInput.css'
import { Step1Errors } from '../../interfaces/rolesTypes'

function Step1Rol({onNext, prevData, setStep1Data}) {
  const [rolName, setRolName] = useState(prevData?.rolName || '')
  const [assignType, setAssignType] = useState(prevData?.assignType || '')
  const [assignForUsers, setAssignForUsers] = useState(prevData?.assignForUsers || '')

  // En el step1 se aamcenarán los datos de Nombr solamente y el input de tipo de rol y si es por vendedores o grupos
  
  const [errors, setErrors] = useState<Step1Errors>({
    rolName: '',
    assignType: '',
    assignForUsers: ''
  })

  const validateInputs = () => {
    const newErrors: Step1Errors = {
      rolName: !rolName ? 'El nombre es obligatorio' : '',
      assignType: !assignType ? 'Selecciona un tipo de Usuario' : '',
      assignForUsers: !assignForUsers ? 'Selecciona un tipo de Rol' : '',
    }

    // Aquí actualizamos el estado errors con los nuevos mensajes de error
    setErrors(newErrors)

    console.log(newErrors)

    return Object.values(newErrors).every((error) => error === '')
  }

  const handleNext = async () => {
    if (validateInputs()) {
      onNext({
        rolName,
        assignType,
        assignForUsers,
      })

      setStep1Data({
        rolName,
        assignType,
        assignForUsers,
      })
    }
  }

  // Array de objetos de tipo CountryCode con los códigos de países que deseas mostrar

  return (
    <div className='mb-5'>
    <input
      type='text'
      name='rolName'
      value={rolName}
      onChange={(e) => setRolName(e.target.value)}
      placeholder='Ingresa un Nombre'
      className='form-control form-control-solid mb-3 mb-lg-0'
    />
    <div className='text-danger mb-6'>{errors.rolName}</div>

    <div className='row'>
      <div className='col-md-6'>
        <div className='mb-5'>
          <label className='fw-bold fs-6 mb-2'></label>
          <select
            name='role'
            className='form-control form-control-lg form-control-solid'
            value={assignType}
            onChange={(e) => setAssignType(e.target.value)}
          >
            <option value=''>Asignación por...</option>
            <option value='Marca'>Marca</option>
            <option value='Producto'>Producto</option>
            <option value='Campaña'>Campaña</option>
          </select>
        </div>
        <div className='text-danger mb-6'>{errors.assignType}</div>
      </div>
      <div className='col-md-6'>
        <div className='mb-5'>
          <label className='fw-bold fs-6 mb-2'></label>
          <select
            name='roleUsers'
            className='form-control form-control-lg form-control-solid'
            value={assignForUsers}
            onChange={(e) => setAssignForUsers(e.target.value)}
          >
            <option value=''>Asignar por...</option>
            <option value='Vendedor'>Vendedor</option>
            <option value='Grupo'>Grupo</option>
          </select>
        </div>
        <div className='text-danger mb-6'>{errors.assignForUsers}</div>
      </div>
    </div>

    <div className='mb-5 justify-content-center'>
      <div className='alert alert-info d-flex align-items-center' role='alert'>
        <p>Al confirmar la configuración las campañas se verán afectadas con este nuevo rol</p>
      </div>
      <div className='text-center'>
        <button className='btn btn-primary' onClick={handleNext}>
          Confirmar
        </button>
      </div>
    </div>
    </div>
  )

  }

export {Step1Rol}
