//Importar función general para hacer peticiones
import {apiGetDeletePetitions} from '../../../_metronic/helpers/apis'
//Importar constantes que utiliza la función general para realizar las peticiones
import {apiDragon, DragonMainApi, EPValidateToken} from '../../utils/constants/_Constants'

export async function validateToken(userToken: string) {
  try {
    const data = await apiGetDeletePetitions(
      apiDragon,
      `${DragonMainApi + EPValidateToken}`,
      {
        /* Los parametros se mandan como undefined 
            ya que estos se envian solo en peticiones de eliminacion 'delete' */
        undefined,
      },
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'get'
    )

    const jsonDataToken = data

    return jsonDataToken
  } catch (error) {
    throw error
  }
}
