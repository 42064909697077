import {DragonMainApi, EPLeadEdit, apiDragon} from '../../../utils/constants/_Constants'
import {apiPetitions} from '../../../../_metronic/helpers/apis'

async function leadEdit(
  userToken: string,
  leadId: string,
  name: string,
  mail: string,
  phone: string,
  LeadUrl: string,
  dcfs: string[],
  comments: string,
  reason: string
) {
    try {
        const data = await apiPetitions(apiDragon, `${DragonMainApi+EPLeadEdit+leadId}`, {
            name,
            phone,
            mail,
            LeadUrl,
            // dcfs,
            comments,
            reason
        },{
            'Content-Type': 'application/json',
            'x-token': userToken
        },
        'put')

    const jsonDataLeadEdit = data;

    return jsonDataLeadEdit;

    }catch (error){
        throw error;
    }
}

export { leadEdit }
