// Importamos hooks de react que usaremos para este componente
import { useState, useEffect, useContext } from 'react';
// Importamos componentes de la libreria de bootstrap
import { Tab, Tabs, Container, Row, Col } from 'react-bootstrap';
// Importamos libreria para manejo de lenguaje 
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from "../../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------

// Importamos el componente LeadTabForm que usaremos en este componente
import { LeadTabForm } from "./tabsEditIscore/LeadTabForm";
// Importamos el componente SellerTabForm que usaremos en este componente
import { SellerTabForm } from "./tabsEditIscore/SellerTabForm";

// ---------------------------------------------------------------------------------------

// Importamos la funcion productIscoreList para conexion con la API de productos
import { productIscoreList } from "../services/_ProductsIscoreListApi";

// ---------------------------------------------------------------------------------------

// Importamos funcion sessionExpired para el vencimiento de sesion
import { sessionExpired } from "../../../utils/functions/_Functions";

// Iniciamos funcion para construir el componente ModalEditIscores y recibimos props
function ModalEditIscores({idProduct, formDataEditLead, setFormDataEditLead, formDataEditSeller, setFormDataEditSeller, getProductsRegisters, closeModalEditIscore}) {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl();

  //Algunas variables desde el usercontext
  const { token, updateToken } = useContext(UserContext);
  const userToken = token;

  //Variables para el manejo de estado de las tabs activas
  const [activeTab, setActiveTab] = useState('leads');
  
  //Funcion para manejo de visibilidad de tabs
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  
  //Funcion para obtener el iscore del producto en cuestion
  const getProductIscoreRegisters = async () => {
       
    try {
      const { data: jsonData, code, msg } = await productIscoreList(userToken, idProduct);
      //  console.log('data: ', jsonData.data);
      
      if ( code === 401 && msg === 'Token no valido' ){
        
        sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
        return;
        
      } else {
        
        const productIscoreReceived = jsonData.data;

        const userTokenNew = await jsonData.token;
        updateToken(userTokenNew);

        setFormDataEditLead({
          ...formDataEditLead,
          leadLlamadaValido: productIscoreReceived.leads.llamada.valido,
          leadLlamadaContestada: productIscoreReceived.leads.llamada.contestada,
          leadLlamadaAceptada: productIscoreReceived.leads.llamada.aceptada,
          leadLlamadaGrabacion: productIscoreReceived.leads.llamada.grabacion,
          leadWhatsInbound: productIscoreReceived.leads.whatsapp.inbound,
          leadWhatsHolabot: productIscoreReceived.leads.whatsapp.holabot,
          leadWhatsUbicacion: productIscoreReceived.leads.whatsapp.ubicacion,
          leadWhatsHablar: productIscoreReceived.leads.whatsapp.hablar,
          leadWhatsWhatsapp: productIscoreReceived.leads.whatsapp.whatsapp,
          leadWhatsWebsite: productIscoreReceived.leads.whatsapp.website,
          leadWhatsVirtual: productIscoreReceived.leads.whatsapp.virtual,
          leadWhatsCita: productIscoreReceived.leads.whatsapp.cita,
          leadWhatsWhattxt: productIscoreReceived.leads.whatsapp.whattxt,
          leadWhatsCalifica: productIscoreReceived.leads.whatsapp.califica,
          leadWhatsBrochure: productIscoreReceived.leads.whatsapp.brochure,
          leadEmailValido: productIscoreReceived.leads.email.valido,
          leadEmailEntregado: productIscoreReceived.leads.email.entregado,
          leadEmailAbierto: productIscoreReceived.leads.email.abierto,
        })

        setFormDataEditSeller({
          ...formDataEditSeller,
          sellerLlamadaGrabacion: productIscoreReceived.vendedores.llamada.grabacion,
          sellerWhatsappHablar: productIscoreReceived.vendedores.whatsapp.hablar,
          sellerWhatsappWhatsapp: productIscoreReceived.vendedores.whatsapp.whatsapp,
          sellerWhatsappHolabot: productIscoreReceived.vendedores.whatsapp.holabot,
          sellerWhatsappCalifica: productIscoreReceived.vendedores.whatsapp.califica,
          sellerWhatsappEditar: productIscoreReceived.vendedores.whatsapp.editar,
          sellerWhatsappComentarios: productIscoreReceived.vendedores.whatsapp.comentarios,
          sellerWhatsappWhatxt: productIscoreReceived.vendedores.whatsapp.whattxt,
          sellerWhatsappIscore: productIscoreReceived.vendedores.whatsapp.iscore,
          sellerEmailAbierto: productIscoreReceived.vendedores.email.mail_abierto,
        })

      }

    } catch (error) {
      // console.log('error obteniendo iscore');
      // throw(error);
    }

  }
   
  /*UseEfect para ejecutar la funcion de listado de iscore en cuanto 
  renderice el componente*/    
  useEffect(() => {
    getProductIscoreRegisters();
  }, [])

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <Tabs activeKey={activeTab} onSelect={handleTabChange}>
            <Tab eventKey="leads" title={intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.LABEL_TABLEAD'})}>
              <LeadTabForm
                idProduct={idProduct}
                formDataEditLead={formDataEditLead}
                setFormDataEditLead={setFormDataEditLead}
                getProductsRegisters={getProductsRegisters}
                closeModalEditIscore={closeModalEditIscore}
              />
            </Tab>
            <Tab eventKey="vendedores" title={intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.LABEL_TABSELLER'})}>
              <SellerTabForm 
                idProduct={idProduct}
                formDataEditSeller={formDataEditSeller}
                setFormDataEditSeller={setFormDataEditSeller}
                getProductsRegisters={getProductsRegisters}
                closeModalEditIscore={closeModalEditIscore}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}

export {ModalEditIscores};