import NavItem from './NavItem'
import TabPane from './TabPane'
import AccordionHeader from './AccordionHeader'
import {useIntl} from 'react-intl'

export const SupportInfoComponent = () => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  //Títulos de los NavItems
  const guide = intl.formatMessage({id: 'SUPPORT.NAV.TITLE.GUIDE'})
  const newConnection = intl.formatMessage({id: 'SUPPORT.NAV.TITLE.NEW_CONNECTION'})
  const connectionError = intl.formatMessage({id: 'SUPPORT.NAV.TITLE.CONNECTION_ERROR'})
  const connectionUpdate = intl.formatMessage({id: 'SUPPORT.NAV.TITLE.CONNECTION_UPDATE'})
  const dcSupport = intl.formatMessage({id: 'SUPPORT.NAV.TITLE.DC_SUPPORT'})
  const webAPI = intl.formatMessage({id: 'SUPPORT.NAV.TITLE.WAB_API'})
  const customBot = intl.formatMessage({id: 'SUPPORT.NAV.TITLE.CUSTOM_BOT'})
  const manualUpload = intl.formatMessage({id: 'SUPPORT.NAV.TITLE.MANUAL_UPLOAD'})
  const newRequest = intl.formatMessage({id: 'SUPPORT.NAV.TITLE.NEW_REQUEST'})

  //Títulos de cada item de un AccordionHeader
  // Guía
  const guideLead = intl.formatMessage({id: 'SUPPORT.ACCORDION.TITLE.GUIDE.LEAD'})
  const guideContactReasons = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.GUIDE.CONTACT_REASONS',
  })

  // Nueva conexión
  const newConnectionSocialNetwork = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.NEW_CONNECTION.SOCIAL_NETWORK',
  })
  const newConnectionWebSites = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.NEW_CONNECTION.WEB_SITES',
  })
  const newConnectionRealEstatePortals = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.NEW_CONNECTION.REALSTATE_PORTALS',
  })
  const newConnectionCrmIntegration = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.NEW_CONNECTION.CRM_INTEGRATION',
  })

  // Error conexión
  const connectionErrorSocialNetwork = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.CONNECTION_ERROR.SOCIAL_NETWORK',
  })
  const connectionErrorWebSites = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.CONNECTION_ERROR.WEB_SITES',
  })
  const connectionErrorRealEstatePortals = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.CONNECTION_ERROR.REALSTATE_PORTALS',
  })
  const connectionErrorCrmIntegration = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.CONNECTION_ERROR.CRM_INTEGRATION',
  })

  // Conexión update
  const connectionUpdateSocialNetwork = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.CONNECTION_UPDATE.SOCIAL_NETWORK',
  })
  const connectionUpdateWebSites = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.CONNECTION_UPDATE.WEB_SITES',
  })
  const connectionUpdateRealEstatePortals = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.CONNECTION_UPDATE.REALSTATE_PORTALS',
  })
  const connectionUpdateCrmIntegration = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.CONNECTION_UPDATE.CRM_INTEGRATION',
  })

  // Soporte DC
  const dcDcSupport = intl.formatMessage({id: 'SUPPORT.ACCORDION.TITLE.DC_SUPPORT.DC_SUPPORT'})

  // WAB API
  const wabApiNewProcedure = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.WAB_API.NEW_PROCEDURE',
  })
  const wabApiStatus = intl.formatMessage({id: 'SUPPORT.ACCORDION.TITLE.WAB_API.STATUS'})
  const wabApiWabTemplate = intl.formatMessage({id: 'SUPPORT.ACCORDION.TITLE.WAB_API.WAB_TEMPLATE'})

  // Bot Personalizado
  const customBotNew = intl.formatMessage({id: 'SUPPORT.ACCORDION.TITLE.CUSTOM_BOT.NEW'})
  const customBotUpdate = intl.formatMessage({id: 'SUPPORT.ACCORDION.TITLE.CUSTOM_BOT.UPDATE'})
  const customBotError = intl.formatMessage({id: 'SUPPORT.ACCORDION.TITLE.CUSTOM_BOT.ERROR'})

  // Carga manual
  const manualUploadLeadsUpload = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.MANUAL_UPLOAD.LEADS_UPLOAD',
  })
  const manualUploadRemarketing = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.MANUAL_UPLOAD.REMARKETING',
  })

  // Solicitudes nuevas
  const newRequestImprovePlatform = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.NEW_REQUEST.IMPROVE_PLATFORM',
  })
  const newRequestBot = intl.formatMessage({id: 'SUPPORT.ACCORDION.TITLE.NEW_REQUEST.BOT'})
  const newRequestLandingPage = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.TITLE.NEW_REQUEST.LANDING_PAGE',
  })

  //En vez de que el content, sea por las llaves, como este tiene y depende de mucho contenido HTML, este sería los únicos textos que se definirían como unas variables, ya sea directamente en es módulo
  //o en un apartado de utils o comodín o algo, para que sea posible agregarle etiquetas y estilos html

  //Contenidos de cada item de un AccordionHeader
  // Guía
  const guideLeadContent = intl.formatMessage({id: 'SUPPORT.ACCORDION.CONTENT.GUIDE.LEAD'})
  const guideContactReasonsContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.GUIDE.CONTACT_REASONS',
  })

  // Nueva conexión
  const newConnectionSocialNetworkContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.NEW_CONNECTION.SOCIAL_NETWORK',
  })
  const newConnectionWebSitesContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.NEW_CONNECTION.WEB_SITES',
  })
  const newConnectionRealEstatePortalsContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.NEW_CONNECTION.REALSTATE_PORTALS',
  })
  const newConnectionCrmIntegrationContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.NEW_CONNECTION.CRM_INTEGRATION',
  })

  // Error conexión
  const connectionErrorSocialNetworkContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.CONNECTION_ERROR.SOCIAL_NETWORK',
  })
  const connectionErrorWebSitesContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.CONNECTION_ERROR.WEB_SITES',
  })
  const connectionErrorRealEstatePortalsContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.CONNECTION_ERROR.REALSTATE_PORTALS',
  })
  const connectionErrorCrmIntegrationContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.CONNECTION_ERROR.CRM_INTEGRATION',
  })

  // Conexión update
  const connectionUpdateSocialNetworkContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.CONNECTION_UPDATE.SOCIAL_NETWORK',
  })
  const connectionUpdateWebSitesContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.CONNECTION_UPDATE.WEB_SITES',
  })
  const connectionUpdateRealEstatePortalsContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.CONNECTION_UPDATE.REALSTATE_PORTALS',
  })
  const connectionUpdateCrmIntegrationContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.CONNECTION_UPDATE.CRM_INTEGRATION',
  })

  // Soporte DC
  const dcDCSupportContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.DC_SUPPORT.DC_SUPPORT',
  })
  
  const dcDCSupportContentHTML = (
    <div>
        {dcDCSupportContent}
        <ul>
            <li>{intl.formatMessage({id: 'SUPPORT.ACCORDION.CONTENT.DC_SUPPORT.DC_SUPPORT.LI1'})}</li>
            <li>{intl.formatMessage({id: 'SUPPORT.ACCORDION.CONTENT.DC_SUPPORT.DC_SUPPORT.LI2'})}</li>
            <li>{intl.formatMessage({id: 'SUPPORT.ACCORDION.CONTENT.DC_SUPPORT.DC_SUPPORT.LI3'})}</li>
            <li>{intl.formatMessage({id: 'SUPPORT.ACCORDION.CONTENT.DC_SUPPORT.DC_SUPPORT.LI4'})}</li>
            <li>{intl.formatMessage({id: 'SUPPORT.ACCORDION.CONTENT.DC_SUPPORT.DC_SUPPORT.LI5'})}</li>
            <li>{intl.formatMessage({id: 'SUPPORT.ACCORDION.CONTENT.DC_SUPPORT.DC_SUPPORT.LI6'})}</li>
            <li>{intl.formatMessage({id: 'SUPPORT.ACCORDION.CONTENT.DC_SUPPORT.DC_SUPPORT.LI7'})}</li>
            <li>{intl.formatMessage({id: 'SUPPORT.ACCORDION.CONTENT.DC_SUPPORT.DC_SUPPORT.LI8'})}</li>

        </ul>       
    </div>
  )

  // WAB API
  const wabApiNewProcedureContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.WAB_API.NEW_PROCEDURE',
  })
  const wabApiStatusContent = intl.formatMessage({id: 'SUPPORT.ACCORDION.CONTENT.WAB_API.STATUS'})
  const wabApiWabTemplateContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.WAB_API.WAB_TEMPLATE',
  })

  // Bot Personalizado
  const customBotNewContent = intl.formatMessage({id: 'SUPPORT.ACCORDION.CONTENT.CUSTOM_BOT.NEW'})
  const customBotUpdateContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.CUSTOM_BOT.UPDATE',
  })
  const customBotErrorContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.CUSTOM_BOT.ERROR',
  })

  // Carga manual
  const manualUploadLeadsUploadContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.MANUAL_UPLOAD.LEADS_UPLOAD',
  })
  const manualUploadRemarketingContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.MANUAL_UPLOAD.REMARKETING',
  })

  // Solicitudes nuevas
  const newRequestImprovePlatformContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.NEW_REQUEST.IMPROVE_PLATFORM',
  })
  const newRequestBotContent = intl.formatMessage({id: 'SUPPORT.ACCORDION.CONTENT.NEW_REQUEST.BOT'})
  const newRequestLandingPageContent = intl.formatMessage({
    id: 'SUPPORT.ACCORDION.CONTENT.NEW_REQUEST.LANDING_PAGE',
  })

  return (
    <>
      <div className='row'>
        <div className='col-lg-3'>
          <ul className='nav nav-tabs nav-pills border-0 flex-row flex-md-column me-5 mb-3 mb-md-0 fs-6 '>
            <NavItem title={guide} refId={1} isActive={true} />
            <NavItem title={newConnection} refId={2} isActive={false} />
            <NavItem title={connectionError} refId={3} isActive={false} />
            <NavItem title={connectionUpdate} refId={4} isActive={false} />
            <NavItem title={dcSupport} refId={5} isActive={false} />
            <NavItem title={webAPI} refId={6} isActive={false} />
            <NavItem title={customBot} refId={7} isActive={false} />
            <NavItem title={manualUpload} refId={8} isActive={false} />
            <NavItem title={newRequest} refId={9} isActive={false} />
          </ul>
        </div>
        <div className='col-lg-9'>
          <div className='tab-content' id='myTabContent'>
            {/* Guía */}
            <TabPane refId={1} isActive={true}>
              <AccordionHeader itemId={1} title={guideLead} content={guideLeadContent} />
              <AccordionHeader
                itemId={2}
                title={guideContactReasons}
                content={guideContactReasonsContent}
              />
            </TabPane>

            {/* Nueva conexión */}
            <TabPane refId={2} isActive={false}>
              <AccordionHeader
                itemId={1}
                title={newConnectionSocialNetwork}
                content={newConnectionSocialNetworkContent}
              />
              <AccordionHeader
                itemId={2}
                title={newConnectionWebSites}
                content={newConnectionWebSitesContent}
              />
              <AccordionHeader
                itemId={3}
                title={newConnectionRealEstatePortals}
                content={newConnectionRealEstatePortalsContent}
              />
              <AccordionHeader
                itemId={4}
                title={newConnectionCrmIntegration}
                content={newConnectionCrmIntegrationContent}
              />
            </TabPane>

            {/* Error conexión */}
            <TabPane refId={3} isActive={false}>
              <AccordionHeader
                itemId={1}
                title={connectionErrorSocialNetwork}
                content={connectionErrorSocialNetworkContent}
              />
              <AccordionHeader
                itemId={2}
                title={connectionErrorWebSites}
                content={connectionErrorWebSitesContent}
              />
              <AccordionHeader
                itemId={3}
                title={connectionErrorRealEstatePortals}
                content={connectionErrorRealEstatePortalsContent}
              />
              <AccordionHeader
                itemId={4}
                title={connectionErrorCrmIntegration}
                content={connectionErrorCrmIntegrationContent}
              />
            </TabPane>

            {/* Conexión update */}
            <TabPane refId={4} isActive={false}>
              <AccordionHeader
                itemId={1}
                title={connectionUpdateSocialNetwork}
                content={connectionUpdateSocialNetworkContent}
              />
              <AccordionHeader
                itemId={2}
                title={connectionUpdateWebSites}
                content={connectionUpdateWebSitesContent}
              />
              <AccordionHeader
                itemId={3}
                title={connectionUpdateRealEstatePortals}
                content={connectionUpdateRealEstatePortalsContent}
              />
              <AccordionHeader
                itemId={4}
                title={connectionUpdateCrmIntegration}
                content={connectionUpdateCrmIntegrationContent}
              />
            </TabPane>

            {/* Soporte DC */}
            <TabPane refId={5} isActive={false}>
              <AccordionHeader itemId={1} title={dcDcSupport} content={dcDCSupportContentHTML} />
            </TabPane>

            {/* WAB API */}
            <TabPane refId={6} isActive={false}>
              <AccordionHeader
                itemId={1}
                title={wabApiNewProcedure}
                content={wabApiNewProcedureContent}
              />
              <AccordionHeader itemId={2} title={wabApiStatus} content={wabApiStatusContent} />
              <AccordionHeader
                itemId={3}
                title={wabApiWabTemplate}
                content={wabApiWabTemplateContent}
              />
            </TabPane>

            {/* Bot Personalizado */}
            <TabPane refId={7} isActive={false}>
              <AccordionHeader itemId={1} title={customBotNew} content={customBotNewContent} />
              <AccordionHeader
                itemId={2}
                title={customBotUpdate}
                content={customBotUpdateContent}
              />
              <AccordionHeader itemId={3} title={customBotError} content={customBotErrorContent} />
            </TabPane>

            {/* Carga manual */}
            <TabPane refId={8} isActive={false}>
              <AccordionHeader
                itemId={1}
                title={manualUploadLeadsUpload}
                content={manualUploadLeadsUploadContent}
              />
              <AccordionHeader
                itemId={2}
                title={manualUploadRemarketing}
                content={manualUploadRemarketingContent}
              />
            </TabPane>

            {/* Solicitudes nuevas */}
            <TabPane refId={9} isActive={false}>
              <AccordionHeader
                itemId={1}
                title={newRequestImprovePlatform}
                content={newRequestImprovePlatformContent}
              />
              <AccordionHeader itemId={2} title={newRequestBot} content={newRequestBotContent} />
              <AccordionHeader
                itemId={3}
                title={newRequestLandingPage}
                content={newRequestLandingPageContent}
              />
            </TabPane>
          </div>
        </div>
      </div>
    </>
  )
}
