import axios from 'axios';
import { DragonMainApi, EPClientNew, apiDragon } from '../../../utils/constants/_Constants';
import { apiPetitions } from '../../../../_metronic/helpers/apis';

async function ClientsNew(
    clientName: string, 
    clientRfc: string, 
    clientPhone: string, 
    employees: number, 
    industry: string, 
    cliClass: string,
    subIndustry: string,
    cliId: string,
    name: string,
    phone: string,
    whatsapp: string,
    mail: string,
    password: string,
    validPassword: string,
    nameBrand: string,
    proName: string,
    proPrice: number,
    proType: string,
    proCountry: string,
    proState: string,
    proCity: string,
    proZone: string,
    proMails: [],
    userToken: string
    
    ){
        try{
            const response = await apiPetitions (apiDragon,DragonMainApi+EPClientNew, {
                clientName, 
                clientRfc,
                clientPhone, 
                employees, 
                industry, 
                cliClass,
                subIndustry,
                cliId,
                name,
                phone,
                whatsapp,
                mail,
                password,
                validPassword,
                nameBrand,
                proName,
                proPrice,
                proType,
                proCountry,
                proState,
                proCity,
                proZone,
                proMails,
                automaticsCampaigns: ["leadGenFace", "leadGenInsta", "landingGoogle", "visitaWalk", "refRef"]

            }, {
                
                    'Content-Type': 'application/json',
                    'x-token': userToken
            },
            'post');

            const jsonDataClientsNew = response;

            // console.log(jsonDataClientsNew)

            return jsonDataClientsNew;


        } catch(error) {
            throw error;
            // console.log('Error de nuevo Cliente ', error)

        }
    }

    export { ClientsNew };