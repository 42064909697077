// Importamos constantes para realizar la peticion a la APi de productos
import { DragonMainApi, EPProductEditInfo, EPProductEditMedia, apiDragon } from '../../../utils/constants/_Constants';

// ---------------------------------------------------------------------------------------

// Importamos funcion para obtener codigos de respuesta del servidor
import { apiPetitions } from '../../../../_metronic/helpers/apis';

// Funcion para realizar la peticion a la API de edicion de producto en la tab de informacion
export async function productsEditInfo( 
    edition: boolean,
    pro_legals: string,
    crm_id: string,
    status: boolean,
    pro_price: number,
    pro_country: string,
    pro_state: string,
    pro_city: string,
    pro_zone: string,
    pro_gmaps: string,
    pro_waze: string,
    pro_web: string,
    pro_virtual: string,
    pro_intro: string,
    pro_mails: [],
    roleId: string,
    idProduct: string,
    userToken: string
){

    try{
        const response = await apiPetitions(apiDragon,`${DragonMainApi+EPProductEditInfo+idProduct}`, {
            edition,
            proLegals: pro_legals,
            crmId: crm_id,
            status: status,
            proPrice: pro_price,
            proCountry: pro_country,
            proState: pro_state,
            proCity: pro_city,
            proZone: pro_zone,
            proGmaps: pro_gmaps,
            proWaze: pro_waze,
            proWeb: pro_web,
            proVirtual: pro_virtual,
            proIntro: pro_intro,
            proMails: pro_mails,
            roleId,

        }, {
        
            'Content-Type': 'application/json',
            'x-token': userToken
        
        },'put');

        const jsonDataProductsInfoEdit = response;

        // console.log('producto editado con exito')
        // console.log('respuesta api: ', jsonDataProductsEdit)
        return jsonDataProductsInfoEdit;

    }catch(error){
        throw error;
        // console.log('Error en la edicion del producto', error)

    }
}

// Funcion para realizar la peticion a la API de edicion de producto en la tab de media
export async function productsEditMedia(
    idProduct: string, 
    formData: FormData, 
    userToken: string
){

    try{
        const response = await apiPetitions(apiDragon,`${DragonMainApi+EPProductEditMedia+idProduct}`, formData, {
            'Content-Type': 'multipart/form-data',
            'x-token': userToken
        },
        'put');

        const jsonDataProductsMediaEdit = response;

        return jsonDataProductsMediaEdit;

    } catch (error) {
        throw error;
    }
}