import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardNew} from '../standard-modules/dashboard/DashboardNew'
// import {MenuTestPage} from '../standard-modules/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import { FaqPage } from '../standard-modules/support/Faq'
import { Leads } from '../standard-modules/leads/Leads'
import {AccountManagement} from "../standard-modules/account-management/AccountManagement"
import SellersPage from '../standard-modules/control-sellers/sellers/Sellers'
import {Products} from "../standard-modules/products/Products"
import {Brands} from '../standard-modules/brands/Brands'
import {Campaigns} from '../standard-modules/campaigns/Campaigns'
import {DetailsPage} from '../standard-modules/details/DetailModal'
import { Assigns } from '../standard-modules/assigns/Assigns'
import {Logs} from '../standard-modules/logs/Logs'
import {ReportsPage} from '../standard-modules/reports/Reports'
import { LeadDetail } from '../standard-modules/leads/lead-detail/LeadDetail'
import { LeadSpam } from '../standard-modules/leads/lead-spam/LeadSpam'
import { ShareAccess } from '../standard-modules/share-access/ShareAccess'
import { LinksDcem } from '../standard-modules/linksdcem/LinksDcem'
import { ChatWab } from '../plus-modules/chat-wab/ChatWab'
import { Login } from '../auth/aux-components/Login'
import { Dates } from '../plus-modules/dates/Dates'
import { Bi } from '../plus-modules/bi/Bi'
import { PreCRM } from '../plus-modules/crm/PreCRM'
import SellersGroupPage from '../standard-modules/control-sellers/sellers-groups/SellersGroups'
import { Support } from '../standard-modules/help/support/Support'
import { Faq } from '../standard-modules/help/faq/Faq'
import { Manual } from '../standard-modules/help/manual/Manual'

// Paginas de reportes
import { LeadsReport } from '../standard-modules/reports/each-report/leads/LeadsReport'
import { GradesReport } from '../standard-modules/reports/each-report/grades/GradesReport'
import { CrmReport } from '../standard-modules/reports/each-report/crm/CrmReport'
import { QualityReport } from '../standard-modules/reports/each-report/quality/QualityReport'
import { CampaignsReport } from '../standard-modules/reports/each-report/campaigns/CampaignsReport'
import { InteractionsReport } from '../standard-modules/reports/each-report/interactions/InteractionsReport'
import { IscoreReport } from '../standard-modules/reports/each-report/iscore/IscoreReport'
import { YearlyReport } from '../standard-modules/reports/each-report/yearly/YearlyReport'
// Servicios de Twlio
import { TwlioServices } from '../standard-modules/twlio-services/twlio-services/TwlioServices'
import { TwlioTemplates } from '../standard-modules/twlio-services/twlio-templates/TwlioTemplates'
import { TwlioTemplatesAddForm } from '../standard-modules/twlio-services/twlio-templates/AddTemplatesForm'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/leads' />} />
        {/* Pages */}
        <Route path='sellers' element={<SellersPage />} />
        <Route path='group-sellers' element={<SellersGroupPage/>} />
        <Route path='support' element={<Support/>} />
        <Route path='faq' element={<Faq/>} />
        <Route path='manual' element={<Manual/>} />
        {/* <Route path='/assigns' element={<Assigns />} /> */}
        <Route path='/assigns' element={<Assigns />} />
        <Route path='/logs' element={<Logs />} />
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route path='/products' element={<Products />} />
        <Route path='/campaigns' element={<Campaigns /> } />
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route path='/brands' element={<Brands />} />
        <Route path='dashboard' element={<DashboardNew />} />

        <Route path='/leads' element={<Leads />} />
        <Route path='/account-management' element={<AccountManagement />} />
        {/* <Route path='/lead-detail/:leadId' element={<LeadDetail />} /> */}
        <Route path='/lead-spam' element={<LeadSpam />} />
        <Route path='/lead-detail' element={<LeadDetail />} />
        <Route path='/lead-spam' element={<LeadSpam />} />
        <Route path='/linksdcem' element={<LinksDcem/>} />
        {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
        <Route path='/share-access' element={<ShareAccess />} />
        <Route path='/chat-wab' element={<ChatWab />} />
        <Route path='/bi' element={<Bi />} />
        <Route path='/login' element={<Login />} />
        <Route path='/dates' element={<Dates />} />
        <Route path='/pre-CRM' element={<PreCRM />} />
        {/* Paginas de reportes */}
        <Route path='/report-leads' element={<LeadsReport />} />
        <Route path='/grades-report' element={<GradesReport />} />
        <Route path='/quality-report' element={<QualityReport />} />
        <Route path='/yearly-report' element={<YearlyReport />} />
        <Route path='/crm-report' element={<CrmReport />} />
        <Route path='/campaigns-report' element={<CampaignsReport/>} />
        <Route path='/interactions-report' element={<InteractionsReport />} />
        <Route path='/iscore-report' element={<IscoreReport />} />
        {/* Twilio */}
        <Route path='/twlio-services/twlio-services' element={<TwlioServices />} />
        <Route path='/twlio-services/twlio-templates' element={<TwlioTemplates />} />
        <Route path='/twlio-services/twlio-templates/AddTemplatesForm' element={<TwlioTemplatesAddForm />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='details/sellers/*'
          element={
            <SuspensedView>
              <DetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/*'
          element={
            <SuspensedView>
              <ReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path='Support/*'
          element={
            <SuspensedView>
              <Support />
            </SuspensedView>
          }
        />
        <Route
          path='/chat-wab/*'
          element={
            <SuspensedView>
              <ChatWab />
            </SuspensedView>
          }
        />
        <Route
          path='/share-access/*'
          element={
            <SuspensedView>
              <ShareAccess />
            </SuspensedView>
          }
        />
        <Route
          path='/dates/*'
          element={
            <SuspensedView>
              <Dates />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
