import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../../../_metronic/partials'
import {getCSS} from '../../../../../../_metronic/assets/ts/_utils'
import {ReportApiWhatsapp, ReportInteractionsChart} from '../interfaces/interactionsReportsTypes'
import {Modal} from 'react-bootstrap'
import {InteractionDetailReportWATable} from '../tables/InteractionDetailReportWATable'

type PropsChartWAReport = {
  arrInteracciones: Array<any>
  rowsInTable: ReportApiWhatsapp[]
}

const InteractionWAReportChart: React.FC<PropsChartWAReport> = ({
  arrInteracciones,
  rowsInTable,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const initialInteractionWAReportData: ReportInteractionsChart = {
    series: [],
    labels: [],
  }
  // Constante para manejar los datos recibidos para graficar
  const [dataInteraction, setDataInteraction] = useState<ReportInteractionsChart>(
    initialInteractionWAReportData
  )
  //Constante para actualizar el dato clickeado en la gráfica para usar en la tabla del modal
  const [dataClicked, setDataClicked] = useState<ReportApiWhatsapp[]>([])
  //Manejar el estado del modal
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, dataInteraction])

  // Hook para renderizar los nuevos datos de la grafica
  useEffect(() => {
    setDataInteraction({
      ...dataInteraction,
      labels: arrInteracciones.map((item) => item.friendlyName),
      series: arrInteracciones.map((item) => item.total),
    })
  }, [arrInteracciones])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const c_type = 'donut'
    const serieOptions = dataInteraction.labels
    const serieCounts = dataInteraction.series

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, c_type, serieOptions, serieCounts)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }
  function getChartOptions(
    height: number,
    c_type: any,
    serieOptions: any,
    serieCounts: any
  ): ApexOptions {
    return {
      series: serieCounts,
      chart: {
        height: 450,
        type: c_type,
        fontFamily: 'inherit',
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
          export: {
            csv: {
              filename: 'reporte-whatsapp',
            },
            svg: {
              filename: 'reporte-whatsapp',
            },
            png: {
              filename: 'reporte-whatsapp',
            },
          },
        },
        events: {
          dataPointSelection: (event, chartContext, opts) => {
            const dataPointIndex = opts.dataPointIndex
            const category = opts.w.globals.labels[dataPointIndex]
            handleClickDataPoint(category)
          },
        },
      },
      labels: serieOptions,
      legend: {
        show: true,
        fontFamily: 'inherit',
        fontSize: '13px',
        position: 'bottom',
        horizontalAlign: 'center',
      },
      stroke: {
        show: false,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total',
                fontSize: '16px',
                fontWeight: 'bold',
                color: 'black',
              },
            },
          },
        },
      },
    }
  }
  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  //Funcion para comparar el click de la grafica con el arreglo de objetos en la tabla
  const handleClickDataPoint = (category: string) => {
    //Buscar la categoria en el rowsInTable
    let findCat = rowsInTable.find((row) => row.friendlyName === category)
    if (findCat) {
      //setDataClicked(
      setDataClicked([findCat])
      console.log([findCat])
      openModal()
    }
  }

  return (
    <>
      {dataInteraction.labels.length > 0 && (
        <>
          <div className='card-xxl-stretch mb-5 mb-xl-10'>
            <div className=''>
              <div ref={chartRef} id='kt_charts_widget_2_chart' style={{height: '350px'}} />
            </div>
          </div>
          {/* Modal */}
          <Modal show={showModal} onHide={closeModal} className='modal-xl' scrollable>
            <Modal.Header
              closeButton
              onClick={() => {
                closeModal()
              }}
            >
              <Modal.Title>
                <div className='container-fluid'>
                  <div className='row justify-content-center mb-2'>
                    <div className='col-md-12'>
                      <div className='d-flex flex-wrap flex-column mb-6'>
                        <h1 className='my-2'>Detalle de interacción por WhastApp</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>{/* <QaReportGeneralTable cardSelected={cardSelected} /> */}</>
              <InteractionDetailReportWATable detailReportWA={dataClicked} />
            </Modal.Body>
            <Modal.Footer>
              <button
                type='reset'
                className='btn btn-sm btn-secondary'
                onClick={() => {
                  closeModal()
                }}
              >
                Cerrar
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  )
}

export default InteractionWAReportChart
