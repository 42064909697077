import { FC, useState, useRef, useEffect } from "react"
import { KTIcon } from "../../../../../_metronic/helpers"
import * as XLSX from 'xlsx'
import { ExportRegistersDropdown } from "../ExportRegistersDropdown"
import { Lead } from "../../../../standard-modules/leads/interfaces/leadsTypes"
import { DropdownExportStyles } from "../../../constants/_Constants"

type Props = {
    dataToExport: Lead[]
    title: string
    handleSetData: () => void
    disabled: boolean
    size: string
}

const LeadsRegistersExport: FC<Props> = ({
    dataToExport,
    title,
    handleSetData,
    disabled,
    size
}) => {

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [dropUp, setDropUp] = useState<boolean>(false)
    const [alignRight, setAlignRight] = useState<boolean>(false)
    const menuRef = useRef<HTMLDivElement | null>(null)
    const buttonRef = useRef<HTMLButtonElement | null>(null)

    // Definir el mapeo de cabeceras personalizadas
    const headerMap = {
        "Id del lead": "lead_id",
        "Fecha de Lead": "lead_date",
        "Nombre del lead": "lead_data.name",
        "Correo de Lead": "lead_data.lead_mail",
        "Teléfono de Lead": "lead_data.lead_phone",
        "Producto": "pro_name",
        "Campaña": "cam_name",
        "Medio": "medium",
        "Calificación": "ledGrade",
        "Comentarios calificación": "leadGradeComments",
        "Lead original": "original",
        "Iscore del lead": "leadIscore",
        "Vendedor": "seller_name",
        "Iscore del vendedor": "seller_score"
    };

    //Funcion para mostrar/ocultar menu
    const toggleMenu = () => {
        if (!isOpen && menuRef.current && buttonRef.current) {
            const rect = menuRef.current.getBoundingClientRect()
            const buttonRect = buttonRef.current.getBoundingClientRect()

            // Calcular si hay espacio suficiente abajo, de lo contrario abre hacia arriba
            const isOverflowing = rect.height + buttonRect.bottom > window.innerHeight
            setDropUp(isOverflowing)
        }
        setIsOpen(!isOpen)
        handleSetData()
    }

    //Funcion para cerrar menu al hacer click fuera del menu
    const handleClickOutside = (event: MouseEvent) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target as Node) &&
            buttonRef.current &&
            !buttonRef.current.contains(event.target as Node)
        ) {
            setIsOpen(false)
        }
    }

    //Hook para añadir evento click al document
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [])

    //Hook para detectar cambios en el estado del botón, y mostrar menú
    useEffect(() => {
        if (isOpen && menuRef.current && buttonRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect()
            const menuHeight = menuRef.current.offsetHeight
            const menuWidth = menuRef.current.offsetWidth

            // Verificar si hay espacio debajo del botón para el menú
            const hasSpaceBelow = buttonRect.bottom + menuHeight <= window.innerHeight
            const hasSpaceAbove = buttonRect.top - menuHeight >= 0

            // Verificar si hay espacio a la derecha del botón para el menú
            const hasSpaceRight = buttonRect.left + menuWidth <= window.innerWidth
            const hasSpaceLeft = buttonRect.right - menuWidth >= 0

            // Decidir si el menú se despliega hacia arriba o hacia abajo
            if (hasSpaceBelow) {
                setDropUp(false)
            } else if (hasSpaceAbove) {
                setDropUp(true)
            } else {
                setDropUp(window.innerHeight - buttonRect.bottom < buttonRect.top)
            }

            // Decidir si el menú se despliega hacia la derecha o hacia la izquierda
            if (hasSpaceRight) {
                setAlignRight(false)
            } else if (hasSpaceLeft) {
                setAlignRight(true)
            } else {
                setAlignRight(window.innerWidth - buttonRect.right < buttonRect.left)
            }
        }
    }, [isOpen])

    type DataRow = Record<string, any>;

    // Función para obtener el valor de una propiedad anidada usando una cadena de ruta
    const getNestedValue = (obj: any, path: string): any => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    const convertToCSV = (data: DataRow[], headerMap: Record<string, string>): string => {
        // Obtener los nombres de las cabeceras personalizadas
        const headers = Object.keys(headerMap);

        // Crear filas CSV basadas en el mapeo de cabeceras personalizadas
        const rows = data.map((row) => {
            return headers
                .map((header) => {
                    const keyPath = headerMap[header]; // Obtener la clave correspondiente al nombre de cabecera personalizada
                    let value = getNestedValue(row, keyPath); // Obtener el valor anidado usando la ruta

                    // Verificar si el valor es un objeto o un array
                    if (typeof value === 'object' && value !== null) {
                        value = JSON.stringify(value);
                    }

                    // Formatear los números de teléfono como texto
                    if (typeof value === 'string' && (value.startsWith('+') || /^[0-9]+$/.test(value))) {
                        value = `"${value}"`; // Asegura que se trate como texto en Excel
                    }

                    // Escapar comillas dobles y agregar comillas dobles si es necesario
                    if (typeof value === 'string') {
                        value = value.replace(/"/g, '""'); // Doblar las comillas para escapar
                        if (value.includes(',') || value.includes('"') || value.includes('\n')) {
                            value = `"${value}"`;
                        }
                    }

                    return value !== undefined ? value : ''; // Manejar valores undefined
                })
                .join(',');
        });

        // Unir las cabeceras personalizadas y las filas
        const csvContent = [headers.join(','), ...rows].join('\n');

        // Añadir el BOM para UTF-8
        const BOM = '\uFEFF';
        return BOM + csvContent;
    };

    const handleToExportDataCSV = () => {
        try {
            // Verificar si hay datos para exportar
            if (dataToExport.length === 0) {
                console.error('No hay datos para exportar.')
                return
            }

            // Convertir los datos a formato CSV
            let csvData
            if (title === 'sellersGroupData' && dataToExport.some((row) => 'users' in row)) {
                // csvData = convertToCSVWithUsers(dataToExport)
            } else if (title === 'SellersData' && dataToExport.some((row) => 'brandProducts' in row)) {
                // csvData = convertToCSVWithBrandProducts(dataToExport)
            } else {
                csvData = convertToCSV(dataToExport, headerMap)
            }

            // Crear un Blob a partir de los datos CSV
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' })

            // Crear un objeto URL a partir del Blob
            const url = window.URL.createObjectURL(blob)

            // Crear un enlace de descarga
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${title}.csv`)
            document.body.appendChild(link)

            // Simular un clic en el enlace para iniciar la descarga
            link.click()

            // Limpiar el objeto URL y el enlace
            window.URL.revokeObjectURL(url)
            document.body.removeChild(link)
        } catch (error) {
            console.error('Error al exportar a CSV:', error)
        }
    }

    // Función para convertir los datos a formato XLSX
    const convertToXLSX = (data: DataRow[], headerMap: Record<string, string>): any[] => {
        const headers = Object.keys(headerMap);
        const transformedData = data.map((row) => {
            const newRow: Record<string, any> = {};
            headers.forEach((header) => {
                const keyPath = headerMap[header];
                let value = getNestedValue(row, keyPath);
                if (typeof value === 'object' && value !== null) {
                    value = JSON.stringify(value);
                }
                if (typeof value === 'string' && (value.startsWith('+') || /^[0-9]+$/.test(value))) {
                    value = `${value}`;
                }
                if (typeof value === 'string') {
                    value = value.replace(/"/g, '""');
                    if (value.includes(',') || value.includes('"') || value.includes('\n')) {
                        value = `"${value}"`;
                    }
                }
                newRow[header] = value !== undefined ? value : '';
            });
            return newRow;
        });
        return transformedData;
    };

    const handleToExportDataXLSX = () => {
        try {
            if (dataToExport.length === 0) {
                console.error('No hay datos para exportar.');
                return;
            }
            const worksheet = XLSX.utils.book_new();
            const transformedData = convertToXLSX(dataToExport, headerMap);
            const sheet = XLSX.utils.json_to_sheet(transformedData);
            XLSX.utils.book_append_sheet(worksheet, sheet, title);
            XLSX.writeFile(worksheet, `${title}.xlsx`);
        } catch (error) {
            console.error('Error al exportar a Excel:', error);
        }
    }

    return (
        <div style={DropdownExportStyles.dropdownContainer}>
            <button
                disabled={disabled ? disabled : false}
                type='button'
                className={`btn btn-primary d-flex align-items-center ${size}`}
                ref={buttonRef}
                onClick={toggleMenu}
            >
                <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />
                <span>Exportar datos</span>
            </button>

            {isOpen && (
                <div
                    ref={menuRef}
                    style={{
                        ...DropdownExportStyles.menuSubDropdown,
                        ...(dropUp ? DropdownExportStyles.menuDropUp : DropdownExportStyles.menuDropDown),
                        ...(alignRight ? DropdownExportStyles.alignLeft : DropdownExportStyles.alignRight),
                        ...(isOpen ? DropdownExportStyles.showMenu : DropdownExportStyles.hideMenu), // Aplica la transición aquí
                    }}
                    className='w-250px w-md-300px'
                >
                    <ExportRegistersDropdown
                        handleToExportDataCSV={handleToExportDataCSV}
                        handleToExportDataXLSX={handleToExportDataXLSX}
                        toggleMenu={toggleMenu}
                    />
                </div>
            )}
        </div>
    )
}

export { LeadsRegistersExport }