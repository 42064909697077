import React, { useState, useEffect, FC } from "react";

interface Seller {
  id: string;
  name: string;
}

interface Props {
  sellers: Seller[];
  sellersGroupFormData: {
    name: string;
    manager: string;
    sellers: string[];
  };
  setSellersGroupFormData: React.Dispatch<React.SetStateAction<{
    name: string;
    manager: string;
    sellers: string[];
  }>>;
}

const ModalAddGroup: FC<Props> = ({ sellers, sellersGroupFormData, setSellersGroupFormData }) => {
  const [selectedManager, setSelectedManager] = useState("");
  const [nameError, setNameError] = useState("");

  const handleManagerSelect = (event) => {
    const managerId = event.target.value;
    setSelectedManager(managerId);
    setSellersGroupFormData((prevFormData) => ({
      ...prevFormData,
      manager: managerId,
    }));
  };

  const handleSellerToggle = (sellerId) => {
    setSellersGroupFormData((prevFormData) => {
      const isSelected = prevFormData.sellers.includes(sellerId);

      if (isSelected) {
        return {
          ...prevFormData,
          sellers: prevFormData.sellers.filter((id) => id !== sellerId),
        };
      } else {
        return {
          ...prevFormData,
          sellers: [...prevFormData.sellers, sellerId],
        };
      }
    });
  };

  useEffect(() => {
    if (sellersGroupFormData.name.trim().split(" ").length > 2) {
      setNameError("El nombre no puede ser mayor a dos palabras");
    } else {
      setNameError("");
    }
  }, [sellersGroupFormData.name]);

  const renderSellersRows = () => {
    return sellers.map((seller) => (
      <div key={seller.id} className="fv-row mb-7">
        <div className="form-check form-switch form-check-custom form-check-solid">
          <input
            className="form-check-input me-3"
            type="checkbox"
            id={`flexSwitch${seller.id}`}
            checked={sellersGroupFormData.sellers.includes(seller.id)}
            onChange={() => handleSellerToggle(seller.id)}
          />
          <label className="fw-bolder" htmlFor={`flexSwitch${seller.id}`}>
            {seller.name}
          </label>
        </div>
      </div>
    ));
  };

  return (
    <form action="" id="uwu">
      <div className="fv-row mb-7">
        <label className="required fw-bold fs-6 mb-2">Nombre</label>
        <input
          type="text"
          className="form-control form-control-lg form-control-solid"
          value={sellersGroupFormData.name}
          onChange={(e) =>
            setSellersGroupFormData((prevFormData) => ({
              ...prevFormData,
              name: e.target.value,
            }))
          }
        />
        {nameError && <div className="text-danger">{nameError}</div>}
      </div>
      <div className="fv-row mb-7">
        <label className="required fw-bold fs-6 mb-2">
          Selecciona a un manager
        </label>
        <select
          className="form-select form-select-lg form-select-solid"
          value={selectedManager}
          onChange={handleManagerSelect}
        >
          <option value="">Elige una opción...</option>
          {sellers.map((seller) => (
            <option key={seller.id} value={seller.id}>
              {seller.name}
            </option>
          ))}
        </select>
      </div>
      <label className="required fw-bold fs-6 mb-2">
        Selecciona a los vendedores
      </label>
      {renderSellersRows()}
    </form>
  );
}

export default ModalAddGroup;
