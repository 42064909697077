import { DragonMainApi, EPRoleMilestoneEdit, apiDragon } from "../../../utils/constants/_Constants";

import { apiPetitions } from "../../../../_metronic/helpers/apis";

async function roleMilestoneEdit (startTime: string, endTime: string, milestoneIndex: number, sellersOrGroups: string[], color: string, textColor: string, rolId: string, userToken: string ) {

    try {

        const data = await apiPetitions(apiDragon, `${DragonMainApi+EPRoleMilestoneEdit+rolId}`, {
            start: startTime,
            end: endTime,
            milestoneIndex,
            sellersOrGroups,
            color,
            textColor,

        }, {
            
            'Content-Type': 'application/json',
            'x-token': userToken,
            
        },
        'put');

        const jsonDataMilestoneEdit = data;

        return jsonDataMilestoneEdit;

    } catch (error) {

        throw error;

    }

}

export {roleMilestoneEdit};