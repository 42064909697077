// Importamos hooks de react que usaremos para este componente
import { useState, useContext } from "react";
// Importamos libreria para manejo de lenguaje
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from "../../../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------

// Importamos la funcion productsIscoreEditSeller para conexion con API de productos
import { productsIscoreEditSeller } from "../../services/_ProductsIscoreEditApi";

// ---------------------------------------------------------------------------------------

// Importamos funcion para vencimiento de sesion
import { sessionExpired } from "../../../../utils/functions/_Functions";

// ---------------------------------------------------------------------------------------

// Importamos las interfaces globales que usaremos para este modulo
import { AlertInfo } from "../../../../utils/interfaces/_InterfacesTypes";

// Iniciamos funcion para la construccion del componente SellerTabForm y recibimos props
function SellerTabForm({idProduct, formDataEditSeller, setFormDataEditSeller, getProductsRegisters, closeModalEditIscore}) {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl();

  //Algunas variables desde el usercontext
  const { token } = useContext(UserContext);
  const userToken = token;

  //Variable para el manejo de estado de mensaje de confirmacion o error
  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  });

  //Funcion para el manejo del valor de los inputs
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // Convierte el valor a un número usando parseInt
    const numericValue = parseInt(value, 10);
    
    setFormDataEditSeller({
      ...formDataEditSeller,
      [name]: numericValue,
    });
  };

  //Funcion para el envio de la informacion a travez de la peticion por API
  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    console.log('Current formDataEditLead values:', formDataEditSeller);
    
    const updateLeadSeller = true;

    const mailAbierto = formDataEditSeller.sellerEmailAbierto;

    const llamadaGrabacion = formDataEditSeller.sellerLlamadaGrabacion;
    
    const whatsappEditar = formDataEditSeller.sellerWhatsappEditar;
    const whatsappHablar = formDataEditSeller.sellerWhatsappHablar;
    const whatsappIscore = formDataEditSeller.sellerWhatsappIscore;
    const whatsappHolabot = formDataEditSeller.sellerWhatsappHolabot;
    const whatsappWhattxt = formDataEditSeller.sellerWhatsappWhatxt;
    const whatsappCalifica = formDataEditSeller.sellerWhatsappCalifica;
    const whatsappWhatsapp = formDataEditSeller.sellerWhatsappWhatsapp;
    const whatsappComentarios = formDataEditSeller.sellerWhatsappComentarios;
    
    try {

      const {data: jsonData, code, msg} = await productsIscoreEditSeller(
        updateLeadSeller,
        mailAbierto,
        llamadaGrabacion,
        whatsappEditar,
        whatsappHablar,
        whatsappIscore,
        whatsappHolabot,
        whatsappWhattxt,
        whatsappCalifica,
        whatsappWhatsapp,
        whatsappComentarios,
        userToken,
        idProduct
      );

      if ( code === 401 && msg === 'Token no valido' ) {

        sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
        return;

      } else {

        setAlertInfo({
          text: code === 200 ? intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.SUCCESS_ISCORE_EDITION'}) : intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.FAIL_ISCORE_EDITION'}),
          variant: code === 200 ? 'success'  : 'danger',
        });
  
        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          });
          closeModalEditIscore();
          getProductsRegisters();
        }, 3000);

      }

    } catch(error) {
      setAlertInfo({
        text: 'Error en la solicitud Axios',
        variant: 'danger',
      });
    }

  }; 

  return (
    <div className="container">
      <h4 className='mt-8 mb-6'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.TAGCALLS'})}</h4>
      <div className="row">
        <div className="col">
          <div>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTCALLSGRABACION_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTCALLSGRABACION_SPAN'})}</span>
              <input
                type="number"
                name="sellerLlamadaGrabacion"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditSeller.sellerLlamadaGrabacion}
                onChange={handleInputChange}
              />
          </div>
        </div>
      </div>
      <h4 className='mt-8 mb-6'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.TAGWHATS'})}</h4>
      <div className="row">
        <div className="col">
          <div>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSHABLAR_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSHABLAR_SPAN'})}</span>
              <input
                type="number"
                name="sellerWhatsappHablar"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditSeller.sellerWhatsappHablar}
                onChange={handleInputChange}
              />
          </div>
        </div>
        <div className="col">
          <div>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSWHATSAPP_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSWHATSAPP_SPAN'})}</span>
              <input
                type="number"
                name="sellerWhatsappWhatsapp"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditSeller.sellerWhatsappWhatsapp}
                onChange={handleInputChange}
              />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSPRINCIPAL_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSPRINCIPAL_SPAN'})}</span>
              <input
                type="number"
                name="sellerWhatsappHolabot"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditSeller.sellerWhatsappHolabot}
                onChange={handleInputChange}
              />
          </div>
        </div>
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSCALIFICAR_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSCALIFICAR_SPAN'})}</span>
              <input
                type="number"
                name="sellerWhatsappCalifica"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditSeller.sellerWhatsappCalifica}                           
                onChange={handleInputChange}
              />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSEDITAR_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSEDITAR_SPAN'})}</span>
              <input
                type="number"
                name="sellerWhatsappEditar"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditSeller.sellerWhatsappEditar}
                onChange={handleInputChange}
              />
          </div>
        </div>
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSCOMENTARIO_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSCOMENTARIO_SPAN'})}</span>                                          
              <input
                type="number"
                name="sellerWhatsappComentarios"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditSeller.sellerWhatsappComentarios}
                onChange={handleInputChange}
              />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSINBOUND_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSINBOUND_SPAN'})}</span>
              <input
                type="number"
                name="sellerWhatsappWhatxt"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditSeller.sellerWhatsappWhatxt}
                onChange={handleInputChange}
              />
          </div>
        </div>
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSSCORE_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTWHATSSCORE_SPAN'})}</span>
              <input
                type="number"
                name="sellerWhatsappIscore"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditSeller.sellerWhatsappIscore}
                onChange={handleInputChange}
              />
          </div>
        </div>
      </div>
      <h4 className='mt-8 mb-6'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.TAGEMAILS'})}</h4>
      <div className="row">
        <div className="col">
          <div>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTEMAILSABIERTO_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.INPUTEMAILSABIERTO_SPAN'})}</span>
              <input
                type="number"
                name="sellerEmailAbierto"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditSeller.sellerEmailAbierto}
                onChange={handleInputChange}
              />
          </div>
        </div>
      </div>
      
      {alertInfo.text && (
        <div className={`alert alert-${alertInfo.variant} d-flex align-items-center`} role='alert'>
          <div>{alertInfo.text}</div>
        </div>
      )}

      <button type="submit" className='mt-8 btn btn-sm btn-primary' onClick={handleFormSubmit}>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_SELLERS.BUTTONSUBMIT'})}</button>
    </div> 
  )
}
  
export { SellerTabForm }