/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string,
  originalLeads: number[],
  duplicateLeads: number[],
  datesData: string[],
  leadsList: any
}

const LeadsMainChart: FC<Props> = ({className, originalLeads, duplicateLeads, datesData, leadsList}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  console.log('--------SE RENDERISA TABLA--------')
  console.log(originalLeads);
  console.log(duplicateLeads);
  console.log(datesData);
  console.log('----------------------------------')

  useEffect(() => {


    const chart = refreshChart()
  
    console.log('renderizamos grafica')
    
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, originalLeads, duplicateLeads, datesData])
  
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const serie_data1 = originalLeads
    const serie_data2 = duplicateLeads
    const serie_date = datesData

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, serie_data1, serie_data2,serie_date))
    if (chart) {
      chart.render()
    }

    return chart
  }

  return (
    <div className={`${className}`}>
      {/* begin::Body */}
      <div className=''>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}} />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {LeadsMainChart}

function getChartOptions(height: number, serie_data1: number[], serie_data2: number[], serie_date: string[]): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  return {
    series: [
      {
        name: 'Originales',
        data: serie_data1,
      },
      {
        name: 'Duplicados',
        data: serie_data2,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: true, // Mostrar el botón de descarga
          selection: true, // Mostrar el botón de selección
          zoom: true, // Mostrar el botón de zoom
          zoomin: true, // Mostrar el botón de zoom-in
          zoomout: true, // Mostrar el botón de zoom-out
          pan: true, // Mostrar el botón de paneo
          reset: true, // Mostrar el botón de reseteo
        },
        export: {
          // Configuración personalizada para la exportación
          csv: {
            filename: 'leads-data', // Nombre de archivo para la exportación CSV
        },
        svg: {
            filename: 'leads-data', // Nombre de archivo para la exportación SVG
          },
          png: {
            filename: 'leads-data', // Nombre de archivo para la exportación PNG
          },
        },         
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '90%',
        borderRadius: 5,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: serie_date,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return 'Leads' + val
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
