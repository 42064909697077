import { DragonMainApi, EPLeadGrade, apiDragon } from "../../../utils/constants/_Constants";
import { apiPetitions } from "../../../../_metronic/helpers/apis";

async function leadGrade(userToken: string, leadId: string, score: string, comments: string) {
    try {
        const response = await apiPetitions(apiDragon,`${DragonMainApi+EPLeadGrade}`, {
            leadId,
            score,
            comments
        }, {
            'Content-Type': 'application/json',
            'x-token': userToken,
        }, 
        'post')

        const jsondataLeadGrade = response 
        return jsondataLeadGrade;

    }catch (error){
        throw error
    }
}

export { leadGrade }