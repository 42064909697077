import { FC, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';

// Definir el esquema de validación usando Yup
const validationSchema = Yup.object({
  newPassword: Yup.string()
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .required('La nueva contraseña es requerida'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Las contraseñas deben coincidir')
    .required('Confirmar contraseña es requerido')
});

const NewPassword: FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate(); // Hook para redirección

  // Configurar Formik
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: (values) => {
      // Simular una solicitud y mostrar el modal
      console.log('Nueva contraseña:', values.newPassword);
      setShowModal(true);
    }
  });

  const handleToCloseModalForNewService = () => {
    setShowModal(false);
    navigate('/login'); // Redirigir después de cerrar el modal
  };

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-11'>
          <img
            className='mb-4'
            src='https://storage.googleapis.com/dragon-bucket-prod/app/img/LogoLogin.png'
            alt='icon'
          />

          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-2'>Reestablecer contraseña</h1>
          {/* end::Title */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Nueva contraseña</label>
          <input
            type='password'
            placeholder='*****'
            className='form-control bg-transparent'
            autoComplete='off'
            name='newPassword'
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.newPassword && formik.errors.newPassword ? (
            <div className='text-danger'>{formik.errors.newPassword}</div>
          ) : null}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Confirmar nueva contraseña</label>
          <input
            type='password'
            placeholder='*****'
            className='form-control bg-transparent'
            autoComplete='off'
            name='confirmPassword'
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className='text-danger'>{formik.errors.confirmPassword}</div>
          ) : null}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-primary me-4'
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          >
            <span className='indicator-label'>Cambiar contraseña</span>
          </button>
        </div>
        {/* end::Form group */}
      </form>

      {/* Modal */}
      <Modal
        show={showModal}
        onHide={handleToCloseModalForNewService}
        scrollable
        centered
        className='modal-md'
      >
        <Modal.Body>
          <div className='text-center'>
            La contraseña ha sido reestablecida exitosamente. Puedes iniciar sesión con tu nueva contraseña.
          </div>
        </Modal.Body>

        <Modal.Footer className='justify-content-center'>
          <button
            className='btn btn-primary btn-sm'
            type='button'
            onClick={handleToCloseModalForNewService}
          >
            Aceptar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { NewPassword };
