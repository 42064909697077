import { FC, useState } from "react"
import { CrmNav } from './interactionsMenuNavs/CrmNav'
import { EndPointNav } from './interactionsMenuNavs/EndPointNav'
import { HistoryNav } from './interactionsMenuNavs/HistoryNav'
import { CallsNav } from './interactionsMenuNavs/CallsNav'
import { MailNav } from './interactionsMenuNavs/MailNav'
import { WhatsappNav } from './interactionsMenuNavs/WhatsappNav'
import { Lead, LeadDetailInfo, LeadDataDetail } from "../../../interfaces/leadsTypes"
import { GradesHistoryNa } from "./interactionsMenuNavs/GradesHistoryNav"

type Props = {
  leadData: LeadDetailInfo | undefined
}

export const InteractionsMenuCard: FC<Props> = ({
  leadData
}) => {

  const [currentNavSelected, setCurrentNavSelected] = useState<string>('')

  return (
    <>
      <div className='card card-custom gutter-b card-campaign'>
        <div className="alert alert-custom alert-success py-4" role="alert">
          <div className="mt-4 mb-4">
            *Selecciona una de las siguientes categorias para visualizar la información
          </div>
        </div>
        <div className='card-body p-6'>
          <nav>
            <div className="d-flex justify-content-center">
              <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                <button
                  className='nav-link'
                  id='nav-calls-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-calls'
                  type='button'
                  role='tab'
                  aria-controls='nav-calls'
                  aria-selected='false'
                  onClick={() => setCurrentNavSelected('Calls')}
                >
                  <span className='h5'>
                    <i className="bi bi-phone-fill fs-2"></i> Llamadas
                  </span>
                </button>
                <button
                  className='nav-link'
                  id='nav-whatsapp-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-whatsapp'
                  type='button'
                  role='tab'
                  aria-controls='nav-whatsapp'
                  aria-selected='false'
                  onClick={() => setCurrentNavSelected('Whatsapp')}
                >
                  <span className='h5'>
                    <i className="bi bi-whatsapp fs-2"></i> WhatsApp
                  </span>
                </button>
                <button
                  className='nav-link'
                  id='nav-Mail-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-Mail'
                  type='button'
                  role='tab'
                  aria-controls='nav-Mail'
                  aria-selected='false'
                  onClick={() => setCurrentNavSelected('Mail')}
                >
                  <span className='h5'>
                    <i className="bi bi-envelope-fill fs-2"></i> Mail
                  </span>
                </button>
                <button
                  className='nav-link'
                  id='nav-logs-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-logs'
                  type='button'
                  role='tab'
                  aria-controls='nav-logs'
                  aria-selected='false'
                  onClick={() => setCurrentNavSelected('History')}
                >
                  <span className='h5'>
                    <i className="bi bi-clock-history fs-2"></i> Historial
                  </span>
                </button>
                <button
                  className='nav-link'
                  id='nav-crm-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-crm'
                  type='button'
                  role='tab'
                  aria-controls='nav-crm'
                  aria-selected='false'
                  onClick={() => setCurrentNavSelected('CRM')}
                >
                  <span className='h5'>
                    <i className="bi bi-node-plus-fill fs-2"></i> CRM
                  </span>
                </button>
                <button
                  className='nav-link'
                  id='nav-grades-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-grades'
                  type='button'
                  role='tab'
                  aria-controls='nav-grades'
                  aria-selected='false'
                  onClick={() => setCurrentNavSelected('Grades')}
                >
                  <span className='h5'>
                    <i className="bi bi-clock-history fs-2"></i> Calificaciones
                  </span>
                </button>
                {/* <button
                  className='nav-link'
                  id='nav-endpoint-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-endpoint'
                  type='button'
                  role='tab'
                  aria-controls='nav-endpoint'
                  aria-selected='false'
                >
                  <span className='h5'>
                    <i className="bi bi-command fs-2"></i> End point
                  </span>
                </button> */}
              </div>
            </div>

          </nav>
          <div className='tab-content' id='nav-tabContent'>
            <div
              className='tab-pane fade'
              id='nav-calls'
              role='tabpanel'
              aria-labelledby='nav-calls-tab'
              style={{ maxHeight: '310px', overflowY: 'scroll' }}
            >
              {currentNavSelected === 'Calls' && (
                <CallsNav leadData={leadData} currentNavSelected={currentNavSelected} />
              )}

            </div>
            <div
              className='tab-pane fade'
              id='nav-whatsapp'
              role='tabpanel'
              aria-labelledby='nav-whatsapp-tab'
              style={{ maxHeight: '310px', overflowY: 'scroll' }}
            >
              {currentNavSelected === 'Whatsapp' && (
                <WhatsappNav leadData={leadData} currentNavSelected={currentNavSelected} />
              )}
            </div>
            <div
              className='tab-pane fade'
              id='nav-Mail'
              role='tabpanel'
              aria-labelledby='nav-Mail-tab'
              style={{ maxHeight: '310px', overflowY: 'scroll' }}
            >
              {currentNavSelected === 'Mail' && (
                <MailNav leadData={leadData} currentNavSelected={currentNavSelected}/>
              )}
            </div>
            <div
              className='tab-pane fade'
              id='nav-logs'
              role='tabpanel'
              aria-labelledby='nav-logs-tab'
              style={{ maxHeight: '310px', overflowY: 'scroll' }}
            >
              {currentNavSelected === 'History' && (
                <HistoryNav leadData={leadData} currentNavSelected={currentNavSelected} />
              )}
            </div>
            <div
              className='tab-pane fade'
              id='nav-crm'
              role='tabpanel'
              aria-labelledby='nav-crm-tab'
              style={{ maxHeight: '310px', overflowY: 'scroll' }}
            >
              {currentNavSelected === 'CRM' && (
                <CrmNav leadData={leadData} currentNavSelected={currentNavSelected} />
              )}
            </div>
            <div
              className='tab-pane fade'
              id='nav-grades'
              role='tabpanel'
              aria-labelledby='nav-grades-tab'
              style={{ maxHeight: '310px', overflowY: 'scroll' }}
            >
              {currentNavSelected === 'Grades' && (
                <GradesHistoryNa leadData={leadData} currentNavSelected={currentNavSelected} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
