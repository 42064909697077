import axios from 'axios';
import { DragonMainApi, EPProductNew, apiDragon } from '../../../utils/constants/_Constants';
import { apiPetitions } from '../../../../_metronic/helpers/apis';

async function productsNew(
    pro_name: string, 
    pro_mails: [], 
    pro_type: string, 
    pro_legals: string, 
    pro_country: string, 
    pro_state: string,
    pro_city: string,
    pro_zone: string,
    crm_id: string,
    productWab: string,
    pro_price: number,
    brand_id: string,
    automaticsCampaigns: [],
    cli_id: string,
    userToken: string
    ){
        try{
            const response = await apiPetitions(apiDragon,`${DragonMainApi+EPProductNew}`, {
                cliId: cli_id,
                brandId: brand_id,
                proName: pro_name,
                proPrice: pro_price,
                proType: pro_type,
                proCountry: pro_country,
                proState: pro_state,
                proCity: pro_city,
                proZone: pro_zone,
                proLegals: pro_legals,
                proMails: pro_mails,
                crmId: crm_id,
                automaticsCampaigns,
                twlWab:"+5215568268355"

            }, {
               
                'Content-Type': 'application/json',
                'x-token': userToken
                
            }, 'post');

            const jsonDataProductsNew = response;

            return jsonDataProductsNew;


        } catch(error) {

            // console.log('Error de nueva marca ', error)
            throw error

        }
    }

    export { productsNew };
