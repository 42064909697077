// Importamos hooks de react que usaremos para este componente
import { useState } from "react";
// Importamos libreria para manejo de lenguaje 
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos los componentes de cada paso para el wizard de nuevo producto
import { Step1 } from "./stepsNewProduct/Step1";
import { Step2 } from "./stepsNewProduct/Step2";
import { Step3 } from "./stepsNewProduct/Step3";
import { Step4 } from "./stepsNewProduct/Step4";
import { Step5 } from "./stepsNewProduct/Step5";
import { Step6 } from "./stepsNewProduct/Step6";

// ---------------------------------------------------------------------------------------

// Importamos las interfaces de marcas que usaremos para este componente
import { Step1Data, Step2Data, Step3Data, Step4Data, Step5Data } from "../interfaces/productsTypes";

// Iniciamos funcion para construir el componente ModalNewProduct
export function ModalNewProduct({closeModal, getProductsRegisters, setCurrentPage}){
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl();

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});

  // const { user, token, updateToken } = useContext(UserContext);
  // const clientId = user.cli_id;
  // const userToken = token;

  // Definimos estado inicial para el step1
  const [step1Data, setStep1Data] = useState<Step1Data>({
    productName: '', 
    productMails: [], 
    productType: '', 
    productLegals: '', 
    productPrize: 0,
  });

  // Definimos estado inicial para el step2
  const [step2Data, setStep2Data] = useState<Step2Data>({
    productCountry: '',
    productState: '',
    productCity: '',
    productLocal: '',

  });

  // Definimos estado inicial para el step3
  const [step3Data, setStep3Data] = useState<Step3Data>({
    productCrm: '',
    productWab: '',

  });

  // Definimos estado inicial para el step4
  const [step4Data, setStep4Data] = useState<Step4Data>({
    productCampaigns: [],

  });

  // Definimos estado inicial para el step5
  const [step5Data, setStep5Data] = useState<Step5Data>({
    productBrand: '',
  });

  // Funcion que nos permitira manipular los datos en los pasos siguientes
  const handleNext = (data) => {
    setCurrentStep(currentStep + 1);
    setFormData({ ...formData, ...data });
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const totalSteps = 6;
  const progress = (currentStep / totalSteps) * 100;

  return (
    <>
      <div className="progress mb-8">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progress}%` }}
          aria-valuenow={progress}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          {Math.round(progress)}%
        </div>
      </div>
      <div className="wizard">
        <h2 className="wizard-label mb-6">
          {intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.LABEL_STEP'})} {currentStep} {intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.LABEL_STEPOF'})} {totalSteps}
        </h2>
        {currentStep === 1 && <Step1 onNext={handleNext} prevData={step1Data} setStep1Data={setStep1Data}/>}
        {currentStep === 2 && <Step2 onNext={handleNext} handlePrev={handlePrev} prevData={step2Data} setStep2Data={setStep2Data}/>}
        {currentStep === 3 && <Step3 onNext={handleNext} handlePrev={handlePrev} prevData={step3Data} setStep3Data={setStep3Data}/>}
        {currentStep === 4 && <Step4 onNext={handleNext} handlePrev={handlePrev} prevData={step4Data} setStep4Data={setStep4Data}/>}
        {currentStep === 5 && <Step5 onNext={handleNext} handlePrev={handlePrev} prevData={step5Data} setStep5Data={setStep5Data}/>}
        {currentStep === 6 && <Step6 data={formData} handlePrev={handlePrev} closeModal={closeModal} getProductsRegisters={getProductsRegisters} setCurrentPage={setCurrentPage}/>}
      </div>
    </>
  );
}