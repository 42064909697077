import { useState } from "react";
import { Step1Rol } from "./stepsNewRol/Step1";
import { Step2Rol } from "./stepsNewRol/Step2";
import { Step3Rol } from "./stepsNewRol/Step3";
import { Step4Rol } from "./stepsNewRol/Step4";
import Step5Rol from "./stepsNewRol/Step5";

const ModalNewRol = ({close, updateRolesList}) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({});

// Se definirán los datos que almacenará cada step y se inicilizarán vacios
    const [step1Data, setStep1Data] = useState({
        rolName: '',
        assignType: '',
        assignForUsers : ''
    })

    

    const [step2Data, setStep2Data] = useState({
        campaigns: [],
        brands: '',
        products: []
    })

    const [step3Data, setStep3Data] = useState({
        users: [],
        usersName: [],
        groups: [],
        groupsName: []
    })

    const [step4Data, setStep4Data] = useState({
        schedules: [[],[],[],[],[],[],[]]
    })

    //En handle Next se guarda la información dl step
    const handleNext = (data) => {
        setCurrentStep(currentStep + 1);
        setFormData({ ...formData, ...data });
    };

    //En handleprev se mantine la información de cada step
    const handlePrev = () => {
    setCurrentStep(currentStep - 1);
    };

    const totalSteps = 5;
    const progress = (currentStep / totalSteps) * 100;

    return(
        <>
        <div className="progress mb-8">
            <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${progress}%` }}
                aria-valuenow={progress}
                aria-valuemin={0}
                aria-valuemax={100}   
            >
                {progress}%    
            </div>
        </div>

        <div className="wizard">
            <h2 className="wizard-label mb-6">
                Paso {currentStep} de {totalSteps}
            </h2>
            {/* Aqui pasaros lo datos de data en el handleNext para poder acceder a ellos en los diferentes steps */}
            {currentStep === 1 && <Step1Rol onNext={handleNext} prevData={step1Data} setStep1Data={setStep1Data}/>}
            {currentStep === 2 && <Step2Rol data={formData} onNext={handleNext} handlePrev={handlePrev} prevData={step2Data} setStep2Data={setStep2Data} />}
            {currentStep === 3 && <Step3Rol data={formData} onNext={handleNext} handlePrev={handlePrev} prevData={step3Data} setStep3Data={setStep3Data} />}
            {currentStep === 4 && <Step4Rol data={formData} onNext={handleNext} handlePrev={handlePrev} prevData={step4Data} setStep4Data={setStep4Data} />}
            {currentStep === 5 && <Step5Rol data={formData} handlePrev={handlePrev} close={close} updateRolesList= {updateRolesList}/>}
        </div>
        </>

        
    )
}

export{ModalNewRol};