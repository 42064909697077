import { FC } from "react";
import { shortenString } from "../../../utils/functions/_Functions";

type Props = {
    linkDcem: any
}

const ModalDcemDetail: FC<Props> = ({
    linkDcem
}) => {
    return (
        <div className="d-flex">

            <div className="col-6">
                <label>Clics totales: </label><span className="text-muted">{linkDcem.totalClicks}</span><br /><br /><br />
                <label>Link DCEM: </label><span className="text-muted">{linkDcem.shortedLink}</span><br /><br /><br />
                <label>URL Asociada: </label><span className="text-muted">{shortenString(linkDcem.originalLink)}</span><br /><br /><br />
                <label>Creado por: </label><span className="text-muted">{linkDcem.autorLinkName}</span><br /><br /><br />
                <label>Fecha de última modificación: </label><span className="text-muted"> 2022-11-01 11:37:32</span>
            </div>

            <div className="col-6">
            <div style={{ display: 'flex', justifyContent: 'center' }}>

                {
                    linkDcem.qrRoute ? (
<img 
                        src={linkDcem.qrRoute}
                        style={{ width: '300px', height: 'auto', margin: '0 auto' }}
                    />
                    ) : (
                        <span>No hay un QR para este link</span>
                    )
                }
                    
                </div>
            </div>

        </div>
    )
}

export {ModalDcemDetail};