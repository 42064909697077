import React from 'react';

const NavItem = ({ title, refId, isActive }) => {
  return (
    <li className={`nav-item mb-2 ${isActive ? 'active' : ''}`}>
      <a className={`nav-link text-dark fs-4 ${isActive ? 'active' : ''}`} data-bs-toggle='tab' href={`#kt_vtab_pane_${refId}`}>
        {title}
      </a>
    </li>
  );
};

export default NavItem;
