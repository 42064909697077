import axios from 'axios';
import { apiDragon, DragonMainApi, EPCampaignsList, EPCampaignsListByClient, EPCampaignById } from '../../../utils/constants/_Constants';

// ---------------------------------------------------------------------------------------

// Importamos funcion para obtener codigos de respuesta del servidor
import { apiGetDeletePetitions } from "../../../../_metronic/helpers/apis";

// Funcion para obtener el listado de campañas por cliente
export async function campaignsListForProduct(userToken: string, productValue: string | null) {

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPCampaignsList+productValue}`, {

            /* Los parametros se mandan como undefined
            ya que estos se envian solo en peticiones de eliminacion 'delete */
            undefined

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },
        
        'get')

        const jsonDataCampaignsList = data;

        console.log(jsonDataCampaignsList)

        return jsonDataCampaignsList;

    } catch (error) {

        throw error;

    }
}

//Peticion para listar todas las camapañas por cliente

export async function campaignsListForClient(userToken: string, idClient: string) {

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPCampaignsListByClient+idClient}`, {

            /* Los parametros se mandan como undefined
            ya que estos se envian solo en peticiones de eliminacion 'delete */
            undefined

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },
        
        'get')

        const jsonDataCampaignsList = data;

        console.log(jsonDataCampaignsList)

        return jsonDataCampaignsList;

    } catch (error) {

        throw error;

    }
}

export async function campaignById (userToken: string, campaignId: string) {

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPCampaignById+campaignId}`, {
            undefined
        }, {
   
            'Content-Type': 'application/json',
            'x-token': userToken

        },
        'get')

        const jsonDataCampaignInfo = data;

        return jsonDataCampaignInfo;

    }catch (error){

        throw error;
        
    }

}