import { useEffect, useState } from 'react'
import CalendarView from './calendarView/CalendarView'
import { useIntl } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { ModalCalendarSettings } from './modals/ModalCalendarSettings';
import { setPageTitle } from '../../utils/functions/_Functions';
import { DragonCem } from '../../utils/constants/_Constants';

export const Dates = () => {

  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  }

  const closeModal = () => {
    setShowModal(false);
  }

  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    setPageTitle(`${intl.formatMessage({ id: "DATES.TITLE" })} | ${DragonCem}`);
  }, [])

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2'>
                Calendario de citas
              </h1>

              <div className='d-flex align-items-center my-2'>
                <div className='w-100px me-5'>
                  <select 
                    name='seller'
                    data-control='selectSeller'
                    data-hidden-search='true'
                    className='form-select form-select-white form-select-sm'
                  >
                    <option value='0'>Antonio Pérez</option>
                    <option value='1'>Maria Garcia</option>
                    <option value='2'>Angel Alfaro</option>

                  </select> 
                </div>
                <button
                  type='button'
                  className='btn btn-primary btn-sm'
                  onClick={openModal}  
                >
                  Configurar mi calendario de citas
                </button>

                <Modal 
                  show={showModal}
                  onHide={closeModal}
                  scrollable
                  className='modal-default'
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h1>Calendario de citas</h1>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ModalCalendarSettings />
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className='btn btn-sm btn-secondary'
                      type='button'
                      onClick={closeModal}  
                    >
                      Cancelar
                    </button>
                    <button
                      className='btn btn-sm btn-primary'
                      type='submit'
                      onClick={closeModal}
                    >
                      Guardar
                    </button>
                  </Modal.Footer>
                </Modal>


              </div>
            </div>
            <CalendarView />
          </div>
        </div>
      </div>
    </>
  );
}