import { FC, useState, useEffect } from "react";
import { Lead, GradeHistoryLeadDetail, GradeHistory, LeadDetailInfo } from "../../../../interfaces/leadsTypes";
import { LoadingAnimation } from "../../../../../../utils/components/loading/LoadingAnimation";

type Props = {
    leadData: LeadDetailInfo | undefined;
    currentNavSelected: string;
}

export const GradesHistoryNa: FC<Props> = ({
    leadData,
    currentNavSelected
}) => {

    const initialGradesHistoryList: GradeHistoryLeadDetail = {
        gradesHistory: [],
        gradeHistoryCount: 0
    }

    //Constantes para manejar el lestado de historial de calificaciones
    const [gradesHistoryList, setGradesHistoryList] = useState<GradeHistoryLeadDetail>(initialGradesHistoryList)

    //Constante para el manejo de estado de la animacion de carga
    const [isLoading, setIsLoading] = useState<boolean>(true);
    //Constante para el manejo de estado del mensaje post carga de registros
    const [infoMessageLoading, setInfoMessageLoading] = useState<string>("");

    //Funcion para verificar el tamaño del arreglo de historial de calificaciones
    const checkGradesHistoryListLength = () => {
        gradesHistoryList.gradesHistory.length === 0 && setInfoMessageLoading("No se encontro ningún registro de calificaciones")
    }

    const getGradesHistoryByLead = () => {
        setTimeout(() => {
            try {
                const newGradesHistoryList =
                {
                    gradesHistory: [
                        {
                            gradeHistoryId: '00004',
                            gradeHistoryLeadId: leadData?.leadId,
                            gradeHistorySellerId: leadData?.SellerId,
                            gradeHistorySellerName: leadData?.SellerName,
                            gradeHistoryNewGrade: 18,
                            gradeHistoryNewGradeComment: 'Dice que sigue interesado',
                            gradeHistoryNewGradeConcept: 'Interés Alto',
                            gradeHistoryDateAdd: '2024-05-15 13:22:00'
                        },
                        {
                            gradeHistoryId: '00003',
                            gradeHistoryLeadId: leadData?.leadId,
                            gradeHistorySellerId: leadData?.SellerId,
                            gradeHistorySellerName: leadData?.SellerName,
                            gradeHistoryNewGrade: 9,
                            gradeHistoryNewGradeComment: 'Solicito seguimiento',
                            gradeHistoryNewGradeConcept: 'Interés Medio',
                            gradeHistoryDateAdd: '2024-05-10 11:00:00'
                        },
                        {
                            gradeHistoryId: '00002',
                            gradeHistoryLeadId: leadData?.leadId,
                            gradeHistorySellerId: leadData?.SellerId,
                            gradeHistorySellerName: leadData?.SellerName,
                            gradeHistoryNewGrade: 35,
                            gradeHistoryNewGradeConcept: 'Interés Alto',
                            gradeHistoryNewGradeComment: 'Respondió la llamada y pidió más info.',
                            gradeHistoryDateAdd: '2024-05-05 18:45:00'
                        },
                        {
                            gradeHistoryId: '00001',
                            gradeHistoryLeadId: leadData?.leadId,
                            gradeHistorySellerId: leadData?.SellerId,
                            gradeHistorySellerName: leadData?.SellerName,
                            gradeHistoryNewGrade: 3,
                            gradeHistoryNewGradeConcept: 'No interesado',
                            gradeHistoryNewGradeComment: 'No mostro mucho interés en realidad.',
                            gradeHistoryDateAdd: '2024-05-01 20:15:00'
                        }
                    ],

                    gradeHistoryCount: 4,

                };

                setGradesHistoryList(newGradesHistoryList)
                //Ocultamos la animacion de carga
                setIsLoading(false);
                //Verificamos tamaño del arreglo de historial de calificaciones dis parando la funcion
                checkGradesHistoryListLength()

            } catch (error) {
                console.log('Error obteniendo los registros de calificaciones: ', error)
                //Ocultamos la animacion de carga
                setIsLoading(false)
                //Seteamos mensaje informativo
                setInfoMessageLoading('Hubo error en la petición')
            }
        }, 2000)
    }

    /*Disparamos la funcion de obtencion de registros de calificacioes
    al montar el componente */
    useEffect(() => {
        getGradesHistoryByLead();
    }, [])

    return (
        <>
            {/* <p>Hola</p> */}
            <div className="container ml-15 mt-4 py-12" id="calificaciones-container">
                {/* Si isLoading es igual a 'true' lo mostramos */}
                {isLoading && <div className='py-12'><LoadingAnimation alignment='center' label='Obteniendo historial de calificaciones...' /></div>} 
               
                {!isLoading && gradesHistoryList.gradesHistory.length > 0 && (
                    <>
                        <div className="mt-5">

                            <div className="mb-8">
                                <h4>Total de registros: {gradesHistoryList.gradeHistoryCount}</h4>
                            </div>
                            {gradesHistoryList.gradesHistory.slice(0, 1).map((historyItem, index) => (
                                <div key={index} className="d-flex align-items-center mb-8">
                                    <div className="bullet bullet-vertical h-40px bg-success me-3"></div>

                                    <div className="flex-grow-1">
                                        <span className="text-gray-900 fw-bold fs-6">
                                            Se realiza actualización de calificación por el usuario: {historyItem.gradeHistorySellerName}
                                        </span>
                                        <span className="text-gray-700 fw-semibold d-block mb-1">
                                            El lead fue calificado con: <span className="badge badge-light-success">{historyItem.gradeHistoryNewGrade}</span>
                                        </span>
                                        <span className="text-gray-700 fw-semibold d-block mb-1">
                                            Concepto de la calificación: <span className="badge badge-light-success">{historyItem.gradeHistoryNewGradeConcept}</span>
                                        </span>
                                        <span className="text-gray-700 fw-semibold d-block mb-1">
                                            Comentario de la calificación: <span className="badge badge-light-success">{historyItem.gradeHistoryNewGradeComment}</span>
                                        </span>
                                    </div>

                                    <span className='badge badge-light-success fs-8 fw-bold'>
                                        {new Date(historyItem.gradeHistoryDateAdd).toLocaleString('es-MX', {
                                            day: '2-digit',
                                            month: 'long',
                                            year: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true,
                                        })}
                                    </span>

                                </div>
                            ))}

                            {gradesHistoryList.gradesHistory.slice(1).map((historyItem, index) => (
                                <div key={index} className="d-flex align-items-center mb-8">
                                    <div className="bullet bullet-vertical h-40px bg-secondary me-3"></div>

                                    <div className="flex-grow-1">
                                        <span className="text-gray-900 fw-bold fs-6">
                                            Se realiza actualización de calificación por el usuario: {historyItem.gradeHistorySellerName}
                                        </span>
                                        <span className="text-gray-700 fw-semibold d-block mb-1">
                                            El lead fue calificado con: <span className="badge badge-secondary">{historyItem.gradeHistoryNewGrade}</span>
                                        </span>
                                        <span className="text-gray-700 fw-semibold d-block mb-1">
                                            Concepto de la calificación: <span className="badge badge-light-success">{historyItem.gradeHistoryNewGradeConcept}</span>
                                        </span>
                                        <span className="text-gray-700 fw-semibold d-block mb-1">
                                            Comentario de la calificación: <span className="badge badge-secondary">{historyItem.gradeHistoryNewGradeComment}</span>
                                        </span>
                                    </div>

                                    <span className='badge badge-secondary fs-8 fw-bold'>
                                        {new Date(historyItem.gradeHistoryDateAdd).toLocaleString('es-MX', {
                                            day: '2-digit',
                                            month: 'long',
                                            year: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true,
                                        })}
                                    </span>

                                </div>
                            ))}
                        </div>


                    </>
                )}

                {/* //Si isLoading es diferente a 'true' y la longitud del arreglo del historial es igual a '0' mostramos mensaje de error */}
                {!isLoading && gradesHistoryList.gradesHistory.length === 0 && (
                    <div className='py-12'><p>{infoMessageLoading}</p></div>
                )}

            </div>
        </>

    )
}