import axios from 'axios'
import { DragonMainApi, EPLogin } from '../../utils/constants/_Constants'

// const API_DRAGON_URL= 'https://dev-api-main.dcem.io/auth/login'

async function LoginApi(mail: string, password: string) {
  try {
    const response = await axios.post(DragonMainApi + EPLogin, {
      mail,
      password,
    });

    // Comprobar si existe 'miData' en localStorage
    if (localStorage.getItem('miData') !== null) {
      // La clave 'miData' existe y contiene información, así que podemos eliminarla
      localStorage.removeItem('miData');
      console.log('Había información, ya se borró');
    }

    // Ahora, la respuesta JSON está almacenada en la variable 'response.data'
    const jsonData = response.data;
    // console.log(jsonData)
    //Volvemos una cadena de texto la data devuelta por la api
    const jsonString = JSON.stringify(jsonData);

    console.log(jsonString)

    if (jsonData.token) {
      // Redirige al path '/dashboard' si existe 'token'
      //window.location.replace("/dashboard");

      //TODO: VALIDAR QUE NO EXISTAN DATOS EN EL LOCALSTORAGE DETALLE DE LEAD.(leadId) si existe, redirigir a la ruta http://localhost:3000/lead-detail?leadId=leadId.

      if (localStorage.getItem('leadPath')) {
        //Acá la funcionalidad para armar la url y redireccionar a la ruta detalle de lead
        let path = localStorage.getItem('leadPath')
        window.location.replace(path ? path : '/leads');
      } else {
        // Actualizar el item al valor de la ruta actual, si no existe, agregar una por defecto. 
        const actualPath = localStorage.getItem('actualPath') as string | null;
        window.location.replace(actualPath ? actualPath : "/leads");
      }
      //Guardamos la información (en formato string) que nos regresan, en localStorage
      localStorage.setItem('miData', jsonString);
      return;
    }
    return jsonData; // Si deseas retornar los datos


  } catch (error) {
    throw error; // Puedes manejar el error aquí o propagarlo.
  }
}

export { LoginApi }; 