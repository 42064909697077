import {DragonMainApi, EPLeadToSpam, apiDragon} from '../../../utils/constants/_Constants'

import {apiPetitions, apiGetDeletePetitions} from '../../../../_metronic/helpers/apis'

export async function leadSpamListByClient(userToken: string, clientId: string) {
  try {
    const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPLeadToSpam+clientId}`, {

    }, {
      'Content-Type': 'application/json',
      'x-token': userToken   
    },
  'get')

  const jsonDataLeadsSpamList = data
  return jsonDataLeadsSpamList
  }catch(error){
    throw error
  }
}

export async function leadToSpam(userToken: string, leadId: string, comments: string) {
  try {
    const response = await apiPetitions(
      apiDragon,
      `${DragonMainApi + EPLeadToSpam}`,
      {
        leadId,
        comments,
      },
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'post'
    )

    const jsonDataLeadSpam = response
    return jsonDataLeadSpam
  } catch (error) {
    throw error
  }
}

export async function restoreLead(userToken: string, leadId: string) {

  try {
    const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPLeadToSpam+leadId}`, {

    }, {
      'Content-Type': 'application/json',
      'x-token': userToken  
    },
    'delete')

    console.log('se mando restaurar')

    const jsonDataRestoreLead = data 
    return jsonDataRestoreLead

  }catch(error){
    console.log('no se pudo restaurar')

    throw error
  }
}
