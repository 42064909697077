/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'

const NavDetails: React.FC = () => {
  const location = useLocation()

  return (
    <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/details/sellers/performance' && 'active')
                }
                to='/details/sellers/performance'
              >
                Rendimiento
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/details/sellers/assignproducts' && 'active')
                }
                to='/details/sellers/assignproducts'
              >
                Asignación de productos
              </Link>
            </li>
          </ul>
        </div>
  )
}

export {NavDetails}
