import React, { useState, useContext, useEffect } from 'react'
import { UserContext } from '../../../app-context/user-context/UserContext'
import { SellerByBU, SellerData, SellerByBUListApiResponse } from '../interfaces/accountManagerTypes'
import { amSellersBUList } from '../services/_AMSellersBUListApi'
import { LoadingAnimation } from '../../../utils/components/loading/LoadingAnimation'
import { sessionExpired, dateFormatterTextualEn, formatTextMIddleDash } from '../../../utils/functions/_Functions'

type Props = {
  formDataReasignClient: any
  setFormDataReasignClient: any
  currentClientNameSelected: string
  currentClientIdSelected: string
  currentClientMailOwnerSelected: string | undefined
  // currentClientOwnerSelected: string | undefined
}

export const ModalReasignClient: React.FC<Props> = ({
  formDataReasignClient,
  setFormDataReasignClient,
  currentClientNameSelected,
  currentClientIdSelected,
  currentClientMailOwnerSelected,
  // currentClientOwnerSelected
}) => {

  const { user, token, updateToken } = useContext(UserContext)
  const clientId: string = user.cli_id;
  const userToken: string = token;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedPerson, setSelectedPerson] = useState(1) // Estado para el checkbox seleccionado

  console.log({formDataReasignClient})
  console.log('email current: ', currentClientMailOwnerSelected)

  // Función para manejar el cambio del checkbox
  const handleCheckBoxChange = (personId) => {
    setSelectedPerson(personId) // Actualiza el estado con el ID de la persona seleccionada
  }

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    setFormDataReasignClient((prevData) => ({
      ...prevData,
      mail: value,
    }))

    console.log('Estado actual de formdata: ', formDataReasignClient)

  }

  const handleSelectReasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value 

    setFormDataReasignClient((prevData) => ({
      ...prevData,
      reasignReason: value,
    }))

    console.log('Estado actual de formdata: ', formDataReasignClient)
  }

  const handleSelectLvlChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value 

    setFormDataReasignClient((prevData) => ({
      ...prevData,
      lvl_access: value,
    }))

    console.log('Estado actual de formdata: ', formDataReasignClient)

  }

  const [sellersByBUList, setSellersByBUList] = useState<SellerByBU[]>([])

  const getSellersRegisters = async (): Promise<void> => {
    setIsLoading(true);

    try {
      const { data: jsonData, code, msg }: SellerByBUListApiResponse = await amSellersBUList(userToken, clientId)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired('La sesion a caducado');
        setIsLoading(false);
        return;
      } else {
        const sellersListReceived: SellerByBU[] = jsonData.data;

        const userTokenNew: string = jsonData.token;

        updateToken(userTokenNew)

        const sellerItem: SellerByBU[] = sellersListReceived.map((seller: SellerByBU) => {

          const sellerDateAddFormatted: string = dateFormatterTextualEn(seller.date_add)
          const sellerDateModifyFormatted: string = dateFormatterTextualEn(seller.date_modify)

          const sellerData: SellerData = {
            usr_name: seller.usrData.usr_name,
            usr_phone: seller.usrData.usr_phone,
            wha_phone: seller.usrData.wha_phone,
            usr_mail: seller.usrData.usr_mail,
            sms_phone: seller.usrData.sms_phone,
          }

          return {
            crm_id: seller.crm_id,
            user_type: seller.user_type,
            calendly: seller.calendly,
            status: seller.status,
            date_add: sellerDateAddFormatted,
            date_modify: sellerDateModifyFormatted,
            uid: seller.uid,
            usrData: sellerData,
          }
        })

        setSellersByBUList(sellerItem)
        setIsLoading(false);

      }

      console.log(sellersByBUList)

    } catch (error) {
      console.log("Error receiving sellers: ", error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getSellersRegisters()
  }, [])

  return (
    <>
      <div>
        <div className='alert alert-dismissible bg-light-warning border border-warning'>
          <div className='d-flex flex-column flex-sm-row p-3'>
            <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-exclamation-triangle-fill'
                viewBox='0 0 16 16'
              >
                <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
              </svg>
            </span>
            <div className='d-flex flex-column text-primary pe-0 pe-sm-2'>
              <h5 className='mb-1'>Reasignar cliente</h5>
              <span className='text-dark'>
                <p>¿Estás seguro de reasignar a {formatTextMIddleDash(currentClientNameSelected)}?</p>
                <p>Al reasignarlo se quitara de tu cuenta y perderas acceso al cliente.</p>
              </span>
            </div>
          </div>
        </div>

        <div className='fv-row mb-4'>
          <label className='required fw-bold fs-6 mb-4'>Selecciona al nuevo vendedor</label>
          <div className='pt-2' style={{ maxHeight: '160px', overflowY: 'scroll' }}>
            {isLoading && <LoadingAnimation alignment={'center'} label={'Obteniendo vendedores..'} />}
            <ul>
              {sellersByBUList.map((seller: SellerByBU) => (
                <li key={seller.usrData.usr_mail} className='list-group-item'>
                  <input
                    type='radio'
                    id={seller.usrData.usr_mail}
                    name='selectSeller'
                    className='form-check-input me-2 mb-3'
                    value={seller.usrData.usr_mail} // Agrega el atributo value
                    checked={formDataReasignClient.mail === seller.usrData.usr_mail}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor={seller.usrData.usr_mail} className='form-check-label'>
                    {seller.usrData.usr_name}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className='fv-row'>
          <label className='required fw-bold fs-6 mb-4'>Selecciona al nuevo vendedor</label>
            <select 
              className='form-select form-select-lg form-select-solid'
              name='lvl_accss'
              onChange={handleSelectLvlChange}  
              value={formDataReasignClient.lvl_access}
            >
              <option value=''>-- Selecciona nivel de acceso --</option>
              <option value='3'>Administrador</option>
              <option value='4'>Gerente</option>
              <option value='5'>Vendedor</option>
            </select>
        </div>

        <div className='fv-row'>
          <label className='required fw-bold fs-6 mb-4'>Motivo reasignación</label>
          <select 
            className='form-select form-select-lg form-select-solid'
            name='reasignReason'
            onChange={handleSelectReasonChange}  
            value={formDataReasignClient.reasignReason}
          >
            <option value=''>-- Selecciona motivo de reasignación --</option>
            <option value='1'>El vendedor original ya no está disponible</option>
            <option value='2'>El nuevo vendedor tiene una mejor relación con el cliente</option>
            <option value='3'>El cliente requiere un nivel de servicio diferente</option>
            <option value='4'>El vendedor original está sobrecargado de trabajo</option>
            <option value='5'>El cliente ha solicitado un cambio de vendedor</option>
          </select>
          {/* <input
            className='form-control form-control-solid mb-4'
            placeholder='Motivo de reasignacion'
          />
          <textarea
            value='Ingresa el motivo por el cual reasignarás a este cliente...'
            className='form-control form-control-solid'
          /> */}
        </div>
      </div>
    </>
  )
}
