// Importamos hooks y aux de React y helpers de metronic y bootstrap
import {FC, useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {OverlayTrigger, Tooltip, TooltipProps} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import {UserContext} from '../../../../app-context/user-context/UserContext'

// ---------------------------------------------------------------------------------------

// Importamos componentes secundarios a reenderizar en este componente primario
import {ProductsByClientDropdown} from '../../../../utils/components/dropdowns/ProductsByClientDropdown'
import { ProductsByUserDropdown } from '../../../../utils/components/dropdowns/ProductsByUserDropdown'
import {LeadsReportChart} from './charts/LeadsReportChart'
import LeadsCampaignsProgress from './aux-components/LeadsCampaignsProgress'
import {LoadingAnimation} from '../../../../utils/components/loading/LoadingAnimation'
import {DateRangePickerFull} from '../../../../utils/components/date-pickers/DateRangePickerFull'
import {LeadsRegistersModal} from './modals/LeadsRegistersModal'

// ---------------------------------------------------------------------------------------

// Importamos las funciones para conectarnos a los endpoints a usar en este componente
import {leadsReportDataPetition} from './services/_LeadsReportApi'

// ---------------------------------------------------------------------------------------

// Importamos interfaces a utilizar en este componente
import {ProductList} from '../../../../utils/interfaces/_InterfacesTypes'
import {LeadReport, LeadsByCampaigns} from './interfaces/leadsReportTypes'

// ---------------------------------------------------------------------------------------

// Importamos constantes a utilizar en este componente
import {
  BucketChannelLogosURL,
  BucketMediaURL,
  DragonCem,
} from '../../../../utils/constants/_Constants'

// ---------------------------------------------------------------------------------------

// Importamos funciones para este componente
import {
  getFileLogoForCampaign,
  sessionExpired,
  setPageTitle,
  dateFormatterTextualEnTwo,
  dateFormatterISO8601Variant,
  handleCopyToClipboard,
} from '../../../../utils/functions/_Functions'

// Montamos componente LeadsReport
const LeadsReport: FC = () => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  // Creamos un estado inicial para nuestro objeto que contendra la data del reporte
  const initialLeadsReportData: LeadReport = {
    leadsByCampaigns: [
      {
        duplicateLeads: 0,
        leadCampaignId: '',
        leadCampaignName: '',
        totalLeads: 0,
        leadCampaignRole: '',
        leadCampaignType: '',
        leadCampaignMedium: '',
        originalLeads: 0,
      },
    ],
    dataForChart: [
      {
        leadMonth: '',
        totalLeads: 0,
        originalLeads: 0,
        duplicatedLeads: 0,
      },
    ],
    productName: '',
    productBanner: '',
  }

  // Valores iniciales para el rango de fechas
  const initialStartDate: Date = new Date(new Date().setDate(new Date().getDate() - 30))
  const initialEndDate: Date = new Date()

  // Constantes que usaremos para manejar las variables para los labels que muestran la fecha
  const [labelStartDate, setLabelStartDate] = useState<Date>(initialStartDate)
  const [labelEndDate, setLabelEndDate] = useState<Date>(initialEndDate)

  // Constantes que usaremos para manejar las fechas que enviaremos a la peticion
  const [startDate, setStartDate] = useState<string>(dateFormatterISO8601Variant(initialStartDate))
  const [endDate, setEndDate] = useState<string>(dateFormatterISO8601Variant(initialEndDate))

  // Manejador para la disponibilidad del dateRangePicker
  const [dataRangeState, setDataRangeState] = useState<boolean>(true)

  // Obtenemos propiedades del contexto de usuario
  const {user, token, updateToken} = useContext(UserContext)
  const clientId: string = user.cli_id
  const lvlUser: string = user.user_type
  const userToken: string = token

  // Manejamos el id y el nombre del producto seleccionado
  const [productIdSelected, setProductIdSelected] = useState<string>('')
  const [productNameSelected, setProductNameSelected] = useState<string>('')
  const [productsDropdownState, setProductsDropdownState] = useState<boolean>(false)
  const [campaignIdSelected, setCampaignIdSelected] = useState<string>('')

  // Manejamos la data para el arreglo de campañas
  const [campaignData, setCampaignData] = useState<LeadsByCampaigns[]>([])
  // Arreglo con los conteos de leads duplicados
  const [duplicateLeadsArrayValue, setDuplicateLeadsArrayValue] = useState<number[]>([])
  // Arreglo con los conteos de leads originales
  const [originalLeadsValue, setOriginalLeadsValue] = useState<number[]>([])
  // Arreglo con los conteos de leads totales
  const [totalLeadsValue, setTotalLeadsValue] = useState<number[]>([])
  // Arreglo con los meses para la grafica
  const [leadMonthArrayValue, setLeadMonthArrayValue] = useState<string[]>([])
  // Manejamos la cadena para el banner de producto a mostrar
  const [urlForBanner, setUrlForBanner] = useState<string>('')
  // Manejamos la categoria para los modales
  const [category, setCategory] = useState<string>('');

  // Manejamos el lsitado de productos para el dropdown
  const [productsList, setProductsList] = useState<ProductList[]>([])

  // Definición  de constante para la variable con el array con la información de los leads
  const [leadsReportData, setLeadsReportData] = useState<LeadReport>(initialLeadsReportData)

  // Constante para el manejo de estado de la animacion de carga
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // Manejador estatus y nombre del modal
  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalTitle, setModalTitle] = useState<string>('')

  // Constantes para tooltips de botones
  const renderTooltip = (props: TooltipProps): JSX.Element => (
    <Tooltip {...props}>Copiar id de la campaña</Tooltip>
  )
  const renderTooltipProduct = (props: TooltipProps): JSX.Element => (
    <Tooltip {...props}>Copiar id del producto</Tooltip>
  )

  // Funcion para verificar si existe un dato en la propiedad productBanner
  const checkLeadsReport = (): void => {
    leadsReportData.dataForChart.length === 0 &&
      setInfoMessageLoading('No se encontro data para mostrar')
  }

  // Constante para el manejo de estado de la animacion de carga
  const [infoMessageLoadingP, setInfoMessageLoadingP] = useState<string>('')
  const [isLoadingP, setIsLoadingP] = useState<boolean>(false)

  // Funcion para conectarnos al endpoint que nos devuelve la data del reporte
  const getLeadsReportData = async (
    productId: string,
    startDate: string,
    endDate: string
  ): Promise<void> => {
    // Limpiamos la data para obtener datos nuevos
    setLeadMonthArrayValue([])
    setDuplicateLeadsArrayValue([])
    setOriginalLeadsValue([])
    setTotalLeadsValue([])
    setCampaignData([])
    setUrlForBanner('')

    setIsLoading(true)

    try {
      const {
        data: jsonData,
        code,
        msg,
      } = await leadsReportDataPetition(userToken, productId, startDate, endDate)

      if (code === 401 && msg === 'Token no valido') {
        setLeadsReportData(initialLeadsReportData)

        sessionExpired('La sesion ha caducado')
        return
      } else {
        const leadsReportData: LeadReport = jsonData.data
        const userTokenNew: string = jsonData.token

        updateToken(userTokenNew)

        // console.log({ leadsReportData })

        setLeadsReportData(leadsReportData)

        // Obtener arrays de valores de dataForChart
        const duplicateLeadsValues: number[] = leadsReportData.dataForChart.map(
          (item) => item.duplicatedLeads
        )

        const originalLeadsValues: number[] = leadsReportData.dataForChart.map(
          (item) => item.originalLeads
        )
        const totalLeadsValues: number[] = leadsReportData.dataForChart.map(
          (item) => item.totalLeads
        )
        const leadMonthValues: string[] = leadsReportData.dataForChart.map((item) => item.leadMonth)

        const campaigns: LeadsByCampaigns[] = leadsReportData.leadsByCampaigns.flatMap(
          (campaign) => campaign
        )
        const banner: string = BucketMediaURL + leadsReportData.productBanner

        setLeadMonthArrayValue(leadMonthValues)
        setDuplicateLeadsArrayValue(duplicateLeadsValues)
        setOriginalLeadsValue(originalLeadsValues)
        setTotalLeadsValue(totalLeadsValues)
        setCampaignData(campaigns)
        setUrlForBanner(banner)
        setIsLoading(false)
        setIsLoadingP(false)
        checkLeadsReport()
      }
    } catch (error) {
      console.error('Hubo un error en la peticion: ', error)
      setLeadsReportData(initialLeadsReportData)
      setIsLoading(false)
      setIsLoadingP(false)
      setInfoMessageLoading('No se encontró información para mostrar')
      // throw error
    }
  }

  // Funcion para determinar el color a mostrar segun el tipo de campaña
  const getClassForLeadCampaignType = (leadCampaignType: string): string => {
    switch (leadCampaignType) {
      case 'Online':
        return 'text-info'
      case 'Offline':
        return 'text-danger'
      case 'WhatsApp':
        return 'text-success'
      default:
        return 'text-success'
    }
  }

  // Manejamos las acciones que queremos para cuando se seleccione un producto
  const handleProductChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setIsLoadingP(true)
    let productId: string = e.target.value

    if (productId) {
      setDataRangeState(false)
      setProductIdSelected(productId)
      setProductNameSelected(e.target.options[e.target.selectedIndex].text)
      getLeadsReportData(productId, startDate, endDate)
    } else {
      setDataRangeState(true)
      setProductIdSelected('')
      setProductNameSelected('')
    }
  }

  // Manejamos las acciones que queremos para cuando se interactue con las fechas del DateRange
  const handleDates = (): void => {
    const startDateNew: string = dateFormatterISO8601Variant(labelStartDate)
    const endDateNew: string = dateFormatterISO8601Variant(labelEndDate)

    setStartDate(startDateNew)
    setEndDate(endDateNew)

    getLeadsReportData(productIdSelected, startDateNew, endDateNew)
  }

  // Funcion para abrir el modal
  const openModal = (title: string, campId: string, origin: string) => {
    setModalTitle(title)
    setCampaignIdSelected(campId)
    setCategory(origin)
    setShowModal(true)
  }

  // Funcion para cerrar el modal
  const closeModal = () => {
    setShowModal(false)
  }

  // Seteamos el nombre de la pagina al montar el componente
  useEffect(() => {
    setPageTitle(`Reporte de Leads | ${DragonCem}`)
  }, [])

  // Funcion con la que recorremos el arreglo con la data
  // de campañas para crear un registro para las tarjetas
  const renderCampaigns = (): JSX.Element[] => {
    const rows: JSX.Element[] = []

    for (let i = 0; i < campaignData.length; i += 4) {
      const rowCampaigns = campaignData.slice(i, i + 4)

      rows.push(
        <div key={i} className='d-flex row mb-3'>
          {rowCampaigns.map((campaign: any, index: number) => (
            <div key={index} className='col-3'>
              <div className='card card-custom'>
                <div className='card-header d-flex align-items-center'>
                  <div className='card-title d-flex align-items-center flex-column flex-sm-row'>
                    <div className='d-flex align-items-center mb-2 mb-sm-0 me-sm-5'>
                      <div className='symbol symbol-45px w-45px bg-light me-3'>
                        <img
                          src={
                            BucketChannelLogosURL +
                            getFileLogoForCampaign(campaign.leadCampaignRole)
                          }
                          alt={campaign.leadCampaignRole}
                          className='p-1'
                        />
                      </div>
                      <div>
                        <h2 className='fs-6 text-muted mb-0 me-2'>Nombre:</h2>
                        <h2 className='fs-6 fw-bolder mb-0'>{campaign.leadCampaignName}</h2>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-column flex-sm-row'>
                    <div className='mb-1 me-sm-1'>
                      <h2 className='fs-6 text-muted mb-0'>Tipo:</h2>
                      <h2 className='fs-6 text-muted mb-0'>Medio:</h2>
                    </div>
                    <div>
                      <h2
                        className={`fs-6 fw-bolder mb-0 ${getClassForLeadCampaignType(
                          campaign.leadCampaignType
                        )}`}
                      >
                        {campaign.leadCampaignType}
                      </h2>
                      <h2 className='fs-6 fw-bolder mb-0'>{campaign.leadCampaignMedium}</h2>
                    </div>
                  </div>
                </div>

                <div className='card-body'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0'>Id:</h5>
                      <OverlayTrigger placement='top' overlay={renderTooltip}>
                        <button
                          className='btn badge btn-light-primary ms-2'
                          onClick={() => handleCopyToClipboard(`${campaign.leadCampaignId}`)}
                        >
                          {campaign.leadCampaignId}
                        </button>
                      </OverlayTrigger>
                    </div>

                    <li className='d-flex align-items-center py-2'>
                      <span className='bullet bullet-vertical bg-success me-3'></span>
                      Leads originales:
                      <button
                        className='btn btn-clean btn-sm btn-icon btn-light-success ms-3'
                        onClick={() => {
                          openModal('Leads originales', campaign.leadCampaignId, 'orignals')
                        }}
                      >
                        {campaign.originalLeads}
                      </button>
                    </li>

                    <li className='d-flex align-items-center py-2'>
                      <span className='bullet bullet-vertical bg-warning me-3'></span>
                      Leads duplicados:
                      <button
                        className='btn btn-clean btn-sm btn-icon btn-light-warning ms-3'
                        onClick={() => {
                          openModal('Leads duplicados', campaign.leadCampaignId, 'duplicated')
                        }}
                      >
                        {campaign.duplicateLeads}
                      </button>
                      {/* <span className='badge badge-circle badge-outline badge-warning fw-bolder ms-3'>
                        {campaign.duplicateLeads}
                      </span> */}
                    </li>

                    <li className='d-flex align-items-center py-2'>
                      <span className='bullet bullet-vertical bg-primary me-3'></span>
                      Leads totales:
                      <button
                        className='btn btn-clean btn-sm btn-icon btn-light-primary ms-3'
                        onClick={() => {
                          openModal('Leads totales', campaign.leadCampaignId, 'all')
                        }}
                      >
                        {campaign.totalLeads}
                      </button>
                      {/* <span className='badge badge-circle badge-outline badge-primary fw-bolder ms-3'>
                        {campaign.totalLeads}
                      </span> */}
                    </li>

                    <LeadsCampaignsProgress
                      duplicateLeads={campaign.duplicateLeads}
                      originalLeads={campaign.originalLeads}
                      totalLeads={campaign.totalLeads}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    }

    return rows
  }

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center mb-2'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2'>
                {/* Reporte de Leads <span className='fs-4 text-gray-500 fw-bold ms-1'>{dateFormatterTextualEnTwo(labelStartDate) + ' a ' + dateFormatterTextualEnTwo(labelEndDate)} </span> */}
                Reporte de leads
              </h1>
            </div>
          </div>
        </div>

        <div className='card card-custom d-flex mb-5'>
          <div className='card-body d-flex'>
            <div className='col-3'>
              <div className='card' id='Banner'>
                {!productIdSelected && (
                  <>
                    <div
                      className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                      role='alert'
                    >
                      <h5 className='mb-1 text-center'>Selecciona un producto</h5>
                    </div>
                  </>
                )}

                {isLoadingP && productIdSelected &&(
                  <div className='py-12'>
                    <LoadingAnimation
                      alignment='center'
                      label='Obteniendo header del producto...'
                    />
                  </div>
                )}

                {!isLoadingP &&
                  productIdSelected &&
                  leadsReportData.productBanner !== '' && ( // Verifica si urlForBanner no está vacío ni es ''
                    <img
                      className='card-img-top rounded border border-dark'
                      src={BucketMediaURL+leadsReportData.productBanner}
                      alt='Banner Image'
                      style={{width: '100%', height: 'auto'}}
                    />
                  )}

                {!isLoadingP && productIdSelected && leadsReportData.productBanner === '' && (
                  <>
                    <div
                      className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                      role='alert'
                    >
                      <h5 className='mb-1 text-center'>{infoMessageLoading}</h5>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className='col-5 text-center'>
              <h3>{productNameSelected}</h3>

              <OverlayTrigger placement='top' overlay={renderTooltipProduct}>
                <button
                  className='btn badge btn-light-primary ms-2 mb-3'
                  onClick={() => handleCopyToClipboard(productIdSelected)}
                >
                  {productIdSelected}
                </button>
              </OverlayTrigger>

              <div className='d-flex justify-content-center'>
                <span className='badge text-white bg-info me-2'>Online</span>
                <span className='badge text-white bg-success me-2'>Whatsapp</span>
                <span className='badge text-white bg-danger'>Offline</span>
              </div>
            </div>

            <div className='col-4 '>
              {/* Componente con el dropdown de seleccion de producto */}
              <ProductsByUserDropdown
                updateToken={updateToken}
                clientId={clientId}
                userToken={userToken}
                sessionExpired={sessionExpired}
                productIdSelected={productIdSelected}
                handleProductChange={handleProductChange}
                productsList={productsList}
                setProductsList={setProductsList}
                productsDropdownState={productsDropdownState}
                dropdownClass='form-select form-select-sm form-select-solid mb-2 '
                allProductsOption={false}
                onlyActiveProducts={true}
              />

              <div className='d-flex'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-secondary me-1'
                  disabled={dataRangeState}
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <KTIcon iconName='calendar' className='fs-2' />
                </button>

                <button
                  type='button'
                  className='btn btn-sm btn-secondary disabled'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  {'Rango actual: ' +
                    dateFormatterTextualEnTwo(labelStartDate) +
                    '  -  ' +
                    dateFormatterTextualEnTwo(labelEndDate)}
                </button>

                {/* Componente con el DateRangePicker */}
                <DateRangePickerFull
                  initialStartDate={initialStartDate}
                  initialEndDate={initialEndDate}
                  months={2}
                  handleDates={handleDates}
                  setLabelStartDate={setLabelStartDate}
                  setLabelEndDate={setLabelEndDate}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='row gx-2 mb-6'>
          <div className='col-xl-12'>
            <div className='card card-custom px-8 py-10 h-100'>
              <div className='row-justify-content-center mb-2'>
                <div className='col-md-12'>
                  {!productIdSelected && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>Selecciona un producto</h5>
                      </div>
                    </>
                  )}

                  {isLoadingP && productIdSelected && (
                    <div className='py-12'>
                      <LoadingAnimation
                        alignment='center'
                        label='Obteniendo registros del reporte de leads...'
                      />
                    </div>
                  )}

                  {!isLoadingP && productIdSelected && leadsReportData.dataForChart.length > 1 && (
                    <>
                      <LeadsReportChart
                        duplicateLeads={duplicateLeadsArrayValue}
                        originalLeads={originalLeadsValue}
                        totalLeads={totalLeadsValue}
                        datesData={leadMonthArrayValue}
                        productIdSelected={productIdSelected}
                      />
                    </>
                  )}

                  {!isLoadingP &&
                    productIdSelected &&
                    leadsReportData.dataForChart.length === 1 && (
                      <>
                        <div
                          className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                          role='alert'
                        >
                          <h5 className='mb-1 text-center'>{infoMessageLoading}</h5>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row gx-2 mb-6'>
          <div className='col-xl-12'>
            <div className='card card-custom px-8 py-10 h-100'>
              <div className='row-justify-content-center mb-2'>
                <div className='col-md-12'>
                  {!productIdSelected && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>Selecciona un producto</h5>
                      </div>
                    </>
                  )}

                  {isLoading && (
                    <div className='py-12'>
                      <LoadingAnimation
                        alignment='center'
                        label='Obteniendo registros del reporte de leads...'
                      />
                    </div>
                  )}

                  {!isLoading && productIdSelected && urlForBanner && (
                    <>
                      <div
                        className='bg-transparent'
                        style={{maxHeight: '320px', overflowY: 'scroll'}}
                      >
                        {renderCampaigns()}
                      </div>
                    </>
                  )}

                  {!isLoading && productIdSelected && !urlForBanner && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>{infoMessageLoading}</h5>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal para mostrar los registros dependiendo la categoria */}
      <Modal
        show={showModal}
        className='modal-xl'
        scrollable
        onHide={() => {
          closeModal()
        }}
      >
        <LeadsRegistersModal
          modalTitle={modalTitle}
          closeModal={closeModal}
          startDate={startDate}
          endDate={endDate}
          clientId={clientId}
          userToken={userToken}
          updateToken={updateToken}
          productIdSelected={productIdSelected}
          campaignIdSelected={campaignIdSelected}
          category={category}
        />
      </Modal>
    </>
  )
}

export {LeadsReport}
