import { apiDragon, DragonMainApi, EPGradesReportLeads } from "../../../../../utils/constants/_Constants";
import { apiPetitions } from "../../../../../../_metronic/helpers/apis";

export async function getLeadsFromCountsGradesReport(userToken, leadsArray, selectedIdProduct, camId, startDate, endDate){
    try{
        const response = await apiPetitions (apiDragon,DragonMainApi+EPGradesReportLeads+selectedIdProduct, {
            ...(camId && camId !== '' && {camId: camId}),
            dateStart: startDate,
            dateEnd: endDate,
            leadIds: leadsArray,
            
        },{
            'Content-Type': 'application/json',
            'x-token': userToken
        },
    'post');

    const jsonDataGradesReportLeads = response.data

    console.log(jsonDataGradesReportLeads)

    return jsonDataGradesReportLeads
    }catch(error){
        console.error('Hubo un error en la peticion: ', error)
    }
}

export async function getLeadsFromCategoryGradesReport(userToken, optionNumberSelected, selectedIdProduct, camId, startDate, endDate){
    try{
        const response = await apiPetitions (apiDragon,DragonMainApi+EPGradesReportLeads+selectedIdProduct, {
            ...(camId && camId !== '' && {camId: camId}),
            dateStart: startDate,
            dateEnd: endDate,
            score: optionNumberSelected
        },{
            'Content-Type': 'application/json',
            'x-token': userToken
        },
    'post')

    const jsonDataGradesReportLeads = response.data
    console.log(jsonDataGradesReportLeads)

    return jsonDataGradesReportLeads
    }catch(error){
        console.error('Hubo un error en la peticion: ', error)
    }
}