import {DragonMainApi, EPClientReasign, apiDragon} from '../../../utils/constants/_Constants'

import {apiPetitions} from '../../../../_metronic/helpers/apis'

async function clientReasign(clientId: string, mail: string | undefined, status: boolean, lvl_access: number, userType: string, reasignReason: string, userToken: string){

    try {
        const data = await apiPetitions(apiDragon, `${DragonMainApi+EPClientReasign+clientId}`, {

            mail,
            status,
            lvl_access,
            userType,

        }, {
            'Content-Type': 'application/json',
            'x-token': userToken
        }, 'put');
        
        const jsonDataClientReasign = data;

        return jsonDataClientReasign;
    
    } catch (error) {
        throw error;
    }

}

export {clientReasign};