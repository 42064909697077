import React, { FC, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DropdownExportLogs } from "../../../../../../_metronic/partials/content/dropdown/DropdownExportLogs";
import {
    Column,
    Table,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    FilterFn,
    ColumnDef,
    flexRender,
} from '@tanstack/react-table'
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import { Button, Dropdown, DropdownButton, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { KTIcon } from "../../../../../../_metronic/helpers";
import { UserContext } from "../../../../../app-context/user-context/UserContext";
import { LoadingAnimation } from "../../../../../utils/components/loading/LoadingAnimation";
import { Lead, LeadData } from "../interfaces/gradesReportTypes";
import { getLeadsFromCountsGradesReport } from "../services/_GradesReportLeadsApi";
import { sessionExpired, dateFormatterDateTimeExtended } from "../../../../../utils/functions/_Functions";
import { LeadsRegistersExport } from "../../../../../utils/components/export-dropdowns/leads/LeadsRegistersExport";

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

type Props = {
    cardSelected: string
    gradesReportItemByProduct: any
    selectedIdProduct: any
    selectedIdCampaign: any
    startDate: any
    endDate: any
}

export const GradesReportCountsTable: FC<Props> = ({
    cardSelected,
    gradesReportItemByProduct,
    selectedIdProduct,
    selectedIdCampaign,
    startDate,
    endDate,
}) => {
    console.log('renderizamos componente tabla')

    //Algunas constantes desde el userContext
    const { user, token, updateToken } = useContext(UserContext)
    const clientId: string = user.cli_id
    const userToken: string = token

    const handleCopyToClipboard = (text: string) => {
        // Intentar copiar el texto al portapapeles
        navigator.clipboard.writeText(text).catch((error) => {
            console.error('Error al copiar al portapapeles:', error)
            alert('Error al copiar al portapapeles. Por favor, inténtelo de nuevo.')
        })
    }

    const renderTooltip = (props) => <Tooltip {...props}>Copiar id del registro</Tooltip>

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')
    const [dataToExport, setDataToExport] = useState<Lead[]>([]);

    const [leadsList, setLeadsList] = useState<Lead[]>([])

    //Constante para el manejo de estado de la animacion de carga
    const [isLoading, setIsLoading] = useState<boolean>(true);
    //Constante para el manejo de estado del mensaje post carga de registros
    const [infoMessageLoading, setInfoMessageLoading] = useState<string>("");

    //Función para definir la data que se va a exportar
    const handleSetData = () => {
        const dataToExport = table.getRowModel().flatRows.map((row) => row.original);
        setDataToExport(dataToExport)
    }

    //Funcion para navegar a la apgina de detalle de lead pasando el objeto lead
    const navigate = useNavigate()
    const toLeadDetail = (lead) => {
        navigate(`/lead-detail?leadId=${lead}`)
    }

    const checkLeadsListLength = () => {
        leadsList.length === 0 && setInfoMessageLoading("No se encontraron registros para mostrar")
    }

    const getLeadsByClient = async (leadsArray: string[]) => {

        console.log(leadsArray)
        console.log(selectedIdProduct)
        console.log(selectedIdCampaign)
        console.log(startDate)
        console.log(endDate)

        let camId: string = ''

        if (selectedIdCampaign === '0' || selectedIdCampaign === '') {
            camId = ''
        } else {
            camId = selectedIdCampaign
        }

        try {
            const {
                Data: jsonData, msg, code, token: userTokenNew
            } = await getLeadsFromCountsGradesReport(userToken, leadsArray, selectedIdProduct, camId, startDate, endDate)

            if (code === 401 && msg === 'Token no valido') {
                sessionExpired('La sesion ha caducado')

            } else {
                const leadsListReceived: Lead[] = jsonData

                console.log(userTokenNew)

                updateToken(userTokenNew)

                console.log(leadsListReceived)

                const leadRow: Lead[] = leadsListReceived.map((lead: Lead) => {
                    const leadData: LeadData = {
                        name: lead.lead_data.name,
                        lead_mail: lead.lead_data.lead_mail,
                        lead_phone: lead.lead_data.lead_phone,
                    }

                    const formattedDate: string = dateFormatterDateTimeExtended(lead.lead_date)

                    return {
                        lead_date: formattedDate,
                        lead_data: leadData,
                        lead_id: lead.lead_id,
                        seller_score: lead.seller_score,
                        dcfs: lead.dcfs,
                        mail_delivered: lead.mail_delivered,
                        call_recording: lead.call_recording,
                        wha_sent: lead.wha_sent,
                        lead_qa: lead.lead_qa,
                        crm_insert: lead.crm_insert,
                        lead_comments: lead.lead_comments,
                        seller_id: lead.seller_id,
                        seller_name: lead.seller_name,
                        pro_name: lead.pro_name,
                        pro_id: lead.pro_id,
                        cam_id: lead.cam_id,
                        cam_name: lead.cam_name,
                        medium: lead.medium,
                        lead_endpoint: 'Zapier',
                        leadGrade: lead.leadGrade,
                        leadGradeComments: lead.leadGradeComments,
                        leadIscore: lead.leadIscore,
                        lead_original: '',
                        leadUrl: lead.leadUrl,
                        spamFlag: lead.spamFlag,
                    }
                })

                setLeadsList(leadRow)
                setIsLoading(false)
                checkLeadsListLength()

            }
        } catch (error) {
            console.error('Error receiving leads list: ', error)
            setInfoMessageLoading('Ocurrio un error al obtener el listado de leads')
            setIsLoading(false)
        }

    }

    //Disparamos la funcion con un useEffect
    useEffect(() => {

        let leadsArray: string[] = []
        if (cardSelected === 'noDuplicate') {
            leadsArray = gradesReportItemByProduct.totalLeadsNoDuplicateIds.map(item => item.lead_id);
        } else if (cardSelected === 'gradesCount') {
            leadsArray = gradesReportItemByProduct.totalLeadsIdGradesCount.map(item => item.lead_id);

        } else if (cardSelected === 'multipleGrades') {
            leadsArray = gradesReportItemByProduct.multipleGradesLeads.map(item => item.lead_id);

        } else if (cardSelected === 'unratedGrades') {
            leadsArray = gradesReportItemByProduct.unratedLeads.map(item => item.lead_id);

        }

        getLeadsByClient(leadsArray)

    }, [])

    const columns = React.useMemo<ColumnDef<Lead, any>[]>(
        () => [
            {
                header: 'Registro de leads',
                footer: (props) => props.column.id,
                columns: [
                    {
                        accessorKey: 'lead_id',
                        cell: (info) => (
                            <button className="btn btn-light-primary" onClick={() => {
                                toLeadDetail(info.row.original.lead_id)
                            }}>{info.getValue()}</button>
                        ),
                        header: () => <span>Id</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: 'lead_date',
                        cell: (info) => info.getValue(),
                        header: () => <span>Fecha</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: 'pro_name',
                        cell: (info) => (
                            <>
                                <p className="fw-bold">{info.row.original.pro_name}</p>
                                <p>{info.row.original.cam_name}</p>
                            </>
                        ),
                        filterValue: (rows, id, filterValue) => {
                            return rows.filter((row) => row.original.pro_name.includes(filterValue))
                        },
                        header: () => <span>Producto | Campaña</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: 'lead_data.name',
                        cell: (info) => (
                            <>
                                <p className="fw-bold">{info.row.original.lead_data.name}</p>
                                <p>{info.row.original.lead_data.lead_mail}</p>
                                <p>{info.row.original.lead_data.lead_phone}</p>
                            </>
                        ),
                        filterValue: (rows, id, filterValue) => {
                            return rows.filter((row) => row.original.lead_data.name.includes(filterValue))
                        },
                        header: () => <span>Datos del lead</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: 'leadGrade',
                        cell: (info) => (
                            <>
                                <p className="fw-bold">{info.row.original.leadGrade}</p>
                                <p>{info.row.original.leadGradeComments}</p>
                            </>
                        ),
                        filterValue: (rows, id, filterValue) => {
                            return rows.filter((row) => row.original.leadGrade.include(filterValue))
                        },
                        header: () => <span>Calificacion</span>,
                        footer: (props) => props.column.id,
                    }
                ],
            },
        ],
        []
    )

    const table = useReactTable({
        data: leadsList,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    React.useEffect(() => {
        if (table.getState().columnFilters[1]?.id === 'name') {
            if (table.getState().sorting[0]?.id !== 'name' || table.getState().sorting[0]?.desc) {
                table.setSorting([{ id: 'name', desc: false }])
            }
        }
    }, [table.getState().columnFilters[1]?.id])

    return (
        <>

            <div className='card card-custom'>
                {isLoading && <div className='py-12'><LoadingAnimation alignment='center' label='Generando tabla de leads...' /></div>}

                {!isLoading && leadsList.length > 0 && (
                    <div className='p-2'>
                        <div className="card-header pt-6 pb-6">
                            <div className="d-flex">
                                <DebouncedInput
                                    value={globalFilter ?? ''}
                                    onChange={(value) => setGlobalFilter(String(value))}
                                    className='form-control form-control-solid w-250px me-3'
                                    placeholder='Buscar registro...'
                                />

                                <LeadsRegistersExport
                                    dataToExport={dataToExport}
                                    title={`Leads ${cardSelected}`}
                                    handleSetData={handleSetData}
                                    disabled={false}
                                    size='btn-sm'
                                />

                            </div>
                        </div>
                        <div className='h-2' />
                        <div className='card-body table-responsive'>
                            <table className='table table-row-bordered gs-3 gy-4 gx-12'>
                                <thead>
                                    {table.getHeaderGroups().map((headerGroup) => (
                                        <tr
                                            key={headerGroup.id}
                                            className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                                        >
                                            {headerGroup.headers.map((header) => {
                                                return (
                                                    <th key={header.id} colSpan={header.colSpan}>
                                                        {header.isPlaceholder ? null : (
                                                            <>
                                                                <div
                                                                    {...{
                                                                        className: header.column.getCanSort()
                                                                            ? 'cursor-pointer select-none'
                                                                            : '',
                                                                        onClick: header.column.getToggleSortingHandler(),
                                                                    }}
                                                                >
                                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                                    {{
                                                                        asc: ' 🔼',
                                                                        desc: ' 🔽',
                                                                    }[header.column.getIsSorted() as string] ?? null}
                                                                </div>
                                                                {header.column.getCanFilter() ? (
                                                                    <div>
                                                                        <Filter column={header.column} table={table} />
                                                                    </div>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </th>
                                                )
                                            })}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    {table.getRowModel().rows.map((row) => {
                                        return (
                                            <tr key={row.id}>
                                                {row.getVisibleCells().map((cell) => {
                                                    return (
                                                        <td key={cell.id}>
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className='h-2' />

                        <div className='card-footer'>
                            <div className='d-flex justify-content-between gap-2'>
                                <div id='SwitchPages'>
                                    <button
                                        className='btn btn-primary border rounded p-1'
                                        onClick={() => table.setPageIndex(0)}
                                        disabled={!table.getCanPreviousPage()}
                                    >
                                        {'<<'}
                                    </button>
                                    <button
                                        className='btn btn-primary border rounded p-1'
                                        onClick={() => table.previousPage()}
                                        disabled={!table.getCanPreviousPage()}
                                    >
                                        {'<'}
                                    </button>
                                    <button
                                        className='btn btn-primary border rounded p-1'
                                        onClick={() => table.nextPage()}
                                        disabled={!table.getCanNextPage()}
                                    >
                                        {'>'}
                                    </button>
                                    <button
                                        className='btn btn-primary border rounded p-1'
                                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                        disabled={!table.getCanNextPage()}
                                    >
                                        {'>>'}
                                    </button>
                                </div>

                                <div id='PagesFinder' className='text-center'>
                                    {' '}
                                    <span className='flex items-center gap-1'>
                                        Página{' '}
                                        <strong>
                                            {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
                                        </strong>{' '}
                                        | Ir a la página:
                                        <input
                                            type='number'
                                            defaultValue={table.getState().pagination.pageIndex + 1}
                                            onChange={(e) => {
                                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                                table.setPageIndex(page)
                                            }}
                                            className='form-control form-control-solid w-16'
                                        />
                                    </span>
                                </div>

                                <div id='showFiles' className='text-center'>
                                    <select
                                        className='form-select form-select-solid'
                                        value={table.getState().pagination.pageSize}
                                        onChange={(e) => {
                                            table.setPageSize(Number(e.target.value))
                                        }}
                                    >
                                        {[10, 20, 30, 40, 50].map((pageSize) => (
                                            <option key={pageSize} value={pageSize}>
                                                Mostrar {pageSize} registros
                                            </option>
                                        ))}
                                    </select>
                                    <p>{table.getPrePaginationRowModel().rows.length} Registros en total</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {!isLoading && leadsList.length === 0 && (
                    <>
                        <div
                            className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                            role='alert'
                        >
                            <h5 className='mb-1 text-center'>{infoMessageLoading}</h5>
                        </div>
                    </>)}
            </div>
        </>
    )

}

function Filter({ column, table }: { column: Column<any, unknown>; table: Table<any> }) {
    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue()

    const sortedUniqueValues = React.useMemo(
        () =>
            typeof firstValue === 'number'
                ? []
                : Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [column.getFacetedUniqueValues()]
    )

    return typeof firstValue === 'number' ? (
        <div>
            <div className='flex space-x-2'>
                <DebouncedInput
                    type='number'
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[0] ?? ''}
                    onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
                    placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
                        }`}
                    className='w-24 border shadow rounded'
                />
                <DebouncedInput
                    type='number'
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[1] ?? ''}
                    onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
                    placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
                        }`}
                    className='w-24 border shadow rounded'
                />
            </div>
            <div className='h-1' />
        </div>
    ) : (
        <>
            <datalist id={column.id + 'list'}>
                {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                    <option value={value} key={value} />
                ))}
            </datalist>
            <DebouncedInput
                type='text'
                value={(columnFilterValue ?? '') as string}
                onChange={(value) => column.setFilterValue(value)}
                placeholder={`Buscar... (${column.getFacetedUniqueValues().size})`}
                className='w-36 border shadow rounded'
                list={column.id + 'list'}
            />
            <div className='h-1' />
        </>
    )
}

// A debounced input react component
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}