import { apiDragon, DragonMainApi, EPLeadsListByClient, EPLeadDetailInfo } from "../../../utils/constants/_Constants";

import { apiGetDeletePetitions } from "../../../../_metronic/helpers/apis";

export async function leadsListByClient(userToken: string, startDate: any, endDate: any, clientId: string) {

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPLeadsListByClient+clientId}`, {
            /* Los parametros se mandan como undefined 
            ya que estos se envian solo en peticiones de eliminacion 'delete' */
            dateBegin: startDate,
            dateEnd: endDate
        }, {
            'Content-Type': 'application/json',
            'x-token': userToken
        },
            'get'
        );

        const jsonDataLeadsList = data;

        return jsonDataLeadsList;

    } catch (error) {
        throw error;
    }
}

export async function leadDetailInfo (userToken: string, leadId: string) {

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPLeadDetailInfo+leadId}`, {
            /* Los parametros se mandan como undefined 
            ya que estos se envian solo en peticiones de eliminacion 'delete' */
            undefined 
        }, {
            'Content-Type': 'application/json',
            'x-token': userToken
        },
            'get'
        );

        const jsonLeadDetailInfo = data;

        return jsonLeadDetailInfo;

    }catch (error){
        throw error;
    }

}