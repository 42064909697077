import {DragonMainApi, EPLeadNew, apiDragon} from '../../../utils/constants/_Constants'

import {apiPetitions} from '../../../../_metronic/helpers/apis'

async function leadNew(
  userToken: string,
  name: string,
  phone: string,
  mail: string,
  campaign: string,
  userAssigned: string,
  comments: string
) {
  try {
    const response = await apiPetitions(
      apiDragon,
      `${DragonMainApi + EPLeadNew}`,
      {
        name,
        phone,
        mail,
        campaign,
        userAssigned,
        comments,
      },
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'post'
    )

    const jsonDataLeadNew = response
    return jsonDataLeadNew
  } catch (error) {
    throw error
  }
}

export {leadNew}
