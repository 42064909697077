import {useState, ChangeEvent, useEffect, useContext, ReactElement} from 'react'
import {useIntl} from 'react-intl'
import {sessionExpired} from '../../../../utils/functions/_Functions'
import {ValidateEmailAndWhats} from '../../../../utils/services/_ValidateEmailAndWhats'
import {UserContext} from '../../../../app-context/user-context/UserContext'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css' // Asegúrate de importar también los estilos
import '../../../../../_metronic/assets/styles/PhoneInput.css'

import {Step1Props} from '../../interfaces/clientsTypes'

import {Step1Errors} from '../../interfaces/clientsTypes'
import {allowedCountries} from '../../../../utils/constants/_Constants'

function Step1({onNext, prevData, setStep1Data}: Step1Props) {
  const [clientUserName, setClientUserName] = useState(prevData?.clientUserName || '')
  const [clientUserMail, setClientUserMail] = useState(prevData?.clientUserMail || '')
  const [clientPass1, setClientPass1] = useState(prevData?.clientPass1 || '')
  const [clientPass2, setClientPass2] = useState(prevData?.clientPass2 || '')
  const [clientCallPhone, setClientCallPhone] = useState(prevData?.clientCallPhone || '')
  const [clientWhatsPhone, setClientWhatsPhone] = useState(prevData?.clientWhatsPhone || '')

  const [errors, setErrors] = useState<Step1Errors>({
    clientUserName: '',
    clientUserMail: '',
    clientPass1: '',
    clientPass2: '',
    clientCallPhone: '',
    clientWhatsPhone: '',
  })

  const {user, token, updateToken} = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token

  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const validateInputs = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Inicializa los errores con valores predeterminados
    const newErrors: Step1Errors = {
      clientUserName: !clientUserName ? 'El nombre es obligatorio' : '',
      clientUserMail: !clientUserMail
        ? 'El email es obligatorio'
        : !emailRegex.test(clientUserMail)
        ? 'El email no es válido'
        : '',
      clientPass1: !clientPass1
        ? 'La contraseña es obligatoria'
        : clientPass1.length < 6
        ? 'La contraseña debe tener al menos 6 caracteres'
        : '',
      clientPass2: !clientPass2
        ? 'Confirma la contraseña'
        : clientPass2 !== clientPass1
        ? 'Las contraseñas no coinciden'
        : '',
      clientCallPhone: !clientCallPhone ? 'El telefono es obligatorio' : '',
      clientWhatsPhone: !clientWhatsPhone ? 'El whastApp es obligatorio' : '',
    }

    try {
      const {
        data: jsonData,
        code,
        msg,
      } = await ValidateEmailAndWhats(clientWhatsPhone, clientUserMail, userToken)

      // Inicializa el objeto para los nuevos errores
      let newErrorsUpdate = {...newErrors}

      if (code === 400 && msg && msg.whatsapp) {
        if (msg.whatsapp.msg === 'Ocurrió un error al validar el whatsapp del usuario') {
          newErrorsUpdate.clientWhatsPhone = 'El WhatsApp ya está registrado'
          console.log(msg.whatsapp.msg)
        }
      }

      if (code === 400 && msg && msg.mail) {
        if (msg.mail.msg === 'Hubo un error al validar este correo') {
          newErrorsUpdate.clientUserMail = 'El correo ya está registrado'
          console.log(msg.mail.msg)
        }
      }

      // Actualiza los errores
      setErrors(newErrorsUpdate)

      // Validación del mensaje y llamada a la función limpiarYRecargar
      if (msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
      }

      // Retorna si todos los nuevos errores son vacíos
      const isValid = Object.values(newErrorsUpdate).every((error) => error === '')
      return isValid
    } catch (error) {
      console.error('Error en el crear cliente', error)
      return false // Maneja el error según tus necesidades
    }
  }

  const handleNext = async () => {
    if (await validateInputs()) {
      onNext({
        clientUserName,
        clientUserMail,
        clientPass1,
        clientPass2,
        clientCallPhone,
        clientWhatsPhone,
      })

      setStep1Data({
        clientUserName,
        clientUserMail,
        clientPass1,
        clientPass2,
        clientCallPhone,
        clientWhatsPhone,
      })
    }
  }

  // Array de objetos de tipo CountryCode con los códigos de países que deseas mostrar

  return (
    <div>
      <h3>Usuario</h3>
      <span className='text-muted'>
        Ingresa los datos para crear el usuario que administrara este cliente.
      </span>

      <form>
        <div className='d-flex mt-4'>
          <div className='col-6 me-2'>
            <input
              type='text'
              id='clientUserName'
              placeholder='Nombre completo'
              name='nameuser'
              className='form-control'
              value={clientUserName}
              onChange={(e) => setClientUserName(e.target.value)}
            />
            <div className='text-danger mb-6'>{errors.clientUserName}</div>
          </div>

          <div className='col-6'>
            <input
              type='text'
              id='clientUserMail'
              placeholder='Correo electronico'
              name='mailuser'
              className='form-control'
              value={clientUserMail}
              onChange={(e) => setClientUserMail(e.target.value)}
            />
            <div className='text-danger mb-6'>{errors.clientUserMail}</div>
          </div>
        </div>

        <div className='d-flex'>
          <div className='col-6 me-2'>
            <input
              type='password'
              id='clientPass1'
              placeholder='Contraseña'
              className='form-control'
              name='password1'
              value={clientPass1}
              onChange={(e) => setClientPass1(e.target.value)}
            />
            <div className='text-danger mb-6'>{errors.clientPass1}</div>
          </div>

          <div className='col-6'>
            <input
              type='password'
              id='cli'
              placeholder='Confirme contraseña'
              className='form-control'
              name='password2'
              value={clientPass2}
              onChange={(e) => setClientPass2(e.target.value)}
            />
            <div className='text-danger mb-6'>{errors.clientPass2}</div>
          </div>
        </div>

        <div className='d-flex'>
          <div className='col-6 me-2'>
            <h3>Llamadas</h3>
            <span className='text-muted'>Ingresa el teléfono para llamadas</span>
            <PhoneInput
              placeholder='Ingresa el número telefónico'
              className='mt-4 form-control  input-test'
              name='phoneuser'
              value={clientCallPhone}
              onChange={(e) => setClientCallPhone(e?.toString() || '')}
              countryCallingCodeEditable={false}
              defaultCountry='MX' // Especifica el país por defecto si lo deseas
              international
              autoComplete='off'
              countries={allowedCountries}
            />
            <div className='text-danger mb-6'>{errors.clientCallPhone}</div>
          </div>

          <div className='col-6'>
            <h3>WhatsApp</h3>
            <span className='text-muted'>Ingresa el teléfono para whatsapp</span>
            <PhoneInput
              placeholder='Ingresa el Whatsapp'
              className='mt-4 form-control  input-test'
              name='whauser'
              value={clientWhatsPhone}
              onChange={(e) => setClientWhatsPhone(e?.toString() || '')}
              countryCallingCodeEditable={false}
              defaultCountry='MX' // Especifica el país por defecto si lo deseas
              international
              autoComplete='off'
              countries={allowedCountries}
            />
            <div className='text-danger'>{errors.clientWhatsPhone}</div>
          </div>
        </div>
      </form>

      <button className='btn btn-primary btn-sm mt-6' onClick={handleNext}>
        Siguiente
      </button>
    </div>
  )
}

export {Step1}
