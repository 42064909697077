// Importamos hooks de react que usaremos para este componente
import { useState, useContext } from "react";
// Importamos libreria para manejo de lenguaje
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from "../../../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------

// Importamos la funcion productsIscoreEditLead para conexion con API de productos
import { productsIscoreEditLead } from "../../services/_ProductsIscoreEditApi";

// ---------------------------------------------------------------------------------------

// Importamos funcion para vencimiento de sesion
import { sessionExpired } from "../../../../utils/functions/_Functions";

// ---------------------------------------------------------------------------------------

// Importamos las interfaces globales que usaremos para este modulo
import { AlertInfo } from "../../../../utils/interfaces/_InterfacesTypes";

// Iniciamos funcion para construir el componente LeadTabForm y recibimos props
function LeadTabForm({idProduct, formDataEditLead, setFormDataEditLead, getProductsRegisters, closeModalEditIscore}) {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl();

  //Algunas variables desde el usercontext
  const { token } = useContext(UserContext);
  const userToken = token;

  //Variable para el manejo de estado de mensaje de confirmacion o error
  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  });

  //Funcion para el manejo del valor de los inputs
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // Convierte el valor a un número usando parseInt
    const numericValue = parseInt(value, 10);
    
    setFormDataEditLead({
      ...formDataEditLead,
      [name]: numericValue,
    });
  };

  //Funcion para el envio de la informacion a travez de la peticion por API
  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    console.log('Current formDataEditLead values:', formDataEditLead);

    const updateLeadSeller = false;

    const emailValido = formDataEditLead.leadEmailValido;
    const emailAbierto = formDataEditLead.leadEmailAbierto;
    const emailEntregado = formDataEditLead.leadEmailEntregado;

    const llamadaValido = formDataEditLead.leadLlamadaValido;
    const llamadaAceptada = formDataEditLead.leadLlamadaAceptada;
    const llamadaGrabacion = formDataEditLead.leadLlamadaGrabacion;
    const llamadaContestada = formDataEditLead.leadLlamadaContestada;

    const whatsappCita = formDataEditLead.leadWhatsCita;
    const whatsappHablar = formDataEditLead.leadWhatsHablar;
    const whatsappHolabot = formDataEditLead.leadWhatsHolabot;
    const whatsappInbound = formDataEditLead.leadWhatsInbound;
    const whatsappVirtual = formDataEditLead.leadWhatsVirtual;
    const whatsappWebsite = formDataEditLead.leadWhatsWebsite;
    const whatsappWhattxt = formDataEditLead.leadWhatsWhattxt;
    const whatsappBrochure = formDataEditLead.leadWhatsBrochure;
    const whatsappCalifica = formDataEditLead.leadWhatsCalifica;
    const whatsappWhatsapp = formDataEditLead.leadWhatsWhatsapp;
    const whatsappUbicacion = formDataEditLead.leadWhatsUbicacion;

    try {

      const {data: jsonData, code, msg} = await productsIscoreEditLead(
        updateLeadSeller,
        emailValido,
        emailAbierto,
        emailEntregado,
        llamadaValido,
        llamadaAceptada,
        llamadaGrabacion,
        llamadaContestada,
        whatsappCita,
        whatsappHablar,
        whatsappHolabot,
        whatsappInbound,
        whatsappVirtual,
        whatsappWebsite,
        whatsappWhattxt,
        whatsappBrochure,
        whatsappCalifica,
        whatsappWhatsapp,
        whatsappUbicacion,
        userToken,
        idProduct
      );

      if ( code === 401 && msg === 'Token no valido' ) {

        sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
        return;

      } else {

        setAlertInfo({
          text: code === 200 ? intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.SUCCESS_ISCORE_EDITION'}) : intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.FAIL_ISCORE_EDITION'}),
          variant: code === 200 ? 'success' : 'danger',
        });
  
        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          });
          closeModalEditIscore();
          getProductsRegisters();
        }, 3000);

      }

    } catch(error) {
      setAlertInfo({
        text: 'Error en la solicitud Axios',
        variant: 'danger',
      });
    }

  };  

  return (
    <div className="container">
      <h4 className='mt-8 mb-6'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.TAGCALLS'})}</h4>
      <div className="row">
        <div className="col">
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTCALLSVALIDO_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTCALLSVALIDO_SPAN'})}</span>
            <input
                type="number"
                name="leadLlamadaValido"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadLlamadaValido}
                onChange={handleInputChange}
              />
          </div>
        <div className="col">
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTCALLSCONTESTADA_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTCALLSCONTESTADA_SPAN'})}</span>
            <input
                type="number"
                name="leadLlamadaContestada"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadLlamadaContestada}
                onChange={handleInputChange}
              />
          </div>
      </div>
      <div className="row">
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTCALLSACEPTADA_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTCALLSACEPTADA_SPAN'})}</span>
            <input
                type="number"
                name="leadLlamadaAceptada"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadLlamadaAceptada}
                onChange={handleInputChange}
              />
          </div>
        </div>
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTCALLSGRABACION_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTCALLSGRABACION_SPAN'})}</span>
            <input
                type="number"
                name="leadLlamadaGrabacion"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadLlamadaGrabacion}
                onChange={handleInputChange}
              />
          </div>
        </div>
      </div>
      <h4 className='mt-8 mb-6'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.TAGWHATS'})}</h4>
      <div className="row">
        <div className="col">
          <div>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSINBOUND_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSINBOUND_SPAN'})}</span>
            <input
                type="number"
                name="leadWhatsInbound"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadWhatsInbound}
                onChange={handleInputChange}
              />
          </div>
        </div>
        <div className="col">
          <div>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSPRINCIPAL_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSPRINCIPAL_SPAN'})}</span>
              <input
                type="number"
                name="leadWhatsHolabot"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadWhatsHolabot}
                onChange={handleInputChange}
              />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSUBICACION_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSUBICACION_SPAN'})}</span>
            <input
                type="number"
                name="leadWhatsUbicacion"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadWhatsUbicacion}
                onChange={handleInputChange}
              />
          </div>
        </div>
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSHABLAR_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSHABLAR_SPAN'})}</span>
            <input
                type="number"
                name="leadWhatsHablar"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadWhatsHablar}
                onChange={handleInputChange}
              />
          </div>
        </div >
      </div>
      <div className="row">
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSWHATSAPP_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSWHATSAPP_SPAN'})}</span>
            <input
                type="number"
                name="leadWhatsWhatsapp"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadWhatsWhatsapp}
                onChange={handleInputChange}
              />
          </div>
        </div>
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSWEBSITE_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSWEBSITE_SPAN'})}</span>
            <input
                type="number"
                name="leadWhatsWebsite"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadWhatsWebsite}
                onChange={handleInputChange}
              />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSVIRTUAL_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSVIRTUAL_LABEL'})}</span>
            <input
                type="number"
                name="leadWhatsVirtual"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadWhatsVirtual}
                onChange={handleInputChange}
              />
          </div>
        </div>
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSCITA_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSCITA_SPAN'})}</span>
            <input
                type="number"
                name="leadWhatsCita"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadWhatsCita}
                onChange={handleInputChange}
              />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSFREE_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSFREE_SPAN'})}</span>
            <input
                type="number"
                name="leadWhatsWhattxt"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadWhatsWhattxt}
                onChange={handleInputChange}
              />
          </div>
        </div>
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSSCORE_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSSCORE_SPAN'})}</span>
            <input
                type="number"
                name="leadWhatsCalifica"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadWhatsCalifica}
                onChange={handleInputChange}
              />
          </div>
        </div>
      </div >
      <div className="row">
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSPDF_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTWHATSPDF_SPAN'})}</span>
              <input
                type="number"
                name="leadWhatsBrochure"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadWhatsBrochure}
                onChange={handleInputChange}
              />
          </div>
        </div>
      </div>
      <h4 className='mt-8 mb-6'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.TAGEMAILS'})}</h4>
      <div className="row">
        <div className="col">
          <div>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTEMAILSVALIDO_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTEMAILSVALIDO_SPAN'})}</span>
            <input
                type="number"
                name="leadEmailValido"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadEmailValido}
                onChange={handleInputChange}
              />
          </div>
        </div>
        <div className="col">
          <div>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTEMAILSENTREGADO_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTEMAILSENTREGADO_SPAN'})}</span>
            <input
                type="number"
                name="leadEmailEntregado"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadEmailEntregado}
                onChange={handleInputChange}
              />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className='mt-6'>
            <label className="form-label fw-bold">{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTEMAILSABIERTO_LABEL'})}</label><br />
            <span className='text-muted'>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.INPUTEMAILSABIERTO_SPAN'})}</span>
              <input
                type="number"
                name="leadEmailAbierto"
                className='form-control form-control-solid form-control-lg'
                value={formDataEditLead.leadEmailAbierto}
                onChange={handleInputChange}
              />
          </div>
        </div>
      </div>

      {alertInfo.text && (
        <div className={`alert alert-${alertInfo.variant} d-flex align-items-center`} role='alert'>
          <div>{alertInfo.text}</div>
        </div>
      )}

      <button type="submit" className='mt-6 btn btn-sm btn-primary' onClick={handleFormSubmit}>{intl.formatMessage({id: 'PRODUCTS.MODAL_EDIT_ISCORE.TAB_LEADS.BUTTONSUBMIT'})}</button>
    
    </div> 
  )
}
  
export { LeadTabForm }