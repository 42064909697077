import { FC, useRef, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { useEffect } from "react";
import { GradeReport } from '../interfaces/gradesReportTypes';
import { useThemeMode } from "../../../../../../_metronic/partials";
import { dataExtract } from "../../../../../utils/functions/_Functions";
import { getCSS, getCSSVariableValue } from "../../../../../../_metronic/assets/ts/_utils";
import { Highlight } from 'prism-react-renderer';
import { Modal } from "react-bootstrap";
import { GradesReportByGradeTabe } from "../tables/GradesReportByGradeTable";

type Props = {
    gradesReportItemByProduct: GradeReport
    selectedIdProduct: any 
    selectedIdCampaign: any 
    startDate: any 
    endDate: any
}

const GradesReportMainChart: FC<Props> = ({
    gradesReportItemByProduct,
    selectedIdProduct,
    selectedIdCampaign,
    startDate,
    endDate
}) => {

    const chartRef = useRef<HTMLDivElement | null>(null)
    const { mode } = useThemeMode()

    const [optionNumberSelected, setOptionNumberSelected] = useState<number>(0);
    const [optionTextSelected, setOptionTextSelected] = useState<string>('');

    const [showModal, setShowModal] = useState<boolean>(false);

    const categoriesArray = Object.keys(gradesReportItemByProduct.gradeCounts)
    const countsArray = Object.values(gradesReportItemByProduct.gradeCounts)

    const [cardTitleSelected, setCardTitleSelected] = useState<string>('')
    const [numberCategorySelected, setNumberCategorySelected] = useState<number>(0)


    const openModal = () => {
        setShowModal(true)

    }

    const closeModal = () => {
        setShowModal(false)
    }

    useEffect(() => {
        const chart = refreshChart()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, mode, gradesReportItemByProduct])

    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))
        const c_type = 'bar'
        // const serieOptions = dataExtract(gradesReportItemByProduct.qualifiedLeads, "option");
        // const serieCounts = dataExtract(gradesReportItemByProduct.qualifiedLeads, "count");

        const serieOptions = categoriesArray;
        const serieCounts = countsArray;

        console.log(serieOptions)
        console.log(serieCounts)

        const chart = new ApexCharts(chartRef.current, getChartOptions(height, c_type, gradesReportItemByProduct, serieOptions, serieCounts, setOptionNumberSelected, setOptionTextSelected, openModal, handleSelection))
        if (chart) {
            chart.render()
        }

        return chart

    }

    const handleSelection = (indexSelection: number | undefined) => {

        console.log(indexSelection)

        if(indexSelection !== undefined && indexSelection >= 0) {
            console.log(categoriesArray)

            const categorySelected = String(categoriesArray[indexSelection])

            const getNumberCategory = parseInt(categorySelected.charAt(0), 0);

            console.log(getNumberCategory)

            console.log(categorySelected)

            setOptionNumberSelected(getNumberCategory)

            setCardTitleSelected(categorySelected)

            openModal()
        }else{
            console.log('Indice invalido o no definido')
        }
        // console.log(gradesReportItemByProduct.qualifiedLeads);

        // if (indexSelection !== undefined && indexSelection >= 0 && indexSelection < gradesReportItemByProduct.qualifiedLeads.length) {
        //     const findedObject = gradesReportItemByProduct.qualifiedLeads[indexSelection];
        //     const id = findedObject.id;
        //     const option = findedObject.option;
        //     const count = findedObject.count;

        //     setOptionNumberSelected(id)
        //     setOptionTextSelected(option)

        //     openModal();

        //     console.log('id de la opcion seleccionada: ', id)
        //     console.log('textp de la opcion seleccionada: ', option);
        // } else {
        //     console.log('Índice inválido o no definido');
        // }

    }

    return (

        <>
            {/* // <><p>hola</p></> */}
            <div className='card-xxl-stretch mb-5 mb-xl-10'>
                <div className="">
                    <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '350px' }} />
                </div>
            </div>

            <Modal
                show={showModal}
                onHide={closeModal}
                className="modal-xl"
                scrollable
            >
                <Modal.Header closeButton onClick={closeModal}>
                    <Modal.Title>
                        <div className="d-flex">
                            <div className="me-2">
                                <h1>{cardTitleSelected}</h1>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {/* <h4>Renderizar tabla de leads calificados con {optionNumberSelected} - {optionTextSelected}</h4> */}
                        <GradesReportByGradeTabe 
                            optionNumberSelected={optionNumberSelected} 
                            cardTitleSelected={cardTitleSelected}
                            gradesReportItemByProduct={gradesReportItemByProduct}
                            selectedIdProduct={selectedIdProduct}
                            selectedIdCampaign={selectedIdCampaign}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="reset"
                        className="btn btn-sm btn-secondary"
                        onClick={() => {
                            closeModal();
                        }}
                    >
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export { GradesReportMainChart }

function getChartOptions(height: number, c_type: any, gradesReportItemByProduct: GradeReport, serieOptions: any, serieCounts: any, setOptionNumberSelected: any, setOptionTextSelected: any, openModal: any, handleSelection: (indexSelection: number | undefined) => void): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    // const baseColor = getCSSVariableValue('--bs-primary')
    // const secondaryColor = getCSSVariableValue('--bs-gray-300')

    const colors = ['#E20000', '#FF6C0E', '#E2B900', '#62B702', '#02DE87', '#02DEC0', '#02CADE', '#029FDE', '#0270DE', '#0237DE', '#2002DE', '#5502DE', '#003462']

    return {
        series: [{
            name: 'Leads calificados',
            data: serieCounts,
        }],
        chart: {
            height,
            type: c_type,
            fontFamily: "inherit",
            events: {
                legendClick: function (chartContext, seriesIndex, config) {
                    console.log('se dio click legendclick')
                    console.log('Se hizo clic en el elemento de la leyenda: ', seriesIndex);
                    handleSelection(seriesIndex)
                },
                dataPointSelection: function (event, chartContext, config) {
                    console.log('se dio click datapointselection')
                    console.log('Se hizo clic en el elemento: ', config.dataPointIndex)
                    const indexSelection: number = config.dataPointIndex
                    handleSelection(indexSelection)
                }
            },
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                export: {
                    csv: {
                        filename: "reporte-calificaciones",
                    },
                    svg: {
                        filename: "reporte-calificaciones",
                    },
                    png: {
                        filename: "reporte-calificaciones",
                    },
                },
            }
        },
        colors: colors,
        plotOptions: {
            bar: {
                // columnWidth: '45%',
                distributed: true,
                horizontal: false,
                columnWidth: "30%",
                borderRadius: 5,
            }
        },
        legend: {
            show: true,
            fontFamily: "inherit",
            fontSize: "13px",
            labels: {
                colors: labelColor
            },
            position: 'top',
            horizontalAlign: 'center',
        },
        dataLabels: {
            enabled: true
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
        },
        xaxis: {
            categories: serieOptions,
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: true,
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: "12px",
                }
            }
        },
        fill: {
            opacity: 1,
        },
        states: {
            normal: {
                filter: {
                    type: "none",
                    value: 0,
                }
            },
            hover: {
                filter: {
                    type: "none",
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: "none",
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: "12px",
            },
            y: {
                formatter: function (val) {
                    return val + " leads";
                },
            },
        },
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    }
}