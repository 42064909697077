import { apiDragon, DragonMainApi, EPRoleMilestoneDelete } from "../../../utils/constants/_Constants";

import {apiGetDeletePetitions} from "../../../../_metronic/helpers/apis";

async function roleMilestoneDelete (rolId: string, selectedMilestoneIndex: string, userToken: string) {

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPRoleMilestoneDelete+rolId}/${selectedMilestoneIndex}`, {

            undefined
            
        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },
        
        'delete');

        const jsonDataRoleList = data;

        return jsonDataRoleList;

    } catch (error) {

        throw error;

    }

}

export {roleMilestoneDelete};