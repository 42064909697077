import {Dropdown} from 'react-bootstrap'
import * as XLSX from 'xlsx'

export const DropdownItemExportXLSX = ({dataToExport, title}) => {
  console.log(dataToExport)
  console.log(title)

  const handleToExportProductsAssignedDataXLSX = () => {
    try {
  // Verificar si hay datos para exportar
  if (!dataToExport || !dataToExport.brandProducts) {
    console.error('No hay datos para exportar o no hay productos disponibles.');
    return;
  }

  // Obtener el usuario y sus productos
  const { id: id, name: name, brandProducts } = dataToExport;

      // Crear una nueva hoja de cálculo
      const wb = XLSX.utils.book_new()

      const worksheetData = [
        ['Prouctos asignados', '', '', '', ''],
        ['Id usuario','Nombre usuario','Id del producto','Nombre del producto','Fecha de asignación',],
      ]

  // Recorrer los productos del usuario
  brandProducts.forEach(product => {
    // Agregar datos del producto a la hoja de cálculo
    const productId = product.pro_id;
    const productName = product.name;

    // Agregar fila a la matriz de datos de la hoja de cálculo
    worksheetData.push([id, name, productId, productName, '']);
  });

      // Crear la hoja de cálculo
      const ws = XLSX.utils.aoa_to_sheet(worksheetData)

      // Fusionar las celdas para cada propiedad
      const mergeRanges = [
        {s: {r: 0, c: 0}, e: {r: 0, c: 4}}, // Productos asignados
        {s: {r: 1, c: 0}, e: {r: 1, c: 0}}, // Id usuario
        {s: {r: 1, c: 1}, e: {r: 1, c: 1}}, // Nombre usuario
        {s: {r: 1, c: 2}, e: {r: 1, c: 2}}, // Id del producto
        {s: {r: 1, c: 4}, e: {r: 1, c: 4}}, // Nombre del producto
        {s: {r: 1, c: 4}, e: {r: 1, c: 4}}, // Fecha de asignación
      ]

      // Especificar el ancho de las columnas
      ws['!cols'] = [
        {wch: 25}, // Id usuario
        {wch: 30}, // Nombre usuario
        {wch: 25}, // Id del producto
        {wch: 30}, // Nombre del producto
        {wch: 30}, // Fecha de asignación
      ]

      ws['!merges'] = mergeRanges

      // Agregar la hoja de cálculo al libro de trabajo
      XLSX.utils.book_append_sheet(wb, ws, 'Productos asignados')

      // Guardar el libro de trabajo en un archivo XLSX
      XLSX.writeFile(wb, `Data exportada de los productos asignados a ${title}.xlsx`)
    } catch (error) {
      console.error('Error al generar el archivo XLSX:', error)
    }
  }

  return (
    <Dropdown.Item>
      <span onClick={handleToExportProductsAssignedDataXLSX}>
        Exportar productos asignados del vendedor (.xlsx)
      </span>
    </Dropdown.Item>
  )
}
