// Importamos hooks de react que usaremos para este componente
import { useState } from "react";
// Importamos libreria para manejo de lenguaje 
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos interfaces de productos que usaremos en este componente
import { Step2Props } from "../../interfaces/productsTypes";

import { Step2Errors } from "../../interfaces/productsTypes";

// Iniciamos funcion para construir el componete Step2
function Step2({ onNext, handlePrev, prevData, setStep2Data }: Step2Props) {
    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl();


    const [productCountry, setProductCountry] = useState(prevData?.productCountry || '');
    const [productState, setProductState] = useState(prevData?.productState || '');
    const [productCity, setProductCity] = useState(prevData?.productCity || '');
    const [productLocal, setProductLocal] = useState(prevData?.productLocal || '');

    const [errors, setErrors] = useState<Step2Errors>({
        productCountry: '',
        productState: '',
        productCity: '',
        productLocal: '',
    });

    const validateInputs = () => {
        const newErrors: Step2Errors = {
            productCountry: productCountry === '' ? 'Selecciona un país' : '',
            productState: productState === '' ? 'Selecciona un estado' : '',
            productCity: !productCity ? 'Ingresa una ciudad' : '',
            productLocal: !productLocal ? 'Ingresa una ubicación local' : '',
        };

        setErrors(newErrors);

        // Devuelve true si no hay errores
        return Object.values(newErrors).every(error => error === '');
    };



    const handleNext = () => {
        if (validateInputs()) {
            onNext({
                productCountry,
                productState,
                productCity,
                productLocal
            });

            setStep2Data({
                productCountry,
                productState,
                productCity,
                productLocal
            });
        }
    };


    return (
        <div>
            <h2 className='modal-title mb-4'>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.LABEL_PROUBI' })}</h2>
            <form>
                <div className='d-flex'>
                    <div className='col-6 me-2'>
                        <label className="form-label fw-bold" htmlFor="productCountry">{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SELECTCOUNTRY' })}</label>
                        <select
                            id="productCountry"
                            className='form-select form-select-solid mb-6'
                            value={productCountry}
                            onChange={(e) => setProductCountry(e.target.value)}>
                            <option value="">-- {intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SELECTCOUNTRY_OPTION1' })} --</option>
                            <option value="MX">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.MX` })}</option>
                            <option value="BR">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.BR` })}</option>
                            <option value="US">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.US` })}</option>
                            <option value="CA">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.CA` })}</option>
                            <option value="HN">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.HN` })}</option>
                            <option value="SV">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.SV` })}</option>
                            <option value="GT">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.GT` })}</option>
                            <option value="AR">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.AR` })}</option>
                            <option value="CH">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.CH` })}</option>
                            <option value="CO">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.CO` })}</option>
                            <option value="EC">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.EC` })}</option>
                            <option value="PA">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.PA` })}</option>
                            <option value="UR">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.UR` })}</option>
                            <option value="VE">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.VE` })}</option>

                        </select>
                        <div className='text-danger mb-6'>{errors.productCountry}</div>
                    </div>

                    <div className='col-6'>
                        <label className="form-label fw-bold" htmlFor="productState">{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SELECTSTATE' })}</label>
                        <select
                            id="productState"
                            className='form-select form-select-solid mb-6'
                            value={productState}
                            onChange={(e) => setProductState(e.target.value)}>
                            <option value="">-- {intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SELECTSTATE_OPTION1' })} --</option>
                            <option value="Aguascalientes"> Aguascalientes </option>
                            <option value="Baja California"> Baja California </option>
                            <option value="Baja California Sur"> Baja California Sur </option>
                            <option value="Campeche"> Campeche </option>
                            <option value="Ciudad de Mexico"> Ciudad de Mexico </option>
                            <option value="Chiapas"> Chiapas </option>
                            <option value="Chihuahua"> Chihuahua </option>
                            <option value="Coahuila de Zaragoza"> Coahuila de Zaragoza </option>
                            <option value="Colima"> Colima </option>
                            <option value="Durango"> Durango </option>
                            <option value="Estado de Mexico"> Estado de México </option>
                            <option value="Guanajuato"> Guanajuato </option>
                            <option value="Guerrero"> Guerrero </option>
                            <option value="Hidalgo"> Hidalgo </option>
                            <option value="Jalisco"> Jalisco </option>
                            <option value="Michoacan"> Michoacán </option>
                            <option value="Morelos"> Morelos </option>
                            <option value="Nayarit"> Nayarit </option>
                            <option value="Nuevo Leon"> Nuevo León </option>
                            <option value="Oaxaca"> Oaxaca </option>
                            <option value="Puebla"> Puebla </option>
                            <option value="Queretaro"> Querétaro </option>
                            <option value="Quintana Roo"> Quintana Roo </option>
                            <option value="San Luis Potosi"> San Luis Potosí </option>
                            <option value="Sinaloa"> Sinaloa </option>
                            <option value="Sonora"> Sonora </option>
                            <option value="Tabasco"> Tabasco </option>
                            <option value="Tamaulipas"> Tamaulipas </option>
                            <option value="Tlaxcala"> Tlaxcala </option>
                            <option value="Veracruz"> Veracruz </option>
                            <option value="Yucatan"> Yucatán </option>
                            <option value="Zacatecas"> Zacatecas </option>
                            <option value="Extranjero"> Extranjero </option>
                        </select>
                        <div className='text-danger mb-6'>{errors.productState}</div>
                    </div>

                </div>

                <div className='d-flex'>
                    <div className='col-6 me-2'>
                        <label className="form-label fw-bold" htmlFor="productCity">{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.INPUTCITY' })}</label>
                        <input
                            type="text"
                            id="productCity"
                            className='form-control form-control-solid mb-6'
                            placeholder={intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.INPUTCITY_PLACEHOLDER' })}
                            value={productCity}
                            onChange={(e) => setProductCity(e.target.value)}
                        />
                        <div className='text-danger mb-6'>{errors.productCity}</div>
                    </div>

                    <div className='col-6'>
                        <label className="form-label fw-bold" htmlFor="productLocal">{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.INPUTLOCATE' })}</label>
                        <input
                            type="text"
                            id="productLocal"
                            className='form-control form-control-solid mb-6'
                            placeholder={intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.INPUTLOCATE_PLACEHOLDER' })}
                            value={productLocal}
                            onChange={(e) => setProductLocal(e.target.value)}
                        />
                        <div className='text-danger mb-6'>{errors.productLocal}</div>
                    </div>
                </div>
            </form>
            {/* <button onClick={handleNext}>Siguiente</button> */}
            <button className="btn btn-light btn-sm mt-6 me-2" onClick={handlePrev}>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.BUTTONLAST' })}</button>

            <button className="btn btn-primary btn-sm mt-6" onClick={handleNext}>{intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.BUTTONNEXT' })}</button>

        </div>
    )
}

export { Step2 };