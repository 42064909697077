import {useState, ChangeEvent} from "react";
import * as React from 'react';
import { Step3Props } from "../../interfaces/clientsTypes";
import { Step3Errors } from "../../interfaces/clientsTypes";

function Step3({onNext, handlePrev, prevData, setStep3Data}: Step3Props) {
    const [brandName, setBrandName] = useState(prevData?.brandName || '');
    const [productName, setProductName] = useState(prevData?.productName || '');
    const [productPrice, setProductPrice] = useState(prevData?.productPrice || '');
    const [productMailsUsers, setProductMailsUsers] = useState(prevData?.productMailsUsers || []);
    const [productType, setProductType] = useState(prevData?.productType || "");
    const [productLegacy, setProductLegacy] = useState(prevData?.productLegacy || '');
    const [productCountry, setProductCountry] = useState(prevData?.productCountry || '');
    const [productState, setProductState] = useState(prevData?.productState || '');
    const [productCity, setProductCity] = useState(prevData?.productCity || '');
    const [productZone, setProductZone] = useState(prevData?.productZone || '');

    const [errors, setErrors] = useState<Step3Errors>({
        brandName: "",
        productName: "",
        productPrice: "", 
        productMailsUsers: "", 
        productType: "", 
        productCountry: "",
        productState: "",
        productCity: "",
        productZone: "",
    });

    console.log(productType)

    const validateInputs = () => {
    
        const newErrors: Step3Errors = {
            brandName: !brandName ? "La marca es obligatoria" : "",
            productName: !productName ? "El producto es obligatorio" : "",
            productPrice: !productPrice ? "El precio es obligatorio" : "",
            productMailsUsers: productMailsUsers.length === 1 && productMailsUsers[0] === '' ? "Ingresa el correo de las personas" : productMailsUsers.length === 0
            ? "Debe haber al menos un correo"
            : "",
            productType: productType === "0" || !productType ? "El tipo de producto es obligatorio" : "",
            productCountry: !productCountry ? "El País es obligatorio" : "",
            productState: !productState ? "El Estado es obligatorio" : "",
            productCity: !productCity ? "La ciudad es obligatoria" : "",
            productZone: !productZone ? "La alcaldia es obligatoria" : "",
        };

        // Aquí actualizamos el estado errors con los nuevos mensajes de error
        setErrors(newErrors);
    
        return Object.values(newErrors).every((error) => error === "");
    };

    console.log(errors)
    console.log(productMailsUsers)

    const handleNext = () => {
        if (validateInputs()) {
        onNext({
            brandName,
            productName,
            productPrice: typeof productPrice === 'number' ? productPrice : 0,
            productMailsUsers,
            productType, 
            productLegacy,
            productCountry,
            productState,
            productCity,
            productZone
        })

        setStep3Data({
            brandName, 
            productName,
            productPrice: typeof productPrice === 'number' ? productPrice : 0,
            productMailsUsers,
            productType, 
            productLegacy,
            productCountry,
            productState,
            productCity,
            productZone
        });
    };
    }

    const handleMailsChange = (e: ChangeEvent<HTMLInputElement>) => {
        // Dividir la cadena de correos electrónicos por comas y actualizar el estado
        const mailsArray = e.target.value.split(',').map(email => email.trim());
        setProductMailsUsers(mailsArray);
    };

    const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.trim();
        setProductPrice(newValue === '' ? '' : Number(newValue));
      };

    return(
        <div>
            <h3>Marca</h3>
            <span className="text-muted">Crea el nombre de la marca de tu producto</span>
            <form>
            <div className="col-6 me-2 mb-5 mt-3">
                        <input 
                            type='text'
                            id='brandName'
                            placeholder="Ingresa el nombre de la Marca"
                            className="form-control"
                            value={brandName} 
                            onChange={(e) => setBrandName(e.target.value)}
                        />
                        <div className="text-danger mb-6">{errors.brandName}</div>
                    </div>
            <h3>Producto</h3>
            <span className="text-muted">Ahora crea un producto vinculado a este cliente</span>
            
                <div className="d-flex mt-4">
                    <div className="col-6 me-2">
                        <input 
                            type='text'
                            id='productName'
                            placeholder="Ingresa el nombre del producto"
                            className="form-control"
                            value={productName} 
                            onChange={(e) => setProductName(e.target.value)}
                        />
                        <div className="text-danger mb-6">{errors.productName}</div>
                    </div>

                    <div className="col-6">
                        <input
                            type="number"
                            id='productPrice'
                            placeholder="Precio Producto"
                            className="form-control"
                            value={productPrice}
                            onChange={handlePriceChange}
                        />
                        <div className="text-danger mb-6">{errors.productPrice}</div>
                    </div>
                </div>

                <div>
                    <input
                        type="text"
                        id='productMailsUsers'
                        placeholder="Ingresa correo de las personas que recibiran notificaciones"
                        className="form-control mt-4"
                        value={productMailsUsers.join(', ')} 
                        onChange={handleMailsChange}
                    />
                    <div className="text-danger mb-6">{errors.productMailsUsers}</div>
                </div>

                <div className="d-flex mt-4">
                    <div className="col-6 me-2">
                        <select 
                            className="form-select"
                            id='productType'
                            value={productType} 
                            onChange={(e) => setProductType(e.target.value)}
                        >
                            <option value="0">Selecciona el tipo de producto</option>
                            <option value="Consultorios">Consultorios</option>
                            <option value="Desarrollo Inmobiliario">Desarrollo Inmobiliario</option>
                            <option value="App Digital">App Digital</option>
                            <option value="Empresa">Empresa</option>
                            <option value="Corporation">Corporation</option>
                            <option value="Corretaje Inmobiliario">Corretaje Inmobiliario</option>
                            <option value="Agencia de Autos">Agencia de Autos</option>
                            <option value="Casas y Departamentos">Casas y Departamentos</option>
                            <option value="Asesoría Legal">Asesoría Legal</option>
                            <option value="Agencia">Agencia</option>
                            <option value="Corporativo">Corporativo</option>
                            <option value="Oficinas">Oficinas</option>
                            <option value="Casas">Casas</option>
                            <option value="Departamentos">Departamentos</option>
                            <option value="Terrenos">Terrenos</option>
                            <option value="Procesador de Pagos">Procesador de Pagos</option>
                            <option value="Gimnasios">Gimnasios</option>
                            <option value="Fintech">Fintech</option>
                            <option value="Lojas">Lojas</option>
                        </select>
                        <div className="text-danger mb-6">{errors.productType}</div>
                    </div>

                    <div className="col-6">
                        <input
                            type='text'
                            id='productLegacy'
                            placeholder="Aviso de privacidad"
                            className="form-control"
                            value={productLegacy}
                            onChange={(e) => setProductLegacy(e.target.value)}
                        />
                    </div>
                </div>

                <div className="d-flex mt-4">
                    <div className="col-6 me-2">
                        <input 
                            type='text'
                            id='productCountry'
                            placeholder="Pais"
                            className="form-control"
                            value={productCountry} 
                            onChange={(e) => setProductCountry(e.target.value)}
                        />
                        <div className="text-danger mb-6">{errors.productCountry}</div>
                    </div>

                    <div className="col-6">
                        <input
                            type="text"
                            id='productState'
                            placeholder="Estado"
                            className="form-control"
                            value={productState} 
                            onChange={(e) => setProductState(e.target.value)}
                        />
                        <div className="text-danger mb-6">{errors.productState}</div>
                    </div>
                </div>

                <div className="d-flex mt-4">
                    <div className="col-6 me-2">
                        <input 
                            type='text'
                            id='productCity'
                            placeholder="Ciudad"
                            className="form-control"
                            value={productCity} 
                            onChange={(e) => setProductCity(e.target.value)}
                        />
                        <div className="text-danger mb-6">{errors.productCity}</div>
                    </div>

                    <div className="col-6">
                        <input
                            type="text"
                            id='productZone'
                            placeholder="Alcaldia"
                            className="form-control"
                            value={productZone}
                            onChange={(e) => setProductZone(e.target.value)}
                        />
                        <div className="text-danger mb-6">{errors.productZone}</div>
                    </div>
                </div>
            </form>

            <button className="btn btn-light btn-sm mt-6 me-2" onClick={handlePrev}>Anterior</button>
            <button className="btn btn-primary btn-sm mt-6" onClick={handleNext}>Siguiente</button>
        </div>
    )
}

export {Step3};