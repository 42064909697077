// Importamos constantes con rutas de api
import { apiDragon, DragonMainApi, EPWhatsappReport } from '../../../../../utils/constants/_Constants'
// Importamos funcion para realizar la peticion
import { apiGetDeletePetitions } from '../../../../../../_metronic/helpers/apis'

// Funcion para realizar la peticion al endpoint
export const whatsappReportDataPetition = async (
    userToken: string,
    productId: string,
    startDate: string,
    endDate: string,
    typeUser : string,
                                                                                                                                                                                                               
) => {
    try {
        const data = await apiGetDeletePetitions(
            apiDragon,
            `${DragonMainApi + EPWhatsappReport + productId}`,
            {
                dateStart: startDate,
                dateEnd: endDate,
                typeUser,
            },
            {
                'Content-Type': 'application/json',
                'x-token': userToken,
            },
            'get'
        )

        const jsonDataWAReport = data
        return jsonDataWAReport
    } catch (error) {
        console.error('Hubo un error en la peticion: ', error)
        throw error
    }
}
