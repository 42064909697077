import React, {useContext, useEffect, useState} from 'react'
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import {RankingInfo, rankItem} from '@tanstack/match-sorter-utils'
import {UserContext} from '../../../../../app-context/user-context/UserContext'
import {Modal, OverlayTrigger} from 'react-bootstrap'
import {Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {
  ShareAccessList,
  UsersShareAccessData,
  UsersShareAccessFormData,
} from '../../../interfaces/shareAccessTypes'
import {
  UsersSharedAccessEdit,
  UsersSharedAccessList,
  UsersSharedAccessNew,
} from '../../../services/_ShareAccessApi'
import {AlertInfo} from '../../../../../utils/interfaces/_InterfacesTypes'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {ShareAccessAddModalForm} from './Modals/ShareAccessAddModalForm'
import {sessionExpired} from '../../../../../utils/functions/_Functions'
import {ShareAcessEditModalForm} from './Modals/ShareAcessEditModalForm'
import { DropdownExport } from '../../../../../../_metronic/partials/content/dropdown/DropdownExport'
import { mapAccessLevelToWord } from '../../../../../utils/functions/_Functions';
import { mapUserStatusToWord } from '../../../../../utils/functions/_Functions';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

export function ShareAccessTableComponent() {
  // Estado inicial para el formulario de nuevo registro de ShareAccess
  const initialFormUserShareAccess = {
    user_mail: '',
    lvl_access: '',
    status: '',
  }
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const [dataToExport, setDataToExport] = useState({}); // Definimos variables para la exportación de la data a formato hoja de calculos
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [formData, setFormData] = useState<UsersShareAccessFormData>(initialFormUserShareAccess)
  const [selectedUser, setSelectedUser] = useState<ShareAccessList | null>(null)
  const [data, setData] = useState<ShareAccessList[]>([])
  const [showModal, setShowModal] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [errors, setErrors] = useState<{[key: string]: string}>({})
  //Variables con el estado de la alerta da confirmacion o error
  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })
  // Obtener información del usuario y token desde el contexto
  const {user, token, updateToken} = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token
  const renderTooltip = (props) => <Tooltip {...props}>Copiar id de vendedor</Tooltip>

  // Función para abrir el Modal de creación de nuevo registro share Access
  const openModalForNewShareAccess = (): void => {
    setErrors({})
    setShowModal(true)
    resetForm()
  }

  // Función para cerrar el modal de nuevo registro share Access
  const closeModalForNewShareAccess = () => {
    setShowModal(false)
    resetForm()
  }

  // Función para manejar la edición de un usuario de Share Access, muestra el modal y obtiene los datos del usuario seleccionado
  const handleEditUserShareAccess = (userShareAcess) => {
    setErrors({})
    console.log(userShareAcess)
    setShowModalEdit(true)
    setSelectedUser(userShareAcess)
  }

  //Función para cerrar el Modal de edición de registro de share Access
  const closeModalForEditShareAccess = () => {
    setShowModalEdit(false)
  }

  //Funcion para resetear formulario
  const resetForm = (): void => {
    setFormData(initialFormUserShareAccess)
  }

  // Función para obtener la lista de usuarios con acceso compartido
  const getUsersSharedAccessList = async () => {
    try {
      // Realizar la llamada a la API para obtener la lista de usuarios con acceso compartido
      const response = await UsersSharedAccessList(userToken, clientId)
      // Actualizar el token con el nuevo token obtenido de la respuesta
      const {usersShareAccess, token: newToken} = response
      console.log(usersShareAccess)
      updateToken(newToken)

      // Obtener la lista de usuarios desde la respuesta y transformarla a un formato deseado
      const mappedUserShared: ShareAccessList[] = usersShareAccess.map((userShareAcess: any) => ({
        user_id: userShareAcess.user_id,
        usr_name: userShareAcess.usr_name,
        usr_mail: userShareAcess.usr_mail,
        lvl_access: mapAccessLevelToWord(userShareAcess.lvl_access), // Mapear el nivel de acceso a texto
        evangelist: userShareAcess.evangelist,
        status: mapUserStatusToWord(userShareAcess.status),
      }))

      // Actualizar el estado con la nueva lista de usuarios con acceso compartido
      setData(mappedUserShared)
    } catch (error) {
      console.error('Error receiving SharedAccessList: ', error)
    }
  }

  // Obtener datos de vendedores al montar el componente
  useEffect(() => {
    getUsersSharedAccessList()
  }, [userToken, clientId, showModal]) // Ejecutar al montar el componente y cada vez que userToken o clientId cambien

  // Función que maneja el envío del formulario de compartir acceso de usuario
  const handleUserShareAccessFormSubmit = async (): Promise<void> => {
    // Limpiar errores antes de cada intento de envío
    setErrors({})

    // Realizar validaciones
    if (!formData.user_mail) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        user_mail: 'Este es un campo obligatorio',
      }))
    }
    if (formData.lvl_access == '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        lvl_access: 'Este es un campo obligatorio',
      }))
    }
    if (formData.status == '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        status: 'Este es un campo obligatorio',
      }))
      return
    }

    // Si todas las validaciones pasan, proceder con el envío del formulario

    const email = formData.user_mail
    const levelAccess = formData.lvl_access
    const status = formData.status
    const userType = 'dragon_user'

    console.log(formData)

    try {
      // Llamada a la API para compartir acceso de usuario
      const {
        data: jsonData,
        code,
        msg,
      } = await UsersSharedAccessNew(clientId, email, levelAccess, userType, status, userToken)

      setAlertInfo({
        text: code === 200 ? '¡Usuario agregado correctamente!' : `Ha ocurrido un error`,
        variant: code === 200 ? 'success' : 'danger',
      })

      // Validación del mensaje y llamada a la función limpiarYRecargar
      if (msg === 'Token no válido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
      }

      setTimeout(() => {
        if (code === 200) {
          setAlertInfo({
            text: '',
            variant: '',
          })

          closeModalForNewShareAccess()
        }
      }, 3000)

      const userTokenNew = jsonData.token
      updateToken(userTokenNew)
    } catch (error) {
      setAlertInfo({
        text: `Error en la solicitud: ${error}`,
        variant: 'danger',
      })
      console.error('Error en la solicitud Axios:', error)
    }
  }

  //Función que maneja el envío del formulario de editar accesso de usuario Share Access
  const handleFormEditSubmit = async (): Promise<void> => {
    // Limpiar errores antes de cada intento de envío
    setErrors({})

    // Realizar validaciones
    if (!formData.user_mail) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        user_mail: 'Este es un campo obligatorio',
      }))
    }
    if (formData.lvl_access == '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        lvl_access: 'Este es un campo obligatorio',
      }))
    }

    // Si todas las validaciones pasan, proceder con el envío del formulario
    const email = formData.user_mail
    const levelAccess = formData.lvl_access
    const status = formData.status
    const userType = 'dragon_user'

    console.log(email)
    console.log(levelAccess)
    console.log(status)

    try {
      // Realiza una solicitud para editar los permisos de usuario
      const {
        data: jsonData,
        code,
        msg,
      } = await UsersSharedAccessEdit(clientId, email, levelAccess, status, userType, userToken)

      setAlertInfo({
        text: code === 200 ? '¡Usuario editado correctamente!' : `Ha ocurrido un error`,
        variant: code === 200 ? 'success' : 'danger',
      })

      // Validación del mensaje y llamada a la función limpiarYRecargar
      if (msg === 'Token no válido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
      }

      // Cerrar el modal después de 3 segundos
      setTimeout(() => {
        closeModalForEditShareAccess()
        setAlertInfo({
          text: '',
          variant: '',
        })
      }, 3000)

      console.log('Todo fino pa')

      // Actualiza el token de usuario con el nuevo token obtenido de la respuesta
      const userTokenNew = jsonData.token
      updateToken(userTokenNew)
    } catch (error) {
      setAlertInfo({
        text: `Error en la solicitud: ${error}`,
        variant: 'danger',
      })
      console.error('Error en la solicitud Axios:', error)
    }
  }

  // Función para determinar la clase del botón basado en el nivel de acceso
  const getAccessButtonClass = (lvl_access) => {
    return lvl_access === 'Gerente' || lvl_access === 'Administrador'
      ? 'btn btn-light-info'
      : 'btn btn-light-warning'
  }

  // Función para determinar la clase del botón basado en el estatus
  const getStatusButtonClass = (status) => {
    return status === 'Activo' || status === true ? 'btn btn-light-success' : 'btn btn-light-danger'
  }

  const columns = React.useMemo<ColumnDef<UsersShareAccessData, any>[]>(
    () => [
      {
        header: 'Datos',
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'user_id',
            cell: (info) => (
              <OverlayTrigger placement='top' overlay={renderTooltip}>
                <button className='btn btn-light-primary'>{info.getValue()}</button>
              </OverlayTrigger>
            ),
            header: () => <span>Id</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'usr_name',
            cell: (info) => info.getValue(),
            header: () => <span>Nombre</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'usr_mail',
            id: 'usr_mail',
            cell: (info) => info.getValue(),
            header: () => <span>Correo</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'lvl_access',
            id: 'lvl_access',
            cell: ({row}) => (
              <button className={getAccessButtonClass(row.original.lvl_access)}>
                {row.original.lvl_access}
              </button>
            ),
            header: () => <span>Nivel de Acceso</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'status',
            id: 'status',
            cell: ({row}) => (
              <button className={getStatusButtonClass(row.original.status)}>
                {row.original.status}
              </button>
            ),
            header: () => <span>Estatus</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'actions',
            cell: (info) => {
              return (
                <div>
                  <button
                    className='btn btn-primary btn-sm'
                    onClick={() => handleEditUserShareAccess(info.row.original)}
                  >
                    Editar
                  </button>
                </div>
              )
            },
            header: () => <span>Acciones</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  )

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  React.useEffect(() => {
    if (table.getState().columnFilters[1]?.id === 'usr_name') {
      if (table.getState().sorting[0]?.id !== 'usr_name' || table.getState().sorting[0]?.desc) {
        table.setSorting([{id: 'usr_name', desc: false}])
      }
    }
  }, [table.getState().columnFilters[1]?.id])

  const handleSetData = () => {
    const dataToExport = table.getRowModel().flatRows.map((row) => row.original);
    setDataToExport(dataToExport)
  }

  return (
    <div className='p-2'>
      <div className='card-header pt-6 pb-6'>
        <div className='d-flex justify-content-between'>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className='form-control form-control-solid w-250px ps-14 me-3'
            placeholder='Buscar usuario compartido...'
          />

          <button
            type='button'
            className='btn btn-primary d-flex align-items-center me-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={handleSetData}
          >
            <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />{' '}
            <span>Exportar datos</span>
          </button>

          <DropdownExport dataToExport={dataToExport} title={'ShareAccessUsersData'}/>

          {/* <button className='btn btn-primary me-3' onClick={() => handleExportToExcel()}>
            Descargar información
          </button> */}
        </div>

        <div>
          {/* begin::Add user */}
          <button
            type='button'
            className='btn btn-primary me-3'
            onClick={openModalForNewShareAccess}
          >
            <KTIcon iconName='plus' className='fs-2' />
            Compartir Accesos
          </button>
          {/* end::Add user */}
        </div>
      </div>

      <div className='h-2' />

      <div className='card-body table-responsive'>
        <table className='table table-row-bordered gs-3 gy-4 gx-12'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className='h-2' />

      <div className='card-footer'>
        <div className='d-flex justify-content-between gap-2'>
          <div id='SwitchPages'>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
          </div>

          <div id='PagesFinder' className='text-center'>
            {' '}
            <span className='flex items-center gap-1'>
              Página{' '}
              <strong>
                {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
              </strong>{' '}
              | Ir a la página:
              <input
                type='number'
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
                className='form-control form-control-solid w-16'
              />
            </span>
          </div>

          <div id='showFiles' className='text-center'>
            <select
              className='form-select form-select-solid'
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Mostrar {pageSize} registros
                </option>
              ))}
            </select>
            <p>{table.getPrePaginationRowModel().rows.length} Registros en total</p>
          </div>
        </div>
      </div>

      {/* <div>
        <button onClick={() => rerender()}>Force Rerender</button>
      </div> */}
      {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre>  Aquí se pone toda la información de las propiedades de la tabla*/}

      {/* Estructura del modal de Nuevo registro*/}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        scrollable
        centered
        className='modal-md'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Compartir Acceso</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShareAccessAddModalForm formData={formData} setFormData={setFormData} errors={errors} />

          {alertInfo.text && (
            <div
              className={`alert alert-${alertInfo.variant} d-flex align-items-center`}
              role='alert'
            >
              <div>{alertInfo.text}</div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-sm btn-secondary' onClick={closeModalForNewShareAccess}>
            Cancelar
          </button>
          <button
            className='btn btn-sm btn-primary'
            type='submit'
            onClick={handleUserShareAccessFormSubmit}
          >
            Guardar
          </button>
        </Modal.Footer>
      </Modal>

      {/* Estructura del modal de Edición de registro*/}
      <Modal
        show={showModalEdit}
        onHide={() => setShowModalEdit(false)}
        scrollable
        centered
        className='modal-lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Editar usuario</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Pasamos los datos del usuario al formulario modal */}
          <ShareAcessEditModalForm
            formData={formData}
            setFormData={setFormData}
            userData={selectedUser}
            errors={errors}
          />
          {alertInfo.text && (
            <div
              className={`alert alert-${alertInfo.variant} d-flex align-items-center`}
              role='alert'
            >
              <div>{alertInfo.text}</div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-sm btn-secondary' onClick={closeModalForEditShareAccess}>
            Cancelar
          </button>
          <button className='btn btn-sm btn-primary' type='submit' onClick={handleFormEditSubmit}>
            Guardar
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

function Filter({column, table}: {column: Column<any, unknown>; table: Table<any>}) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Buscar... (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded'
        list={column.id + 'list'}
      />
      <div className='h-1' />
    </>
  )
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}
