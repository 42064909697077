// Importamos constantes para realizar la peticion a la API de marcas
import { DragonMainApi, EPBrandEdit, apiDragon } from '../../../utils/constants/_Constants';

// ---------------------------------------------------------------------------------------

// Importamos funcion para obtener codigos de respuesta del servidor
import { apiPetitions } from '../../../../_metronic/helpers/apis';

// Funcion para realizar la peticion a la API de edicion de marca
async function brandsEdit(brand_id: string, name_brand: string, crm_id: string, roleId: string, products: number[], userToken: string){

    try{
        const data = await apiPetitions(apiDragon,`${DragonMainApi+EPBrandEdit+brand_id}`, {
            nameBrand: name_brand,
            status:true,
            crmId: crm_id,
            roleId,
            products,

        }, {
           
            'Content-Type': 'application/json',
            'x-token': userToken
            
        },
        'put');

        const jsonDataBrandsEdit = data;

        // console.log('Marca editada con exito');
        
        return jsonDataBrandsEdit;

    
    } catch (error) {
        // console.log('Error en la edicion de marca ', error);
        throw error;
    }
}

export { brandsEdit };