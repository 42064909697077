import { FC, useState, useRef, useEffect } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { useThemeMode } from "../../../../../../_metronic/partials";
import { getCSS, getCSSVariableValue } from "../../../../../../_metronic/assets/ts/_utils";
import { Modal } from "react-bootstrap";
import { QaCountsReport } from "../interfaces/qaReportTypes";
import { dataExtract } from "../../../../../utils/functions/_Functions";
import { QaReportGeneralTable } from "../tables/QaReportGeneralTable";

type Props = {
    qaReportItem: QaCountsReport
}

const QaReportChart: FC<Props> = ({
    qaReportItem
}) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const { mode } = useThemeMode()

    const [modalTitle, setModalTitle] = useState<string>('');

    const [cardSelected, setCardSelected] = useState<number>();

    const [showModal, setShowModal] = useState<boolean>(false)

    const openModal = () => {
        setShowModal(true)
    }

    const closeModal = () => {
        setShowModal(false)
    }

    useEffect(() => {
        const chart = refreshChart()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, mode, qaReportItem])

    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))
        const c_type = 'donut'
        const serieOptions = ['Respuestas positivas', 'Respuestas negativas', 'Sin respuesta'];
        const serieCounts = [qaReportItem.totalPositiveQaResponse, qaReportItem.totalNegativeQaResponse, qaReportItem.totalNoQaResponse];

        console.log(serieOptions)
        console.log(serieCounts)

        const chart = new ApexCharts(chartRef.current, getChartOptions(height, c_type, qaReportItem, serieOptions, serieCounts, setModalTitle, openModal, cardSelected, setCardSelected))
        if (chart) {
            chart.render()
        }

        return chart

    }

    return (
        <>
            <div className='card-xxl-stretch mb-5 mb-xl-10'>
                <div className="">
                    <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '350px' }} />
                </div>
            </div>

            <Modal
                show={showModal}
                onHide={closeModal}
                className="modal-xl"
                scrollable
            >
                <Modal.Header
                    closeButton
                    onClick={() => {
                        closeModal()
                    }}
                >
                    <Modal.Title>
                        <h1>{modalTitle}</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <QaReportGeneralTable cardSelected={cardSelected} />
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="reset"
                        className="btn btn-sm btn-secondary"
                        onClick={() => {
                            closeModal()
                        }}
                    >
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export {QaReportChart}

function getChartOptions(height: number, c_type: any, crmReportItem:any, serieOptions: any, serieCounts: any, setModalTitle: any, openModal: any, cardSelected: any, setCardSelected: any): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')

    const colors = ['#00BDCD', '#ffa800', '#f64e60']

    const handleSelection = (indexSelection: number | undefined) => {

        console.log(indexSelection)

        if (indexSelection === 0) {
            console.log('Respuestas positivas')

            setModalTitle('Respuestas positivas')

            setCardSelected(3)

            openModal()
        } else if(indexSelection === 1) {
            console.log('Respuestas negativas')

            setModalTitle('Respuestas negativas')

            setCardSelected(4)

            openModal()
        }else if(indexSelection === 2){
            console.log('Sin respuesta')

            setModalTitle('Sin respuesta')

            setCardSelected(5)

            openModal()
        }

    }

    return {
        series: serieCounts,
        chart: {
            height: 450,
            type: c_type,
            fontFamily: "inherit",
            events: {
                legendClick: function (chartContext, seriesIndex, config) {
                    console.log('se dio click legendclick')
                    console.log('Se hizo clic en el elemento de la leyenda: ', seriesIndex);
                    handleSelection(seriesIndex)
                },

                dataPointSelection: function (event, chartContext, config) {
                    console.log('se dio click datapointselection')
                    console.log('Se hizo clic en el elemento: ', config.dataPointIndex)
                    const indexSelection: number = config.dataPointIndex
                    handleSelection(indexSelection)
                }

            },
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                export: {
                    csv: {
                        filename: "reporte-quality",
                    },
                    svg: {
                        filename: "reporte-quality",
                    },
                    png: {
                        filename: "reporte-quality",
                    },
                },
            }
        },
        colors: colors,
        labels: serieOptions,
        legend: {
            show: true,
            fontFamily: "inherit",
            fontSize: "13px",
            labels: {
                colors: labelColor
            },
            position: 'bottom',
            horizontalAlign: 'center',
        },
        stroke: {
            show: false,
        },
    }
}