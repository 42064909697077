import {FC, useState} from 'react'
import {UserShareAccessAddModalFormProps} from '../../../../interfaces/shareAccessTypes'
import {KTIcon} from '../../../../../../../_metronic/helpers'

const ShareAccessAddModalForm: FC<UserShareAccessAddModalFormProps> = ({
  formData,
  setFormData,
  errors,
}) => {
  const [emailError, setEmailError] = useState('')
  const [lvl_accessError, setlvl_accessError] = useState('')
  const [statusError, setStatusError] = useState('')

  const isEmailValid = (user_mail: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(user_mail)
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = e.target

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))

    // Validación específica para el campo de correo electrónico
    if (name === 'user_mail') {
      if (!isEmailValid(value)) {
        setEmailError('Correo electrónico no válido')
      } else {
        setEmailError('')
      }
    }

    // Validación específica para el campo de nivel de acceso
    if (name === 'lvl_access') {
      if (value === '') {
        setlvl_accessError('Este campo no puede estar vacio')
      } else {
        setlvl_accessError('')
      }
    }

    // Validación específica para el campo de estado
    if (name === 'status') {
      if (value === '') {
        setStatusError('Este campo no puede estar vacio')
      } else {
        setStatusError('')
      }
    }
  }

  return (
    <>
      <form>
        <div className='fv-row mb-7'>
          <div className='alert alert-warning d-flex align-items-center' role='alert'>
            <KTIcon iconName='information-4' className='fs-4x' />
            <div className='ml-6'>
              Antes de guardar tu registro, asegúrate de verificar el correo del usuario al que
              darás acceso a tu cliente.
            </div>
          </div>
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Correo</label>
          <input
            name='user_mail'
            type='email'
            id='email'
            placeholder='usuario@dragon.com'
            value={formData.user_mail}
            onChange={handleInputChange}
            className={`form-control form-control-lg form-control-solid ${
              (errors.user_mail || emailError) && 'is-invalid'
            }`}
          />
          {errors.user_mail && <div className='invalid-feedback'>{errors.user_mail}</div>}
          {emailError && <div className='invalid-feedback'>{emailError}</div>}
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Niveles de acceso</label>
          <select
            name='lvl_access'
            value={formData.lvl_access}
            onChange={handleInputChange}
            className={`form-control form-control-lg form-control-solid ${
              (errors.lvl_access || lvl_accessError) && 'is-invalid'
            }`}
          >
            <option value=''>Selecciona una opción</option>
            <option value='3'>Administrador</option>
            <option value='5'>Gerente</option>
          </select>
          {errors.lvl_access && <div className='invalid-feedback'>{errors.lvl_access}</div>}
          {lvl_accessError && <div className='invalid-feedback'>{lvl_accessError}</div>}
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Estatus del usuario</label>
          <select
            className={`form-control form-control-lg form-control-solid ${
              (errors.status || statusError) && 'is-invalid'
            }`}
            name='status'
            value={formData.status}
            onChange={handleInputChange}
          >
            <option value=''>Selecciona una opción</option>
            <option value='false'>Inactivo</option>
            <option value='true'>Activo</option>
          </select>
          {errors.status && <div className='invalid-feedback'>{errors.status}</div>}
          {statusError && <div className='invalid-feedback'>{statusError}</div>}
        </div>
      </form>
    </>
  )
}

export {ShareAccessAddModalForm}
