// Importamos hooks de react que usaremos para este componente
import React, { useContext, useEffect } from "react";
// Importamos componente Modal de la libreria de bootstrap para react
import { Modal } from "react-bootstrap";
// Importamos componente Separator de la libreria de react-aria-components para react
import { Separator } from "react-aria-components";
// Importamos componente isEmptyArray de la libreria de formik para detectar arreglos vacios
import { isEmptyArray } from "formik";

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from "../../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------

import { ModalDeleteMilestoneSingle } from "./ModalDeleteMilestoneSingle";

// Importamos la funcion sessionExpired para vencimiento de sesion
import { sessionExpired } from "../../../utils/functions/_Functions";

// ---------------------------------------------------------------------------------------

// Importamos funcion milestoneByRol para conexion con la API de listado de hitos por rol
import { milestoneByRol } from "../services/_RolesListApi";
// Importamos funcion roleMilestoneNew para conexion con la API de edicion de hito
import { roleMilestoneEdit } from "../services/_RolesMilestoneEditApi";

// ---------------------------------------------------------------------------------------

// Importamos las interfaces de roles que usaremos en este componente
import { UsersOrGroups } from "../interfaces/rolesTypes";

// Iniciamos con la construccion del componente ModalEditMilestoneSingle y recibimos props
function ModalEditMilestoneSingle({
    closeModalEdit,
    showModalEdit,
    selectedMilestoneIndex,
    rolId,
    rolName,
    getMilestoneRegister,
    formDataEditMilestone,
    setFormDataEditMilestone,
    startHour24,
    endHour24,
    selectedInfo,
    usersOrGroupsForSwitch,
    setUsersOrGroupsForSwitch,
    alertInfoInput,
    setAlertInfoInput,
    alertInfo,
    setAlertInfo,
    buttonSubmitState,
    setButtonSubmitState,
    openModalDelete,
    closeModalDelete,
    showModalDelete
}) {
    // console.log(typeof startHour24);
    // console.log(typeof endHour24);
    // console.log('index:',selectedMilestoneIndex);

    //Algunas variables desde el usercontext
    const { token, updateToken } = useContext(UserContext);
    const userToken = token;

    // Variable para guardar el arreglo de id de usuarios o grupos actuales
    let currentUsersOrGroupsAssociated: string[] = [];

    // Funcion para obtener la informacion del hito seleccionado
    const getMilestoneItem = async () => {

        try {

            const { data: jsonData, code, msg } = await milestoneByRol(userToken, rolId);

            if (code === 401 && msg === 'Token no valido') {

                sessionExpired('session ha expirado');
                return;

            } else {

                const milestoneData = jsonData.assign.schedule[selectedMilestoneIndex];

                // console.log(milestoneData)

                const groupsAssociated = jsonData.assign.groupsAssociated;
                const usersAssociated = jsonData.assign.usersAssociated;

                const userTokenNew = jsonData.token;

                updateToken(userTokenNew);

                if (isEmptyArray(groupsAssociated) === false && isEmptyArray(usersAssociated) === false) {

                    const newGroupAssociated: UsersOrGroups[] = [];

                    for (const group of groupsAssociated) {
                        if (group.groupRoles) {
                            newGroupAssociated.push({
                                itemId: group.groupId,
                                itemName: group.groupName,
                            })
                        }
                    }

                    setUsersOrGroupsForSwitch(newGroupAssociated);
                    // const newGroupsAssociated: string[] = [];

                    for (const group of groupsAssociated) {
                        if (group.groupRoles.includes(parseInt(selectedMilestoneIndex))) {
                            currentUsersOrGroupsAssociated.push(group.groupId);
                        }
                    }

                    // currentUsersOrGroupsAssociated = newGroupsAssociated;

                } else if (isEmptyArray(groupsAssociated) === true && isEmptyArray(usersAssociated) === false) {

                    const newUserAssociated: UsersOrGroups[] = [];

                    for (const user of usersAssociated) {
                        if (user.userRoles) {
                            newUserAssociated.push({
                                itemId: user.usrId,
                                itemName: user.usrName,
                            })
                        }
                    }

                    setUsersOrGroupsForSwitch(newUserAssociated);

                    // const newUsersAssociated: string[] = [];

                    for (const user of usersAssociated) {
                        if (user.userRoles.includes(parseInt(selectedMilestoneIndex))) {
                            currentUsersOrGroupsAssociated.push(user.usrId);
                        }
                    }

                    // currentUsersOrGroupsAssociated = newUsersAssociated;

                }

                const newFormDataEdit = {
                    ...formDataEditMilestone,
                    start: typeof startHour24 === 'string' ? startHour24 : milestoneData.start,
                    end: typeof endHour24 === 'string' ? endHour24 : milestoneData.end,
                    milestoneIndex: parseInt(selectedMilestoneIndex),
                    sellersOrGroups: currentUsersOrGroupsAssociated,
                    color: milestoneData.color,
                    textColor: milestoneData.text_color,
                };

                setFormDataEditMilestone(newFormDataEdit);
                // console.log('estado del formdata: ',formDataEdit.start)
                // console.log('estado del formdata: ',formDataEdit.sellersOrGroups)

            }

        } catch (error) {
            console.log(error);
        }

    }

    // Funcion para ver si el elemento aparecera seleccionado o no
    const isSellerOrGroupAssociated = (userOrGroup: string) => {
        return formDataEditMilestone.sellersOrGroups.includes(userOrGroup);
    }

    // Funcion para validar los elementos input del formulario
    const handleInputValidation = (errorType: string, inputName: string) => {

        switch (errorType) {
            case 'endHourSmaller':
                console.log('entra caso end')
                setAlertInfoInput({
                    text: "La hora de fin debe ser posterior a la de inicio",
                    variant: 'danger',
                    inputName: inputName
                });
                setButtonSubmitState(true);
                break;

            case 'startHourEmpty':
                setAlertInfoInput({
                    text: "Debes ingresar una hora de inicio",
                    variant: 'danger',
                    inputName: inputName
                });
                setButtonSubmitState(true);
                break;
            case 'endHourEmpty':
                setAlertInfoInput({
                    text: "Debes ingresar una hora de fin",
                    variant: 'danger',
                    inputName: inputName
                });
                setButtonSubmitState(true);
                break;
            case 'success':
                setAlertInfoInput({
                    text: '',
                    variant: '',
                    inputName: inputName
                });
                setButtonSubmitState(false);
                break;
            default:
                break;
        }
    }

    // Funcion para manejar los cambios en los elementos input que son los de seleccion de color
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormDataEditMilestone((prevData) => {
            const newData = {
                ...prevData,
                [name]: value,
            };
            return newData;
        })
    }

    // Funcion para manejar el cambio en el select de hora de inicio
    const handleStartHourChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;

        setFormDataEditMilestone((prevData) => {
            const newData = {
                ...prevData,
                [name]: value,
            };
            return newData;
        })

        if (formDataEditMilestone.end && value >= formDataEditMilestone.end && value !== '') {
            handleInputValidation('endHourSmaller', 'end');
        } else if (value === '') {
            handleInputValidation('startHourEmpty', name);
        } else {
            handleInputValidation('success', 'end');
        }
    }

    // Funcion para manejar el cambio en el select de hora de fin
    const handleEndHourChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;

        setFormDataEditMilestone((prevdata) => {
            const newData = {
                ...prevdata,
                [name]: value,
            };
            return newData;
        })

        if (formDataEditMilestone.start && value <= formDataEditMilestone.start && value !== '') {
            handleInputValidation('endHourSmaller', name);
        } else if (value === '') {
            handleInputValidation('endHourEmpty', name);

        } else {
            handleInputValidation('success', name);
        }

    }

    // funcion para manejar los cambios en los elementos de switch (vendedores o grupos)
    const handleCheckboxChange = (userOrGroup: string) => {

        const foundIndex = formDataEditMilestone.sellersOrGroups.findIndex((item) => item === userOrGroup);

        if (foundIndex !== -1) {

            const updateSellersOrGroups = [...formDataEditMilestone.sellersOrGroups];
            updateSellersOrGroups.splice(foundIndex, 1);
            setFormDataEditMilestone({
                ...formDataEditMilestone,
                sellersOrGroups: updateSellersOrGroups
            });

        } else {

            setFormDataEditMilestone({
                ...formDataEditMilestone,
                sellersOrGroups: [...formDataEditMilestone.sellersOrGroups, userOrGroup]
            })

        }

        // console.log('Arreglo actualizado:', formDataEditMilestone.sellersOrGroups);

    }

    // Funcion para el envio de formulario de creacion de hito
    const handleSubmitFormEdit = async () => {
        const start: string = formDataEditMilestone.start;
        let end: string;

        if (formDataEditMilestone.end === '23:59') {

            end = '00:00';

        } else {

            end = formDataEditMilestone.end;
        }

        const milestoneIndex = formDataEditMilestone.milestoneIndex;
        const sellersOrGroups = formDataEditMilestone.sellersOrGroups;
        const color = formDataEditMilestone.color;
        const textColor = formDataEditMilestone.textColor;
        console.log('---estos datos se estan mandando---');
        // console.log(start)
        console.log(end)
        // console.log(milestoneIndex)
        // console.log(sellersOrGroups)
        // console.log(color)
        // console.log(textColor)
        // console.log(rolId);

        if (start === '') {
            handleInputValidation('startHourEmpty', 'start');
        } else if (end === '') {
            handleInputValidation('endHourEmpty', 'end');
        } else {

            try {

                const { data: jsonData, code, msg } = await roleMilestoneEdit(
                    start,
                    end,
                    milestoneIndex,
                    sellersOrGroups,
                    color,
                    textColor,
                    rolId,
                    userToken
                );

                if (code === 401 && msg === 'Token no valido') {

                    sessionExpired('sesion expiro');
                    return;

                } else {

                    setAlertInfo({
                        text: code === 200 ? 'Se edito correctamente' : 'Ha ocurrido un error',
                        variant: code === 200 ? 'success' : 'danger',
                    });

                    setTimeout(() => {
                        setAlertInfo({
                            text: '',
                            variant: '',
                        });
                        closeModalEdit();
                        getMilestoneRegister(rolId);
                    }, 3000);

                }

            } catch (error) {
                setAlertInfo({
                    text: 'Error en la solicitud Axios',
                    variant: 'danger',
                });
            }

        }

    }

    // Variable de tipo arreglo donde guardare el listado de horas para los select de hora
    const hoursAvailable: string[] = [];

    // Funcion para llenar el arreglo de horas en intervalos de 30 minutos
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            const formatedHour = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            hoursAvailable.push(formatedHour);
        }
    }

    // Efecto para ejecutar la consulta de informacion del hito al abrir el modal
    useEffect(() => {

        if (showModalEdit) {
            getMilestoneItem();
        }

    }, [showModalEdit]);

    // console.log({formDataEditMilestone});

    return (
        <>
            <Modal
                show={showModalEdit}
                onHide={() => {
                    closeModalEdit();

                    if (selectedInfo) {
                        selectedInfo.revert();
                    }

                }}
                scrollable
            >
                <Modal.Header
                    closeButton
                    onClick={() => {
                        closeModalEdit();

                        if (selectedInfo) {
                            selectedInfo.revert();
                        }

                    }}>
                    <Modal.Title>
                        <h1>Editar hito</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex">
                        <div className="col-6 me-2">
                            <label className="fs-6 fw-bold required">Hora de inicio: </label>
                            <select
                                className="form-select form-select-solid" name="start" value={formDataEditMilestone.start} onChange={handleStartHourChange}
                            >
                                <option value="">----</option>
                                {hoursAvailable.map((hour, index) => (
                                    <option key={index} value={hour}>{hour}</option>
                                ))}
                            </select>
                            {alertInfoInput.text && alertInfoInput.variant === 'danger' && alertInfoInput.inputName === 'start' && (
                                <div className='mb-4'>
                                    <span className={`text-${alertInfoInput.variant}`}>*{alertInfoInput.text}</span>
                                </div>
                            )}
                        </div>
                        <div className="col-6">
                            <label className="fs-6 fw-bold required">Hora de fin:</label>
                            <select
                                className="form-select form-select-solid" name="end" value={formDataEditMilestone.end} onChange={handleEndHourChange}
                            >
                                <option value="">----</option>
                                {hoursAvailable.map((hour, index) => (
                                    <option key={index} value={hour}>{hour}</option>
                                ))}
                                <option value="23:59">23:59</option>
                            </select>
                            {alertInfoInput.text && alertInfoInput.variant === 'danger' && alertInfoInput.inputName === 'end' && (
                                <div className='mb-4'>
                                    <span className={`text-${alertInfoInput.variant}`}>*{alertInfoInput.text}</span>
                                </div>
                            )}
                        </div>
                    </div>

                    <Separator className="my-6" />

                    <div className="d-flex">
                        <div className="col-6">
                            <label className="form-label fw-bold mr-4">Color del hito:</label>&nbsp;&nbsp;&nbsp;
                            <input type="color" name="color" value={formDataEditMilestone.color} onChange={handleInputChange} />
                        </div>
                        <div className="col-6">
                            <label className="form-label fw-bold mr-4">Color de texto:</label>&nbsp;&nbsp;&nbsp;
                            <input type="color" name="textColor" value={formDataEditMilestone.textColor} onChange={handleInputChange} />
                        </div>
                    </div>

                    <Separator className="my-6" />

                    <div id="switchContainer">

                        {usersOrGroupsForSwitch.map((item: UsersOrGroups) => (
                            <div
                                className="form-check form-switch form-switch-sm form-check-custom form-check-solid mb-4"
                                key={item.itemId}
                            >
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name={`item_${item.itemId}`}
                                    checked={isSellerOrGroupAssociated(item.itemId)}
                                    onChange={() => handleCheckboxChange(item.itemId)}
                                />
                                <label>{item.itemName}</label>
                            </div>
                        ))}

                    </div>
                </Modal.Body>

                {alertInfo.text && (
                    <div className='px-20'>
                        <div
                            className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`}
                            role='alert'
                        >
                            <div>{alertInfo.text}</div>
                        </div>
                    </div>
                )}

                <Modal.Footer>
                    <button
                        className="btn btn-sm btn-secondary"
                        type="button"
                        onClick={() => {
                            closeModalEdit();

                            if (selectedInfo) {
                                selectedInfo.revert();
                            }

                        }}
                    >
                        Cancelar
                    </button>

                    <button
                        className="btn btn-sm btn-danger"
                        type="button"
                        onClick={() => {
                            openModalDelete();
                            closeModalEdit();
                        }}
                    >
                        Eliminar hito
                    </button>

                    <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        disabled={buttonSubmitState}
                        onClick={() => {
                            handleSubmitFormEdit();
                        }}
                    >
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>

            <ModalDeleteMilestoneSingle
                closeModalDelete={closeModalDelete}
                showModalDelete={showModalDelete}
                selectedMilestoneIndex={selectedMilestoneIndex}
                rolId={rolId}
                rolName={rolName}
                getMilestoneRegister={getMilestoneRegister}
                alertInfo={alertInfo}
                setAlertInfo={setAlertInfo}
            />

        </>

    )

}

export { ModalEditMilestoneSingle };