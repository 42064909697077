// Importamos constantes y funciones necesarias
import { apiDragon, DragonMainApi, EPClientsSharedBUList } from '../../../utils/constants/_Constants';
import { apiGetDeletePetitions } from '../../../../_metronic/helpers/apis'

// Iniciamos funcion asincronica amClientsBUList para obtener el listado de clientes compartidos por unidad de negocio
export const amClientsBUList = async (userToken: string, clientId: string): Promise<any> => {

    try {

        // Realizamos peticion a la API
        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi + EPClientsSharedBUList + clientId}`, {
            /* Los parametros se mandan como undefined
            ya que estos se envian solo en peticiones de eliminacion 'delete' */
            undefined

        }, {
            // Encabezados de la solicitud que incluyen el token de usuario y el tipo de contenido
            'Content-Type': 'application/json',
            'x-token': userToken
        },

            'get'); // Método de la solicitud API, en este caso, 'get'

        // Guardamos la data regresada por la API
        const jsonDataClientsBUList: any = data;

        // console.log(jsonDataClientsBUList)

        // Devolvemos los datos obtenidos
        return jsonDataClientsBUList;

    } catch (error: any) {

        // throw error;
        console.log('error getting shared clients list: ', error)

    }

}