import {Route, Routes} from 'react-router-dom'
import { Login } from './aux-components/Login'
import { LoginLayout } from './aux-components/LoginLayout'
import { RecoverPassword } from './aux-components/RecoverPassword'
import { NewPassword } from './aux-components/NewPassword'

const AuthPage = () => (
  <Routes>
    <Route element={<LoginLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='recover-password' element={<RecoverPassword />}/>
      <Route path='new-password' element={<NewPassword />}/>
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}