import { FC } from "react";

const ModalDcemNew: FC = () => {

    return (
        <>
            <div className='px-7'>

                <div className='mb-5'>
                    <div>
                        <label className='fs-6 fw-bold required'>
                            URL
                        </label>
                        <input
                            required
                            type='url'
                            name='shortedLink'
                            className='form-control form-control-solid mb-3 mb-lg-0 required'
                            placeholder='Pega la URL a recortar'
                        />
                    </div>
                </div>

                <div className='mb-5'>
                    <div>
                        <label className='fs-6 fw-bold required'>
                            Nombre
                        </label>
                        <input
                            required
                            type='mail'
                            name='nameLink'
                            className='form-control form-control-solid mb-3 mb-lg-0 required'
                            placeholder='Asigna un nombre al Link DCEM'
                        />
                    </div>
                </div>
            </div>
        </>
    )

}

export { ModalDcemNew }