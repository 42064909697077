import { FC, useState } from "react";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { iscoreReport } from "../interfaces/iscoreReportTypes";
import '../../../../../utils/assets/css/styles.css'
import { IscoreReportCountsTable } from "../tables/IscoreReportCountsTable";

type Props = {
  iscoreReportItemByProduct: iscoreReport;
  selectedIdProduct: string;
};

// Función para asignar colores de acuerdo a la categoría
const getCategoryColor = (category): string => {
  switch (category) {
    case "No Localizable":
      return "text-danger";
    case "Sin interaccion":
      return "text-secondary";
    case "Interaccion Baja":
      return "text-warning";
    case "Interaccion Media":
      return "text-info";
    case "Interaccion Alta":
      return "text-primary";
    case "Interaccion Muy Alta":
      return "text-success";
    default:
      return "text-gray-400";
  }
};

const LeadsIscoreCountsItem: FC<Props> = ({
  iscoreReportItemByProduct,
  selectedIdProduct,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [cardTitleSelected, setCardTitleSelected] = useState<string>("");
  const [cardSelected, setCardSelected] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);

  console.log({iscoreReportItemByProduct})

  const openModal = (title: string) => {
    setShowModal(true);
    setCardTitleSelected(title);
  };

  const closeModal = () => {
    setShowModal(false);
    setCardTitleSelected("");
  };

  const handleMouseEnter = (card: number) => {
    setIsHovered(true);
    setCardSelected(card);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setCardSelected(0);
  };

  return (
    <>
      <div className="d-flex flex-wrap justify-content-center">
        {/* Tarjeta de Total Leads Recibidos */}
        
          <div
            // onMouseEnter={() => handleMouseEnter(1)}
            // onMouseLeave={() => handleMouseLeave()}
            // onClick={() => openModal("Total Leads recibidos")}
            className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === 1 ? 'bg-gray-200' : ''}`}
          >
            <div className="d-flex align-items-center">
              <KTIcon iconName="toggle-on" className="fs-3 text-success me-2" />
              <div className="fs-2 fw-bolder me-2">
                {iscoreReportItemByProduct.totalLeads}
              </div>
              <div className="fw-bold fs-6 text-gray-400">Leads recibidos</div>
            </div>
          </div>

        {/* Tarjetas dinámicas para cada categoría */}
        {iscoreReportItemByProduct.data.map((item, index) => (
          <OverlayTrigger
            key={index}
            placement="top"
            overlay={<Tooltip>Ver {item.category}</Tooltip>}
          >
            <div
              onMouseEnter={() => handleMouseEnter(index + 2)}
              onMouseLeave={() => handleMouseLeave()}
              onClick={() => openModal(item.category.toString())}
              className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === index + 2 ? 'bg-gray-200' : ''} cursor-pointer`}
            >
              <div className="d-flex align-items-center">
                <KTIcon
                  iconName="toggle-on"
                  className={`fs-3 text-gray me-2`} // Aquí se aplica el color
                />
                <div className="fs-2 fw-bolder me-2">{item.count}</div> 
                <div className="fw-bold fs-6 text-gray-400">{item.category}</div>
              </div>
            </div>
          </OverlayTrigger>
        ))}
      </div>

      <Modal
        show={showModal}
        onHide={closeModal}
        className="modal-xl"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>{cardTitleSelected}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IscoreReportCountsTable selectedIdProduct={selectedIdProduct} iscoreReportItemByProduct={iscoreReportItemByProduct} cardTitleSelected={cardTitleSelected}/>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="reset"
            className="btn btn-sm btn-secondary"
            onClick={closeModal}
          >
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { LeadsIscoreCountsItem };
