import React from 'react'
import { SupportInfoComponent } from './SupportInfoComponent'
import { useIntl } from 'react-intl'


export const Support = () => {

  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <h1 className='my-2'>{intl.formatMessage({ id: 'SUPPORT.TITLE' })}</h1>
            <div className='card card-body p-10'>
              <SupportInfoComponent/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
