import React from "react";
import { formatTextMIddleDash } from "../../../utils/functions/_Functions";
type Props = {
    product: any
}
const ModalRenameProduct: React.FC<Props> = ({
    product,
}) => {

    return (
        <>
            <div className='mb-6'>
              <label className='fs-6'>Nombre actual</label>
              <input 
                className='form-control form-control-solid'
                value={formatTextMIddleDash(product.name)}
                readOnly={true}
              />
            </div>

            <div>
              <label className='required fs-6'>Nuevo nombre</label>
              <input 
                className='form-control form-control-solid'
                // value={currentClientNameSelected}
                placeholder=''               
              />
            </div>

            <div className="mt-4">
              <label className='required fw-bold fs-6 mb-2'>Motivo cambio de nombre</label>
              <select className='form-select form-select-lg form-select-solid mb-4'>
                <option value='0'>
                  --Selecciona el motivo de la actualización--
                </option>
                <option value='1'>
                  El producto no está generando el retorno de inversión esperado.
                </option>
                <option value='2'>Cambios en la estrategia de marketing de la empresa.</option>
                <option value='3'>El producto ha alcanzado el final de su ciclo de vida útil.</option>
                <option value='4'>
                  El producto ha sido reemplazado por una versión mejorada o una alternativa más
                  efectiva.
                </option>
                <option value='5'>
                  La demanda del mercado para el producto ha disminuido significativamente.
                </option>
              </select>
            </div>
          </>

    )
}

export {ModalRenameProduct};