import {useIntl} from 'react-intl'
import {DragonCem} from '../../utils/constants/_Constants'
import {setPageTitle} from '../../utils/functions/_Functions'
import {ShareAccessTableComponent} from './aux-components/tabs/ShareAccessTab/ShareAccessTableComponent'
import {useEffect} from 'react'

export const ShareAccess = () => {
  const intl = useIntl()
  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    setPageTitle(`${intl.formatMessage({id: 'SHAREACCESS.TITLE'})} | ${DragonCem}`)
  }, [])

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <h1 className='my-2'>Share Access</h1>
            <div className='card card-custom'>
              <ShareAccessTableComponent />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
