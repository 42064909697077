import axios from 'axios'
import {DragonMainApi, EPRoleEdit, apiDragon} from '../../../utils/constants/_Constants'
import {apiPetitions} from '../../../../_metronic/helpers/apis'

async function roleEdit(
  idRole: string,
  users: string[],
  idGroups: string[],
  name: string,
  userToken: string
) {
  // console.log('token antes del envio en brands: ', userToken)
  try {
    // Construye el objeto de datos condicionalmente
    const data: {
      users?: string[],
      idGroups?: string[],
      name?: string // Hacer el campo name opcional
    } = {};

    console.log(users)
    console.log(idGroups)

     // Verificar y agregar users si no está vacío y idGroups es cero
     if (users.length > 0 && idGroups.length === 0) {
      data.users = users;
    }

    // Verificar y agregar groups si no está vacío
    if (idGroups.length > 0 ) {
      data.idGroups = idGroups;
    }

    // Verificar y agregar name si no está vacío
    if (name.trim() !== '') {
      data.name = name;
    }

    const response = await apiPetitions(
      apiDragon,
      `${DragonMainApi}${EPRoleEdit}/${idRole}`,
      data,
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'put'
    )

    const jsonDataRoleEdit = response
    console.log(jsonDataRoleEdit)
    return jsonDataRoleEdit

    // Resto de tu código de manejo de respuesta
  } catch (error) {
    throw error
  }
}

export {roleEdit}
