// Importamos constantes para realizar la peticion a la API de marcas
import {DragonMainApi, EPBrandActivate, apiDragon} from '../../../utils/constants/_Constants'

// ---------------------------------------------------------------------------------------

// Importamos funcion para obtener codigos de respuesta del servidor
import {apiPetitions} from '../../../../_metronic/helpers/apis'

//Función para realizar la petición a la API de desactivación de marcas
async function brandStatusActivate(brand_id: string, userToken: string) {
  try {
    const data = await apiPetitions(
      apiDragon,
      `${DragonMainApi + EPBrandActivate + brand_id}`,{},
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'put')

    const jsonDataBrandsActivate = data

    return jsonDataBrandsActivate
  } catch (error) {
    throw error
  }
}

export {brandStatusActivate}
