import axios from 'axios';
import { DragonMainApi, EPGroupSellersNew, apiDragon } from '../../../../utils/constants/_Constants';
import { apiPetitions } from '../../../../../_metronic/helpers/apis';

async function GroupsellersAdd(
    idClient: string,
    users: string[],
    groupName: string,
    manager: string,
    userToken: string,
    ){

    interface RequestData {
        idClient: string;
        users: string[];
        groupName: string;
        manager?: string;
        }

    try{
        const requestData: RequestData = { // Definimos requestData como any para poder agregar propiedades dinámicamente
            idClient,
            users,
            groupName,
            
        };
        
        // Verificamos si userPrivilage es distinto de 0 antes de agregarlo al objeto requestData
        if (manager !== '') {
            requestData.manager = manager;
        }
        console.log({requestData})
        
        const response = await apiPetitions(apiDragon, DragonMainApi+EPGroupSellersNew, requestData, {
            'Content-Type': 'application/json',
            'x-token': userToken
        }, 'post');
        console.log({response});

        return response;

    } catch (error) {
        throw error;
        // console.log('Error de nuevo Vendedor ', error)
    }
}

export { GroupsellersAdd };