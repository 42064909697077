import React from 'react';
import { FC } from 'react';

const PrivacyNotice: FC = () => {
  return (
    <>
      {/* Begin::Container */}
      <div className="container" style={{ maxHeight: '80vh', overflowY: 'auto'}}>
        {/* New card DC */}
        <div className="card">
          <div className="card-body">
            <div className="mb-6">
              <h1 className="pt-6 pb-6 text-center">Aviso de privacidad</h1>
              <div className="separator separator-solid separator-border-1 mb-6"></div>
              
              <p>
                <strong>API DIGITAL, S.A.P.I. DE C.V.</strong>, en su carácter de responsable de datos personales, a través del sitio 
                <a href="https://www.dragoncem.com" target="_blank" rel="noopener noreferrer"> www.dragoncem.com</a>, en el uso de información y protección de la misma.
              </p>
              
              <p><strong>Origen de la Información</strong></p>
              <ul>
                <li>Primaria: Portal <a href="https://www.dragoncem.com" target="_blank" rel="noopener noreferrer">www.dragoncem.com</a></li>
                <li>Secundaria: Vía correo electrónico y/o llamada telefónica y/o cualquier otro medio digital en seguimiento de información conseguida por la vía primaria.</li>
              </ul>
              
              <p><strong>Información Primaria recabada:</strong></p>
              <ul>
                <li>Nombre del interesado</li>
                <li>Correo electrónico</li>
                <li>Teléfono</li>
                <li>Comentarios</li>
              </ul>
              
              <p><strong>Información Secundaria recabada:</strong></p>
              <ul>
                <li>Hora de recepción del mensaje</li>
                <li>Hora de Whatsapp (si aplica)</li>
                <li>Mensajes de Whatsapp (si aplica)</li>
                <li>Respuestas de Whatsapp (si aplica)</li>
                <li>Hora de Email (si aplica)</li>
                <li>Apertura de Email (si aplica)</li>
                <li>Click en Email (si aplica)</li>
                <li>Hora de Llamada telefónica (si aplica)</li>
                <li>Duración de Llamada telefónica (si aplica)</li>
                <li>Ubicación geográfica de la llamada telefónica (si aplica)</li>
                <li>Grabación de la llamada (si aplica)</li>
              </ul>
              
              <p><strong>Finalidad Principal de los Datos Personales recabados:</strong></p>
              <p>Elaboración de estadística respecto al perfil que visita el portal <a href="https://www.dragoncem.com" target="_blank" rel="noopener noreferrer">www.dragoncem.com</a>, con el fin de mejorar la atención al público.</p>
              
              <p><strong>Acceso, rectificación, cancelación o negativa para el trato de datos personales</strong></p>
              <p>
                Desde este momento usted nos lo puede manifestar enviando un correo electrónico a la dirección 
                <a href="mailto:hola@dragoncem.com">hola@dragoncem.com</a>, con el objeto de acceder, rectificar, cancelar el uso de datos personales, ya sea con fines principales o adicionales.
                La negativa para el uso de sus datos personales para estas finalidades no será un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.
                La petición será resuelta en un máximo de cinco días hábiles al correo electrónico establecido por el interesado.
              </p>
              
              <p><strong>¿Con quién compartimos su información personal y para qué fines?</strong></p>
              <p>
                API DIGITAL, S.A.P.I. de C.V. para poder realizar sus funciones, utiliza proveedores externos en Estados Unidos, con el fin de proveer un mejor servicio a sus clientes para las siguientes funciones:
              </p>
              <ul>
                <li>Enlaces telefónicos</li>
                <li>Envío de mensajes y correos electrónicos</li>
                <li>Almacenamiento de información encriptada para API DIGITAL, S.A.P.I. de C.V.</li>
              </ul>
              
              <p><strong>¿Cómo puede revocar su consentimiento sobre el uso de su información personal?</strong></p>
              <p>
                Si desea dejar de recibir cualquier tipo de mensajes, ya sea de ofrecimiento de promociones o seguimiento de nuestra parte, puede solicitarlo mandando un correo electrónico a: 
                <a href="mailto:hola@dragoncem.com">hola@dragoncem.com</a>
              </p>
              
              <p><strong>¿Cómo puede limitar el uso o divulgación de su información personal?</strong></p>
              <p>
                Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios:
              </p>
              <p>
                Su inscripción en el Registro Público para Evitar Publicidad, que está a cargo de la Procuraduría Federal del Consumidor, con la finalidad de que sus datos personales no sean utilizados para recibir publicidad o promociones de empresas de bienes o servicios. Para mayor información sobre este registro, usted puede consultar el portal de Internet de la PROFECO, o bien ponerse en contacto directo con ésta.
              </p>
              
              <p><strong>¿Cómo puede conocer los cambios a este aviso de privacidad?</strong></p>
              <p>
                El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.
                Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través del portal 
                <a href="https://www.dragoncem.com" target="_blank" rel="noopener noreferrer">www.dragoncem.com</a>.
                El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad será a través del portal 
                <a href="https://www.dragoncem.com" target="_blank" rel="noopener noreferrer">www.dragoncem.com</a>.
              </p>
              
              <p><strong>Cookies y Web Beacons</strong></p>
              <p>
                Le informamos que utilizamos cookies y web beacons para obtener la siguiente información personal:
              </p>
              <ul>
                <li>El tipo de navegador web y Sistema Operativo</li>
                <li>Los enlaces que usted sigue</li>
                <li>El sitio web que visitó antes al nuestro siempre y cuando dicho sitio lo haya redireccionado al nuestro</li>
              </ul>
              
              <p>Última actualización [18/AGOSTO/2021]</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyNotice;
