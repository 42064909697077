// Importamos hooks de react que usaremos para este componente
import React, { useState, useContext } from "react";
// Importamos libreria para manejo de lenguaje 
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from "../../../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------

// Importamos la funcion campaignConnectInfoEdit para conexion con API de campañas
import { campaignConnectInfoEdit } from "../../services/_CampaignsEditApi";

// ---------------------------------------------------------------------------------------

/* Importamos funcion sessionExpired, convertObjectsToText y countKeys para vencimiento de sesion
para conversion de objetos a texto y conteo de indices */
import { convertObjectsToText, countKeys, sessionExpired } from "../../../../utils/functions/_Functions";

// ---------------------------------------------------------------------------------------

// Importamos las interfaces globales que usaremos para este modulo
import { AlertInfo } from "../../../../utils/interfaces/_InterfacesTypes";

// Iniciamos funcion para construir componente ConnectTabForm
function ConnectTabForm({formDataEditConnect, setFormDataEditConnect, cam_idCampaign, closeModalEdit, getCampaignsRegisters}){
    
    console.log(formDataEditConnect);
    console.log(formDataEditConnect.grade_options);
    
    //Variables desde el usercontext
    const { token } = useContext(UserContext);
    const userToken = token;

    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl();

    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        text: '',
        variant: 'primary',
    });
    
    const gradeOptionsText = convertObjectsToText(formDataEditConnect.grade_options);
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        const { name, value } = event.target;

        setFormDataEditConnect(prevState => ({
            ...prevState,
            [name]: value
        }));

    }

    const handleFormSubmit = async () => {
        console.log("FormDatasConnect values:");
        console.log("mail_template: ", formDataEditConnect.mail_template);
        console.log("wab_bot: ", formDataEditConnect.wab_bot);
        console.log("crm_id: ", formDataEditConnect.crm_id);
        console.log("grade_options: ", formDataEditConnect.grade_options);

        const mail_template = formDataEditConnect.mail_template;
        const wab_bot = formDataEditConnect.wab_bot;
        const crm_id = formDataEditConnect.crm_id;
        const grade_options = formDataEditConnect.grade_options;

        try {

            const { data: jsonData, code, msg } = await campaignConnectInfoEdit(mail_template, wab_bot, crm_id, grade_options, userToken, cam_idCampaign);

            if ( code === 401 && msg === 'Token no valido' ) {

                sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
                return;

            } else {

                setAlertInfo({
                    text: code === 200 ? 'Informacion editada' : 'Algo salio mal',
                    variant: code === 200 ? 'success' : 'danger'
                });

                setTimeout(() => {
                    setAlertInfo({
                        text: '',
                        variant: ''
                    });
                    closeModalEdit();
                    getCampaignsRegisters();
                }, 3000)

            }

        } catch (error) {

            console.log(error);

        }
        
    }

    return (
        <>
        <div className="d-flex mb-6">
            <label className="col-3">Configuración CRM: </label>
            <select className="form-select form-select-solid" name="crmconfig" id="crmconfig">
                <option value="Hubspot DragonCEM">Hubspot DragonCEM</option>
            </select>
            </div>
    
            <div className="d-flex mb-6">
            <label className="col-3">Canal CRM ID: </label>
            <input 
                type="text"
                className="form-control form-control-solid"
                id="channelcrm"
                name="channelcrm"
            />
            </div>
    
            <div className="d-flex mb-6">
            <label className="col-3">Mail template: </label>
            <select 
                className="form-select form-select-solid" 
                name="mail_template" 
                id="mail_template"
                value={formDataEditConnect.mail_template}
                onChange={handleInputChange}
            >
                <option value={formDataEditConnect.mail_template}>{formDataEditConnect.mail_template}</option>
                <option value="mail_option_example">Opcion de ejemplo</option>
            </select>
            </div>
    
            <div className="d-flex mb-6">
            <label className="col-3">Bot: </label>
            <select 
                className="form-select form-select-solid" 
                name="wab_bot" 
                id="wab_bot" 
                value={formDataEditConnect.wab_bot}
                onChange={handleInputChange}
            >
                <option value="botGralPruebaDragon">botGralPruebaDragon</option>
                <option value="botGralenglish">botGralenglish</option>
                <option value={formDataEditConnect.wab_bot}>{formDataEditConnect.wab_bot}</option>
            </select>
            </div>
    
            <div className="d-flex mb-6">
            <label className="col-3">CRM ID: </label>
            <input 
                type="text"
                className="form-control form-control-solid"
                value={formDataEditConnect.crm_id}
                id="crm_id"
                name="crm_id"
                onChange={handleInputChange}
            />
            </div>
    
            <div className="d-flex mb-6">
            <label className="col-3">Configuración de calificaciones: </label>
            <select 
                className="form-select form-select-solid" 
                name="grade_options" 
                id="grade_options" 
                value={formDataEditConnect.grade_options}
                onChange={handleInputChange}
            >
                <option value={formDataEditConnect.grade_options}>Configuración de {countKeys(formDataEditConnect.grade_options)} ponderadores</option>
            </select>
            </div>
    
            <div className="d-flex mb-6">
            <label className="col-3">Ponderadores:</label>
            <textarea
                id="ponderadores"
                className="form-control form-control-solid"
                value={gradeOptionsText}
                readOnly
                rows={4}
            >
            </textarea>
            </div>
    
            {alertInfo.text && (
                <div className={`alert alert-${alertInfo.variant} d-flex align-items-center`} role='alert'>
                    <div>{alertInfo.text}</div>
                </div>
            )}

            <button type="submit" className='btn btn-primary btn-sm mt-8' onClick={handleFormSubmit}>Enviar</button>

        </>

    )
   
}

export { ConnectTabForm };