/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ChartsWidgetCustom} from '../../../_metronic/partials/widgets'
import { setPageTitle } from '../../utils/functions/_Functions';
import { DragonCem } from '../../utils/constants/_Constants';

// import React from 'react'

const data1 = [44, 55, 57, 56, 61, 58];
const data2 = [76, 85, 101, 98, 87, 105];

const data3 = [50, 35, 69, 30, 15, 60];
const data4 = [80, 45, 110, 120, 35, 110];

const data5 = [12, 14, 20, 24, 25, 30];
const data6 = [60, 70, 85, 100, 95, 100];

const data7 = [30, 35, 60, 78, 10, 56];
const data8 = [90, 75, 130, 75, 65, 110];



const DashboardPage: FC = () => (
  
<>
  <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <ChartsWidgetCustom
            className='card-xxl-stretch mb-5 mb-xl-10' 
            title='Leads'
            subtitle='Mejores respuestas en los últimos 4 meses'
            type='bar'
            dataA={data1}
            dataB={data2}
            />
        </div>
        <div className='col-xl-6'>
          <ChartsWidgetCustom 
            className='card-xxl-stretch mb-5 mb-xl-10' 
            title='Campañas'
            subtitle='Valores más recientes'
            type='area'
            dataA={data3}
            dataB={data4}
            />
        </div>
  </div>
  <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <ChartsWidgetCustom 
            className='card-xxl-stretch mb-5 mb-xl-10' 
            title='QA'
            subtitle='Indices de respuesta actuales'
            type='bar'
            dataA={data5}
            dataB={data6}
            />
        </div>
        <div className='col-xl-6'>
          <ChartsWidgetCustom 
            className='card-xxl-stretch mb-5 mb-xl-10' 
            title='CRM'
            type='area'
            subtitle='Registros hechos en CRM'
            dataA={data7}
            dataB={data8}
            />
        </div>
  </div>
</>
)

const DashboardNew: FC = () => {
  const intl = useIntl();

  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    setPageTitle(`${intl.formatMessage({ id: "MENU.DASHBOARD" })} | ${DragonCem}`);
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardNew}
