import { Dropdown } from 'react-bootstrap';

export const DropdownItemExportCSV = ({ dataToExport, title }) => {
  console.log(dataToExport);

  const handleToExportProductsAssignedDataCSV = () => {
    try {
      // Verificar si hay datos para exportar
      if (!dataToExport || !dataToExport.brandProducts) {
        console.error('No hay datos para exportar o no hay productos disponibles.');
        return;
      }

      // Obtener el usuario y sus productos
      const { id, name, brandProducts } = dataToExport;

      // Crear una matriz para almacenar los datos del CSV
      const csvData = [
        ['Id usuario', 'Nombre usuario', 'Id del producto', 'Nombre del producto', 'Fecha de asignación'],
      ];

      // Recorrer los productos del usuario y agregarlos a la matriz del CSV
      brandProducts.forEach(product => {
        const productId = product.pro_id;
        const productName = product.name;
        csvData.push([id, name, productId, productName, '']);
      });

      // Convertir la matriz en formato CSV
      const csvContent = csvData.map(row => row.join(',')).join('\n');

      // Crear un enlace temporal para descargar el archivo CSV
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `Data exportada de los productos asignados a ${title}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error al generar el archivo CSV:', error);
    }
  };

  return (
    <Dropdown.Item>
      <span onClick={handleToExportProductsAssignedDataCSV}>
        Exportar productos asignados del vendedor (.csv)
      </span>
    </Dropdown.Item>
  );
};
