import React from 'react';
import { KTSVG } from '../../../../_metronic/helpers';

const AccordionItem = ({ itemId, title, content }) => {
  return (
    <div className="mb-5">
      <div
        className={`accordion-header py-3 d-flex ${itemId === 1 ? '' : 'collapsed'}`}
        data-bs-toggle="collapse"
        data-bs-target={`#kt_accordion_2_item_${itemId}`}
      >
        <span className="accordion-icon">
          <KTSVG className="svg-icon svg-icon-4" path="media/icons/duotune/arrows/arr064.svg" />
        </span>
        <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
          {title}
        </h3>
      </div>
      <div
        id={`kt_accordion_2_item_${itemId}`}
        className={`collapse fs-6 ps-10 ${itemId === 1 ? 'show' : ''}`}
        data-bs-parent="#kt_accordion_2"
      >
        {content}
      </div>
    </div>
  );
};

export default AccordionItem;
