// Importamos constantes para realizar la peticion a la API de marcas

import { apiPetitions } from "../../../../../_metronic/helpers/apis"
import { DragonMainApi, EPSellerActivate, apiDragon } from "../../../../utils/constants/_Constants"

//Función para realizar la petición a la API de desactivación de marcas
async function sellerStatusActivate(selller_id: string, userToken: string) {
  try {
    const data = await apiPetitions(
      apiDragon,
      `${DragonMainApi + EPSellerActivate + selller_id}`,{},
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'put')

    const jsonDataSellersActivate = data

    return jsonDataSellersActivate
  } catch (error) {
    throw error
  }
}

export {sellerStatusActivate}
