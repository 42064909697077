import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import {GradesReport} from './each-report/grades/GradesReport'
import {IscoreReport} from './each-report/iscore/IscoreReport'
import {CrmReport} from './each-report/crm/CrmReport'
import {QualityReport} from './each-report/quality/QualityReport'
import { NavLi } from '../../utils/components/navs/NavLi';
import { CampaignsReport } from './each-report/campaigns/CampaignsReport';
import { YearlyReport } from './each-report/yearly/YearlyReport';
import { InteractionsReport } from './each-report/interactions/InteractionsReport';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { setPageTitle } from '../../utils/functions/_Functions';
import { DragonCem } from '../../utils/constants/_Constants';
import { LeadsReport } from './each-report/leads/LeadsReport';

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Reports',
    path: '/reports/leads/LeadsReport',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const miPath = ['/reports/leads/LeadsReport','/reports/qualifications','/reports/crm','/reports/iscore','/reports/quality','/reports/yearly','/reports/campaigns','/reports/interacts']
const titleNav = ['Leads', 'Calificaciones', 'CRM', 'IScore', 'Quality', 'Anual', 'Campañas', 'Interacciones'];

const ReportsPage = () => {

  const intl = useIntl();
  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    setPageTitle(`${intl.formatMessage({ id: "REPORTS.TITLE" })} | ${DragonCem}`);
  }, [])

  return (
    <Routes>
      <Route
        element={
          <>
            <div className='container-fluid'>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div style={{ width: '95%'}}>
                      <NavLi 
                        miPath={miPath}
                        titleNav={titleNav}
                      />
                        <Outlet />
                      </div>
                    </div>
                </div>
            </div>
          </>
        }
      >
        <Route
          path='Leads/leadsReport'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Leads</PageTitle>
              <LeadsReport/>
            </>
          }
        />
        <Route
          path='qualifications'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Calificaciones</PageTitle>
              <GradesReport/>
            </>
          }
        />
        <Route
          path='iscore'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>iScore</PageTitle>
              <IscoreReport/>
            </>
          }
        />
        <Route
          path='crm'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>CRM</PageTitle>
              <CrmReport/>
            </>
          }
        />
        <Route
          path='quality'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Quality</PageTitle>
              <QualityReport/>
            </>
          }
        />
        <Route
          path='yearly'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Anual</PageTitle>
              <YearlyReport/>
            </>
          }
        />
        <Route
          path='campaigns'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Campaigns</PageTitle>
              <CampaignsReport/>
            </>
          }
        />
                <Route
          path='interacts'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Campaigns</PageTitle>
              <InteractionsReport/>
            </>
          }
        />
        <Route index element={<Navigate to='/reports/Leads/LeadsReport' />} />
      </Route>
    </Routes>
)
};

export {ReportsPage};
