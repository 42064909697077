import {FC, useState} from 'react'
import * as Yup from 'yup'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {Modal} from 'react-bootstrap'

// Definir el esquema de validación usando Yup
const validationSchema = Yup.object({
  email: Yup.string()
    .email('Correo electrónico inválido')
    .required('El correo electrónico es requerido'),
})

const RecoverPassword: FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const navigate = useNavigate(); // Hook para redirección
  // Configurar Formik
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      // Simular una solicitud y mostrar el modal
      console.log('Enviando valores:', values)
      setShowModal(true)
    },
  })

  const handleToCloseModalForNewService = () => {
    setShowModal(false)
    navigate('/login'); // Redirigir después de cerrar el modal
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-11'>
        <img
          className='mb-4'
          src='https://storage.googleapis.com/dragon-bucket-prod/app/img/LogoLogin.png'
          alt='icon'
        />

        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-2'>¿Olvidaste tu contraseña?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Ingresa el correo electrónico que usas para ingresar a la plataforma.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Correo electrónico</label>
        <input
          type='email'
          placeholder='user@domain.com'
          className='form-control bg-transparent'
          autoComplete='off'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className='text-danger'>{formik.errors.email}</div>
        ) : null}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-primary me-4'
          disabled={formik.isSubmitting}
        >
          <span className='indicator-label'>Enviar</span>
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
          >
            Cancelar
          </button>
        </Link>
      </div>
      {/* end::Form group */}

      {/* Modal */}
      <Modal
        show={showModal}
        onHide={handleToCloseModalForNewService}
        scrollable
        centered
        className='modal-md'
      >
        <Modal.Body>
          <div className='text-center'>
            Te hemos enviado un correo con instrucciones para reestablecer tu contraseña.
          </div>
        </Modal.Body>

        <Modal.Footer className='justify-content-center'>
          <button
            className='btn btn-primary btn-sm'
            type='button'
            onClick={handleToCloseModalForNewService}
          >
            Aceptar
          </button>
        </Modal.Footer>
      </Modal>
    </form>
  )
}

export {RecoverPassword}
