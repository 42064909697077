import React, { FC, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Crm, Lead, LeadDetailInfo } from '../../../../interfaces/leadsTypes'
import { LoadingAnimation } from '../../../../../../utils/components/loading/LoadingAnimation';

type Props = {
  leadData: LeadDetailInfo | undefined;
  currentNavSelected: string;
}

export const CrmNav: FC<Props> = ({
  leadData,
  currentNavSelected
}) => {
  const crmInitialData: Crm = {
    apiId: '',
    apiMessage: '',
    apiResponse: '',
    apidataSent: '',
    apiName: ''
  }

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>("");

  const [showModalForCrmResponse, setShowModalForCrmResponse] = useState(false)
  const [showModalForSendData, setShowModalForSendData] = useState(false)
  const [crmDataApi, setCrmDataApi] = useState(crmInitialData)
  const [crmResponse, setCrmResponse] = useState('')
  const [sendData, setSendData] = useState('')

    //Funcion para verificar el tamaño del arreglo de llamadas
    const checkWhatsappsListLength = () => {
      //Seteamos mensaje informativo
      crmDataApi.apiId.length === 0 && setInfoMessageLoading("No hay registros de CRM")
    }

  const getCRM = async () => {
    //Simulación de la respuesta de la API

    setTimeout(() => {
      try {
        
        const crmDataApi: Crm = {
          apiId: '12345-ASDAFAS-SDASD',
          apiMessage: 'ok',
          apiResponse: '[{"code":0,"msg":"Ok","details":[{"tkContacto":"P-D8443628-1ADA-4D5F-B385-4DBA57DAS51DA","action":"updated"}]}]',
          apidataSent: '//Interface para CRM en detalle de lead export interface Crm {apiId: string;apiMessage: string;apiResponse: string;apidataSent: string;apiName: string;}',
          apiName: 'crm-api'
        }
    
        setCrmDataApi(crmDataApi)
        setIsLoading(false);
        //Verificamos el tamaño del arreglo de llamadas disparando la funcion
        checkWhatsappsListLength()

      } catch (error) {
        console.log('Error obteniedo los datos de CRM: ', error)
        //Ocultamos la animacion de carga
        setIsLoading(false);
        //Seteamos mensaje informativo
        setInfoMessageLoading('Hubo un error en la petición')
      }
    }, 2000);
  }

  useEffect(() => {
    getCRM()
  }, [])

  const handleToOpenCrmRespondeModal = (crmData: Crm) => {
    setShowModalForCrmResponse(true)
    setCrmResponse(crmData.apiResponse)

  }

  const handleToCloseCrmRespondeModal = () => {
    setShowModalForCrmResponse(false)
  }

  const handleToOpenSendDataModal = (crmData: Crm) => {
    setShowModalForSendData(true)
    setSendData(crmData.apidataSent)
  }


  const handleToCloseSendDataModal = () => {
    setShowModalForSendData(false)
  }

  return (
    <>
      <div className='container ml-15 mt-4 py-12' id='crm-container'>
      {isLoading && <div className='py-12'><LoadingAnimation alignment='center' label='Obteniendo registros del CRM...' /></div>}

      {!isLoading && crmDataApi.apiId.length > 0 && (
        <>
      <div className='mb-2 border-bottom border-dark'>
        <div className='mb-1 text-info fw-bold'>CRM</div>
        <div>
          <span className='mb-1 fw-bold fw-bold'>ID:</span>
          <span>{crmDataApi.apiId}</span>
        </div>
        <div>
          <span className='mb-1 fw-bold'>Mensaje:</span>
          <span className='ml-3'>{crmDataApi.apiMessage}</span>
        </div>
        <div className='d-flex align-items-center mt-3 mb-3'>
          <button className='btn btn-light-success me-2' onClick={() => handleToOpenCrmRespondeModal(crmDataApi)}>
            Respuesta CRM
          </button>
          <button className='btn btn-light-success' onClick={() => handleToOpenSendDataModal(crmDataApi)}>
            Datos enviados
          </button>
        </div>
      </div>

      {/* Modal de la respuesta del CRM*/}
      <Modal show={showModalForCrmResponse} onHide={handleToCloseCrmRespondeModal} scrollable centered className='modal-lg'>
        <Modal.Header closeButton onClick={handleToCloseCrmRespondeModal}>
          <Modal.Title>
            <h1>Respuesta CRM</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className='mb-1'>Respuesta:</span>
          {crmResponse}
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-sm btn-secondary' onClick={handleToCloseCrmRespondeModal}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal de los datos enviados*/}
      <Modal show={showModalForSendData} onHide={handleToCloseSendDataModal} scrollable centered className='modal-lg'>
        <Modal.Header closeButton onClick={handleToCloseSendDataModal}>
          <Modal.Title>
            <h1>Datos enviados</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className='mb-1'>Respuesta:</span>
          {sendData}
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-sm btn-secondary' onClick={handleToCloseSendDataModal}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
        </>
      )}

              {/* Si isLoading es falso y la longitud del id es 0 (string vacio), mostramos error */}
              {!isLoading && crmDataApi.apiId.length === 0 && (
          <div className='py-12'><p>{infoMessageLoading}</p></div>
        )}

      </div>
    </>
  );
}
