
type Props = {
    userIconSource: string,
    userName: string,
    userMail: string,
    userLastConnection: string
  }
  export const SingleChatB: React.FC<Props> = ({userIconSource, userName, userMail, userLastConnection}) => {
  return (
    <>
                      <div className='d-flex flex-stack py-4'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px symbol-circle'>
                      <img alt='Pic' src={`/media/avatars/${userIconSource}.jpg`} />
                      </div>
                      <div className='ms-5'>
                        <a
                          href='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'
                        >
                        {`${userName}`}

                        </a>
                        <div className='fw-bold text-gray-400'>{`${userMail}`}</div>
                      </div>
                    </div>
                    <div className='d-flex flex-column align-items-end ms-2'>
                      <span className='text-muted fs-7 mb-1'>{`${userLastConnection}`}</span>
                    </div>
                  </div>
                  <div className='separator separator-dashed d-none'></div>
    </>
  )
}
