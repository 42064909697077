import axios from 'axios'
import {
  DragonMainApi,
  apiDragon,
  EPClientsSharedList,
  EPUsersSharedAccessList,
  EPUsersSharedAccessEdit,
  EPUsersSharedAccessNew,
} from '../../../utils/constants/_Constants'
import {apiPetitions} from '../../../../_metronic/helpers/apis'

async function UsersSharedAccessList(userToken: string, cliId: string) {
  try {
    const response = await axios.get(DragonMainApi + EPUsersSharedAccessList + cliId, {
      headers: {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
    })

    const jsonUsersSharedAccessList = response.data

    //  console.log(jsonUsersSharedAccessList)

    return jsonUsersSharedAccessList
  } catch (error) {
    // throw error;
    console.log('Error de listado de marcas ', error)
  }
}

async function UsersSharedAccessNew(
  cliId: string,
  mail: string,
  lvl_access: string,
  userType: string,
  status: string,
  userToken: string,
) {
  try {
    const data = await apiPetitions(
      apiDragon,
      `${EPUsersSharedAccessNew + cliId}`,
      {
        mail,
        lvl_access,
        userType,
        status,
      },
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'put'
    )

    const jsonDataUserSharedAccessNew = data

    return jsonDataUserSharedAccessNew
  } catch (error) {
    throw error
  }
}

async function UsersSharedAccessEdit(
  cliId: string,
  mail: string,
  lvl_access: string | undefined,
  status: string,
  userType: string,
  userToken: string,
) {
  try {
    const data = await apiPetitions(
      apiDragon,
      `${EPUsersSharedAccessEdit + cliId}`,
      {
        mail,
        lvl_access,
        status,
        userType
      },
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'put'
    )

    const jsonDataUserSharedAccessEdit = data

    return jsonDataUserSharedAccessEdit
  } catch (error) {
    throw error
  }
}

export {UsersSharedAccessList, UsersSharedAccessNew, UsersSharedAccessEdit}
