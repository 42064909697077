import * as React from 'react';
import {ReactElement, useState} from 'react'

import {Step2Props} from '../../interfaces/clientsTypes'

import {Step2Errors} from '../../interfaces/clientsTypes'

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css' // Asegúrate de importar también los estilos
import '../../../../../_metronic/assets/styles/PhoneInput.css'
import { allowedCountries } from '../../../../utils/constants/_Constants'

function Step2({onNext, handlePrev, prevData, setStep2Data}: Step2Props) {
  const [clientName, setClientName] = useState(prevData?.clientName || '')
  const [clientRfc, setClientRfc] = useState(prevData?.clientRfc || '')
  const [clientPhone, setClientPhone] = useState(prevData?.clientPhone || '')
  const [clientIndustry, setClientIndustry] = useState(prevData?.clientIndustry || '')
  const [clientSize, setClientSize] = useState(prevData?.clientSize || '')
  const [clientGiro, setClientGiro] = useState(prevData?.clientGiro || '')
  const [clientEmployeeNumber, setClientEmployeeNumber] = useState(
    prevData?.clientEmployeeNumber || ''
  )

  const [errors, setErrors] = useState<Step2Errors>({
    clientName: '',
    clientRfc: '',
    clientPhone: '',
    clientIndustry: '',
    clientSize: '',
    clientGiro: '',
    clientEmployeeNumber: '',
  })

  const validateInputs = () => {
    const newErrors: Step2Errors = {
      clientName: !clientName ? 'El nombre es obligatorio' : '',
      clientRfc: !clientRfc ? 'El RFC es obligatorio' : '',
      clientPhone: !clientPhone ? 'El telefono es obligatorio' : '',
      clientIndustry:
        !clientIndustry || clientIndustry === '0' ? 'El tipo de industria es obligatorio' : '',
      clientSize: !clientSize || clientSize === '0' ? 'El tamaño de empresa es obligatorio' : '',
      clientGiro: !clientGiro ? 'El giro de la empresa es obligatorio' : '',
      clientEmployeeNumber: !clientEmployeeNumber ? 'El tipo de industria es obligatorio' : '',
    }

    // Aquí actualizamos el estado errors con los nuevos mensajes de error
    setErrors(newErrors)

    console.log(newErrors)

    return Object.values(newErrors).every((error) => error === '')
  }

  const handleNext = () => {
    if (validateInputs()) {
      onNext({
        clientName,
        clientRfc,
        clientPhone,
        clientIndustry,
        clientSize,
        clientGiro,
        clientEmployeeNumber,
      })

      setStep2Data({
        clientName,
        clientRfc,
        clientPhone,
        clientIndustry,
        clientSize,
        clientGiro,
        clientEmployeeNumber,
      })

      console.log(clientIndustry)
    }
  }

  return (
    <div>
      <h3>Cliente</h3>
      <span className='text-muted'>
        Ingresa los datos solicitados para crear el registro del cliente en la plataforma
      </span>
      <form>
        <div className='mt-4'>
          <input
            type='text'
            id='clientName'
            placeholder='Ingresa el nombre del cliente'
            name='clientname'
            className='form-control '
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
          />
          <div className='text-danger mb-6'>{errors.clientName}</div>
        </div>

        <div className='d-flex mt-4'>
          <div className='col-6 me-2'>
            <input
              type='text'
              id='clientRcf'
              placeholder='RFC'
              className='form-control'
              value={clientRfc}
              onChange={(e) => setClientRfc(e.target.value)}
            />
            <div className='text-danger mb-6'>{errors.clientRfc}</div>
          </div>

          <div className='col-6'>
            <PhoneInput
              placeholder='Ingresa el número telefónico'
              className='form-control  input-test'
              name='phoneuser'
              value={clientPhone}
              onChange={(e) => setClientPhone(e?.toString() || '')}
              countryCallingCodeEditable={false}
              defaultCountry='MX' // Especifica el país por defecto si lo deseas
              international
              autoComplete='off'
              countries={allowedCountries}
            />
            <div className='text-danger mb-6'>{errors.clientPhone}</div>
          </div>
        </div>

        <h3>Tipo de empresa</h3>
        <div className='d-flex mt-4'>
          <div className='col-6 me-2'>
            <select
              className='form-select'
              id='clientIndustry'
              value={clientIndustry}
              onChange={(e) => setClientIndustry(e.target.value)}
            >
              <option value='0'>Seleccion de industria</option>
              <option value="Technology">Tecnología de la información (TI)</option>
              <option value="Finance">Finanzas y banca</option>
              <option value="Management Consulting">Consultoría de gestión</option>
              <option value="Manufacturing">Manufactura y producción</option>
              <option value="Healthcare">Salud y farmacéutica</option>
              <option value="Retail">Comercio minorista y venta al por menor</option>
              <option value="Education">Educación y formación</option>
              <option value="Media">Medios de comunicación y entretenimiento</option>
              <option value="Agriculture">Agricultura y agroindustria</option>
              <option value="Construction">Construcción y desarrollo inmobiliario</option>
              <option value="Energy">Energía y recursos naturales</option>
              <option value="Transportation">Transporte y logística</option>
              <option value="Professional Services">Servicios profesionales</option>
              <option value="Tourism">Turismo</option>
              <option value="Automotive">Automoción y transporte</option>
              <option value="Business Consulting">Servicios de consultoría empresarial</option>
              <option value="Real Estate">Bienes raíces y propiedades</option>
              <option value="Arts & Entertainment">Arte, cultura y entretenimiento</option>
              <option value="Food Industry">Industria alimentaria</option>
            </select>
            <div className='text-danger mb-6'>{errors.clientIndustry}</div>
          </div>

          <div className='col-6'>
            <select
              className='form-select'
              id='clientSize'
              value={clientSize}
              onChange={(e) => setClientSize(e.target.value)}
            >
              <option value='0'>Tamaño</option>
              <option value='Micro'>Micro</option>
              <option value='Mediana'>Mediana</option>
              <option value='PYME'>PYME</option>
              <option value='Grande'>Grande</option>

            </select>
            <div className='text-danger mb-6'>{errors.clientSize}</div>
          </div>
        </div>

        <div className='d-flex mt-4'>
          <div className='col-6 me-2'>
            <input
              type='text'
              placeholder='Giro'
              id='clientGiro'
              className='form-control'
              value={clientGiro}
              onChange={(e) => setClientGiro(e.target.value)}
            />
            <div className='text-danger mb-6'>{errors.clientGiro}</div>
          </div>

          <div className='col-6'>
            <input
              type='number'
              id='clientEmployeeNumber'
              placeholder='Numero de empleados'
              className='form-control'
              value={clientEmployeeNumber}
              onChange={(e) => setClientEmployeeNumber(e.target.value)}
            />
            <div className='text-danger mb-6'>{errors.clientEmployeeNumber}</div>
          </div>
        </div>
      </form>

      <button className='btn btn-light btn-sm mt-6 me-2' onClick={handlePrev}>
        Anterior
      </button>

      <button className='btn btn-primary btn-sm mt-6' onClick={handleNext}>
        Siguiente
      </button>
    </div>
  )
}

export {Step2}
