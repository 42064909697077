import React, {FC} from 'react'
import { Link, useLocation } from 'react-router-dom'

type Props = {
    miPath: string[];
    titleNav: string[];
}

const NavLi: FC<Props> = ({

    miPath,
    titleNav,

}) => {
    const location = useLocation();

    return (
        <div className='card card-custom card-body mb-4'>
            <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                    {miPath.map((ruta, index) => (
                        <li key={index} className="nav-item">
                        <Link
                            to={ruta}
                            className={`nav-link me-6 ${location.pathname === ruta ? 'active' : ''}`}
                        >
                            {titleNav[index]}
                        </Link>
                    </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export {NavLi};