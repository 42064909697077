import { apiDragon, DragonMainApi, EPGroupStatusFalse, EPGroupStatusTrue } from "../../../../utils/constants/_Constants";

import { apiGetDeletePetitions, apiPetitions } from "../../../../../_metronic/helpers/apis";

export async function groupStatusFalse(userToken: string, groupId: string) {

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPGroupStatusFalse+groupId}`, {

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },
        
        'delete');

        const jsonDataRoleList = data;

        console.log(jsonDataRoleList)

        return jsonDataRoleList;

    } catch (error) {

        throw error;

    }

}

export async function groupStatusTrue(userToken: string, groupId: string) {

    try {

        const data = await apiPetitions(apiDragon, `${DragonMainApi+EPGroupStatusTrue+groupId}`, {

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },
        
        'put');

        const jsonDataRoleList = data;

        console.log(jsonDataRoleList)

        return jsonDataRoleList;

    } catch (error) {

        throw error;

    }

}
