import { ChangeEvent, FC, useState, useEffect, useContext } from "react";
import { UserContext } from "../../../app-context/user-context/UserContext";
import { Lead, FormDataSpamLead, LeadSpamData } from '../interfaces/leadsTypes';
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { sessionExpired } from "../../../utils/functions/_Functions";
import { leadToSpam } from "../services/_LeadSpamApi";
import { AlertInfo } from "../../../utils/interfaces/_InterfacesTypes";

type Props = {
  lead: Lead | LeadSpamData
  closeModalLeadToSpam: () => void
  getLeadsByClient?: () => void
  getLeadsSpamByClient?: () => void
  origin: string
}

const ModalLeadToSpam: FC<Props> = ({
  lead,
  closeModalLeadToSpam,
  getLeadsByClient,
  getLeadsSpamByClient,
  origin
}) => {
  console.log({ lead })

  const initialFormDataSpamLead: FormDataSpamLead = {
    leadId: lead.lead_id,
    comments: '',
  }

  const [formDataSpamLead, setFormDataSpamLead] = useState<FormDataSpamLead>(initialFormDataSpamLead)

  //Algunas constantes desde el userContext
  const { user, token, updateToken } = useContext(UserContext)
  const clientId: string = user.cli_id
  const userToken: string = token

  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({})

  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })

  const navigate = useNavigate()
  const toLeadSpam = () => {
    navigate('/lead-spam')
  }

  const handleFormSubmit = async (): Promise<void> => {
    console.log('se ha enviado el form')

    const leadId: string = formDataSpamLead.leadId
    const comments: string = formDataSpamLead.comments

    try {
      const { data: jsonData, code, msg } = await leadToSpam(userToken, leadId, comments)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired('Ha expirado la sesion')
        return
      } else {
        setAlertInfo({
          text: code === 200 ? 'El lead ha sido enviado al SPAM' : 'No se pudo enviar el lead al SPAM',
          variant: code === 200 ? 'success' : 'danger'
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: ''
          })
          closeModalLeadToSpam()
          setFormDataSpamLead(initialFormDataSpamLead)
          setErrors({})

          origin === 'spam' && getLeadsSpamByClient ? (
            getLeadsSpamByClient()
          ) : (
            toLeadSpam()
          )
          
        }, 3000);

      }
    } catch (error) {
      setAlertInfo({
        text: 'Hubo un error al intentar enviar el lead a SPAM',
        variant: 'danger'
      })
    }

  }

  const handleComments = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target

    if (value !== '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        comments: undefined,
      }))

    }

    if (value === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        comments: undefined,
      }))

    }

    setFormDataSpamLead((prevData) => ({
      ...prevData,
      comments: value,
    }))

  }

  const checkFormToSubmit = () => {

    if (formDataSpamLead.comments === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        comments: 'Debes agregar un comentario'
      }))
      return
    }

    if (formDataSpamLead.comments !== '') {
      handleFormSubmit()
    }

  }

  return (
    <>
      <Modal.Header closeButton onClick={closeModalLeadToSpam}>
        <Modal.Title>
          <h1>Enviar lead a SPAM</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='alert alert-dismissible bg-light-warning border border-warning mb-4'>
          <div className='d-flex flex-column flex-sm-row p-3'>
            <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-exclamation-triangle-fill'
                viewBox='0 0 16 16'
              >
                <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
              </svg>
            </span>
            <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
              {/* <h5 className='mb-1'>Activar producto</h5> */}
              <span className='text-dark'>
                <h4 className="mb-4">¿Estás seguro de enviar a spam este lead?</h4>
                <div className="d-flex">
                  <p className="fw-bold">Nombre del lead: </p>&nbsp;&nbsp;<p>{'lead_data' in lead ? (lead.lead_data.name) : (lead.lead_name)}</p>
                </div>
                <div className="d-flex">
                  <span className="fw-bold">Correo electrónico del lead: </span>&nbsp;&nbsp;<p>{'lead_data' in lead ? (lead.lead_data.lead_mail) : (lead.lead_mail)}</p>
                </div>
                <div className="d-flex">
                  <span className="fw-bold">Teléfono del lead: </span>&nbsp;&nbsp;<p>{'lead_data' in lead ? (lead.lead_data.lead_phone) : (lead.lead_phone)}</p>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className='mb-5'>
          <div>
            <label className='fs-6 fw-bold required'>
              Motivo
            </label>
            <textarea
              placeholder='Escribe el motivo por el cual quieres enviar a SPAM este lead...'
              // className='form-control form-control-solid'
              className={`form-control form-control-solid mb-3 mb-lg-0 ${errors.comments && 'is-invalid'}`}
              rows={2}
              name='comments'
              value={formDataSpamLead.comments}
              onChange={(e) => {
                handleComments(e)
              }}
            />
            {errors.comments && <div className='invalid-feedback'>{errors.comments}</div>}
          </div>
        </div>

        {alertInfo.text && (
          <div className='px-20'>
            <div
              className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`}
              role='alert'
            >
              <div>{alertInfo.text}</div>
            </div>
          </div>
        )}

      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-sm btn-primary'
          type='submit'
          onClick={() => {
            checkFormToSubmit()
          }}
        >
          Aceptar
        </button>
        <button
          className='btn btn-sm btn-secondary'
          type='button'
          onClick={() => {
            closeModalLeadToSpam()
          }}
        >
          Cancelar
        </button>
      </Modal.Footer>

    </>
  )
}

export { ModalLeadToSpam }