import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

export const ShareAcessEditModalForm = ({userData, formData, setFormData, errors}) => {
  const intl = useIntl()
  const [emailError, setEmailError] = useState('')
  const [lvl_accessError, setlvl_accessError] = useState('')
  const [statusError, setStatusError] = useState('')

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  // Objeto para mapear los valores del status
  const statusLabels = {
    true: 'Activo',
    false: 'Inactivo',
  }

  // Objeto para mapear los valores de los niveles de acceso
  const levelAccessLabels = {
    '5': 'Administrador',
    '3': 'Gerente',
    evangelista: 'Evangelista',
  }

  // Actualizar el estado del formulario cuando cambia la información del vendedor
  useEffect(() => {
    if (userData) {
      console.log(userData)
      setFormData({
        user_mail: userData.usr_mail,
        lvl_access: mapLvlAccess(userData.lvl_access),
        status: mapStatus(userData.status),
      })
    }
  }, [userData, setFormData])

  // Función para mapear el valor del nivel de acceso
  const mapLvlAccess = (value) => {
    if (value === 'Administrador') return '5'
    if (value === 'Gerente') return '3'
    return value // Si no es Administrador ni Gerente, devuelve el mismo valor
  }

  // Función para mapear el valor del status
  const mapStatus = (value) => {
    return value === 'Activo' ? true : false
  }

  const handleInputChange = (e) => {
    const {name, value} = e.target

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))

    // Validación específica para el campo de correo electrónico
    if (name === 'user_mail') {
      if (!isEmailValid(value)) {
        setEmailError('Correo electrónico no válido')
      } else {
        setEmailError('')
      }
    }

    // Validación específica para el campo de nivel de acceso
    if (name === 'lvl_access') {
      if (value === '') {
        setlvl_accessError('Este campo no puede estar vacio')
      } else {
        setlvl_accessError('')
      }
    }

    // Validación específica para el campo de estado
    if (name === 'status') {
      // Convertimos el valor del select de texto a booleano
      const statusValue = value === 'true'
      setFormData((prevData) => ({
        ...prevData,
        [name]: statusValue,
      }))
      if (statusValue === undefined) {
        // Cambio aquí
        setStatusError('Este campo no puede estar vacio')
      } else {
        setStatusError('')
      }
    }
  }

  return (
    <>
      <form>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Correo</label>
          <input
            placeholder='usuario@dragon.com'
            name='user_mail'
            type='email'
            id='email'
            value={formData.user_mail}
            onChange={handleInputChange}
            className={`form-control form-control-lg form-control-solid ${
              (errors.user_mail || emailError) && 'is-invalid'
            }`}
          />
          {errors.user_mail && <div className='invalid-feedback'>{errors.user_mail}</div>}
          {emailError && <div className='invalid-feedback'>{emailError}</div>}
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Niveles de acceso</label>
          <select
            name='lvl_access'
            value={formData.lvl_access}
            onChange={handleInputChange}
            className={`form-control form-control-lg form-control-solid ${
              (errors.lvl_access || lvl_accessError) && 'is-invalid'
            }`}
          >
            {formData.lvl_access && (
              <option value={formData.lvl_access}>{levelAccessLabels[formData.lvl_access]}</option>
            )}

            {Object.entries(levelAccessLabels)
              .filter(([value]) => value !== formData.lvl_access)
              .map(([value, label], index) => (
                <option key={index} value={value}>
                  {label}
                </option>
              ))}
          </select>
          {errors.lvl_access && <div className='invalid-feedback'>{errors.lvl_access}</div>}
          {lvl_accessError && <div className='invalid-feedback'>{lvl_accessError}</div>}
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Estatus del usuario</label>
          <select
            className={`form-control form-control-lg form-control-solid ${
              (errors.status || statusError) && 'is-invalid'
            }`}
            name='status'
            value={formData.status} // Convertimos el valor booleano a texto
            onChange={handleInputChange}
          >
            <option value=''>Selecciona una opción</option>
            {Object.entries(statusLabels).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          {errors.status && <div className='invalid-feedback'>{errors.status}</div>}
          {statusError && <div className='invalid-feedback'>{statusError}</div>}
        </div>
      </form>
    </>
  )
}
