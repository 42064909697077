import { apiDragon, DragonMainApi, EPRolesListByClient, EPRoleListById } from "../../../utils/constants/_Constants";

import { apiGetDeletePetitions } from "../../../../_metronic/helpers/apis";

export async function rolesListByClient(userToken: string, cliId: string) {

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPRolesListByClient+cliId}`, {

            /* Los parametros se mandan como undefined
            ya que estos se envian solo en peticiones de eliminacion 'delete */
            undefined

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },
        
        'get');

        const jsonDataRoleList = data;

        console.log(jsonDataRoleList)

        return jsonDataRoleList;

    } catch (error) {

        throw error;

    }

}

export async function milestoneByRol(userToken: string, roleId: string){

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPRoleListById+roleId}`, {

            /* Los parametros se mandan como undefined
            ya que estos se envian solo en peticiones de eliminacion 'delete' */
            undefined 
            
        }, {

            'Content-Type': 'application/json',
            'x-token': userToken
        },
        
        'get');

        const jsonDataRoleList = data;

        return jsonDataRoleList;

    } catch (error) {

        throw error;
    
    }

}

//export { rolesListByClient };