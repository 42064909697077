import { FC, useState } from "react"
import { DropdownExportConversationWab } from "../../../_metronic/partials/content/dropdown/DropdownExportConversationWab"
import { Modal } from "react-bootstrap"

export const Conversation = () => {

  //Constante para manejar el estado de visibilidad del modal de exportacion de conversacion
  const [showModalExportConversation, setShowModalExportConversation] = useState<boolean>(false)

  //Funcion para abrir el modal de exportar conversacion
  const openModalExportConversation = () => {
    setShowModalExportConversation(true)
  }

  //Funcion para cerrar el modal de exportar conversacion
  const closeModalExportConversation = () => {
    setShowModalExportConversation(false)
  }

  return (
    <>
      <div className='card h-100' id='kt_chat_messenger'>
        {/* <div className='card-header' id='kt_chat_messenger_header'>
              <div className='card-title'>
                <div className='symbol-group symbol-hover'></div>
                <div className='d-flex justify-content-center flex-column me-3'>
                  <a
                    href='#'
                    className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                  >
                    Brian Cox
                  </a>
                  <div className='mb-0 lh-1'>
                    <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                    <span className='fs-7 fw-bold text-gray-400'>Active</span>
                  </div>
                </div>
                <div className="d-flex justify-content-end align-items-end">
                  <button
                    className="btn btn-sm btn-primary"
                  >
                    hola
                  </button>
                </div>
              </div>
            </div> */}

        <div className='card-header' id='kt_chat_messenger_header'>
          <div className='card-title d-flex justify-content-between align-items-center'>
            <div className='symbol-group symbol-hover'></div>
            <div className='d-flex justify-content-center flex-column me-3'>
              <a
                href='#'
                className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
              >
                Brian Cox
              </a>
              <div className='mb-0 lh-1'>
                <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                <span className='fs-7 fw-bold text-gray-400'>Active</span>
              </div>
            </div>

          </div>

          <div className="d-flex justify-content-end align-items-end py-6">
            <button
              type='button'
              className="btn btn-sm btn-primary"
              onClick={openModalExportConversation}
            >
              Exportar conversacion
            </button>
          </div>
        </div>

        <Modal
          show={showModalExportConversation}
          onHide={closeModalExportConversation}
          className="modal-sm"
        >
          <Modal.Header closeButton onClick={closeModalExportConversation}>
            <Modal.Title>
              <label>Exportar conversación</label>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <DropdownExportConversationWab />
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-sm btn-secondary"
                type='reset'
                onClick={() => {
                  closeModalExportConversation()
                }}
              >
                Cerrar
              </button>
            </Modal.Footer>
        </Modal>

        <div className='card-body' id='kt_chat_messenger_body'>
          <div
            className='scroll-y me-n5 pe-5 h-300px h-lg-auto'
            data-kt-element='messages'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
            data-kt-scroll-wrappers='#kt_content, #kt_app_content, #kt_chat_messenger_body'
            data-kt-scroll-offset='5px'
          >
            <div className='d-flex d-flex justify-content-start mb-10 mb-10'>
              <div className='d-flex flex-column align-items align-items-start'>
                <div className='d-flex align-items-center mb-2'>
                  <div className='symbol  symbol-35px symbol-circle '>
                    <img alt='Pic' src='/media/avatars/300-25.jpg'></img>
                  </div>
                  <div className='ms-3'>
                    <a
                      href='#'
                      className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                    >
                      Brian Cox
                    </a>
                    <span className='text-muted fs-7 mb-1'>12:19 PM</span>
                  </div>
                </div>
                <div
                  className='p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start'
                  data-kt-element='message-text'
                >
                  Hola, esta es una prueba de texto del módulo de Chat Wab para la v2 de DragonCem
                </div>
              </div>
            </div>
            <div className='d-flex d-flex justify-content-end mb-10 mb-10'>
              <div className='d-flex flex-column align-items align-items-end'>
                <div className='d-flex align-items-center mb-2'>
                  <div className='me-3'>
                    <span className='text-muted fs-7 mb-1'>12:22 PM</span>
                    <a
                      href='#'
                      className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                    >
                      You
                    </a>
                  </div>
                  <div className='symbol  symbol-35px symbol-circle '>
                    <img alt='Pic' src='/media/avatars/300-1.jpg'></img>
                  </div>
                </div>
                <div
                  className='p-5 rounded bg-light-primary text-dark fw-bold mw-lg-400px text-end'
                  data-kt-element='message-text'
                >
                  Genial, esta es una respuesta que representa al vendedor dentro del módulo
                </div>
              </div>
            </div>
            <div className='d-flex d-flex justify-content-start mb-10 mb-10'>
              <div className='d-flex flex-column align-items align-items-start'>
                <div className='d-flex align-items-center mb-2'>
                  <div className='symbol  symbol-35px symbol-circle '>
                    <img alt='Pic' src='/media/avatars/300-25.jpg'></img>
                  </div>
                  <div className='ms-3'>
                    <a
                      href='#'
                      className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                    >
                      Brian Cox
                    </a>
                    <span className='text-muted fs-7 mb-1'>12:24 PM</span>
                  </div>
                </div>
                <div
                  className='p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start'
                  data-kt-element='message-text'
                >
                  Pos que divertido
                </div>
              </div>
            </div>
            <div className='d-flex d-flex justify-content-end mb-10 mb-10'>
              <div className='d-flex flex-column align-items align-items-end'>
                <div className='d-flex align-items-center mb-2'>
                  <div className='me-3'>
                    <span className='text-muted fs-7 mb-1'>12:25 PM</span>
                    <a
                      href='#'
                      className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                    >
                      You
                    </a>
                  </div>
                  <div className='symbol  symbol-35px symbol-circle '>
                    <img alt='Pic' src='/media/avatars/300-1.jpg'></img>
                  </div>
                </div>
                <div
                  className='p-5 rounded bg-light-primary text-dark fw-bold mw-lg-400px text-end'
                  data-kt-element='message-text'
                >
                  Es posible recibir notificaciones y las conversaciones se actualizan en tiempo real cuando entra un nuevo Lead
                </div>
              </div>
            </div>
            <div className='d-flex d-flex justify-content-start mb-10 mb-10'>
              <div className='d-flex flex-column align-items align-items-start'>
                <div className='d-flex align-items-center mb-2'>
                  <div className='symbol  symbol-35px symbol-circle '>
                    <img alt='Pic' src='/media/avatars/300-25.jpg'></img>
                  </div>
                  <div className='ms-3'>
                    <a
                      href='#'
                      className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                    >
                      Brian Cox
                    </a>
                    <span className='text-muted fs-7 mb-1'>12:38 PM</span>
                  </div>
                </div>
                <div
                  className='p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start'
                  data-kt-element='message-text'
                >
                  De momento no es posible responder conversaciones desde este modulo, pero en un futuro lo será
                </div>
              </div>
            </div>
            <div className='d-flex d-flex justify-content-end mb-10 mb-10'>
              <div className='d-flex flex-column align-items align-items-end'>
                <div className='d-flex align-items-center mb-2'>
                  <div className='me-3'>
                    <span className='text-muted fs-7 mb-1'>Hace un minuto</span>
                    <a
                      href='#'
                      className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                    >
                      You
                    </a>
                  </div>
                  <div className='symbol  symbol-35px symbol-circle '>
                    <img alt='Pic' src='/media/avatars/300-1.jpg'></img>
                  </div>
                </div>
                <div
                  className='p-5 rounded bg-light-primary text-dark fw-bold mw-lg-400px text-end'
                  data-kt-element='message-text'
                >
                  Dragon v2 construida en React disponible proximamente
                </div>
              </div>
            </div>
            <div className='d-flex d-flex justify-content-start mb-10 mb-10'>
              <div className='d-flex flex-column align-items align-items-start'>
                <div className='d-flex align-items-center mb-2'>
                  <div className='symbol  symbol-35px symbol-circle '>
                    <img alt='Pic' src='/media/avatars/300-25.jpg'></img>
                  </div>
                  <div className='ms-3'>
                    <a
                      href='#'
                      className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                    >
                      Brian Cox
                    </a>
                    <span className='text-muted fs-7 mb-1'>Justo ahora</span>
                  </div>
                </div>
                <div
                  className='p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start'
                  data-kt-element='message-text'
                >
                  La plantilla nunca se construirá en docker al menos que el devops nos oblige, cosa que espero tarde bastante tiempo en pasar, arriba Windows abajo Linux
                </div>
              </div>
            </div>
          </div>
          {/* <div className='card-footer pt-4' id='kt_chat_messenger_footer'>
                <textarea
                  className='form-control form-control-flush mb-3'
                  data-kt-element='input'
                  placeholder='Escribe un mensaje'
                ></textarea>
                <div className='d-flex flex-stack'>
                  <div className='d-flex align-items-center me-2'>
                    <button
                      className='btn btn-sm btn-icon btn-active-light-primary me-1'
                      type='button'
                      data-bs-toggle='tooltip'
                      title='Coming soon'
                    >
                      <i className='bi bi-paperclip fs-3'></i>
                    </button>
                    <button
                      className='btn btn-sm btn-icon btn-active-light-primary me-1'
                      type='button'
                      data-bs-toggle='tooltip'
                      title='Coming soon'
                    >
                      <i className='bi bi-upload fs-3'></i>
                    </button>
                  </div>
                  <button className='btn btn-primary' type='button' data-kt-element='send'>
                    Enviar
                  </button>
                </div>
              </div> */}
        </div>
      </div>
    </>
  )
}
