// Importamos hooks de react que usaremos para este componente
import React, { useState, useContext, ChangeEvent, useEffect } from "react";
// Importamos componentes de la libreria de bootstrap para react
import { Card } from "react-bootstrap";
// Importamos libreria para manejo de lenguaje 
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from "../../../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------

// Importamos la funcion productsEditInfo para conexion con API de productos
import { productsEditInfo } from "../../services/_ProductsEditApi";
// Importamos la funcion rolesListByClient para conexion con API de roles
import { rolesListByClient } from "../../../assigns/services/_RolesListApi";

// ---------------------------------------------------------------------------------------

// Importamos interfaces de Roles que usaremos en este componente
import { Role } from "../../../assigns/interfaces/rolesTypes";
// Importamos las interfaces globales que utilizaremos en este componente
import { AlertInfo } from "../../../../utils/interfaces/_InterfacesTypes";

// ---------------------------------------------------------------------------------------

// Importamos la funcion sessionExpired para el vencimiento de la sesion
import { sessionExpired } from "../../../../utils/functions/_Functions";

// ---------------------------------------------------------------------------------------

// Importamos constantes
import { BucketMediaURL } from "../../../../utils/constants/_Constants";

// Iniciamos funcion para construir componente InformationTabForm
function InformationTabForm({ idProduct, headerProduct, formDataEdit, setFormDataEdit, mailsArrayEdit, setMailsArrayEdit, getProductsRegisters, closeModalEdit }) {
    const intl = useIntl();
    const { user, token, updateToken } = useContext(UserContext);
    const userToken = token;
    const clientId = user.cli_id;

    const [rolesList, setRolesList] = useState<Role[]>([]);
    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        text: '',
        variant: 'primary',
    });

    // Estado para almacenar los errores
    const [errors, setErrors] = useState({
        countryProduct: '',
        stateProduct: '',
        cityProduct: '',
        zoneProduct: '',
        priceProduct: '',
        mailsArrayEdit: '',
    });

    const handleIputInfoChangeEdit = (e) => {
        const { name, value } = e.target;
        setFormDataEdit({
            ...formDataEdit,
            [name]: value,
        });
    };

    const handleMailsChange = (e: ChangeEvent<HTMLInputElement>) => {
        const mailsArray = e.target.value.split(',').map(email => email.trim());
        setMailsArrayEdit(mailsArray);
    };

    const getRolesRegisters = async () => {
        try {
            const { data: jsonData, code, msg } = await rolesListByClient(userToken, clientId);
            if (code === 401 && msg === 'Token no valido') {
                sessionExpired('La sesion ha expirado');
                return;
            } else {
                const rolesListReceived = jsonData.assigns;
                const userTokenNew = jsonData.token;
                updateToken(userTokenNew);
                setRolesList(rolesListReceived);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setFormDataEdit(prevData => ({
            ...prevData,
            roleIdProduct: value,
        }));
    };

    const validateInputs = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const { countryProduct, stateProduct, cityProduct, zoneProduct, priceProduct } = formDataEdit;

        const invalidEmails = mailsArrayEdit.filter(email => !emailRegex.test(email.trim()));
        const hasInvalidEmails = invalidEmails.length > 0;

        const newErrors = {
            countryProduct: !countryProduct ? 'El país es obligatorio' : '',
            stateProduct: !stateProduct ? 'El estado es obligatorio' : '',
            cityProduct: !cityProduct ? 'La ciudad es obligatoria' : '',
            zoneProduct: !zoneProduct ? 'La zona es obligatoria' : '',
            priceProduct: isNaN(Number(priceProduct)) || Number(priceProduct) <= 0 ? 'El precio debe ser un número válido y mayor que cero' : '',
            mailsArrayEdit: hasInvalidEmails ? 'Uno o más correos electrónicos son inválidos' : '',
        };

        setErrors(newErrors);

        // Verifica si hay errores
        const hasErrors = Object.values(newErrors).some(value => value !== '');

        if (hasErrors) {
            // Si hay errores, muestra el mensaje de alerta correspondiente
            setAlertInfo({
                text: 'Por favor, completa todos los campos correctamente.',
                variant: 'danger',
            });
            return false;
        } else {
            // Si no hay errores, limpia el mensaje de alerta
            setAlertInfo({
                text: '',
                variant: 'primary',
            });
            return true;
        }
    };

    const handleInforFormSubmit = async () => {
        if (!validateInputs()) return;

        const { legalsProduct, crmIdProduct, priceProduct, countryProduct, stateProduct, cityProduct, zoneProduct, gmapsProduct, wazeProduct, webProduct, virtualProduct, introProduct } = formDataEdit;
        const edition = false;
        const status = true;
        const roleId = formDataEdit.roleIdProduct;

        try {
            const { data: jsonData, code, msg } = await productsEditInfo(
                edition,
                legalsProduct,
                crmIdProduct,
                status,
                priceProduct,
                countryProduct,
                stateProduct,
                cityProduct,
                zoneProduct,
                gmapsProduct,
                wazeProduct,
                webProduct,
                virtualProduct,
                introProduct,
                mailsArrayEdit,
                roleId,
                idProduct,
                userToken
            );

            if (code === 401 && msg === 'Token no valido') {
                sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
                return;
            } else {
                setAlertInfo({
                    text: code === 200 ? intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.SUCCESS_PRODUCTINFO_EDITION" }) : intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.FAIL_PRODUCTINFO_EDITION" }),
                    variant: code === 200 ? 'success' : 'danger',
                });

                setTimeout(() => {
                    setAlertInfo({
                        text: '',
                        variant: '',
                    });
                    closeModalEdit();
                    getProductsRegisters();
                }, 3000);
            }
        } catch (error) {
            console.error('No se pudo editar el producto', error);
            setAlertInfo({
                text: 'Error en la solicitud Axios',
                variant: 'danger',
            });
        }
    };

    useEffect(() => {
        getRolesRegisters();
    }, []);

    return (
        <div className="container">
            <Card style={{ width: '32rem' }}>
                <Card.Img variant="top" src={BucketMediaURL + headerProduct} alt="Portada" />
                <Card.Body>
                    <Card.Title>{formDataEdit.nameProduct}</Card.Title>
                    <span className='text-muted'>{intl.formatMessage({ id: `PRODUCTS.COUNTRY.${formDataEdit.countryProduct}` })} - {formDataEdit.stateProduct} - {formDataEdit.zoneProduct}</span><br /><br />
                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.INPUTPRONAME" })}</label><br />
                    <input
                        type="text"
                        name="nameProduct"
                        className='form-control form-control-solid'
                        readOnly
                        value={formDataEdit.nameProduct}
                    // onChange={handleIputInfoChangeEdit}

                    /><br />

                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.INPUTPROTYPE" })}</label>
                    <input
                        type="text"
                        name="typeProduct"
                        className='form-control form-control-solid'
                        readOnly
                        value={formDataEdit.typeProduct}
                    // onChange={handleIputInfoChangeEdit}

                    /><br />

                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.INPUTPROCOUNTRY" })}</label>
                    <select
                        id="productCountry"
                        name="countryProduct"
                        className='form-select form-select-solid mb-6'
                        value={formDataEdit.countryProduct}
                        onChange={handleIputInfoChangeEdit}>
                        <option value="">-- {intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SELECTCOUNTRY_OPTION1' })} --</option>
                        <option value="MX">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.MX` })}</option>
                        <option value="BR">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.BR` })}</option>
                        <option value="US">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.US` })}</option>
                        <option value="CA">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.CA` })}</option>
                        <option value="HN">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.HN` })}</option>
                        <option value="SV">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.SV` })}</option>
                        <option value="GT">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.GT` })}</option>
                        <option value="AR">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.AR` })}</option>
                        <option value="CH">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.CH` })}</option>
                        <option value="CO">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.CO` })}</option>
                        <option value="EC">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.EC` })}</option>
                        <option value="PA">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.PA` })}</option>
                        <option value="UR">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.UR` })}</option>
                        <option value="VE">{intl.formatMessage({ id: `PRODUCTS.COUNTRY.VE` })}</option>
                    </select>
                    {errors.countryProduct && <div className="text-danger">{errors.countryProduct}</div>}
                    <br />

                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.INPUTPROSTATE" })}</label>
                    <select
                        id="stateProduct"
                        name="stateProduct"
                        className='form-select form-select-solid mb-6'
                        value={formDataEdit.stateProduct}
                        onChange={handleIputInfoChangeEdit}>
                        <option value="">-- {intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.SELECTSTATE_OPTION1' })} --</option>
                        <option value="Aguascalientes"> Aguascalientes </option>
                        <option value="Baja California"> Baja California </option>
                        <option value="Baja California Sur"> Baja California Sur </option>
                        <option value="Campeche"> Campeche </option>
                        <option value="Ciudad de Mexico"> Ciudad de Mexico </option>
                        <option value="Chiapas"> Chiapas </option>
                        <option value="Chihuahua"> Chihuahua </option>
                        <option value="Coahuila de Zaragoza"> Coahuila de Zaragoza </option>
                        <option value="Colima"> Colima </option>
                        <option value="Durango"> Durango </option>
                        <option value="Estado de Mexico"> Estado de México </option>
                        <option value="Guanajuato"> Guanajuato </option>
                        <option value="Guerrero"> Guerrero </option>
                        <option value="Hidalgo"> Hidalgo </option>
                        <option value="Jalisco"> Jalisco </option>
                        <option value="Michoacan"> Michoacán </option>
                        <option value="Morelos"> Morelos </option>
                        <option value="Nayarit"> Nayarit </option>
                        <option value="Nuevo Leon"> Nuevo León </option>
                        <option value="Oaxaca"> Oaxaca </option>
                        <option value="Puebla"> Puebla </option>
                        <option value="Queretaro"> Querétaro </option>
                        <option value="Quintana Roo"> Quintana Roo </option>
                        <option value="San Luis Potosi"> San Luis Potosí </option>
                        <option value="Sinaloa"> Sinaloa </option>
                        <option value="Sonora"> Sonora </option>
                        <option value="Tabasco"> Tabasco </option>
                        <option value="Tamaulipas"> Tamaulipas </option>
                        <option value="Tlaxcala"> Tlaxcala </option>
                        <option value="Veracruz"> Veracruz </option>
                        <option value="Yucatan"> Yucatán </option>
                        <option value="Zacatecas"> Zacatecas </option>
                        <option value="Extranjero"> Extranjero </option>
                    </select>
                    {errors.stateProduct && <div className="text-danger">{errors.stateProduct}</div>}
                    <br />

                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.INPUTPROCITY" })}</label>
                    <input
                        type="text"
                        name="cityProduct"
                        className='form-control form-control-solid'
                        value={formDataEdit.cityProduct}
                        onChange={handleIputInfoChangeEdit}

                    />
                    {errors.cityProduct && <div className="text-danger">{errors.cityProduct}</div>}
                    <br />

                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.INPUTPROZONE" })}</label>
                    <input
                        type="text"
                        name="zoneProduct"
                        className='form-control form-control-solid'
                        value={formDataEdit.zoneProduct}
                        onChange={handleIputInfoChangeEdit}

                    />
                    {errors.zoneProduct && <div className="text-danger">{errors.zoneProduct}</div>}
                    <br />

                    <label className="form-label fw-bold">CRM</label>
                    <input
                        type="text"
                        name="crmIdProduct"
                        className='form-control form-control-solid'
                        value={formDataEdit.crmIdProduct}
                        onChange={handleIputInfoChangeEdit}

                    /><br />

                    <label className="form-label fw-bold">WAB's</label>
                    <select
                        name="wabs"
                        className='form-select form-select-solid'
                        onChange={handleIputInfoChangeEdit}
                        defaultValue={"+525598689878"}
                    >
                        <option value="+525511001100">+525511001100</option>
                        <option value="+525598689878">+525598689878</option>
                    </select><br />

                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.LABEL_ACTIVEROL" })}</label>
                    {/* <select className="form-select form-select-solid" name="roles" value={2}>
                        <option value="">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.SELECTROL_OPTION1" })}</option>
                        <option value="0">Rol abc</option>
                        <option value="1">Rol def</option>
                        <option value="2">Rol ghi</option>
                    </select> */}
                    <select className="form-select form-select-solid" name="roles" onChange={handleSelectChange} value={formDataEdit.roleIdProduct}>
                        <option value=''>-- Configured roles --</option>
                        {rolesList.map((role, index) => (
                            <option key={index} id={role.role_id} value={role.role_id}>
                                {role.role_name}
                            </option>
                        ))}
                    </select>
                    <span className="text-muted mt-4 mb-4">{intl.formatMessage({ id: 'GLOBAL.INDICATIONS.SELECTROLE' })}</span><br /><br />

                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.INPUTPROPRICE" })}</label>
                    <input
                        type="number"
                        name="priceProduct"
                        className='form-control form-control-solid'
                        value={formDataEdit.priceProduct}
                        onChange={handleIputInfoChangeEdit}

                    />
                    {errors.priceProduct && <div className="text-danger">{errors.priceProduct}</div>}
                    <br />

                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.INPUTLEGALS" })}</label>
                    <input
                        type="url"
                        name="legalsProduct"
                        className='form-control form-control-solid'
                        value={formDataEdit.legalsProduct}
                        onChange={handleIputInfoChangeEdit}

                    /><br />

                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.INPUTPROGMAPS" })}</label>
                    <input
                        type="url"
                        name="gmapsProduct"
                        className='form-control form-control-solid'
                        value={formDataEdit.gmapsProduct}
                        onChange={handleIputInfoChangeEdit}

                    /><br />

                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.INPUTPROWAZE" })}</label>
                    <input
                        type="url"
                        name="wazeProduct"
                        className='form-control form-control-solid'
                        value={formDataEdit.wazeProduct}
                        onChange={handleIputInfoChangeEdit}

                    /><br />

                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.INPUTPROWEBSITE" })}</label>
                    <input
                        type="url"
                        name="webProduct"
                        className='form-control form-control-solid'
                        value={formDataEdit.webProduct}
                        onChange={handleIputInfoChangeEdit}

                    /><br />

                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.INPUTPROVIRTUALREC" })}</label>
                    <input
                        type="url"
                        name="virtualProduct"
                        className='form-control form-control-solid'
                        value={formDataEdit.virtualProduct}
                        onChange={handleIputInfoChangeEdit}

                    /><br />

                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.TEXTAREAWELCOMEMSG" })}</label>
                    <textarea
                        name="introProduct"
                        className='form-control form-control-solid'
                        value={formDataEdit.introProduct}
                        onChange={handleIputInfoChangeEdit}

                    /><br />

                    <label className="form-label fw-bold">{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.INPUTMAILS" })}</label>
                    <input
                        type="text"
                        name="mailsProduct"
                        className='form-control form-control-solid mb-1'
                        value={mailsArrayEdit}
                        onChange={handleMailsChange}

                    />
                    {errors.mailsArrayEdit && <div className="text-danger">{errors.mailsArrayEdit}</div>}
                    <span className='text-muted mb-4'>{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.INPUTMAILS_SPAN" })}</span>
                    {alertInfo.text && (
                        <div className={`alert alert-${alertInfo.variant} d-flex align-items-center`} role='alert'>
                            <div>{alertInfo.text}</div>
                        </div>
                    )}
                    <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                            <button type="submit" className='mt-8 btn btn-primary btn-sm' onClick={handleInforFormSubmit}>{intl.formatMessage({ id: "PRODUCTS.MODAL_EDIT.TAB_INFO.BUTTONSUBMIT" })}</button>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export { InformationTabForm }