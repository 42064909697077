import { FC } from "react";
import { LinkDCEM } from '../interfaces/linksDcemTypes';

type Props = {
    linkDcem: any
}

const ModalDcemEdit: FC<Props> = ({
    linkDcem
}) => {

    return (
        <>
            <div className='px-7'>
            <span className="text-danger">*En esta sección puedes editar solo la URL asociada a tu Link DCEM*</span><br /><br />

                <div className='mb-5'>
                    <div>
                        <label className='fs-6 fw-bold required'>
                            Link DCEM
                        </label>
                        <input
                            required
                            type='url'
                            name='shortedLink'
                            className='form-control form-control-solid mb-3 mb-lg-0 required'
                            value={linkDcem.shortedLink}
                            readOnly
                        />
                    </div>
                </div>

                <div className='mb-5'>
                    <div>
                        <label className='fs-6 fw-bold required'>
                            URL Asociada
                        </label>
                        <input
                            required
                            type='url'
                            name='OriginalLink'
                            className='form-control form-control-solid mb-3 mb-lg-0 required'
                            value={linkDcem.originalLink}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export { ModalDcemEdit };