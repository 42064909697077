// TabPane.jsx
import React from 'react';

const TabPane = ({ refId, isActive, children }) => {
  return (
    <div className={`tab-pane fade ${isActive ? 'show active' : ''}`} id={`kt_vtab_pane_${refId}`} role="tabpanel">
      <div className="accordion accordion-icon-toggle" id={`kt_accordion_${refId}`}>
        {children}
      </div>
    </div>
  );
};

export default TabPane;


