// Importamos constantes para realizar la peticion a la API de campañas
import {DragonMainApi, EPCampaignActivate, apiDragon} from '../../../utils/constants/_Constants'

// ---------------------------------------------------------------------------------------

// Importamos funcion para obtener codigos de respuesta del servidor
import {apiPetitions} from '../../../../_metronic/helpers/apis'

//Función para realizar la petición a la API de activación de campañas
async function campaignStatusActivate(campaign_id: string, userToken: string) {
  try {
    const data = await apiPetitions(
      apiDragon,
      `${DragonMainApi + EPCampaignActivate + campaign_id}`,{},
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'put')

    const jsonDataBrandsDeactivate = data

    return jsonDataBrandsDeactivate
  } catch (error) {
    throw error
  }
}

export {campaignStatusActivate}
