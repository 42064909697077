import { FC, useState } from "react";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { CrmCountsReport } from "../interfaces/crmReportTypes";
import { CrmReportGeneralTable } from "../tables/CrmReportGeneralTable";
import { CrmReportResponsesTable } from "../tables/CrmReportResponsesTable";

type Props = {
    crmReportItem: CrmCountsReport
}

const LeadCountsItem: FC<Props> = ({
    crmReportItem
}) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const [cardTitleSelected, setCardTitleSelected] = useState<string>('');
    const [cardSelected, setCardSelected] = useState<number>(0);

    const [showModal1, setShowModal1] = useState<boolean>(false);
    const [showModal2, setShowModal2] = useState<boolean>(false)

    const openModal1 = (title: string) => {
        setShowModal1(true)
        setCardTitleSelected(title)
    }

    const closeModal1 = (title: string) => {
        setShowModal1(false)
        setCardTitleSelected(title)
    }

    const openModal2 = (title: string) => {
        setShowModal2(true)
        setCardTitleSelected(title)
    }

    const closeModal2 = (title: string) => {
        setShowModal2(false)
        setCardTitleSelected(title)
    }

    const handleMouseEnter = (card: number) => {
        console.log('entra efecto hover')
        setIsHovered(true)
        setCardSelected(card)
    }

    const handleMouseLeave = (card: number) => {
        console.log('sale efecto hover')
        setIsHovered(false)
        setCardSelected(card)
    }

    return (
        <>
            <div className="d-flex flex-wrap justify-content-center">

                {/* Begin: Total de leads recibidos */}
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Ver leads recibidos</Tooltip>}
                >
                    <div
                        onMouseEnter={() => {
                            handleMouseEnter(1)
                        }}

                        onMouseLeave={() => {
                            handleMouseLeave(0)
                        }}

                        onClick={() => {
                            openModal1('Leads recibidos')
                        }}

                        className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === 1 ? 'bg-gray-200' : ''} cursor-pointer`}
                    >
                        <div className="d-flex align-items-center">
                            <KTIcon iconName="toggle-on" className="fs-3 text-primary me-2" />
                            <div className="fs-2 fw-bolder me-2">
                                {crmReportItem.totalLeadsReceived}
                            </div>
                            <div className="fw-bold fs-6 text-gray-400">Leads recibidos</div>
                        </div>
                    </div>
                </OverlayTrigger>
                {/* End: Total de leads recibidos */}

                {/* Begin: Total de leads enviados a CRM */}
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Ver leads enviados a CRM</Tooltip>}
                >
                    <div
                        onMouseEnter={() => {
                            handleMouseEnter(2)
                        }}

                        onMouseLeave={() => {
                            handleMouseLeave(0)
                        }}

                        onClick={() => {
                            openModal1('Leads enviados a CRM')
                        }}

                        className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === 2 ? 'bg-gray-200' : ''} cursor-pointer`}
                    >
                        <div className="d-flex align-items-center">
                            <KTIcon iconName="toggle-on" className="fs-3 text-info me-2" />
                            <div className="fs-2 fw-bolder me-2">
                                {crmReportItem.totalLeadsToCrm}
                            </div>
                            <div className="fw-bold fs-6 text-gray-400">Leads enviados a CRM</div>
                        </div>
                    </div>
                </OverlayTrigger>
                {/* End. Total de leads enviados a CRM */}

                {/* Begin: Total de leads no enviados a CRM */}
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Ver leads no enviados a CRM</Tooltip>}
                >
                    <div
                        onMouseEnter={() => {
                            handleMouseEnter(3)
                        }}

                        onMouseLeave={() => {
                            handleMouseLeave(0)
                        }}

                        onClick={() => {
                            openModal1('Leads no enviados a CRM')
                        }}

                        className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === 3 ? 'bg-gray-200' : ''} cursor-pointer`}
                    >
                        <div className="d-flex align-items-center">
                            <KTIcon iconName="toggle-on" className="fs-3 text-warning me-2" />
                            <div className="fs-2 fw-bolder me-2">
                                {crmReportItem.totalLeadsNoToCrm}
                            </div>
                            <div className="fw-bold fs-6 text-gray-400">Leads no enviados a CRM</div>
                        </div>
                    </div>
                </OverlayTrigger>
                {/* End: Total de leads no enviados a CRM */}

                {/* Begin: Total de leads con respuesta positiva */}
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Ver leads con respuesta positiva del CRM</Tooltip>}
                >
                    <div
                        onMouseEnter={() => {
                            handleMouseEnter(4)
                        }}

                        onMouseLeave={() => {
                            handleMouseLeave(0)
                        }}

                        onClick={() => {
                            openModal2('Leads con respuesta positiva')
                        }}

                        className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === 4 ? 'bg-gray-200' : ''} cursor-pointer`}
                    >
                        <div className="d-flex align-items-center">
                            <KTIcon iconName="toggle-on" className="fs-3 text-success me-2" />
                            <div className="fs-2 fw-bolder me-2">
                                {crmReportItem.totalLeadsGoodResponse}
                            </div>
                            <div className="fw-bold fs-6 text-gray-400">Respuesta positivas</div>
                        </div>
                    </div>
                </OverlayTrigger>
                {/* End: Total de leads con respuesta positiva */}

                {/* Begin: Total de leads con respuesta negativa */}
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Ver leads con respuesta negativa del CRM</Tooltip>}
                >
                    <div
                        onMouseEnter={() => {
                            handleMouseEnter(5)
                        }}

                        onMouseLeave={() => {
                            handleMouseLeave(0)
                        }}

                        onClick={() => {
                            openModal2('Leads con respuesta negativa')
                        }}

                        className={`border border-gray-300 border-solid rounded min-w-115px py-4 px-4 me-8 mb-3 ${isHovered && cardSelected === 5 ? 'bg-gray-200' : ''} cursor-pointer`}
                    >
                        <div className="d-flex align-items-center">
                            <KTIcon iconName="toggle-on" className="fs-3 text-danger me-2" />
                            <div className="fs-2 fw-bolder me-2">
                                {crmReportItem.totalLeadsBadResponse}
                            </div>
                            <div className="fw-bold fs-6 text-gray-400">Respuesta negativas
                            </div>
                        </div>
                    </div>
                </OverlayTrigger>
                {/* End: Total de leads con respuesta negativa */}
            </div>

            <Modal
                show={showModal1}
                className="modal-xl"
                scrollable
                onHide={() => {
                    closeModal1('')
                }}
            >
                <Modal.Header 
                    closeButton
                    onClick={() => {
                        closeModal1('')
                    }}
                >
                    <Modal.Title>
                        <h1>{cardTitleSelected}</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <CrmReportGeneralTable cardSelected={cardSelected}/>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="reset"
                        className="btn btn-sm btn-secondary"
                        onClick={() => {
                            closeModal1('')
                        }}
                    >
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModal2}
                className="modal-xl"
                scrollable
                onHide={() => {
                    closeModal2('')
                }}
            >
                <Modal.Header 
                    closeButton
                    onClick={() => {
                        closeModal2('')
                    }}
                >
                    <Modal.Title>
                        <h1>{cardTitleSelected}</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <CrmReportResponsesTable cardSelected={cardSelected} />
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="reset"
                        className="btn btn-sm btn-secondary"
                        onClick={() => {
                            closeModal2('')
                        }}
                    >
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export { LeadCountsItem }