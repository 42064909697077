import React, { FC, useEffect, useState } from 'react'
import { LoadingAnimation } from '../../../../../../utils/components/loading/LoadingAnimation'
import { CallLeadDetail, Call, Lead, LeadDetailInfo } from '../../../../interfaces/leadsTypes'

type Props = {
  leadData: LeadDetailInfo | undefined;
  currentNavSelected: string;
}

export const CallsNav: FC<Props> = ({
  leadData,
  currentNavSelected
}) => {

  console.log('se esta renderizando el componente llamadas')
  console.log(currentNavSelected)
  
  //Estado inicial del item de llamadas para evitar datos undefined
  const initialCallsItem: CallLeadDetail = {
    calls: [],
    callsCount: 0,
  }

  //Constante para manejar el item con el listado de llamadas
  const [callsItem, setCallsItem] = useState<CallLeadDetail>(initialCallsItem);

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(true);
  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>("");

  //Funcion para verificar el tamaño del arreglo de llamadas
  const checkCallsListLength = () => {
    //Seteamos mensaje informativo
    callsItem.calls.length === 0 && setInfoMessageLoading("No se encontro ningún registro de llamadas")
  }

  //Funcion para obtener el registro de llamadas por id de lead
  const getCallsByLead = () => {
    setTimeout(() => {
      try {
        const newCallsList =
        {
          calls: [
            {
              callId: '3456343',
              callDateAdd: '2024-05-02 10:14:02',
              callChannel: 'Facebook Ads',
              callSource: 4,
              callDuration: 25,
              callLeadId: leadData?.leadId,
              callLeadName: leadData?.leadData.leadName,
              callLeadStatus: 'Colgó',
              callPhone: leadData?.leadData.leadPhone,
              callSellerId: leadData?.SellerId,
              callSellerName: leadData?.SellerName,
              callSellerStatus: 'Completada',
              callRecording: 'https://app.dragoncem.com',
              callOrigin: '',
            },
            {
              callId: '3456342',
              callDateAdd: '2024-05-02 10:10:00',
              callChannel: 'Facebook Ads',
              callSource: 1,
              callDuration: 0,
              callLeadId: leadData?.leadId,
              callLeadName: leadData?.leadData.leadName,
              callLeadStatus: 'Sin enlace',
              callPhone: leadData?.leadData.leadPhone,
              callSellerId: leadData?.SellerId,
              callSellerName: leadData?.SellerName,
              callSellerStatus: 'Fuera de horario',
              callRecording: '',
              callOrigin: '',
            }
          ],
          callsCount: 2,
        };

        setCallsItem(newCallsList);
        //Ocultamos la animacion de carga
        setIsLoading(false);
        //Verificamos el tamaño del arreglo de llamadas disparando la funcion
        checkCallsListLength()

      } catch (error) {
        console.log('Error obteniedo las llamadas: ', error)
        //Ocultamos la animacion de carga
        setIsLoading(false);
        //Seteamos mensaje informativo
        setInfoMessageLoading('Hubo un error en la petición')
      }
    }, 2000);

  }

  /*Disparamos la funcion de obtencion de registros al 
  montar el componente*/
  useEffect(() => {
    getCallsByLead();
  }, [])

  return (
    <>
      <div className='container ml-15 mt-4 py-12' id='llamadas-container'>

        {/* Si isLoading es igual a 'true' lo mostramos */}
        {isLoading && <div className='py-12'><LoadingAnimation alignment='center' label='Obteniendo registros de llamadas...' /></div>}

        {/* Si isLoading es diferente a 'true' y el arreglo de llamadas es mayor a '0' montamos información */}
        {!isLoading && callsItem.calls.length > 0 && (
          <>
            <div className="mb-8">
              <h4>Total de llamadas: {callsItem.callsCount}</h4>
            </div>

            {callsItem.calls.map((call: Call, index: number) => (
              <div key={index} className='timeline timeline-1 my-8'>
                <div className='timeline-sep bg-primary-opacity-20'></div>
                <div className='timeline-item'>
                  <div className='text-muted'>{call.callDateAdd}</div>
                  <div className='timeline-content font-weight-normal ml-6'>
                    <p>
                      <strong>ID de llamada: </strong>{call.callId}
                    </p>
                    <p>
                      <strong>Origen de la llamada: </strong>
                      {call.callSource === 1 ? (
                        <span>Medio Online</span>
                      ) : call.callSource === 2 ? (
                        <span>Medio Offline</span>
                      ) : call.callSource === 3 ? (
                        <span>Whatsapp Usuario</span>
                      ) : call.callSource === 4 ? (
                        <span>Whatsapp Vendedor</span>
                      ) : <p></p>}
                    </p>
                    <p>
                      <strong>Duración: </strong>{call.callDuration}
                    </p>
                    <p>
                      <strong>Estatus: </strong>
                      {call.callLeadStatus === 'Completada' ? (
                        <span className='badge badge-light-success fw-bolder fs-7 px-2 py-1 ml-4'>{call.callLeadStatus}</span>
                      ) : call.callLeadStatus === 'Ocupado' || call.callLeadStatus === 'Colgó' ? (
                        <span className='badge badge-light-warning fw-bolder fs-7 px-2 py-1 ml-4'>{call.callLeadStatus}</span>
                      ) : call.callLeadStatus === 'No disponible' || call.callLeadStatus === 'cancelled' || call.callLeadStatus === 'Sin enlace' ? (
                        <span className='badge badge-light-danger fw-bolder fs-7 px-2 py-1 ml-4'>{call.callLeadStatus}</span>
                      ) : (
                        <span className='badge badge-light-info fw-bolder fs-7 px-2 py-1 ml-4'>{call.callLeadStatus}</span>
                      )}
                    </p>
                    <p>
                      <strong>Vendedor: </strong>
                      {call.callSellerStatus === 'Completada' ? (
                        <span className='badge badge-light-success fw-bolder fs-7 px-2 py-1 ml-4'>{call.callSellerStatus}</span>
                      ) : call.callSellerStatus === 'Ocupado' || call.callSellerStatus === 'Colgo' ? (
                        <span className='badge badge-light-warning fw-bolder fs-7 px-2 py-1 ml-4'>{call.callSellerStatus}</span>
                      ) : call.callSellerStatus === 'No disponible' || call.callSellerStatus === 'cancelled' ? (
                        <span className='badge badge-light-danger fw-bolder fs-7 px-2 py-1 ml-4'>{call.callSellerStatus}</span>
                      ) : (
                        <span className='badge badge-light-info fw-bolder fs-7 px-2 py-1 ml-4'>{call.callSellerStatus}</span>
                      )}
                    </p>
                    <p>
                      {call.callRecording !== '' ? (
                        <a href={call.callRecording} className='btn-sm btn btn-primary font-weight-bold mr-2'>Escuchar grabación</a>
                      ) : (
                        <a href='' className='btn-sm btn btn-danger font-weight-bold mr-2'>Sin grabación</a>
                      )}
                    </p>
                    <div className='separator separator-solid separator-border-1'></div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        {/* //Si isLoading es diferente a 'true' y el arreglo de llamadas es iguala '0' mostramos mensaje de error */}
        {!isLoading && callsItem.calls.length === 0 && (
          <div className='py-12'><p>{infoMessageLoading}</p></div>
        )}

      </div>
      
    </>
  )
}
