// Importamos constantes para realizar la peticion a a la API productos (iscore)
import { apiDragon, DragonMainApi, EPProductIscoreList } from "../../../utils/constants/_Constants";

// Importamos funcion para get
import { apiGetDeletePetitions } from "../../../../_metronic/helpers/apis";

// Funcion para obtener el listado de iscore por id de producto
async function productIscoreList(userToken: string, idProduct: string){

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi+EPProductIscoreList+idProduct}`, {

            /* Los parametros se mandan como undefined
            ya que estos se envian solo en peticiones de eliminacion 'delete' */
            undefined

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        },
        
        'get');

        const jsonDataIscoreList = data;

        return jsonDataIscoreList;

    } catch (error) {

        throw error;

    }

}

export { productIscoreList }