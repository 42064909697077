/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useLocation} from 'react-router'

const AssignProductsForm: React.FC = () => {
  const location = useLocation()

  return (
    <div className='container-sm text-center' style={{ maxWidth: '30%' }}>
        <div className='mb-7 mx-auto mt-0 mb-0'>
            <label className='required fw-bold fs-6 mb-5'>Asignado</label>
            {/* Roles */}
            <div className='fv-row'>
              {/* Radio y Label en diferentes columnas */}
              <div className='d-flex'>
                {/* Radio */}
                <div className='form-check form-check-custom form-check-solid'>
                  {/* Input */}
                  <input
                    className='form-check-input me-3'
                    type='checkbox'
                    value='Administrator'
                    id=''
                  />
                </div>
                {/* End Radio */}
                
                {/* Label */}
                <label className='form-check-label fs-6 d-flex align-items-center' htmlFor=''>
                  <div className='fw-bolder text-gray-800'>
                  <span className='me-1'>1058 |</span>
                    <span>DragonCEM ChatBotLead</span>
                  </div>
                </label>
                {/* End Label */}
              </div>
              {/* End Radio y Label en diferentes columnas */}
            </div>
            {/* End Input row */}
            <div className='separator separator-dashed my-5'></div>
            <div className='fv-row'>
              {/* Radio y Label en diferentes columnas */}
              <div className='d-flex'>
                {/* Radio */}
                <div className='form-check form-check-custom form-check-solid'>
                  {/* Input */}
                  <input
                    className='form-check-input me-3'
                    type='checkbox'
                    value='Administrator'
                    id=''
                  />
                </div>
                {/* End Radio */}
                
                {/* Label */}
                <label className='form-check-label fs-6 d-flex align-items-center' htmlFor=''>
                  <div className='fw-bolder text-gray-800'>
                  <span className='me-1'>733 |</span>
                    <span>EVANGELISTAS</span>
                  </div>
                </label>
                {/* End Label */}
              </div>
              {/* End Radio y Label en diferentes columnas */}
            </div>
            {/* End Input row */}
            <div className='separator separator-dashed my-5'></div>
            {/* Input row */}
            <div className='d-flex fv-row'>
              {/* Radio */}
              <div className='form-check form-check-custom form-check-solid'>
                {/* Input */}
                <input
                  className='form-check-input me-3'
                  type='checkbox'
                  value='Analyst'
                  id='kt_modal_update_role_option_2'
                />

                {/* End Input */}
                {/* Label */}
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_2'>
                  <div className='fw-bolder text-gray-800'>314 | Estrategias Comerciales</div>
                </label>
                {/* End Label */}
              </div>
              {/* End Radio */}
            </div>
            {/* End Input row */}
            <div className='separator separator-dashed my-5'></div>
            {/* Input row */}
            <div className='d-flex fv-row'>
              {/* Radio */}
              <div className='form-check form-check-custom form-check-solid'>
                {/* Input */}
                <input
                  className='form-check-input me-3'
                  type='checkbox'
                  value='Trial'
                  id='kt_modal_update_role_option_4'
                />
                {/* End Input */}
                {/* Label */}
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_4'>
                  <div className='fw-bolder text-gray-800'>733 | EVANGELISTAS</div>
                </label>
                {/* End Label */}
              </div>
              {/* End Radio */}
            </div>
            {/* End Input row */}
            <button
            type='reset'
            className='btn btn-sm btn-primary me-3 mt-5'
            >
            Guardar Cambios
          </button>
          </div>
        </div>
  );
}

export {AssignProductsForm}
