import React from 'react'

type Props = {
  userIcon: string,
  userName: string,
  userMail: string,
  userLastConnection: string
}

export const SingleChatA: React.FC<Props> = ({userIcon, userName, userMail, userLastConnection}) => {
  return (
<>
<div className='d-flex flex-stack py-4'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px symbol-circle'>
                        <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                        {`${userIcon}`}
                        </span>
                      </div>
                      <div className='ms-5'>
                        <a
                          href='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'
                        >
                          {`${userName}`}
                        </a>
                        <div className='fw-bold text-gray-400'>{`${userMail}`}</div>
                      </div>
                    </div>
                    <div className='d-flex flex-column align-items-end ms-2'>
                      <span className='text-muted fs-7 mb-1'>{`${userLastConnection}`}</span>
                    </div>
                  </div>
                  <div className='separator separator-dashed d-none'></div>
</>

  )
}
