// Importamos constantes para realizar la peticion a la API de marcas
import {DragonMainApi, EPBrandDeactivate, apiDragon} from '../../../utils/constants/_Constants'

// ---------------------------------------------------------------------------------------

// Importamos funcion para obtener codigos de respuesta del servidor
import {apiGetDeletePetitions} from '../../../../_metronic/helpers/apis'

//Función para realizar la petición a la API de desactivación de marcas
async function brandStatusDeactivate(brand_id: string, userToken: string) {
  try {
    const data = await apiGetDeletePetitions(
      apiDragon,
      `${DragonMainApi + EPBrandDeactivate + brand_id}`,{},
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'delete')

    const jsonDataBrandsDeactivate = data

    return jsonDataBrandsDeactivate
  } catch (error) {
    throw error
  }
}

export {brandStatusDeactivate}
