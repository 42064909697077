import {useState, ChangeEvent, useEffect, useContext, ReactElement} from 'react'
import '../../../../../_metronic/assets/styles/PhoneInput.css'
import { HitosHour } from '../HitosHour'
import { Nav } from 'react-bootstrap'

// En este paso se almacenarán os horarios de los hitos por día
function Step4Rol({ data, onNext, handlePrev, prevData, setStep4Data }) {
  const [selectedTab, setSelectedTab] = useState('Lunes');
  const [schedules, setSchedules] = useState(prevData?.schedules || []);
  const [scheduleUpdated, setScheduleUpdated] = useState(false);

  const handleTabSelect = (tabKey) => {
    setSelectedTab(tabKey);
  };

  const handleNext = () => {
    onNext({
      schedules
    });
    setStep4Data({
      schedules
    });
  };

  useEffect(() => {
    if (scheduleUpdated) {
      handleNext();
      setScheduleUpdated(false);
    }
  }, [scheduleUpdated]);

  const replicateSchedule = async () => {
    console.log(schedules)
    const selectedIndex = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'].indexOf(selectedTab);
    const selectedSchedule = schedules[0];
    const updatedSchedules = schedules.map((daySchedule, index) => (
      index === selectedIndex ? selectedSchedule : [...daySchedule, ...selectedSchedule.filter(newHito => !daySchedule.some(oldHito => oldHito.start === newHito.start && oldHito.end === newHito.end))]
    ));
    setSchedules(updatedSchedules);
    setScheduleUpdated(true);
  };
  
    return (
      <>
        <div>
          <Nav variant='tabs' activeKey={selectedTab} onSelect={handleTabSelect}>
            <Nav.Item className='w-10'>
            <Nav.Link eventKey='Lunes'active={selectedTab === 'Lunes'}   style={{ fontSize: '0.75rem' }}>
                Lunes
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='w-10'>
              <Nav.Link eventKey='Martes' style={{ fontSize: '0.75rem' }}>
                Martes
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='w-10'>
              <Nav.Link eventKey='Miércoles' style={{ fontSize: '0.75rem' }}>
                Miércoles
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='w-10'>
              <Nav.Link eventKey='Jueves' style={{ fontSize: '0.75rem' }}>
                Jueves
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='w-10'>
              <Nav.Link eventKey='Viernes' style={{ fontSize: '0.75rem' }}>
                Viernes
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='w-10'>
              <Nav.Link eventKey='Sábado' style={{ fontSize: '0.75rem' }}>
                Sábado
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='w-10'>
            <Nav.Link eventKey='Domingo' style={{ fontSize: '0.75rem' }}>
                Domingo
              </Nav.Link>
            </Nav.Item>
            
          </Nav>
  
          <div className='tab-content mt-4'>
            <div id='Lunes' className={selectedTab === 'Lunes' ? 'tab-pane show active' : 'tab-pane'}>
              <HitosHour day='Lunes' schedules={schedules} setSchedules={setSchedules} prevData={prevData} />
            </div>
            <div id='Martes' className={selectedTab === 'Martes' ? 'tab-pane show active' : 'tab-pane'}>
              <HitosHour day='Martes' schedules={schedules} setSchedules={setSchedules} prevData={prevData} />
            </div>
            <div id='Miércoles' className={selectedTab === 'Miércoles' ? 'tab-pane show active' : 'tab-pane'}>
              <HitosHour day='Miércoles' schedules={schedules} setSchedules={setSchedules} prevData={prevData} />
            </div>
            <div id='Jueves' className={selectedTab === 'Jueves' ? 'tab-pane show active' : 'tab-pane'}>
              <HitosHour day='Jueves' schedules={schedules} setSchedules={setSchedules} prevData={prevData} />
            </div>
            <div id='Viernes' className={selectedTab === 'Viernes' ? 'tab-pane show active' : 'tab-pane'}>
              <HitosHour day='Viernes' schedules={schedules} setSchedules={setSchedules} prevData={prevData} />
            </div>
            <div id='Sábado' className={selectedTab === 'Sábado' ? 'tab-pane show active' : 'tab-pane'}>
              <HitosHour day='Sábado' schedules={schedules} setSchedules={setSchedules} prevData={prevData} />
            </div>
            <div id='Domingo' className={selectedTab === 'Domingo' ? 'tab-pane show active' : 'tab-pane'}>
              <HitosHour day='Domingo' schedules={schedules}
                        setSchedules={setSchedules}
                        prevData={prevData} />
            </div>
            
          </div>
        </div>

        {/* Mostrar el botón solo si el día actual es 'Lunes' */}
      {selectedTab === 'Lunes' && (
        <button className="btn btn-primary btn-sm mt-6" onClick={replicateSchedule}>
          Replicar horario
        </button>
      )}

      <br />
  
        <button className='btn btn-light btn-sm mt-6 me-2' onClick={handlePrev}>
          Anterior
        </button>
  
        <button className='btn btn-primary btn-sm mt-6' onClick={handleNext}>
          Siguiente
        </button>
      </>
    );
  }
  

export {Step4Rol}