// Importamos constantes para realizar la peticion a la API de productos
import {DragonMainApi, EPClientStatusDeactivate, apiDragon} from '../../../utils/constants/_Constants'

// ---------------------------------------------------------------------------------------
// Importamos funcion para obtener codigos de respuesta del servidor
import {apiGetDeletePetitions} from '../../../../_metronic/helpers/apis'

//Función para realizar la petición a la API de activación de un producto
async function AMClientStatusDeactivate(client_id: string, userToken: string) {
  try {
    const data = await apiGetDeletePetitions(
      apiDragon,
      `${DragonMainApi + EPClientStatusDeactivate + client_id}`,
      {},
      {
        'Content-Type': 'application/json',
        'x-token': userToken,
      },
      'delete'
    )

    const jsonDataClientStatusDeactivate = data

    return jsonDataClientStatusDeactivate
  } catch (error) {
    throw error
  }
}

export {AMClientStatusDeactivate}
