import React, {FC, useRef, useEffect} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../../../_metronic/partials'
import {getCSS, getCSSVariableValue} from '../../../../../../_metronic/assets/ts/_utils'
import {CampaignReport} from '../interfaces/campaignsReportTypes'
import {dataExtract} from '../../../../../utils/functions/_Functions'
import {equalizeArraysRangesLead} from '../../../../../utils/functions/_Functions'
import {LeadByDay} from '../interfaces/campaignsReportTypes'
import {combinateDatesLead} from '../../../../../utils/functions/_Functions'

type Props = {
  campaignReportItemROne: CampaignReport
  campaignReportItemRTwo: CampaignReport
}

const CampaignsReportLeadsChart: FC<Props> = ({campaignReportItemROne, campaignReportItemRTwo}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const c_type = 'bar'
    // const serieOptRangeOne = dataExtract(campaignReportItemROne.lead.leadByDay, "leads")
    // const serieOptRangeTwo = dataExtract(campaignReportItemRTwo.lead.leadByDay, "leads")

    const [equalizedRange1, equalizedRange2] = equalizeArraysRangesLead(
      campaignReportItemROne.lead.leadsByDay,
      campaignReportItemRTwo.lead.leadsByDay,
      'No disponible',
      null
    )
    const combinedDates = combinateDatesLead(
      campaignReportItemROne.lead.leadsByDay,
      campaignReportItemRTwo.lead.leadsByDay
    )

    const serieOptions = dataExtract(combinedDates, 'date')
    // const serieOptions = [
    //     '1/abril-1/mayo', '2/abril-2/mayo', '3/abril-3/mayo', '4/abril-4/mayo',
    //     '5/abril-5/mayo', '6/abril-6/mayo', '7/abril-7/mayo', '8/abril-8/mayo',
    //     '9/abril-9/mayo', '10/abril-10/mayo', '11/abril-11/mayo', '12/abril-12/mayo',
    //     '13/abril-13/mayo', '14/abril-14/mayo', '15/abril-15/mayo', '16/abril-16/mayo',
    //     '17/abril-17/mayo', '18/abril-18/mayo', '19/abril-19/mayo', '20/abril-20/mayo',
    //     '21/abril-21/mayo', '22/abril-22/mayo', '23/abril-23/mayo', '24/abril-24/mayo',
    //     '25/abril-25/mayo', '26/abril-26/mayo', '27/abril-27/mayo', '28/abril-28/mayo',
    //     '29/abril-29/mayo', '30/abril-30/mayo', '31/abril-31/mayo'
    // ];

    const serieCounts = []

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, c_type, serieOptions, serieCounts, equalizedRange1, equalizedRange2)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  return (
    <>
      {/* // <><p>hola</p></> */}
      <div className='card-xxl-stretch mb-5 mb-xl-10'>
        <div className=''>
          <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}} />
        </div>
      </div>
    </>
  )
}

export {CampaignsReportLeadsChart}

function getChartOptions(
  height: number,
  c_type: any,
  serieOptions: any,
  serieCounts: any,
  equalizedRange1: any,
  equalizedRange2: any
) {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  const extractedRange1 = dataExtract(equalizedRange1, 'leads')
  const extractedRange2 = dataExtract(equalizedRange2, 'leads')

  return {
    series: [
      {
        name: 'Rango 1',
        // data: [19, 64, 11, 26, 35, 43, 46, 69, 53, 28, 26, 39, 52, 77, 83, 60, 73, 86, 61, 38, 36, 63, 21, 46, 59, 32, 74, 46, 95, 26, 45],
        data: extractedRange1,
        color: '#7cb5ec', // Color para la serie 'Rango 1'
      },
      {
        name: 'Rango 2',
        // data: [79, 50, 33, 23, 44, 91, 14, 68, 58, 18, 42, 89, 83, 27, 52, 17, 30, 75, 12, 96, 38, 87, 60, 10, 95, 81, 67, 46, 20, 73, 25],
        data: extractedRange2,
        color: '#90ed7d', // Color para la serie 'Rango 2'
      },
    ],
    chart: {
      type: c_type,
      height: '450',
      fontFamily: 'inherit',
      events: {},
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        export: {
          csv: {
            filename: 'reporte-campañas-leads',
          },
          svg: {
            filename: 'reporte-campañas-leads',
          },
          png: {
            filename: 'reporte-campañas-leads',
          },
        },
      },
    },
    plotOptions: {
      bar: {
        // columnWidth: '45%',
        horizontal: false,
        columnWidth: '85%',
        borderRadius: 5,
      },
      series: {
        colors: ['#7cb5ec', '#90ed7d'], // Colores para las series en orden correspondiente
      },
    },
    legend: {
      show: true,
      fontFamily: 'inherit',
      fontSize: '13px',
      labels: {
        colors: labelColor,
      },
      position: 'top',
      horizontalAlign: 'center',
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: serieOptions,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return 'Total de leads en el día: ' + val
        },
      },
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
