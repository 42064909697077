import { createContext } from "react";

// Define el tipo para el contexto si es aplicable
type User = {
    user: {
        crm_id: number,
        user_type: string,
        acc_rdwr: boolean,
        usr_data: {
            usr_name: string,
            usr_phone: number,
            wha_phone: string,
            usr_mail: string,
            sms_phone: string
        },
        calendly: string,
        status: boolean,
        date_add: string,
        date_modify: string,
        chatwab_status: boolean,
        products_assigned: Array<object>,
        product_shared: Array<object>,
        clients_shared: Array<any>,
        privilage_user_type: number | undefined,
        uid: string,
        cli_id: string,
        cli_name: string,
        sessionStatus: boolean
    },
 token: string;
 updateToken: (newToken : string | undefined) => void; // Nueva función para actualizar el token

};


// Proporciona un valor inicial o un valor por defecto para el contexto
const initialUser: User = {
        user: {
            crm_id: 0,
            user_type: "",
            acc_rdwr: false,
            usr_data: {
                usr_name: "",
                usr_phone: 0,
                wha_phone: "",
                usr_mail: "",
                sms_phone: ""
            },
            calendly: "",
            status: true,
            date_add: "",
            date_modify: "",
            chatwab_status: false,
            products_assigned: [],
            product_shared: [],
            clients_shared: [],
            privilage_user_type: 0,
            uid: "",
            cli_id: "",
            cli_name: "",
            sessionStatus: false
        },
     token: "",
     updateToken: () => {}, // Función inicial, se reemplazará al utilizar el proveedor
};

export const UserContext = createContext<User>(initialUser);
