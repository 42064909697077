// Importamos constantes con rutas de api
import { apiDragon, DragonMainApi, EPCampaignsReport } from '../../../../../utils/constants/_Constants'
// Importamos funcion para realizar la peticion
import { apiGetDeletePetitions } from '../../../../../../_metronic/helpers/apis'

// Funcion para realizar la peticion al endpoint
export const campaignsReportPetitionNoPeriods = async (
    userToken: string,     
    clientId : string, 
    productId:  string, 
    campaignId : string                                                                                                                                                                                                 
) => {
    try {
        const data = await apiGetDeletePetitions(
            apiDragon,
            `${DragonMainApi + EPCampaignsReport + clientId}`,
            {
                proId : productId, 
                camId : campaignId
            },
            {
                'Content-Type': 'application/json',
                'x-token': userToken,
            },
            'get'
        )

        const jsonDataCampaignReport = data
        return jsonDataCampaignReport
    } catch (error) {
        console.error('Hubo un error en la peticion: ', error)
        throw error
    }
}

