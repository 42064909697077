import { FC, useRef, useState, useEffect } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { Modal } from 'react-bootstrap'
import { IscoreLeadData, iscoreReport } from '../interfaces/iscoreReportTypes'
import { useThemeMode } from '../../../../../../_metronic/partials'
import { getCSS, getCSSVariableValue } from '../../../../../../_metronic/assets/ts/_utils'
import { IscoreReportByIscoreTable } from '../tables/IscoreReportByIscoreTable'
import { useIntl } from 'react-intl'
import { IscoreReportCountsTable } from '../tables/IscoreReportCountsTable'

type Props = {
  iscoreReportItemCampaignOne: any
  iscoreReportItemCampaignTwo: any
  selectedNameCampaignOne: string
  selectedNameCampaignTwo: string
  selectedIdCampaignOne: string
  selectedIdCampaignTwo: string
  selectedIdProduct: string
}

const IscoreReportComparativeChart: FC<Props> = ({
  iscoreReportItemCampaignOne,
  iscoreReportItemCampaignTwo,
  selectedNameCampaignOne,
  selectedNameCampaignTwo,
  selectedIdCampaignOne,
  selectedIdCampaignTwo,
  selectedIdProduct
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const intl = useIntl();

  const initialIscoreLeadData: IscoreLeadData = {
    data: [],
  }

  const initialIscoreReportData: iscoreReport = {
    data: [initialIscoreLeadData],
    totalLeads: 0,
  }

  const categoriesArray = iscoreReportItemCampaignOne.data.map(item => item.category)

  const [optionTypeIntSelected, setOptionTypeIntSelected] = useState<string>('')
  const [optionNumberSelected, setOptionNumberSelected] = useState<number>(0)
  const [optionTextSelected, setOptionTextSelected] = useState<string>('')
  const [optionCampaignSelected, setOptionCampaignSelected] = useState<string>('')
  const [campaignOnFocus, setCampaignOnFocus] = useState()
  const [cardTitleSelected, setCardTitleSelected] = useState<string>('');

  const [showModal, setShowModal] = useState<boolean>(false)

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setOptionCampaignSelected('')
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, iscoreReportItemCampaignOne, iscoreReportItemCampaignTwo])

  const aggregateData = (leads) => {
    const interactionTotals: { [key: string]: number } = {
      WithoutInt: 0,
      LowInt: 0,
      MediumInt: 0,
      HighInt: 0,
      VeryHighInt: 0,
    };

    // leads.forEach((lead) => {
    //   interactionTotals[lead.interactionType] += lead.count;
    // });

    return interactionTotals;
  };

  // const toChangeIscoreTexts = (intType: string) => {
  //   return intl.formatMessage({ id: `REPORT.ISCORE.${intType}` });
  // };

  const handleSelection = (indexSelection: number | undefined, campaignSelection: string | undefined, series: any) => {
    console.log(series)
    if (indexSelection !== undefined && indexSelection >= 0) {

      const categorySelected = String(categoriesArray[indexSelection])

      setCardTitleSelected(categorySelected)

      if (series.cat === 'campaignOne'){
        setCampaignOnFocus(iscoreReportItemCampaignOne)
      }else{
        setCampaignOnFocus(iscoreReportItemCampaignTwo)
      }
      // const interactionTypes = ['WithoutInt', 'LowInt', 'MediumInt', 'HighInt', 'VeryHighInt']
      // const interactionType = interactionTypes[indexSelection] // Obtener tipo de interacción
      // const leads = initialIscoreReportData.iscoreLead.filter(lead => lead.interactionType === interactionType)
      // const id = leads.length > 0 ? leads[0].id : 0

      // setOptionNumberSelected(id)
      // setOptionTextSelected(toChangeIscoreTexts(interactionType))
      // setOptionTypeIntSelected(interactionType) // Establecer tipo de interacción seleccionada

      // if (campaignSelection === selectedNameCampaignOne) {
      //   setOptionCampaignSelected(selectedIdCampaignOne)
      // } else if (campaignSelection === selectedNameCampaignTwo) {
      //   setOptionCampaignSelected(selectedIdCampaignTwo)
      // }

      openModal()
    } else {
      console.log('Indice inválido o no definido')
    }
  }

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const c_type = 'bar'

    const interactionTotalsOne = aggregateData(iscoreReportItemCampaignOne);
    const serieOptionsOne = Object.keys(interactionTotalsOne);
    const iscoreInteracctionsTexts = categoriesArray;

    const serieCountsOne = iscoreReportItemCampaignOne.data.map(item => item.count);

    const interactionTotalsTwo = aggregateData(iscoreReportItemCampaignTwo);
    const serieOptionsTwo = Object.keys(interactionTotalsTwo);
    const serieCountsTwo = iscoreReportItemCampaignTwo.data.map(item => item.count);

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(
        height,
        c_type,
        iscoreReportItemCampaignOne,
        iscoreReportItemCampaignTwo,
        serieOptionsOne,
        iscoreInteracctionsTexts,
        serieCountsOne,
        serieOptionsTwo,
        serieCountsTwo,
        selectedNameCampaignOne,
        selectedNameCampaignTwo,
        setOptionNumberSelected,
        setOptionTextSelected,
        setOptionCampaignSelected,
        openModal,
        initialIscoreReportData,
        // toChangeIscoreTexts, // Pasamos la función aquí también
        aggregateData,
        handleSelection // Pasamos la función aquí
      )
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  return (
    <>
      <div className='card-xxl-stretch mb-5 mb-xl-10'>
        <div className=''>
          <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '350px' }} />
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal} className='modal-xl' scrollable>
        <Modal.Header
          closeButton
          onClick={() => {
            closeModal()
          }}
        >
          <Modal.Title>
            <div className='d-flex'>
              <div className='me-2'>
                <h1>{cardTitleSelected} </h1>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IscoreReportCountsTable
            selectedIdProduct={selectedIdProduct}
            iscoreReportItemByProduct={campaignOnFocus}
            cardTitleSelected={cardTitleSelected}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type='reset'
            className='btn btn-primary btn-sm'
            onClick={() => {
              closeModal()
            }}
          >
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export { IscoreReportComparativeChart }

function getChartOptions(
  height: number,
  c_type: any,
  iscoreReportItemCampaignOne: any,
  iscoreReportItemCampaignTwo: any,
  serieOptionsOne: any,
  iscoreInteracctionsTexts: any,
  serieCountsOne: any,
  serieOptionsTwo: any,
  serieCountsTwo: any,
  selectedNameCampaignOne: string,
  selectedNameCampaignTwo: string,
  setOptionNumberSelected,
  setOptionTextSelected,
  setOptionCampaignSelected,
  openModal,
  initialIscoreReportData: iscoreReport,
  // toChangeIscoreTexts: (intType: string) => string, // Pasamos la función aquí también
  aggregateData: any,
  handleSelection: (indexSelection: number | undefined, campaignSelection: string | undefined, series: any) => void // Agregamos la función aquí
) {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  return {
    series: [
      {
        name: selectedNameCampaignOne,
        cat: 'campaignOne',
        group: 'Campaigns',
        data: serieCountsOne,
      },
      {
        name: selectedNameCampaignTwo,
        cat: 'campaignTwo',
        group: 'Campaigns',
        data: serieCountsTwo,
      },
    ],
    chart: {
      type: c_type,
      height,
      fontFamily: 'inherit',
      stacked: true,
      events: {
        dataPointSelection: function (event, chartContext, config) {
          const series: any = chartContext.w.config.series[config.seriesIndex]
          const campaignSelection: string = series.name
          const indexSelection: number = config.dataPointIndex
          handleSelection(indexSelection, campaignSelection, series)
        },
      },
    },
    toolbar: {
      show: true,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
      },
      export: {
        csv: {
          filename: 'Reporte-iscore',
        },
        svg: {
          filename: 'Reporte-iscore',
        },
        png: {
          filename: 'Reporte-iscore',
        },
      },
    },
    colors: ['#00C1DE', '#FF6C0E'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: true,
      fontFamily: 'inherit',
      fontSize: '13px',
      labels: {
        colors: labelColor,
      },
      position: 'top',
      horizontalAlign: 'left',
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: iscoreInteracctionsTexts,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      lables: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          tytpe: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' leads'
        },
      },
    },
    grid: {
      borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
