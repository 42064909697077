/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: src/app/modules/Auth/pages/AuthPage, src/app/BasePage).
 */

import {FC, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import React, {useEffect, useContext} from 'react'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
// import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {AuthPage} from '../auth/AuthPage'
import {UserContext} from '../app-context/user-context/UserContext'
// Importar el servicio que valida si el token es válido
import {validateToken} from './services/_ValidateToken'
import PrivacyNotice from '../auth/aux-components/PrivacyNotice'
import { FooterLayout } from '../auth/aux-components/FooterLayout'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const [validToken, setValidToken] = useState<boolean>(true)
  const [showLoading, setShowLoading] = useState<boolean>(true)
  const {user, token} = useContext(UserContext)
  let userToken = token
  const sessionStatus = user.sessionStatus

  //TODO: VALIDAR SI EXISTEN PARAMETROS EN LA URL, SI SI CAMBIAR LA RUTA DEFAULT (leads).
  const pathname = window.location.pathname
  const params = new URLSearchParams(window.location.search)
  const leadId = params.get('leadId')
  let path: string | null = null

  if (!localStorage.getItem('leadId') && leadId) {
    const newUrl = `${pathname}?${params.toString()}`
    localStorage.setItem('leadPath', newUrl)
  } else if (localStorage.getItem('leadPath') && leadId) {
    path = localStorage.getItem('leadPath')
  }

  //Funcion para conectar con el endpoint para validar el token
  const getValidateToken = async (): Promise<void> => {
    try {
      const {code} = await validateToken(userToken)

      setShowLoading(false)
      if (userToken && code !== 200) {
        //Cambiar el estado que valida el token
        setValidToken(false)
        return
      }
    } catch (error) {
      console.log('Error receiving token status: ', error)
    }
  }
  // Hook: solo se ejcutará tras montar la aplicación o refrescar la pantalla.
  useEffect(() => {
    //Ejecutar solo al montar la app por primera vez.
    getValidateToken()
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        {showLoading ? null : (
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<AuthPage />} />
            {sessionStatus === true && validToken ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to={path ? path : '/leads'} />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
                <Route element={<FooterLayout/>}>
                  <Route path='privacy-notice' element={<PrivacyNotice />} />
                </Route>
              </>
            )}
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
