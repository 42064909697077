import axios from 'axios';
import { DragonMainApi, EPValidateEmailWhats, apiDragon } from '../constants/_Constants';
import { apiPetitions } from '../../../_metronic/helpers/apis';

async function ValidateEmailAndWhats(whatsapp: string, mail: string, userToken: string){
        try{
            const response = await apiPetitions (apiDragon,DragonMainApi+EPValidateEmailWhats, {    
                whatsapp,
                mail,

            }, {
                
                    'Content-Type': 'application/json',
                    'x-token': userToken
            },
            'post');

            const jsonDataClientsNew = response;

            // console.log(jsonDataClientsNew)

            return jsonDataClientsNew;


        } catch(error) {
            throw error;
            // console.log('Error de nuevo Cliente ', error)

        }
    }

    export { ValidateEmailAndWhats };