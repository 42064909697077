import { DragonMainApi, apiDragon, EPLeadReassign } from "../../../utils/constants/_Constants";

import { apiPetitions } from "../../../../_metronic/helpers/apis";

async function leadReassign(userToken: string, leadId: string, userAssigned: string, reassignReasson: string){

    try {
        const data = await apiPetitions(apiDragon, `${DragonMainApi+EPLeadReassign+leadId}`, {
            userAssigned
        }, {
            'Content-Type': 'application/json',
            'x-token': userToken
        },
        'put')

        const jsonDataLeadReassign = data;

        return jsonDataLeadReassign;

    }catch (error){
        throw error;
    }

}

export {leadReassign}