import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthPage} from './auth/AuthPage'
// import {AuthInit} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'

import { EndSessionBrowser } from './utils/components/session/EndSessionBrowser'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {/* <EndSessionBrowser /> */}
        <I18nProvider>
          <LayoutProvider>
            <ThemeModeProvider>
              <AuthPage />
                <Outlet />
                <MasterInit />
            </ThemeModeProvider>
          </LayoutProvider>
        </I18nProvider>
    </Suspense>
  )
}

export {App}
