// Importamos hooks de react que usaremos para este componente
import { useState } from "react";
// Importamos libreria para manejo de lenguaje 
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos interfaces de productos que usaremos en este componente
import { Step3Props } from "../../interfaces/productsTypes";

// Iniciamos la funcion para construir el componente Step3
function Step3({onNext, handlePrev, prevData, setStep3Data}: Step3Props) {
    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl();
    
    const [productCrm, setProductCrm] = useState(prevData?.productCrm || '');
    const [productWab, setProductWab] = useState(prevData?.productWab || '');

    const handleNext = () => {
        onNext({
            productCrm, 
            productWab
        });

        setStep3Data({ 
            productCrm, 
            productWab
        }); // Actualiza el estado en el componente principal
    }

    return (
        <div>
            <h2 className='modal-title mb-4'>{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.LABEL_PROMEDIA'})}</h2>
            <form>
                <div className='d-flex'>
                    <div className='col-6 me-2'>
                        <label className="form-label fw-bold" htmlFor="productCrm">CRM</label>
                        <input
                            type="text"
                            id="productCrm"
                            className='form-control form-control-solid mb-6'
                            placeholder={intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.INPUTCRM_PLACEHOLDER'})}
                            value={productCrm}
                            onChange={(e) => setProductCrm(e.target.value)}
                        />
                    </div>

                    <div className='col-6'>
                        <label className="form-label fw-bold" htmlFor="productWab">WAB</label>
                        <select 
                            id="productWab"
                            className='form-select form-select-solid mb-6'    
                            value={productWab} 
                            onChange={(e) => setProductWab(e.target.value)}>
                            <option value="">-- {intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.SELECTWAB_OPTION1'})} --</option>
                            <option value="+52550000000">+52550000000</option>
                            <option value="+52551111111">+52551111111</option>
                            <option value="+52552222222">+52552222222</option>
                        </select>
                    </div>
                </div>

                {/* <h2 className='modal-title mb-4'>{intl.formatMessage({ id:'PRODUCTS.MODAL_NEW.LABEL_PROROLE' })}</h2>
                <div className='d-flex'>
                    <div className='col-6 me-2'>
                    <label className="form-label fw-bold">{intl.formatMessage({ id:'PRODUCTS.MODAL_NEW.LABEL_PROROLE' })}</label>
                        <select className="form-select form-select-solid" name="roles">
                            <option value="">{intl.formatMessage({ id:'PRODUCTS.MODAL_NEW.SELECTROL_OPTION1' })}</option>
                            <option value="0">Rol abc</option>
                            <option value="1">Rol def</option>
                            <option value="2">Rol ghi</option>
                        </select>
                    </div>

                    <div className='col-6 mt-8'>
                        <span className="text-muted">{intl.formatMessage({ id:'GLOBAL.INDICATIONS.SELECTROLE' })}</span>
                    </div>
                </div> */}

            </form>
            {/* <button onClick={handleNext}>Siguiente</button> */}
            <button className="btn btn-light btn-sm mt-6 me-2" onClick={handlePrev}>{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.BUTTONLAST'})}</button>

            <button className="btn btn-primary btn-sm mt-6" onClick={handleNext}>{intl.formatMessage({id: 'PRODUCTS.MODAL_NEW.BUTTONNEXT'})}</button>

        </div>
    )
}

export {Step3};