import * as XLSX from 'xlsx'

export const DropdownExportCampaigns = ({dataToExport, title}) => {


const handleToExportDataXLSX = () => {
  try {
      // Verificar si hay datos para exportar
      if (dataToExport.length === 0) {
        console.error('No hay datos para exportar.')
        return
      }

          // Crear una nueva hoja de cálculo
    const wb = XLSX.utils.book_new();

      const worksheetData = [
        ['Datos wab','','','',''                                        ,'Canal','','',''                             ,'Token acceso','Id del producto', 'Id del cliente', 'Id de la campaña', 'Estatus de la campaña', 'iScore', 'Nombre', 'Notify','Call','Quality','Manual','Dupdays','Rol de campaña','Dcem','Plantilla mail','Seller mail template', 'CRM', 'Mailchimp','cam_menu','SMS template', 'Super duper', 'Anti flood', 'Fecha alta', 'fecha actualización', 'B2b bot'],
        ['Plantillas Wab','','Wab bot','Wab Qualitybot', 'Mensajes Wab', 'Canal campaña', 'Tipo', 'Bu', 'Id canal crm'],
        ['Número Wab','Fecha Alta']
      ]

          // Iterar sobre los datos de exportación
    dataToExport.forEach(item => {
      
      // Agregar los datos de web_data
      const wab_data = item.wab_data;

      // Agregar los datos de channel
      const channel = item.channel;

      const accessToken = item.accessToken;
      const pro_id = item.pro_id;
      const cli_id = item.cli_id;
      const cam_id = item.cam_id;
      const cam_status = item.cam_status;
      const iscore = item.iscore;
      const name = item.name;

      // Convertir los correos electrónicos en una cadena separada por comas
      const notify = item.notify.join(', ');

      const call = item.call;
      const quality = item.quality;
      const manual = item.manual;
      const dupdays = item.dupdays;
      const cam_role = item.cam_role;
      const dcem = item.dcem;
      const mail_template = item.mail_template;
      const seller_mailtemplate = item.seller_mailtemplate;
      const crm = item.crm;
      const mailchimp = item.mailchimp;
      const cam_menu = item.cam_menu;
      const sms_template = item.sms_template;
      const super_duper = item.super_duper;
      const anti_flood = item.anti_flood;
      const date_add = item.date_add;
      const date_updated = item.date_updated;
      const b2b_bot = item.b2b_bot;




worksheetData.push([wab_data.wab_templates.wab_number,wab_data.wab_templates.date_add,wab_data.wab_bot,wab_data.wab_qualitybot,wab_data.wab_messages, channel.cam_channel, channel.type, channel.bu, channel.channel_crm_id,accessToken, pro_id, cli_id, cam_id, cam_status, iscore, name,notify,call, quality, manual, dupdays, cam_role, dcem, mail_template, seller_mailtemplate, crm, mailchimp, cam_menu, sms_template, super_duper, anti_flood, date_add, date_updated, b2b_bot ]);

});

      // Crear la hoja de cálculo
      const ws = XLSX.utils.aoa_to_sheet(worksheetData);

        // Fusionar las celdas para cada propiedad
        const mergeRanges = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }, // datos wab
          { s: { r: 0, c: 5 }, e: { r: 0, c: 8 } }, // canal
          { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } }, // PLantillas wab
          { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } }, // Wab bot
          { s: { r: 1, c: 3 }, e: { r: 2, c: 3 } }, // Wab qualitybot
          { s: { r: 1, c: 4 }, e: { r: 2, c: 4 } }, // Mensajes wab
          { s: { r: 1, c: 5 }, e: { r: 2, c: 5 } }, // canal campaña
          { s: { r: 1, c: 6 }, e: { r: 2, c: 6 } }, // tipo
          { s: { r: 1, c: 7 }, e: { r: 2, c: 7 } }, // Bu
          { s: { r: 1, c: 8 }, e: { r: 2, c: 8 } }, // Id canal crm
          { s: { r: 0, c: 9 }, e: { r: 2, c: 9 } },
          { s: { r: 0, c: 10 }, e: { r: 2, c: 10 } },
          { s: { r: 0, c: 11 }, e: { r: 2, c: 11 } },
          { s: { r: 0, c: 12 }, e: { r: 2, c: 12 } },
          { s: { r: 0, c: 13 }, e: { r: 2, c: 13 } },
          { s: { r: 0, c: 14 }, e: { r: 2, c: 14 } },
          { s: { r: 0, c: 15 }, e: { r: 2, c: 15 } },
          { s: { r: 0, c: 16 }, e: { r: 2, c: 16 } },
          { s: { r: 0, c: 17 }, e: { r: 2, c: 17 } },
          { s: { r: 0, c: 18 }, e: { r: 2, c: 18 } },
          { s: { r: 0, c: 19 }, e: { r: 2, c: 19 } },
          { s: { r: 0, c: 20 }, e: { r: 2, c: 20 } },
          { s: { r: 0, c: 21 }, e: { r: 2, c: 21 } },
          { s: { r: 0, c: 22 }, e: { r: 2, c: 22 } },
          { s: { r: 0, c: 23 }, e: { r: 2, c: 23 } },
          { s: { r: 0, c: 24 }, e: { r: 2, c: 24 } },
          { s: { r: 0, c: 25 }, e: { r: 2, c: 25 } },
          { s: { r: 0, c: 26 }, e: { r: 2, c: 26 } },
          { s: { r: 0, c: 27 }, e: { r: 2, c: 27 } },
          { s: { r: 0, c: 28 }, e: { r: 2, c: 28 } },
          { s: { r: 0, c: 29 }, e: { r: 2, c: 29 } },
          { s: { r: 0, c: 30 }, e: { r: 2, c: 30 } },
          { s: { r: 0, c: 31 }, e: { r: 2, c: 31 } },
          { s: { r: 0, c: 32 }, e: { r: 2, c: 32 } },
          { s: { r: 0, c: 33 }, e: { r: 2, c: 33 } },
        ]
        ws['!merges'] = mergeRanges;

            // Agregar la hoja de cálculo al libro de trabajo
    XLSX.utils.book_append_sheet(wb, ws, 'Campañas');

        // Guardar el libro de trabajo en un archivo XLSX
        XLSX.writeFile(wb, 'Data exportada de campañas.xlsx');
      } catch (error) {
        console.error('Error al generar el archivo XLSX:', error);
      }

}

const handleToExportDataCSV = () => {
  try {
    // Verificar si hay datos para exportar
    if (dataToExport.length === 0) {
      console.error('No hay datos para exportar.');
      return;
    }

    // Crear el contenido del archivo CSV
    let csvContent = '';

    // Agregar la cabecera del CSV
    csvContent += 'Datos wab,,,,,,Canal,,,,,,Token acceso,Id del producto,Id del cliente,Id de la campaña,Estatus de la campaña,iScore,Nombre,Notify,Call,Quality,Manual,Dupdays,Rol de campaña,Dcem,Plantilla mail,Seller mail template,CRM,Mailchimp,cam_menu,SMS template,Super duper,Anti flood,Fecha alta,fecha actualización,B2b bot\n';
    csvContent += ',,,Wab bot,Wab Qualitybot,Mensajes Wab,Canal campaña,Tipo,Bu,Id canal crm\n';
    csvContent += 'Número Wab,Fecha Alta\n';

    // Iterar sobre los datos de exportación
    dataToExport.forEach(item => {
      const wabData = item.wab_data;
      const channel = item.channel;

      const accessToken = item.accessToken;
      const proId = item.pro_id;
      const cliId = item.cli_id;
      const camId = item.cam_id;
      const camStatus = item.cam_status;
      const iScore = item.iscore;
      const name = item.name;
      const notify = item.notify.join(', ');
      const call = item.call;
      const quality = item.quality;
      const manual = item.manual;
      const dupdays = item.dupdays;
      const camRole = item.cam_role;
      const dcem = item.dcem;
      const mailTemplate = item.mail_template;
      const sellerMailTemplate = item.seller_mailtemplate;
      const crm = item.crm;
      const mailchimp = item.mailchimp;
      const camMenu = item.cam_menu;
      const smsTemplate = item.sms_template;
      const superDuper = item.super_duper;
      const antiFlood = item.anti_flood;
      const dateAdd = item.date_add;
      const dateUpdated = item.date_updated;
      const b2bBot = item.b2b_bot;

      csvContent += `${wabData.wab_templates.wab_number},${wabData.wab_templates.date_add},${wabData.wab_bot},${wabData.wab_qualitybot},${wabData.wab_messages},${channel.cam_channel},${channel.type},${channel.bu},${channel.channel_crm_id},${accessToken},${proId},${cliId},${camId},${camStatus},${iScore},${name},${notify},${call},${quality},${manual},${dupdays},${camRole},${dcem},${mailTemplate},${sellerMailTemplate},${crm},${mailchimp},${camMenu},${smsTemplate},${superDuper},${antiFlood},${dateAdd},${dateUpdated},${b2bBot}\n`;
    });

    // Generar el archivo CSV
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'Data_exportada_de_campañas.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error al generar el archivo CSV:', error);
  }
};

  const handleToExportDataJSON = () => {
    try {
      // Verificar si hay datos para exportar
      if (dataToExport.length === 0) {
        console.error('No hay datos para exportar.')
        return
      }

      // Convertir los datos a formato JSON
      const jsonData = JSON.stringify(dataToExport, null, 2)

      // Crear un Blob a partir de los datos JSON
      const blob = new Blob([jsonData], {type: 'application/json'})

      // Crear un objeto URL a partir del Blob
      const url = window.URL.createObjectURL(blob)

      // Crear un enlace de descarga
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${title}.json`)
      document.body.appendChild(link)

      // Simular un clic en el enlace para iniciar la descarga
      link.click()

      // Limpiar el objeto URL y el enlace
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    } catch (error) {
      console.error('Error al exportar a JSON:', error)
    }
  }

  const generateXLSXFile = () => {
    try {
      // Crear una nueva hoja de cálculo
      const wb = XLSX.utils.book_new();
  
      // Crear una matriz para almacenar los datos
      const worksheetData = [
        ['Dirección', '', '', '',         'Datos del Producto','', '','', '', '', '','', '', '',                                             'Datos Twilio', '', '', '', '', ''                         ,'Pro id', 'CRM id', 'Status','Nombre','Tipo','Fecha alta','Fecha baja','Sellwab status','Sms status','Wab_mdl status','Active campaigns'],
        ['País', 'Estado', 'CDMX', 'GAM', 'Precio','Correos','Leads Duplicados', 'Parser','Intro','Virtual', 'Web', 'Gmaps', 'Waze','Legals','Access id', 'Acces at', 'Wab', 'Sell Wab', 'SMS', 'Callid'],
      ];
  
      // Crear la hoja de cálculo
      const ws = XLSX.utils.aoa_to_sheet(worksheetData);
  
      // Fusionar las celdas para cada propiedad
      const mergeRanges = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }, // Dirección
        { s: { r: 0, c: 4}, e: { r: 0, c: 13 } }, // Productos
        { s: { r: 0, c: 14}, e: { r: 0, c: 19 } }, // Twilio
        { s: { r: 0, c: 20}, e: { r: 1, c: 20 } }, // ProId //Así solo ocupa 1 espacio
        { s: { r: 0, c: 21}, e: { r: 1, c: 21 } }, // CrmId //Así solo ocupa 1 espacio
        { s: { r: 0, c: 22}, e: { r: 1, c: 22 } }, // status //Así solo ocupa 1 espacio
        { s: { r: 0, c: 23}, e: { r: 1, c: 23 } }, // ProName //Así solo ocupa 1 espacio
        { s: { r: 0, c: 24}, e: { r: 1, c: 24 } }, // type //Así solo ocupa 1 espacio
        { s: { r: 0, c: 25}, e: { r: 1, c: 25 } }, // data_add //Así solo ocupa 1 espacio
        { s: { r: 0, c: 26}, e: { r: 1, c: 26 } }, // data_down //Así solo ocupa 1 espacio
        { s: { r: 0, c: 27}, e: { r: 1, c: 27 } }, // sellwab_status //Así solo ocupa 1 espacio
        { s: { r: 0, c: 28}, e: { r: 1, c: 28 } }, // sms_status //Así solo ocupa 1 espacio
        { s: { r: 0, c: 29}, e: { r: 1, c: 29 } }, // wab_mdl_status //Así solo ocupa 1 espacio
        { s: { r: 0, c: 30}, e: { r: 1, c: 30 } }, // campaignsActive //Así solo ocupa 1 espacio        
      ];
      ws['!merges'] = mergeRanges;
      
  
      // Ajustar el ancho de las columnas
      ws['!cols'] = [
        { width: 10 }, // Dirección
        { width: 12 }, // Productos
        { width: 12 }, // Twlio
        { width: 12 }, // ProId
      ];
  
      // Agregar la hoja de cálculo al libro de trabajo
      XLSX.utils.book_append_sheet(wb, ws, 'Datos');
  
      // Guardar el libro de trabajo en un archivo XLSX
      XLSX.writeFile(wb, 'tabla_vacia.xlsx');
    } catch (error) {
      console.error('Error al generar el archivo XLSX:', error);
    }
  };
  

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Exportar datos</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Formatos:</label>

          <div className='d-flex flex-column'>
            <button
              className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary mb-2'
              onClick={handleToExportDataCSV}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='22'
                height='22'
                fill='currentColor'
                className='bi bi-filetype-csv'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z'
                />
              </svg>
              <span className='ms-2'>Descargar .csv</span>
            </button>

            <button
              className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary mb-2'
              onClick={handleToExportDataXLSX}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='22'
                height='22'
                fill='currentColor'
                className='bi bi-filetype-xlsx'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z'
                ></path>
              </svg>
              <span className='ms-2'>Descargar .xlsx</span>
            </button>

            <button
              className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary mb-2'
              onClick={handleToExportDataJSON}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='22'
                height='22'
                fill='currentColor'
                className='bi bi-filetype-json'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM4.151 15.29a1.2 1.2 0 0 1-.111-.449h.764a.58.58 0 0 0 .255.384q.105.073.25.114.142.041.319.041.245 0 .413-.07a.56.56 0 0 0 .255-.193.5.5 0 0 0 .084-.29.39.39 0 0 0-.152-.326q-.152-.12-.463-.193l-.618-.143a1.7 1.7 0 0 1-.539-.214 1 1 0 0 1-.352-.367 1.1 1.1 0 0 1-.123-.524q0-.366.19-.639.192-.272.528-.422.337-.15.777-.149.456 0 .779.152.326.153.5.41.18.255.2.566h-.75a.56.56 0 0 0-.12-.258.6.6 0 0 0-.246-.181.9.9 0 0 0-.37-.068q-.324 0-.512.152a.47.47 0 0 0-.185.384q0 .18.144.3a1 1 0 0 0 .404.175l.621.143q.326.075.566.211a1 1 0 0 1 .375.358q.135.222.135.56 0 .37-.188.656a1.2 1.2 0 0 1-.539.439q-.351.158-.858.158-.381 0-.665-.09a1.4 1.4 0 0 1-.478-.252 1.1 1.1 0 0 1-.29-.375m-3.104-.033a1.3 1.3 0 0 1-.082-.466h.764a.6.6 0 0 0 .074.27.5.5 0 0 0 .454.246q.285 0 .422-.164.137-.165.137-.466v-2.745h.791v2.725q0 .66-.357 1.005-.355.345-.985.345a1.6 1.6 0 0 1-.568-.094 1.15 1.15 0 0 1-.407-.266 1.1 1.1 0 0 1-.243-.39m9.091-1.585v.522q0 .384-.117.641a.86.86 0 0 1-.322.387.9.9 0 0 1-.47.126.9.9 0 0 1-.47-.126.87.87 0 0 1-.32-.387 1.55 1.55 0 0 1-.117-.641v-.522q0-.386.117-.641a.87.87 0 0 1 .32-.387.87.87 0 0 1 .47-.129q.265 0 .47.129a.86.86 0 0 1 .322.387q.117.255.117.641m.803.519v-.513q0-.565-.205-.973a1.46 1.46 0 0 0-.59-.63q-.38-.22-.916-.22-.534 0-.92.22a1.44 1.44 0 0 0-.589.628q-.205.407-.205.975v.513q0 .562.205.973.205.407.589.626.386.217.92.217.536 0 .917-.217.384-.22.589-.626.204-.41.205-.973m1.29-.935v2.675h-.746v-3.999h.662l1.752 2.66h.032v-2.66h.75v4h-.656l-1.761-2.676z'
                />
              </svg>
              <span className='ms-2'>Descargar .json</span>
            </button>

            {/* <button
              className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary mb-2'
              onClick={generateXLSXFile}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='22'
                height='22'
                fill='currentColor'
                className='bi bi-filetype-json'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM4.151 15.29a1.2 1.2 0 0 1-.111-.449h.764a.58.58 0 0 0 .255.384q.105.073.25.114.142.041.319.041.245 0 .413-.07a.56.56 0 0 0 .255-.193.5.5 0 0 0 .084-.29.39.39 0 0 0-.152-.326q-.152-.12-.463-.193l-.618-.143a1.7 1.7 0 0 1-.539-.214 1 1 0 0 1-.352-.367 1.1 1.1 0 0 1-.123-.524q0-.366.19-.639.192-.272.528-.422.337-.15.777-.149.456 0 .779.152.326.153.5.41.18.255.2.566h-.75a.56.56 0 0 0-.12-.258.6.6 0 0 0-.246-.181.9.9 0 0 0-.37-.068q-.324 0-.512.152a.47.47 0 0 0-.185.384q0 .18.144.3a1 1 0 0 0 .404.175l.621.143q.326.075.566.211a1 1 0 0 1 .375.358q.135.222.135.56 0 .37-.188.656a1.2 1.2 0 0 1-.539.439q-.351.158-.858.158-.381 0-.665-.09a1.4 1.4 0 0 1-.478-.252 1.1 1.1 0 0 1-.29-.375m-3.104-.033a1.3 1.3 0 0 1-.082-.466h.764a.6.6 0 0 0 .074.27.5.5 0 0 0 .454.246q.285 0 .422-.164.137-.165.137-.466v-2.745h.791v2.725q0 .66-.357 1.005-.355.345-.985.345a1.6 1.6 0 0 1-.568-.094 1.15 1.15 0 0 1-.407-.266 1.1 1.1 0 0 1-.243-.39m9.091-1.585v.522q0 .384-.117.641a.86.86 0 0 1-.322.387.9.9 0 0 1-.47.126.9.9 0 0 1-.47-.126.87.87 0 0 1-.32-.387 1.55 1.55 0 0 1-.117-.641v-.522q0-.386.117-.641a.87.87 0 0 1 .32-.387.87.87 0 0 1 .47-.129q.265 0 .47.129a.86.86 0 0 1 .322.387q.117.255.117.641m.803.519v-.513q0-.565-.205-.973a1.46 1.46 0 0 0-.59-.63q-.38-.22-.916-.22-.534 0-.92.22a1.44 1.44 0 0 0-.589.628q-.205.407-.205.975v.513q0 .562.205.973.205.407.589.626.386.217.92.217.536 0 .917-.217.384-.22.589-.626.204-.41.205-.973m1.29-.935v2.675h-.746v-3.999h.662l1.752 2.66h.032v-2.66h.75v4h-.656l-1.761-2.676z'
                />
              </svg>
              <span className='ms-2'>Descargar .Test</span>
            </button> */}
          </div>
        </div>

        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  )
}
