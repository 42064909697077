import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { SellersHeader } from '../control-sellers/sellers/aux-components/UsersHeader';
import { NavDetails } from './NavDetails';
import { AssignProductsForm } from './AssignProductForm';
import { ListsWidget5, ChartsWidget1 } from '../../../_metronic/partials/widgets';

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Details',
    path: '/details/sellers/performance',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DetailsPage = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <SellersHeader />
            <NavDetails />
            <Outlet />
          </>
        }
      >
        <Route
          path='performance'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Rendimiento</PageTitle>
              <div className='d-flex align-items-start'>
                <ListsWidget5 className='mb-3 me-2' />
                <ChartsWidget1 className='card-xxl-stretch w-75' />
              </div>
            </>
          }
        />
        <Route
          path='assignproducts'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Asingación de Productos</PageTitle>
              <AssignProductsForm />
            </>
          }
        />
        <Route index element={<Navigate to='/details/sellers/performance' />} />
      </Route>
    </Routes>
)
};

export { DetailsPage};