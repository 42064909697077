// aquio debe de ir la peticion para obtener la data del reporte
import { apiDragon, DragonMainApi, EPIscoreReport } from "../../../../../utils/constants/_Constants";
import { apiGetDeletePetitions } from "../../../../../../_metronic/helpers/apis";

export const iscoreReportDataPetition = async (userToken?, clientId?, productId?, campaignId?, startdate?, endDate?) => {

    try {
        const data = await apiGetDeletePetitions(apiDragon,`${DragonMainApi+EPIscoreReport+productId}`, {
            dateStart: startdate,
            dateEnd: endDate,
            ...(campaignId && { camId: campaignId }),
            cliId: clientId
        },
        {
            'Content-Type': 'application/json',
            'x-token': userToken,
        },
            'get'
        );

        const jsonDataIscoreReport = data 
        return jsonDataIscoreReport;
    }catch(error){
        console.error('Hubo un error en la peticion: ', error)
    throw error

    }
}