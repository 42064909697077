import { DragonMainApi, EPProductIscoreEdit, apiDragon } from '../../../utils/constants/_Constants';
import { apiPetitions } from '../../../../_metronic/helpers/apis';

export async function productsIscoreEditLead (
    updateLeadSeller: Boolean,
    emailValido: number,
    emailAbierto: number,
    emailEntregado: number,
    llamadaValido: number,
    llamadaAceptada: number,
    llamadaGrabacion: number,
    llamadaContestada: number,
    whatsappCita: number,
    whatsappHablar: number,
    whatsappHolabot: number,
    whatsappInbound: number,
    whatsappVirtual: number,
    whatsappWebsite: number,
    whatsappWhattxt: number,
    whatsappBrochure: number,
    whatsappCalifica: number,
    whatsappWhatsapp: number,
    whatsappUbicacion: number,
    userToken: string,
    idProduct: string,
){

    try {
        const response = await apiPetitions(apiDragon,`${DragonMainApi+EPProductIscoreEdit+idProduct}`, {
            
            updateLeadSeller: updateLeadSeller,

            email:{
                valido: emailValido,
                abierto: emailAbierto,
                entregado: emailEntregado,
            },
            llamada: {
                valido: llamadaValido,
                aceptada: llamadaAceptada,
                grabacion: llamadaGrabacion,
                contestada: llamadaContestada,
            },
            whatsapp: {
                cita: whatsappCita,
                hablar: whatsappHablar,
                holabot: whatsappHolabot,
                inbound: whatsappInbound,
                virtual: whatsappVirtual,
                website: whatsappWebsite,
                whattxt: whatsappWhattxt,
                brochure: whatsappBrochure,
                califica: whatsappCalifica,
                whatsapp: whatsappWhatsapp,
                ubicacion: whatsappUbicacion,
            }
            
        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        }, 'put');

        const jsonDataProductIscoreEdit = response;

        return jsonDataProductIscoreEdit;

    }catch(error){
        throw error;
    } 
}

export async function productsIscoreEditSeller (
    updateLeadSeller: boolean,
    mailAbierto: number,
    llamadaGrabacion: number,
    whatsappEditar: number,
    whatsappHablar: number,
    whatsappIscore: number,
    whatsappHolabot: number,
    whatsappWhattxt: number,
    whatsappCalifica: number,
    whatsappWhatsapp: number,
    whatsappComentarios: number,
    userToken: string,
    idProduct: string,
){

    try {
        const response = await apiPetitions(apiDragon,`${DragonMainApi+EPProductIscoreEdit+idProduct}`, {
            
            updateLeadSeller: updateLeadSeller,

            email:{
                mailAbierto: mailAbierto,
            },
            llamada:{
                grabacion: llamadaGrabacion,
            },
            whatsapp:{
                editar: whatsappEditar,
                hablar: whatsappHablar,
                iscore: whatsappIscore,
                holabot: whatsappHolabot,
                whattxt: whatsappWhattxt,
                califica: whatsappCalifica,
                whatsapp: whatsappWhatsapp,
                comentarios: whatsappComentarios,
            }

        }, {

            'Content-Type': 'application/json',
            'x-token': userToken

        }, 'put');

        const jsonDataProductIscoreEdit = response;

        return jsonDataProductIscoreEdit;

    }catch(error){
        throw error;
    }
}