// Importamos constantes y funciones necesarias
import { apiDragon, DragonMainApi, EPProductsByClientList } from '../../../utils/constants/_Constants'
import { apiGetDeletePetitions } from '../../../../_metronic/helpers/apis'

// Iniciamos funcion asincronica amProductsByClientList para obtener el listado de productos por cliente compartido
export const amProductsByClientList = async (userToken: string, clientId: string): Promise<any> => {

    try {

        const data = await apiGetDeletePetitions(apiDragon, `${DragonMainApi + EPProductsByClientList + clientId}`, {

            /* Los parametros se mandan como undefined
            ya que estos se envian solo en peticiones de eliminacion 'delete' */
            undefined  


        }, {
             // Encabezados de la solicitud que incluyen el token de usuario y el tipo de contenido
             'Content-Type': 'application/json',
             'x-token': userToken
        }, 'get');

        const jsonDataProductByClientBUList: any = data;

        return jsonDataProductByClientBUList;

    } catch (error: any) {

        // throw error;

        console.log('error getting products by shared client list: ', error);
    }

}