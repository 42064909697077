//Este es el principal

import React from 'react'
import {Link} from 'react-router-dom'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {TwlioTemplatesMainTable} from './tables/TwlioTemplatesMainTable'
import {KTIcon} from '../../../../_metronic/helpers'

export const TwlioTemplates = () => {
  const renderTooltip = (props) => <Tooltip {...props}>Agregar plantilla para WhatsApp</Tooltip>
  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2'>WhatsApp Message Templates</h1>
            </div>

            <p>
              To start conversationes with WhatsApp users, you must use an approved sender and the
              outbound message must follow a message template. Learn more about templates .
            </p>
            <OverlayTrigger placement='bottom' overlay={renderTooltip}>
              <Link to={'/twlio-services/twlio-templates/AddTemplatesForm'}>
                <button className='btn btn-primary mb-3'>
                  New message template
                  <KTIcon iconName='black-right' />
                </button>
              </Link>
            </OverlayTrigger>
            <div className='card card-custom'>
              <TwlioTemplatesMainTable />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
